interface IReturnPercentage {
  formatted: string;
  percent: number;
}

const computePercentage = (value: number, total: number): IReturnPercentage => {
  const percent = value / total;
  return {
    formatted: percent.toLocaleString('en', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      style: 'percent',
    }),
    percent: percent * 100,
  };
};

export { computePercentage };
