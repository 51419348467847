import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

import blackDoor from '../../assets/not-found/black-door.png'

export const NotFound: FC = () => {
  return (
    <Box
      mx="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      pt={{
        xs: '56px',
        sm: '128px', //eslint-disable-line
        lg: '56px', //eslint-disable-line
      }}
      pb={{
        xs: '19px',
        sm: '175px', //eslint-disable-line
        lg: '19px', //eslint-disable-line
      }}
      minHeight={{
        lg: 'calc(100vh - 136px)'
      }}
    >
      <Grid
        justifyContent="center"
        alignItems="center"
        columns={{
          xs: 4,
          sm: 8, //eslint-disable-line
          lg: 12 //eslint-disable-line
        }}
        columnSpacing={{ lg: 4 }}
        container
      >
        <Grid item xs={4} sm={7} position="relative">
          <Box
            mb={{ xs: 6, sm: 0 }} //eslint-disable-line
            sx={{
              left: {
                sm: 0
              },
              position: {
                sm: 'absolute'
              },
              top: {
                sm: '50%',
                lg: '40%', //eslint-disable-line
              },
              transform: {
                sm: 'translateY(-50%)'
              },
              width: {
                sm: '50%'
              },
            }}
          >
            <Typography variant="display1" component="h1" fontWeight={700} mb={1}>
              404
            </Typography>

            <Typography variant="h1" component="h2" fontWeight={700} color="grey.700" mb={{ sm: 2 }}>
              Page not found
            </Typography>

            <Typography variant="body1" width={{ sm: '75%' }}>
              The page you are looking for does not exist or you don't have access. If you think this is a mistake please contact a system administrator.
            </Typography>
          </Box>
          
          <Box
            width={{
              xs: '78%',
              sm: '70%' //eslint-disable-line
            }} 
            ml="auto"
            mr={{
              xs: 'auto',
              sm: 0 //eslint-disable-line
            }}
          >
            <img src={blackDoor} alt="Not Found" width="100%"/>
          </Box>

        </Grid>
      </Grid>
    </Box>
  );
};

