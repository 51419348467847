import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IconChevronRight } from '@tabler/icons';
import { FC, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { requireValidationContext } from '../../hooks/useRequireValidationOnLeave';
import { Primary } from '../../theme/tokens/colors'; //eslint-disable-line
import { IProps } from './Breadcrumbs.d';

export const Breadcrumbs: FC<IProps> = ({ current, linksList }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const requireValidation = useContext(requireValidationContext);

  return (
    <MUIBreadcrumbs
      maxItems={isMobile ? 2 : undefined}
      separator={<IconChevronRight size={20} />}
      sx={{
        '.MuiBreadcrumbs-li': {
          display: 'flex',
          justifyContent: 'center',
        },
        '.MuiBreadcrumbs-separator': {
          marginLeft: 0,
          marginRight: 0,
        },
        '.MuiBreadcrumbs-separator svg': {
          fontSize: '20px',
        },
      }} //eslint-disable-line
    >
      {linksList.map(({ path, label, active = true }, index: number) =>
        active ? (
          <Link
            key={`breadcrumbs-${path}-${index}`}
            underline="hover"
            variant="caption"
            fontSize="12px"
            color={Primary[600]}
            component={RouterLink}
            to={path}
            onClick={(event) => requireValidation?.showValidationOnClick(event, path)}
          >
            {label}
          </Link>
        ) : (
          <Typography variant="caption" fontSize="12px" key={`breadcrumbs-${path}-${index}`}>
            {label}
          </Typography>
        )
      )}
      <Typography variant="caption" color="text.secondary" fontSize="12px">
        {current}
      </Typography>
    </MUIBreadcrumbs>
  );
};
