/* eslint-disable sort-keys */
import { Box, Link, List, Stack, Theme, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
  IconBriefcase,
  IconBuilding,
  IconClock,
} from '@tabler/icons';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link as RouterLink } from 'react-router-dom';

import { faBrokersAreas } from '../../../api/brokers';
import { EmptyInvestments } from '../../../components/EmptyStates';
import { MobileTableCard } from '../../../components/MobileTableCard';
import { Paginator } from '../../../components/Paginator';
import { StatusPointer } from '../../../components/ProjectInvestors';
import { Table } from '../../../components/Table';
import { UserDataCard } from '../../../components/UserDataCard';
import { formatDollars } from '../../../helpers/quantityBeautifier';
import { IInvestments } from '../FABrokers'; //eslint-disable-line
import { statusColors, statusDef } from './statusDefinition';

const dateFormat = 'MM/dd/yyyy';

const tableColumns: GridColDef[] = [
  {
    field: 'investor',
    headerName: 'Legal Name',
    renderCell: (params) => (
      <Link
        component={RouterLink}
        to={`/investors/${params.row.id}`}
        color="secondary"
        underline="hover"
        sx={{ cursor: 'pointer' }}
        noWrap
      >
        {params.value}
      </Link>
    ),
    sortable: false,
    minWidth: 150,
    flex: 2,
  },
  {
    field: 'project',
    headerName: 'Project',
    sortable: false,
    minWidth: 150,
    flex: 2,
  },
  {
    field: 'investment',
    headerAlign: 'right',
    headerName: 'Investment (USD)',
    sortable: false,
    type: 'number',
    valueFormatter: ({ value }) => value && formatDollars(value, true),
    minWidth: 150,
    flex: 2,
  },
  {
    align: 'right',
    field: 'date',
    headerAlign: 'right',
    headerName: 'Date',
    sortable: false,
    type: 'date',
    valueFormatter: ({ value }) => value && format(new Date(value), dateFormat),
    valueGetter: ({ value }) => value && new Date(value),
    minWidth: 125,
    flex: 2,
  },
  {
    field: 'commission',
    headerAlign: 'right',
    headerName: 'Commission',
    sortable: false,
    type: 'number',
    valueFormatter: ({ value }) => value && formatDollars(value, true),
    minWidth: 125,
    flex: 2,
  },
  {
    field: 'rate',
    headerAlign: 'right',
    headerName: 'Rate',
    sortable: false,
    type: 'number',
    valueFormatter: ({ value }) => value && `${value}%`,
    minWidth: 75,
    flex: 1,
  },
  {
    align: 'right',
    field: 'paymentDate',
    headerAlign: 'right',
    headerName: 'Payment Date',
    sortable: false,
    type: 'date',
    valueFormatter: ({ value }) => (value ? format(new Date(value), dateFormat) : '-'),
    valueGetter: ({ value }) => value && new Date(value),
    minWidth: 125,
    flex: 2,
  },
  {
    field: 'status',
    headerName: 'Commission Status',
    renderCell: ({ value }) => (
      <Box display="flex" alignItems="center" gap={1}>
        <StatusPointer status={statusColors[value]} />
        {statusDef[value]}
      </Box>
    ),
    sortable: false,
    minWidth: 175,
    flex: 2,
  },
];

const Investments: FC<IInvestments> = ({
  commissions,
  investments,
  onPageChange,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { promiseInProgress: loading } = usePromiseTracker({
    area: faBrokersAreas.getInvestmentsList,
  });
  const [mobilepage, setMobilepage] = useState(0);

  const changePage = (newPage: number) => {
    setMobilepage(newPage);
    onPageChange(newPage);
  };

  const createMobileTableCards = () => {
    const initialRage = mobilepage * 10;
    const finalRage = initialRage + 10;
    return investments.slice(initialRage, finalRage).map((investment) => ({
      data: {
        Investor: (
          <Link
            component={RouterLink}
            to={`/investors/${investment.id}`}
            color="secondary"
            underline="hover"
            sx={{ cursor: 'pointer' }}
          >
            {investment.investor}
          </Link>
        ),
        Project: investment.project,
        'Investment (USD)': formatDollars(investment.investment, true),
        Date: format(new Date(investment.date), dateFormat),
        Commission: formatDollars(investment.commission, true),
        Rate: `${investment.rate}%`,
        'Payment Date': investment.paymentDate
          ? format(new Date(investment.paymentDate), dateFormat)
          : '-',
        'Commission Status': (
          <Box display="flex" alignItems="center" gap={1}>
            <StatusPointer status={investment.status === 'paid' ? 'success' : 'default'} />
            {statusDef[investment.status]}
          </Box>
        ),
      },
      id: investment.id,
    }));
  };

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Stack direction="row" spacing={3} sx={{ mr: -3, pr: 3, overflow: 'auto' }}>
          <UserDataCard
            title={'Total Investment'}
            value={`${formatDollars(commissions?.totalInvested ?? 0, true)}`}
            icon={IconBuilding}
          />
          <UserDataCard
            title={'Commission Received'}
            value={`${formatDollars(commissions?.commissionReceived ?? 0, true)}`}
            icon={IconBriefcase}
          />
          <UserDataCard
            title={'Pending Commissions'}
            value={`${formatDollars(commissions?.commissionPending ?? 0, true)}`}
            icon={IconClock}
          />
        </Stack>
      </Box>
      {isMobile && (
        <>
          <List>
            {investments.length > 0 && !loading ? (
              <>
                {createMobileTableCards().map((row, i) => (
                  <MobileTableCard key={row.id + i} data={row.data} />
                ))}
              </>
            ) : (
              <EmptyInvestments />
            )}
          </List>
          <Paginator page={mobilepage} count={investments.length} limit={10} setPage={changePage} />
        </>
      )}
      {!isMobile && (
        <Table
          columns={tableColumns}
          rows={investments}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{ NoRowsOverlay: EmptyInvestments }}
          area={faBrokersAreas.getInvestmentsList}
          getRowId={(row) => investments.indexOf(row)}
          onPageChange={changePage}
        />
      )}
    </>
  );
};
export { Investments };
