import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

const LicencePlay: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33464 4.9974C3.33464 3.15645 4.82702 1.66406 6.66797 1.66406H15.8346C17.2153 1.66406 18.3346 2.78335 18.3346 4.16406C18.3346 5.54477 17.2153 6.66406 15.8346 6.66406H15.0013V15.8307C15.0013 17.2114 13.882 18.3307 12.5013 18.3307H5.0013C3.16035 18.3307 1.66797 16.8383 1.66797 14.9974V14.1641C1.66797 13.7038 2.04106 13.3307 2.5013 13.3307H3.33464V4.9974ZM3.33464 14.9974C3.33464 15.9179 4.08083 16.6641 5.0013 16.6641H10.1436C10.0514 16.4034 10.0013 16.1229 10.0013 15.8307V14.9974H3.33464ZM12.5013 16.6641C12.9615 16.6641 13.3346 16.291 13.3346 15.8307V4.16406C13.3346 3.87187 13.3848 3.59138 13.4769 3.33073H6.66797C5.74749 3.33073 5.0013 4.07692 5.0013 4.9974V13.3307H10.8346C11.2949 13.3307 11.668 13.7038 11.668 14.1641V15.8307C11.668 16.291 12.0411 16.6641 12.5013 16.6641ZM15.8346 3.33073C15.3744 3.33073 15.0013 3.70383 15.0013 4.16406V4.9974H15.8346C16.2949 4.9974 16.668 4.6243 16.668 4.16406C16.668 3.70383 16.2949 3.33073 15.8346 3.33073Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64228 5.86342C7.73013 5.81433 7.83765 5.81659 7.92336 5.86933L11.5345 8.09155C11.6166 8.14211 11.6667 8.23166 11.6667 8.32812C11.6667 8.42459 11.6166 8.51414 11.5345 8.5647L7.92336 10.7869C7.83765 10.8397 7.73013 10.8419 7.64228 10.7928C7.55443 10.7437 7.5 10.651 7.5 10.5503V6.1059C7.5 6.00527 7.55443 5.91251 7.64228 5.86342ZM8.05556 6.603V10.0532L10.8589 8.32812L8.05556 6.603Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4987 5.61141C7.67879 5.51077 7.89922 5.51541 8.07492 5.62353L11.686 7.84576C11.8544 7.94939 11.957 8.13298 11.957 8.33073C11.957 8.52847 11.8544 8.71206 11.686 8.8157L8.07492 11.0379C7.89922 11.146 7.67879 11.1507 7.4987 11.05C7.31861 10.9494 7.20703 10.7593 7.20703 10.553V6.10851C7.20703 5.90221 7.31861 5.71204 7.4987 5.61141ZM7.79036 6.13336V6.48138C7.81587 6.4272 7.85789 6.38122 7.91198 6.351C7.96606 6.32078 8.02725 6.30909 8.08676 6.31576L7.79036 6.13336ZM11.1017 8.17109C11.1322 8.21782 11.1492 8.2731 11.1492 8.33073C11.1492 8.38836 11.1322 8.44364 11.1017 8.49036L11.3611 8.33073L11.1017 8.17109ZM11.3803 8.34256C11.3803 8.34255 11.3803 8.34253 11.3802 8.34252L11.3803 8.34256ZM11.3802 8.31894C11.3803 8.31892 11.3803 8.31891 11.3803 8.3189L11.3802 8.31894ZM8.08676 10.3457C8.02725 10.3524 7.96606 10.3407 7.91198 10.3105C7.85789 10.2802 7.81587 10.2343 7.79036 10.1801V10.5281L8.08676 10.3457ZM8.34592 7.12756V9.53389L10.3011 8.33073L8.34592 7.12756Z"
      />
    </SvgIcon>
  );
};
export { LicencePlay };
