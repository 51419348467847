import { Stack, Typography } from '@mui/material';

import EmptyInvestorsImg from '../../assets/investors/no-holdings.png';

const EmptyInvestments = () => {
  return (
    <Stack height="350px" alignItems="center" justifyContent="center">
      <img width="220px" src={EmptyInvestorsImg} alt="there are no investors in this table" />
      <Typography mt={3} variant="h2" color="text.secondary">
        There are not investments yet
      </Typography>
    </Stack>
  );
};

export { EmptyInvestments };
