import { Box, SvgIcon, Typography } from '@mui/material';
import { IconUser } from '@tabler/icons';
import { FC } from 'react';

import { EmptySearch } from '../EmptyStates'; //eslint-disable-line
import type { IEmptySearch } from './InvestmentsEmptyState.d';

const InvestmentsEmptyState: FC<IEmptySearch> = ({ withoutInvestors }) => {
  if (withoutInvestors) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        py={10}
        sx={{
          borderBottom: ({ palette }) => ({ xs: 'none', md: `1px solid ${palette.grey[300]}` }), //eslint-disable-line
        }}
      >
        <SvgIcon sx={{ color: 'text.disabled', fontSize: '120px' }}>
          <IconUser />
        </SvgIcon>
        <Typography
          sx={{ maxWidth: '500px' }}
          textAlign="center"
          variant="h2"
          color="text.secondary"
          mt={5}
        >
          There are no investors participating in the project yet.
        </Typography>
      </Box>
    );
  } else {
    return <EmptySearch />;
  }
};

export { InvestmentsEmptyState };
