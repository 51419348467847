import { IFinancialReport } from '../../pages/ProjectDetail/ProjectDetail.d'

interface ITokensResponse {
  amount: number;
  userId: string;
}

interface Obj {
  [k: string]: string | number;
}

interface ITokenItem extends Obj {
  id: string;
  amount: number;
  name: string;
}

interface ITokensBalanceRes {
  investorId: string;
  amountOfTokens: string;
  walletType: string;
  walletAddress: string;
  walletStatus: string;
}
interface ITokensDistribution {
  id: string;
  name: string;
  tokens: number;
  walletType: string;
  address: string;
  whitelistStatus: string;
  url: string;
}

interface IProjectTokensRes {
  contractAddress: string;
  lastDateOfActivity: string;
  balances: ITokensBalanceRes[];
}
interface IProjectTokens {
  contractAddress: string;
  lastDateOfActivity: string;
  distributions: ITokensDistribution[];
}

interface ICreatePlanResponse {
  data: ITokensResponse[];
}

interface IApprovePlanRequest {
  projectId: string;
  tokenName: string;
  tokenSymbol: string;
}

interface ICreateTokenRequest {
  name: string;
  symbol: string;
}

interface ICreateTokensResponse {
  message: string;
  name: string;
  symbol: string;
}
interface IWalletResponse {
  investorId: string;
  walletId: string;
  walletType: 'METAMASK' | 'BUILT_IN';
  status: 'WHITELISTING' | 'WHITELISTED' | 'BLACKLISTING' | 'BLACKLISTED';
  associatedDate: Date;
}

interface IInvestorTokensResponse {
  investorId: string;
  projectId: string;
  tokens: string;
}

interface ISetFinancialReportReq {
  financialReports: IFinancialReport;
}

interface ISetFinancialReportRes {
  data: string;
  status: number;
}

interface ISetMetricsReq {
  projectId: string,
  actualAnnualReturn: number;
}

interface ISetMetricsRes {
  data: string;
  status: number;
}

enum TSmartContractStatus {
  PAUSED = 'paused',
  PAUSING = 'paused_loading',
  RESUMED = 'active',
  RESUMING = 'active_loading',
}

interface IGetSmartContractStatus {
  address: string;
  deployed: 'DESTROY_IN_PROGRESS' | 'DESTROYED';
  projectId: string;
  status: keyof typeof TSmartContractStatus;
}

export type {
  IApprovePlanRequest,
  ICreatePlanResponse,
  ICreateTokenRequest,
  ICreateTokensResponse,
  IGetSmartContractStatus,
  IInvestorTokensResponse,
  IProjectTokens,
  IProjectTokensRes,
  ISetFinancialReportReq,
  ISetFinancialReportRes,
  ISetMetricsReq,
  ISetMetricsRes,
  ITokenItem,
  ITokensBalanceRes,
  ITokensDistribution,
  ITokensResponse,
  IWalletResponse,
};
export { TSmartContractStatus };
