import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  IconAlertTriangle,
  IconArrowLeft,
  IconBuilding,
  IconCopy,
  IconEye,
  IconInfoCircle,
} from '@tabler/icons';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { duplicateProject } from '../../api/projects/projects';
import { PROJECT_STATUSES } from '../../helpers/constants';
import { havePermission } from '../../helpers/havePermission';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { authContext } from '../../hooks/useAuth/authContext';
import { requireValidationContext } from '../../hooks/useRequireValidationOnLeave';
import { toastContext } from '../../hooks/useToast';
import { Primary, Warning } from '../../theme/tokens/colors';
import { Breadcrumbs } from '../Breadcrumbs'; //eslint-disable-line
import { IHeaderProps } from './ProjectHeader.d';

export const ProjectHeader: FC<IHeaderProps> = ({
  name,
  contractStatus,
  projectData,
  projectStatus,
}) => {
  const { id } = useParams();
  const controller = useAbortRequest();
  const { userData } = useContext(authContext);
  const { openToast } = useContext(toastContext);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [duplicateCalled, setDuplicateCalled] = useState(false);
  const [hasWalletAndFinancialInfo, setHasWalletAndFinancialInfo] = useState(false);
  const [isDuplicateAllowed, setIsDuplicateAllowed] = useState(false);
  const financialRole = havePermission(userData?.roles ?? [], ['financial', 'superAdmin']);

  useEffect(() => {
    if (projectData) {
      setHasWalletAndFinancialInfo(
        Object.values(projectData.beneficiaryWallet || []).every((a) => a) &&
          Object.entries(projectData.financialInformation || {})
            .filter((e) => e[0] !== 'annualCash' && e[0] !== 'percentOverage' && e[0] !== 'financialReports')
            .every((a) => a[1])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  useEffect(() => {
    setIsDuplicateAllowed(
      projectStatus === PROJECT_STATUSES.DRAFT &&
        hasWalletAndFinancialInfo &&
        !duplicateCalled &&
        !!projectData?.canBeDuplicated &&
        financialRole
    );
  }, [projectData, projectStatus, hasWalletAndFinancialInfo, duplicateCalled, financialRole]);

  const handleMouseEnter = () => {
    if (!isDuplicateAllowed) {
      setIsTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const tooltipTitle = () => {
    let title = '';
    if (projectStatus !== PROJECT_STATUSES.DRAFT) {
      title += 'Project is not Draft.';
    }
    if (!hasWalletAndFinancialInfo) {
      title += `Project doesn't have Wallet of Financial information.`;
    }
    if (duplicateCalled) {
      title += 'Duplicate button already called.';
    }
    if (!projectData?.canBeDuplicated) {
      title += 'Project was already duplicated.';
    }
    if (!financialRole) {
      title += 'Only Super Admins and Financials can duplicate projects.';
    }

    return title;
  };

  const requireValidation = useContext(requireValidationContext);
  const goToProjects = () => requireValidation?.showValidationOnNavigation('/projects');
  const goToProjectPreview = () => {
    window.open(`${process.env.REACT_APP_INVESTORS_PROJECT_PREVIEW}/${id}?projectsPreview=on&cmsPreview=on`);
  };

  const showAlert =
    contractStatus &&
    ['paused', 'active_loading', 'paused_loading', 'destroy_in_progress', 'destroyed'].includes(
      contractStatus
    );
  const warningState = ['paused', 'destroyed'].includes(contractStatus ?? '');
  const alertColor = warningState ? Warning[600] : Primary[600];
  const warningAlert = useMemo(() => {
    if (contractStatus === 'paused') {
      return 'The smart contract for this project is paused. Secondary Market operations will be paused until contract is resumed.';
    } else {
      return "The smart contract for this project is closed. The tokens were redeemed and are not enabled in the investor's portfolio.";
    }
  }, [contractStatus]);
  const infoAlert = useMemo(() => {
    if (contractStatus === 'destroy_in_progress') {
      return 'The smart contract for this project is being closed. This process can take a few minutes.';
    } else {
      return `The smart contract is being ${
        contractStatus === 'active_loading' ? 'resumed' : 'paused'
      }. This process can take a few minutes.`;
    }
  }, [contractStatus]);

  const onDuplicateProject = async () => {
    const { data, status: statusCode } = await duplicateProject(id || '', controller.signal);
    if (!isRequestError(statusCode)) {
      setDuplicateCalled(true);
      openToast({
        message: 'Duplicate of the project was created successfully!',
        severity: 'success',
        title: '',
      });
    } else {
      const error = data as IServerError;
      openToast({
        message: 'There was an error with the duplication. Please refresh the page and try again.',
        severity: 'error',
        title: error.title as string,
      });
    }
  };

  return (
    <>
      <Box mb={4}>
        <Button
          color="secondary"
          onClick={goToProjects}
          startIcon={<IconArrowLeft />}
          sx={{ fontSize: '12px' }}
        >
          Back to project list
        </Button>
      </Box>
      <Breadcrumbs
        current="Project details"
        linksList={[
          { label: 'Home', path: '/' },
          { label: 'Projects', path: '/projects' },
        ]}
      />

      {showAlert && (
        <Alert
          severity={warningState ? 'warning' : 'info'}
          icon={
            warningState ? (
              <IconAlertTriangle color={Warning[600]} />
            ) : (
              <IconInfoCircle color={Primary[600]} />
            )
          }
          sx={{
            border: `1px solid ${alertColor}`,
            borderRadius: 2,
            color: alertColor,
            mt: 3,
          }}
        >
          {warningState ? warningAlert : infoAlert}
        </Alert>
      )}

      <Box sx={{ alignContent: 'center', flexGrow: 1 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          columns={{ md: 3, xs: 4 }}
          pt={4}
        >
          <Grid item xs={4} md={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <SvgIcon sx={{ color: 'secondary.main' }}>
                <IconBuilding />
              </SvgIcon>
              <Typography variant="h1">{name}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} md={1}>
            <Grid container columns={{ xs: 2 }} direction="row">
              <Grid item xs={1}>
                <span
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onFocus={handleMouseEnter}
                  onBlur={handleMouseLeave}
                >
                  <Tooltip title={tooltipTitle()} open={isTooltipVisible} placement="top" arrow>
                    <Button
                      sx={{
                        fontSize: '1.2rem',
                        m: { xs: 2, md: 0 }, //eslint-disable-line
                      }}
                      variant="outlined"
                      startIcon={<IconCopy />}
                      onClick={onDuplicateProject}
                      disabled={!isDuplicateAllowed}
                    >
                      Duplicate project
                    </Button>
                  </Tooltip>
                </span>
              </Grid>
              <Grid item xs={1}>
                <Button
                  sx={{
                    fontSize: '1.2rem',
                    m: { xs: 2, md: 0 }, //eslint-disable-line
                  }}
                  variant="outlined"
                  startIcon={<IconEye />}
                  onClick={goToProjectPreview}
                >
                  Preview project
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ pt: 2 }} />
    </>
  );
};
