import { FC } from 'react';

import { toastContext } from './toastContext';
import { useToast } from './useToast';

const ToastProvider: FC = ({ children }) => {
  const toastMethods = useToast();
  return <toastContext.Provider value={toastMethods}>{children}</toastContext.Provider>;
};
export { ToastProvider };
