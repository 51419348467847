import { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';

import {
  disconnectInvestor,
  faBrokersAreas,
  getInvestorsPerBroker,
  IInvestorsPerBroker,
} from '../../../api/brokers';
import { isRequestError } from '../../../helpers/isRequestError';
import { useAbortRequest } from '../../../hooks/useAbortRequest'; //eslint-disable-line
import { Investors } from './Investors';

const InvestorPresenter = () => {
  const { id } = useParams();
  const [investors, setInvestors] = useState<IInvestorsPerBroker[]>([]);
  const getInvestorsController = useAbortRequest();

  const setInvestorsPerBroker = useCallback(
    async (page: number) => {
      const { data, status } = await trackPromise(
        getInvestorsPerBroker(id ?? '', page, getInvestorsController.signal),
        faBrokersAreas.getInvestorPerFaBroker
      );
      if (!isRequestError(status)) {
        const investorsPerBroker = data as IInvestorsPerBroker[];
        setInvestors(investorsPerBroker);
      }
    },
    [id, getInvestorsController.signal]
  );

  const onPageChange = (newPage: number) => setInvestorsPerBroker(newPage);

  const handleDisconnectInvestor = async (investorId: string) => {
    const { status } = await disconnectInvestor({ investorId, userId: id ?? '' });
    if (!isRequestError(status)) {
      const deleted = investors.filter((investor) => investor.id !== investorId);
      setInvestors(deleted);
    }
  };

  useEffect(() => {
    setInvestorsPerBroker(0);
  }, [setInvestorsPerBroker]);

  return (
    <Investors
      investors={investors}
      disconnectInvestor={handleDisconnectInvestor}
      onPageChange={onPageChange}
    />
  );
};

export { InvestorPresenter };
