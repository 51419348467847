import { SvgIcon } from '@mui/material';
import { IconCalendarEvent } from '@tabler/icons';
import { FC } from 'react';

const CalendarIcon: FC = () => (
  <SvgIcon sx={{ color: 'inherit' }}>
    <IconCalendarEvent />
  </SvgIcon>
);

export { CalendarIcon };
