import { Stack } from '@mui/material';
import { FC } from 'react';

import { ITableHeaderProps } from './BrokersAndFA.d';

const TableHeader: FC<ITableHeaderProps> = ({ filter, searchBar }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={2}
      p={1.5}
      borderBottom={1}
      borderColor="grey.300"
    >
      {searchBar}

      {filter}
    </Stack>
  );
};

export { TableHeader };
