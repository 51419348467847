import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { getData } from './getData';
import { IStatusChip } from './StatusChip.d';

const StatusChip: FC<IStatusChip> = (props) => {
  const { status, hidden } = props;
  const hiddenState = hidden === undefined ? false : hidden;
  const { code, color, title, bgcolor } = getData(status, hiddenState);

  return (
    <Box
      borderRadius={4}
      borderColor={color}
      sx={{
        borderStyle: 'solid',
        borderWidth: '1px',
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      py={1}
      px="3px"
      height="32px"
      {...props}
    >
      <Box
        width="24px"
        height="24px"
        borderRadius="50%"
        bgcolor={bgcolor}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="caption" color="white" component="span">
          {code}
        </Typography>
      </Box>

      <Typography
        variant="caption2"
        color={color}
        component="span"
        mr="auto"
        ml="auto"
        px={1}
        noWrap
      >
        {title}
      </Typography>
    </Box>
  );
};

export { StatusChip };
