import { Box, Button, Divider, SvgIcon, Typography } from '@mui/material';
import { IconFlag } from '@tabler/icons';
import { format } from 'date-fns';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { computePercentage } from '../../helpers/computePercentage';
import { formatDollars } from '../../helpers/quantityBeautifier';
import { projectTypes } from '../CreateProjectSteps/dropdownsData';
import { LinearProgress } from '../LinearProgress';
import { StatusChip } from '../StatusChip'; //eslint-disable-line
import { IProjectsListItem } from './ProjectsListItem.d';

export const ProjectsListItem: FC<IProjectsListItem> = ({
  name,
  status,
  type,
  id,
  startDate,
  targetedClosingDate,
  financial,
  hidden,
}) => {
  const { committedInvestments, receivedInvestments, totalTargetedInvestment } = financial;
  const hiddenStatus = hidden !== undefined ? hidden : false;
  const nav = useNavigate();
  const goToDetail = () => nav(`/projects/${id}`);
  const projectTypeLabel = () => {
    return projectTypes.find(({ value }) => value === type)?.label;
  };

  const formatDate = (date: string) => {
    const parseDate = date.length > 20 ? date : date.replace(/-/g, '/');
    return format(new Date(parseDate), 'LLL d, yyyy');
  };

  return (
    <Box
      borderRadius={2}
      bgcolor="background.paper"
      border={1}
      borderColor="grey.300"
      minHeight="100%"
      display="grid"
      gridTemplateRows="auto 1fr auto"
      sx={{ overflow: 'hidden' }}
      data-testid={`test-project-${id}`}
    >
      <Box
        p={2}
        pb={3}
        bgcolor="grey.100"
        sx={{ borderBottom: '1px solid', borderColor: 'grey.300' }}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center" mb={1}>
          <StatusChip status={status} hidden={hiddenStatus} />
        </Box>
        <Typography variant="caption" display="block" fontWeight="bold" fontSize={12}>
          {projectTypeLabel()}
        </Typography>
      </Box>
      <Box p={3}>
        <Typography variant="h3" pb={3} sx={{ hyphens: 'auto', wordBreak: 'break-all' }}>
          {name}
        </Typography>
        <Divider sx={{ mb: '12px' }} />
        {startDate && (
          <div>
            <Typography variant="body1" component="span">
              Start date:{' '}
            </Typography>
            <Typography variant="body1" fontWeight="bold" component="span">
              {startDate && formatDate(startDate)}
            </Typography>
          </div>
        )}
        <div>
          <Typography variant="body1" component="span">
            Targeted closing date:{' '}
          </Typography>
          <Typography variant="body1" fontWeight="bold" component="span">
            {targetedClosingDate && formatDate(targetedClosingDate)}
          </Typography>
        </div>
        <Divider sx={{ my: '12px' }} />
        <Box display="flex" alignItems="flex-end" flexWrap="wrap" justifyContent="space-between">
          <Typography variant="body1" mr="4px" component="span">
            Targeted <br /> investment amount:
          </Typography>
          <Box display="flex" alignItems="center">
            <SvgIcon sx={({ palette }) => ({ color: palette.grey[800], fontSize: '16px' })}>
              <IconFlag />
            </SvgIcon>
            <Typography variant="body1" ml="6px" component="div">
              <strong>{formatDollars(totalTargetedInvestment)}</strong> USD
            </Typography>
          </Box>
        </Box>
        {startDate && (
          <>
            <LinearProgress
              sx={{ my: 1 }}
              variant="determinate"
              value={Math.min(
                computePercentage(receivedInvestments, totalTargetedInvestment).percent,
                100
              )}
            />
            <Typography variant="body1" component="span">
              Confirmed:{' '}
            </Typography>
            <Typography variant="body1" component="div">
              <strong>{formatDollars(receivedInvestments)}</strong> USD{' '}
              <strong>
                {computePercentage(receivedInvestments, totalTargetedInvestment).formatted}
              </strong>
            </Typography>
            <Divider sx={{ my: '12px' }} />
            <Typography variant="body1" component="span">
              Committed:{' '}
            </Typography>
            <Typography variant="body1" component="div">
              <strong>{formatDollars(committedInvestments)}</strong> USD{' '}
              <strong>
                {computePercentage(receivedInvestments, totalTargetedInvestment).formatted}
              </strong>
            </Typography>
          </>
        )}
      </Box>
      <Box
        p={3}
        textAlign="center"
        sx={({ palette }) => ({ borderTop: `1px solid ${palette.grey[300]}` })}
      >
        <Button variant="outlined" onClick={goToDetail}>
          View details
        </Button>
      </Box>
    </Box>
  );
};
