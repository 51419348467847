import { Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useOutletContext, useParams } from 'react-router-dom';

import { IMessage } from '../../../api/main';
import {
  IInvestmentProps,
  IInvestmentsParams,
  IProjectsInvestment,
  IProjectsInvestmentsResponse,
  IStatus,
  projectAreas,
  setTransactionStatus,
} from '../../../api/projects';
import { getProjectInvestment } from '../../../api/projects/projects';
import { RefundConfirmationModal } from '../../../components/ProjectDetails/RefundConfirmationModal';
import { INVESTMENT_STATUSES } from '../../../helpers/constants';
import { isRequestError } from '../../../helpers/isRequestError';
import { formatDollars } from '../../../helpers/quantityBeautifier';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { toastContext } from '../../../hooks/useToast';
import { IProjectOutletContext } from '../ProjectDetail.d'; //eslint-disable-line
import { Report } from './Report';

const ReportPresenter: FC = () => {
  const { id } = useParams();
  const { openToast } = useContext(toastContext);
  const getProjectInvestmentController = useAbortRequest();
  const setTransactionStatusController = useAbortRequest();
  const { metrics, reqProjectDetail } = useOutletContext<IProjectOutletContext>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentRefundId, setCurrentRefundId] = useState<string>('');
  const [currentRefundValue, setCurrentRefundValue] = useState<string>('');
  const [currentRefundInvestorName, setCurrentRefundInvestorName] = useState<string>('');

  const [projectInvestments, setProjectInvestments] = useState<IInvestmentProps>({
    count: 0,
    investments: [],
    pages: 0,
  });
  const [investmentsFilters, setInvestmentsFilters] = useState<IInvestmentsParams>({
    limit: 10,
    page: 0,
  });

  const showToastInError = (data: IMessage) => {
    openToast({
      message:
        data.error === 'CONTRACT_VALIDATION_ERROR'
          ? 'The contract was signed by the Investor but not by the Compliance Administrator from Growie'
          : data.message,
      severity: 'error',
      title:
        data.error === 'CONTRACT_VALIDATION_ERROR'
          ? 'Growie signature needed'
          : 'Something went wrong',
    });
  };

  const handleToast = (status: number, data: IMessage) => {
    if (!isRequestError(status)) {
      const toastData = {
        message: 'The latest modifications to the project have been saved.',
        severity: 'success',
        title: data.message,
      };
      openToast(toastData);
    } else {
      showToastInError(data);
    }
  };

  const reqProjectInvestment = useCallback(async () => {
    const { data, status } = await trackPromise(
      getProjectInvestment(
        id as string,
        { ...investmentsFilters, page: investmentsFilters.page + 1 },
        getProjectInvestmentController.signal
      ),
      projectAreas.getProjectInvestments
    );
    if (!isRequestError(status)) {
      const { investments, count, pages } = data as IProjectsInvestmentsResponse;
      setProjectInvestments({ count, investments, pages });
    }
  }, [getProjectInvestmentController.signal, id, investmentsFilters]);

  const reqTransactionStatus = async (transaction: string, newStatus: string) => {
    if (newStatus === INVESTMENT_STATUSES.REFUND_INPROCESS) {
      setCurrentRefundId(transaction);
      projectInvestments.investments.forEach((investor: IProjectsInvestment) => {
        const currentTransactionAmount = investor.transactions.find(
          (t) => t.transferId === transaction
        )?.amount;
        if (currentTransactionAmount) {
          setCurrentRefundValue(formatDollars(currentTransactionAmount ?? 0, true));
          setCurrentRefundInvestorName(investor.fullName);
        }
      });
      setShowConfirmationModal(true);
    } else {
      await updateTransactionStatus(transaction, newStatus);
    }
  };

  const handleConfirmation = () =>
    trackPromise(
      updateTransactionStatus(currentRefundId, INVESTMENT_STATUSES.REFUND_INPROCESS),
      projectAreas.refundTransaction
    );

  const updateTransactionStatus = async (transaction: string, newStatus: string) => {
    const message = { status: newStatus.toUpperCase() };
    const { data, status } = await setTransactionStatus(
      transaction,
      message as IStatus,
      setTransactionStatusController.signal
    );
    setShowConfirmationModal(false);
    if (!isRequestError(status)) {
      setTimeout(() => {
        reqProjectDetail();
        reqProjectInvestment()
      }, 2000); //To avoid getting data not yet updated on BD.
      handleToast(status, data as IMessage);
    } else {
      showToastInError(data);
    }
  };

  useEffect(() => {
    reqProjectInvestment();
  }, [reqProjectInvestment]);

  return (
    <>
      <Report
        metrics={metrics}
        investments={projectInvestments}
        investmentsFilters={investmentsFilters}
        setInvestmentsFilters={setInvestmentsFilters}
        handleTransactionStatus={reqTransactionStatus}
      />
      <RefundConfirmationModal
        title="Are you sure to continue?"
        subtitle={
          <Typography variant="body1">
            You will refund {currentRefundValue} to investor {currentRefundInvestorName}.
          </Typography>
        }
        primaryButtonText="Cancel"
        secondaryButtonText="Refund"
        onClose={() => setShowConfirmationModal(false)}
        open={showConfirmationModal ?? false}
        finishWorking={() => setShowConfirmationModal(false)}
        mainAction={handleConfirmation}
      />
    </>
  );
};

export { ReportPresenter };
