import { Box, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { projectAreas } from '../../api/projects/index';
import { EmptySearch } from '../EmptyStates';
import { OverlayLoader } from '../OverlayLoader';
import { IProjectsListItem, ProjectsListItem } from '../ProjectsListItem'; // eslint-disable-line
import { IProjectsList } from './ProjectsList.d';

export const ProjectsList: FC<IProjectsList> = ({ list }) => {
  const [sortedItems, setSortedItems] = useState<IProjectsListItem[]>([]);

  const { promiseInProgress } = usePromiseTracker({ area: projectAreas.get });

  useEffect(() => {
    setSortedItems(list);
  }, [list]);

  if (!promiseInProgress && list?.length === 0) {
    return <EmptySearch />;
  }

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          columns={{ xs: 4, sm: 8, lg: 12 }} //eslint-disable-line
          component="ul"
          p={0}
          m={0}
          sx={{ listStyle: 'none' }}
          columnSpacing={{ sm: 4, md: 3, lg: 4 }} //eslint-disable-line
        >
          {sortedItems?.map((item) => {
            return (
              <Grid item component="li" xs={4} sm={4} lg={4} key={item.id} mb={3}>
                <ProjectsListItem {...item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <OverlayLoader area={projectAreas.get} />
    </>
  );
};
