import { styled } from '@mui/material';
import { GridRow } from '@mui/x-data-grid';
import { IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons';

const StyledGridRow = styled(GridRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[100],
  },
  fontSize: 16,
}));

const StyledIconArrowNarrowUp = styled(IconArrowNarrowUp)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const StyledIconArrowNarrowDown = styled(IconArrowNarrowDown)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export { StyledGridRow, StyledIconArrowNarrowDown, StyledIconArrowNarrowUp };
