import { AxiosResponse } from 'axios';
import CryptoJS from 'crypto-js';
import { trackPromise } from 'react-promise-tracker';

import { IReturnError, reportError } from '../../helpers/reportError';
import { ILoginFormFields } from '../../pages/Login';
import { IMessage, internalUserApi, IReturnType } from '../main'; // eslint-disable-line
import { areas } from './areas';
import { login as loginDecorator, logout as logoutDecorator } from './decorators';
import { IAuth, ILoginRes, IRefreshResponse, ITwoFactorAuth } from './session.d';

export const login = async (
  loginBody: ILoginFormFields
): Promise<IReturnType<ILoginRes> | IReturnError> => {
  try {
    const req = {
      ...loginBody,
      password: CryptoJS.AES.encrypt(loginBody.password, loginBody.email).toString(),
    };
    const res = await trackPromise(
      internalUserApi.post<ILoginRes>('/users/login', req),
      areas.login
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

export const createTwoFactorCode = async (
  session: string
): Promise<IReturnType<ITwoFactorAuth> | IReturnError> => {
  try {
    const res = await trackPromise(
      internalUserApi.post<ITwoFactorAuth>('users/associate-token', { session })
    );
    return { data: res.data, status: res.status };
  } catch (error) {
    return reportError(error);
  }
};

export const verifyTwoFactorAuth = async (
  session: string,
  code: string,
  userId: string
): Promise<IReturnType<IAuth> | IReturnError> => {
  try {
    const res = await trackPromise(
      internalUserApi.post<IAuth>('/users/verify-token', { code, session, userId }),
      areas.verifyTwoFA
    );
    return { data: loginDecorator(res.data), status: res.status };
  } catch (error) {
    return reportError(error);
  }
};

export const confirmTwoFactorAuth = async (
  session: string,
  code: string,
  userId: string
): Promise<IReturnType<IAuth> | IReturnError> => {
  try {
    const res = await trackPromise(
      internalUserApi.post<IAuth>('/users/confirm-mfa', { code, session, userId }),
      areas.verifyTwoFA
    );
    return { data: loginDecorator(res.data), status: res.status };
  } catch (error) {
    return reportError(error);
  }
};

export const logout = async (
  accessToken: string,
  refreshToken: string
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      internalUserApi.post('/users/logout', { accessToken, refreshToken })
    );
    return {
      data: logoutDecorator(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

export const refresh = async (
  refreshToken: string
): Promise<IReturnType<IRefreshResponse> | IReturnError> => {
  try {
    const res: AxiosResponse<IRefreshResponse> = await internalUserApi.post('/users/refreshToken', {
      refreshToken,
    });
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};
