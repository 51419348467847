import Joi from 'joi';

const emailRegex =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/;

const emailJoi = Joi.string().required().regex(emailRegex).messages({
  'string.empty': 'The email is a required field',
  'string.pattern.base': 'Invalid email, it should be an email',
});

const loginSchema = Joi.object({
  email: emailJoi,
  password: Joi.string()
    .required()
    .messages({ 'string.empty': 'The password is a required field' }),
});

export { emailJoi,loginSchema };
