/* eslint-disable import/order */
/* eslint-disable sort-keys */
import { Box, Button, Divider, Paper, SvgIcon, Typography } from '@mui/material';
import { IconArrowLeft, IconCompass } from '@tabler/icons';
import { FC, SyntheticEvent, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Tab } from '../../components/Tab';
import { Tabs } from '../../components/Tabs';
import {
  UserAvatar,
  UserDataColumn,
  UserDescriptionCard,
} from '../../components/UserDescriptionCard';
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext';
import { CommissionsPresenter } from './Commissions';
import { IFABrokerDetail } from './FABrokers.d';
import { InvestmentsPresenter } from './Investments';
import { InvestorPresenter } from './Investors';

const FABrokerDetail: FC<IFABrokerDetail> = ({ userDetail, toggleBlockModal }) => {
  const nav = useNavigate();
  const [tab, setTab] = useState(0);
  const { pathname } = useLocation();
  const isBroker = pathname.split('/')[2] === 'broker';
  const pickTab = (_event: SyntheticEvent<Element, Event>, newTab: number) => setTab(newTab);
  const { userData } = useContext(authContext);
  const superAdminRole = havePermission(userData?.roles ?? [], ['superAdmin']);

  return (
    <>
      <Button
        size="small"
        variant="text"
        color="secondary"
        startIcon={<IconArrowLeft />}
        onClick={() => nav('/brokers-and-financial-advisors')}
        sx={{ mb: 4 }}
      >
        Back to Brokers & Financial Advisors list
      </Button>
      <Breadcrumbs
        current={isBroker ? 'Broker details' : 'Financial advisor details'}
        linksList={[
          { active: true, label: 'Home', path: '/' },
          {
            active: true,
            label: 'Brokers & Financial Advisors',
            path: '/brokers-and-financial-advisors',
          },
        ]}
      />
      <Typography variant="h1" my={4}>
        <SvgIcon color="secondary" sx={{ mr: 1 }}>
          <IconCompass />
        </SvgIcon>
        {isBroker ? 'Broker' : 'Financial Advisor'} details
      </Typography>
      <Divider variant="middle" sx={{ mb: 3 }} />

      <UserDescriptionCard
        avatar={<UserAvatar legalName={userDetail?.legalName} sx={{ alignSelf: 'self-start' }} />}
        userData={<UserDataColumn data={userDetail?.userData} />}
        blockButton={
          <Button
            onClick={toggleBlockModal}
            variant="outlined"
            size="small"
            disabled={!superAdminRole}
            sx={{ alignSelf: { xs: 'center', md: 'flex-end' }, flexShrink: '0', fontSize: '14px' }}
          >
            {userDetail?.userData?.status === 'Active' ? 'Block user' : 'Enable user'}
          </Button>
        }
      />

      <Paper
        elevation={0}
        sx={{
          border: ({ palette }) => `1px solid ${palette.grey[300]}`,
          borderRadius: { xs: 0, sm: 2 },
          mt: 3,
          mx: { xs: -3, sm: 0 },
        }}
      >
        <Box width="100%">
          <Tabs value={tab} onChange={pickTab} variant="fullWidth">
            <Tab label="Investments" />
            <Tab label="Investors" />
            <Tab label="Commissions" />
          </Tabs>
        </Box>
        <Box p={3}>
          {tab === 0 && (
            <InvestmentsPresenter
              role={userDetail?.role ?? ''}
            />
          )}
          {tab === 1 && <InvestorPresenter />}
          {tab === 2 && (
            <CommissionsPresenter
              userName={`${userDetail?.firstName ?? ''} ${userDetail?.lastName ?? ''}`}
              role={userDetail?.role ?? ''}
            />
          )}
        </Box>
      </Paper>
    </>
  );
};
export { FABrokerDetail };
