import { Box, Button, MenuItem, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import { IconChevronDown, IconFilter } from '@tabler/icons';
import { FC, useEffect, useRef, useState } from 'react';

import { toCamelCase } from '../../helpers';
import { Gray, Primary } from '../../theme/tokens/colors';
import { InputField } from '../InputField'; // eslint-disable-line
import {
  IFilterOptionsModal,
  IFilterSelect,
  IFilterSelectOption,
  ISelectValues,
} from './FilterOptionsModal.d';
import { PopperStyled } from './FilterOptionsModal.styled';

const initValues = (selects: IFilterSelect[]): ISelectValues => {
  const values: ISelectValues = {};
  selects.forEach((item: IFilterSelect) => {
    const optionSelected = item.options.filter((option: IFilterSelectOption) => option.selected);
    values[toCamelCase(item.label)] = optionSelected[0] ? optionSelected[0].value : '';
  });
  return values;
};

export const FilterOptionsModal: FC<IFilterOptionsModal> = ({ selects, onApply, onClear }) => {
  const [display, setDisplay] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [selectsValues, setSelectsValues] = useState<ISelectValues>(initValues(selects));

  const handleToggle = () => {
    setDisplay((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if ((event.target as HTMLElement).localName === 'body') {
      return;
    }
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setDisplay(false);
  };

  const handleChange = (label: string, value: string) => {
    setSelectsValues({
      ...selectsValues,
      [toCamelCase(label)]: value,
    });
  };

  const handleClearFilters = () => {
    const cleanSelects: { [propName: string]: string } = {};
    selects.forEach((item: IFilterSelect) => {
      cleanSelects[toCamelCase(item.label)] = '';
    });
    setSelectsValues(cleanSelects);
    onClear();
    setDisplay(false);
  };

  const handleApplyFilters = () => {
    const valuesNotEmpty = Object.keys(selectsValues).filter((item: string) => selectsValues[item]);
    if (valuesNotEmpty.length) {
      const newSelectsValues: { [propName: string]: string } = {};
      valuesNotEmpty.forEach((item: string) => {
        newSelectsValues[item] = selectsValues[item];
      });
      onApply(newSelectsValues);
    }

    setDisplay(false);
  };

  useEffect(() => {
    setSelectsValues(initValues(selects));
  }, [selects]);

  return (
    <>
      <Button
        ref={anchorRef}
        variant="outlined"
        startIcon={<IconFilter size={19} />}
        fullWidth={true}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          borderRadius: '100px',
          py: '1px',
        }}
      >
        Filter
      </Button>

      <PopperStyled
        open={display}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper
              sx={{
                border: '1px solid #DCDDE2',
                borderRadius: '8px',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box
                  sx={{ width: { xs: 272, md: 316 } }} //eslint-disable-line
                  p={3}
                >
                  <Box mb={3}>
                    <Typography variant="caption" component="h5" fontWeight={700} color={Gray[800]}>
                      Filter options
                    </Typography>
                  </Box>

                  {selects.map((item: IFilterSelect, iSelect: number) => (
                    <Box mb={4} key={`${item.label}-${iSelect}`}>
                      <InputField
                        select
                        fullWidth
                        value={selectsValues[toCamelCase(item.label)]}
                        label={item.label}
                        onChange={(event: SelectChangeEvent) =>
                          handleChange(item.label, event.target.value)
                        }
                        SelectProps={{
                          IconComponent: IconChevronDown,
                          MenuProps: { sx: { '.MuiMenu-paper': { boxShadow: 3 } } },
                          sx: {
                            '.MuiSelect-icon': { right: '14px', top: 'auto' },
                            pl: '14px',
                            py: '16.5px',
                          },
                        }}
                      >
                        {item.options.map((option: IFilterSelectOption, iOption: number) => (
                          <MenuItem
                            value={option.value}
                            key={`${option.label}-${iOption}`}
                            sx={{
                              '&.Mui-selected, &.Mui-selected:focus': {
                                bgcolor: 'transparent',
                                color: 'secondary.main',
                                fontWeight: 'bold',
                              },
                              py: 1.5,
                            }}
                          >
                            <Typography variant="inherit" noWrap sx={{ whiteSpace: 'pre-wrap' }}>
                              {option.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </InputField>
                    </Box>
                  ))}

                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <Button
                      variant="text"
                      size="small"
                      onClick={handleClearFilters}
                      sx={{
                        color: Primary[600],
                        fontSize: 14,
                      }}
                    >
                      Clear
                    </Button>

                    <Box
                      flexShrink={0}
                      sx={{
                        width: {
                          xs: '16px',
                          // eslint-disable-next-line sort-keys
                          sm: '22px',
                        },
                      }}
                    ></Box>

                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleApplyFilters}
                      sx={{
                        borderRadius: '100px',
                        fontSize: 14,
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </PopperStyled>
    </>
  );
};
