import { Box, Button, Theme, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { IconX } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';

import { Gray } from '../../theme/tokens/colors'; // eslint-disable-line
import { IConfirmDialog } from './ConfirmDialog.d';

export const ConfirmDialog: FC<IConfirmDialog> = ({
  display,
  onClose,
  theme = 'medium',
  children,
  buttons,
  confirmButtonLabel,
}) => {
  const [open, setOpen] = useState(display);
  const closeButtonLabel = confirmButtonLabel ? 'Cancel' : 'No';
  let isMobile = useMediaQuery((systemTheme: Theme) => systemTheme.breakpoints.down('sm'));
  if (theme === 'small') {
    isMobile = true;
  }

  const maxWidthModal =
    theme === 'medium' ? { sm: '392px !important', lg: '432px !important' } : '272px'; //eslint-disable-line

  const handleClose = (confirm = false) => {
    setOpen(confirm);
    onClose(confirm);
  };

  useEffect(() => {
    setOpen(display);
  }, [display]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: maxWidthModal,
        },
      }}
      fullWidth
    >
      <Box p="56px 24px 40px" position="relative">
        <Box position="absolute" top="11px" right="11px">
          <IconButton aria-label="Close" size="medium" onClick={() => handleClose()}>
            <IconX color={Gray[900]} size={26} />
          </IconButton>
        </Box>
        {children}

        {buttons ? (
          buttons
        ) : (
          <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap" mt={4}>
            <Button
              variant="text"
              onClick={() => handleClose()}
              color="secondary"
              fullWidth={isMobile}
              sx={{
                fontWeight: 600,
                lineHeight: '24px',
                marginBottom: isMobile ? 3 : 0,
                marginRight: isMobile ? 0 : 1,
              }}
            >
              {closeButtonLabel}
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                borderRadius: '100px',
                fontWeight: 600,
                lineHeight: '24px',
                minHeight: '40px',
              }}
              onClick={() => handleClose(true)}
              color="secondary"
              fullWidth={isMobile}
            >
              {confirmButtonLabel ?? 'Yes'}
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
