import { joiResolver } from '@hookform/resolvers/joi';
import { Divider, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { setMetrics } from '../../../api/blockchain/blockchain';
import { IMessage } from '../../../api/main';
import { IIncompleteFields, projectAreas, updateProjectDetail } from '../../../api/projects';
import { ButtonSubmit } from '../../../components/ButtonSubmit';
import { ClosingProjectModal } from '../../../components/CreateProjectSteps/ClosingProjectModal';
import { IncompleteDialog } from '../../../components/IncompleteDialog';
import { PROJECT_STATUSES } from '../../../helpers/constants';
import { havePermission } from '../../../helpers/havePermission';
import { isRequestError } from '../../../helpers/isRequestError';
import { IServerError } from '../../../helpers/reportError';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { authContext } from '../../../hooks/useAuth/authContext';
import { requireValidationContext } from '../../../hooks/useRequireValidationOnLeave';
import { toastContext } from '../../../hooks/useToast';
import { formatFormRequest } from '../decorators';
import {
  IIncompleteDialog,
  IProjectForm,
  IProjectOutletContext,
  MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME,
  MAX_INVESTMENT_PERCENTAGE_LOANS,
  PROJECT_DETAIL_ERRORS,
  PROJECT_TYPES,
} from '../ProjectDetail.d';
import { updateProjectSchema } from '../schema';

const defaultFormValues: IProjectForm = {
  beneficiaryBank: {
    accountNumber: '',
    bankAddress: '',
    bankCity: '',
    bankCountry: '',
    bankName: '',
    bankPostalCode: '',
    beneficiaryAddress: '',
    beneficiaryName: '',
    routingNumber: '',
    swiftCode: '',
  },
  beneficiaryWallet: {
    address: '',
    currency: 'USDC',
    network: 'ETH',
  },
  closingDate: '',
  financialInformation: {
    actualAnnualReturn: 0,
    annualCash: 0,
    annualInvestment: 0,
    assetManagementFee: 0,
    financialReports: [],
    frequencyDistribution: '',
    investmentBreakpoint: 0,
    maximumInvestmentPercentage: 0,
    minimumInvestment: 0,
    percentOverage: 0,
    placementFee: 0,
    totalReturnInvestment: 0,
    totalTargetedInvestment: 0,
    upfrontFee: 0,
  },
  investmentPeriod: '',
  isUs: true,
  legalInformation: {
    document: '',
  },
  projectTitle: '',
  projectType: '',
};
const EditDataPresenter = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const { userData } = useContext(authContext);
  const updateProjectDetailController = useAbortRequest();
  const setAtualAnnualReturnController = useAbortRequest();
  const { openToast } = useContext(toastContext);
  const { form, status, reqProjectDetail } = useOutletContext<IProjectOutletContext>();
  const validStatus = [
    PROJECT_STATUSES.DRAFT,
    PROJECT_STATUSES.ACTIVE,
    PROJECT_STATUSES.PAUSED,
  ].includes(status as PROJECT_STATUSES);
  const basicRole = havePermission(userData?.roles ?? [], ['financial', 'marketing', 'superAdmin']);
  const financialRole = havePermission(userData?.roles ?? [], ['financial', 'superAdmin']);
  const legalRole = havePermission(userData?.roles ?? [], ['legal', 'superAdmin']);
  const [showEditButton, setShowEditButton] = useState(true);
  const { pathname } = useLocation();
  const [incompleteDialogSave, setIncompleteDialogSave] = useState<IIncompleteDialog>({
    display: false,
    fields: [],
  });
  const [defaultValues, setDefaultValues] = useState<IProjectForm>(defaultFormValues);

  useEffect(() => {
    if (form) {
      setDefaultValues({
        ...form,
        financialInformation: {
          ...form.financialInformation,
          maximumInvestmentPercentage:
            form.financialInformation?.maximumInvestmentPercentage ||
            (form.projectType === PROJECT_TYPES.LOANS.toLocaleLowerCase()
              ? MAX_INVESTMENT_PERCENTAGE_LOANS
              : MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME),
        },
      });
    }
  }, [form]);

  const {
    setIsValidationModalOpen,
    isValidationModalOpen,
    clearValidation,
    navigationRoute,
    setIsValidationRequired,
    isValidationRequired,
  } = useContext(requireValidationContext);

  const methods = useForm<IProjectForm>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(updateProjectSchema),
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const discardChanges = () => {
    reset();
    clearValidation();
    nav(navigationRoute);
  };

  const handleOnCloseSaveDialog = () => {
    setIncompleteDialogSave((data) => {
      return {
        ...data,
        display: false,
      };
    });
  };

  const updateProject = async (updatedData: IProjectForm) => {
    const { data, status: statusCode } = await updateProjectDetail(
      id as string,
      formatFormRequest(updatedData, {
        basic: basicRole,
        financial: financialRole,
        legal: legalRole,
      }),
      updateProjectDetailController.signal
    );
    if (!isRequestError(statusCode)) {
      const res = data as IMessage;
      openToast({
        message: 'The latest modifications to the project have been saved.',
        severity: 'success',
        title: res.message,
      });
      if (!pathname.endsWith('legal')) {
        reqProjectDetail();
      }
      clearValidation();
      if (isValidationModalOpen) {
        clearValidation();
        nav(navigationRoute);
      }
      return;
    }
    const err = data as IIncompleteFields | IMessage;
    const code = (err as IMessage).code ? ((err as IMessage).code as string) : 'default';
    if (statusCode === 400 && code && Object.keys(PROJECT_DETAIL_ERRORS).includes(code)) {
      openToast({
        message: PROJECT_DETAIL_ERRORS[code],
        severity: 'error',
        title: 'Error updating project',
      });
    }
    if (statusCode === 409 && (err as IIncompleteFields)) {
      setIncompleteDialogSave({
        display: true,
        fields: (err as IIncompleteFields).fields,
      });
    }
  };

  const handleSetActualAnnualReturn = async (updatedData: IProjectForm) => {
    const { data, status: statusCode } = await setMetrics(
      id as string,
      updatedData?.financialInformation?.actualAnnualReturn as number,
      setAtualAnnualReturnController.signal
    );
    if (!isRequestError(statusCode)) {
      openToast({
        message: 'The latest modifications to the project have been saved.',
        severity: 'success',
      });
    } else {
      const error = data as IServerError;
      openToast({
        message: error.message,
        severity: 'error',
        title: (error.title as string) || 'Something went wrong',
      });
    }
    if (isValidationModalOpen) {
      nav(navigationRoute);
    }
    clearValidation();
  };

  const handleProjectSubmit = async (updatedData: IProjectForm) => {
    if (status === PROJECT_STATUSES.PROJECT_COMPLETED) {
      handleSetActualAnnualReturn(updatedData);
    } else {
      await updateProject(updatedData);
    }
  };

  useEffect(() => {
    if (isDirty) {
      setIsValidationRequired(true);
    } else {
      setIsValidationRequired(false);
    }

    return () => {
      if (isValidationRequired) {
        setIsValidationRequired(false);
      }
    };
  }, [isDirty, setIsValidationRequired, isValidationRequired]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleProjectSubmit)}>
          <Outlet
            context={{
              basicRole,
              financialRole,
              legalRole,
              setShowEditButton,
              status,
              validStatus,
            }}
          />
          {showEditButton && (
            <>
              <Divider sx={{ mb: 5, mt: 7 }} />
              <Grid container columns={{ lg: 10, md: 10, sm: 8, xs: 4 }} direction="row-reverse">
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <ButtonSubmit area={projectAreas.updateProjectDetail} variant="contained">
                    Save
                  </ButtonSubmit>
                </Grid>
              </Grid>
            </>
          )}
        </form>
      </FormProvider>
      <ClosingProjectModal
        title="Unsaved project changes"
        subtitle="Do you want to exit the project without saving the changes?"
        primaryButtonText="Save changes"
        secondaryButtonText="Discard changes"
        onClose={() => setIsValidationModalOpen(false)}
        open={isValidationModalOpen ?? false}
        finishWorking={discardChanges}
        mainAction={handleSubmit(handleProjectSubmit)}
      />
      <IncompleteDialog
        title="You cannot save the project"
        display={incompleteDialogSave.display}
        onClose={handleOnCloseSaveDialog}
        fields={incompleteDialogSave.fields}
      />
    </>
  );
};
export { EditDataPresenter };
