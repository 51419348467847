import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IconInfoCircle } from '@tabler/icons';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';

import { blockchainAreas } from '../../api/blockchain';
import { Table } from '../../components/Table';
import { formatTokens, quantityBeautifierStandard } from '../../helpers/quantityBeautifier'; //eslint-disable-line
import { IFormContext } from './CreateTokens.d';

const columns: GridColDef[] = [
  { field: 'name', flex: 1, headerName: 'Legal Name' },
  {
    field: 'amount',
    flex: 1,
    headerName: 'Tokens',
    type: 'number',
    valueFormatter: ({ value }) => value && quantityBeautifierStandard(value, true),
  },
];

const ReviewInvestments: FC = () => {
  const { totalTokens, tokensList } = useOutletContext<IFormContext>();

  return (
    <>
      <Box
        sx={({ palette }) => ({
          bgcolor: palette.grey[100],
          border: `1px solid ${palette.grey[300]}`,
          borderRadius: 3,
          mb: 4,
          p: 3,
        })}
      >
        <Stack direction="row" gap={1} mb={1}>
          <Typography fontWeight="bold" component="span" color="primary">
            Total Tokens to Issue:
          </Typography>
          <Typography fontWeight="bold" component="span" color="secondary">
            {formatTokens(totalTokens)}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <SvgIcon sx={{ color: ({ palette }) => palette.grey[700] }}>
            <IconInfoCircle />
          </SvgIcon>
          <Typography component="span">1 Token = 1 dollar</Typography>
        </Stack>
      </Box>

      <Table
        columns={columns}
        rows={tokensList}
        pageSize={10}
        rowsPerPageOptions={[10]}
        area={blockchainAreas.createPlan}
      />
    </>
  );
};

export { ReviewInvestments };
