import { Box, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IMobileTableCard {
  data: { [key: string]: string | ReactNode };
  action?: ReactNode;
}

const MobileTableCard: FC<IMobileTableCard> = ({ data, action }) => {
  return (
    <Box
      component="li"
      mb={3}
      sx={{
        '&:last-of-type': {
          mb: 0,
        },
        backgroundColor: 'white',
        border: ({ palette }) => `1px solid ${palette.grey[300]}`,
        borderRadius: 2,
      }}
    >
      {Object.keys(data).map((row) => (
        <Stack
          px={2}
          gap={1}
          key={row}
          height="48px"
          direction="row"
          color="text.secondary"
          alignItems="center"
          sx={{
            '&:last-of-type': {
              borderBottom: 0,
            },
            borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
          }}
        >
          <Typography color="primary" fontWeight="bold" fontSize="14px" width="40%">
            {row}:
          </Typography>

          <Box width="60%" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {data[row]}
          </Box>
        </Stack>
      ))}
      {action && (
        <Box px={2} py="12px">
          {action}
        </Box>
      )}
    </Box>
  );
};
export { MobileTableCard };
