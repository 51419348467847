import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons';
import { FC } from 'react';

import { projectAreas } from '../../api/projects';
import { ButtonSubmit } from '../ButtonSubmit'; //eslint-disable-line
import { IClosingProjectModal } from './CreateProjectSteps.d';

const ClosingProjectModal: FC<IClosingProjectModal> = ({
  title,
  subtitle,
  open,
  onClose,
  mainAction,
  finishWorking,
  primaryButtonText,
  secondaryButtonText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-validate-closing"
      aria-describedby="modal-validate-closing"
      disableAutoFocus
      PaperProps={{
        sx: {
          borderRadius: 3,
          boxShadow: 4,
          p: 4,
          pt: 6,
        },
      }}
    >
      <IconButton
        sx={{ color: 'text.primary', position: 'absolute', right: 10, top: 10 }}
        onClick={onClose}
      >
        <IconX />
      </IconButton>
      <Typography variant="subtitle2" fontWeight="bold" mb={2}>
        {title}
      </Typography>
      <Typography variant="body1">{subtitle}</Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }} //eslint-disable-line
        mt={5}
        justifyContent="flex-end"
        spacing={2}
      >
        <Box sx={{ minWidth: 120 }}>
          <ButtonSubmit fullWidth area={projectAreas.updateProjectDetail} onClick={mainAction}>
            {primaryButtonText}
          </ButtonSubmit>
        </Box>
        <Button sx={{ minWidth: 120 }} color="secondary" onClick={finishWorking}>
          {secondaryButtonText}
        </Button>
      </Stack>
    </Dialog>
  );
};

export { ClosingProjectModal };
