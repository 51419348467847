import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IconChevronDown } from '@tabler/icons';
import { Controller } from 'react-hook-form';

import { InputField } from '../InputField'; //eslint-disable-line
import { IOption, ISelect } from './Select.d';

export function Select<FormTypes>({
  label,
  options,
  name,
  control,
  ...rest
}: ISelect<FormTypes>): JSX.Element {
  const handleClickOption = (action?: () => void) => {
    if (action) {
      action();
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value = '' } }) => (
        <InputField
          id={`${label?.split(' ').join('')}-select`}
          label={label}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          {...rest}
          SelectProps={{
            IconComponent: IconChevronDown,
            MenuProps: { sx: { '.MuiMenu-paper': { boxShadow: 3 } } },
            sx: { '.MuiSelect-icon': { right: '14px', top: 'auto' }, pl: '14px', py: '16.5px' },
          }}
          select
        >
          {options.map((item: IOption, index: number) => (
            <MenuItem
              value={item.value}
              key={`${item.label}-${index}`}
              onClick={() => handleClickOption(item.action)}
              sx={{
                '&.Mui-selected, &.Mui-selected:focus': {
                  bgcolor: 'transparent',
                  color: 'secondary.main',
                  fontWeight: 'bold',
                },
                py: 1.5,
              }}
            >
              <Typography variant="inherit" noWrap sx={{ whiteSpace: 'pre-wrap' }}>
                {item.label}
              </Typography>
            </MenuItem>
          ))}
        </InputField>
      )}
    />
  );
}
