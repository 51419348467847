import { Stack } from '@mui/material';
import { FC } from 'react';

import { FilterPopover } from '../../components/FilterPopover';
import { Select } from '../../components/Select'; // eslint-disable-line
import { IFilterProps } from './BrokersAndFA.d';

const options = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const Filter: FC<IFilterProps> = ({ applyFilter, clearFilter, control }) => {
  return (
    <FilterPopover
      applyFilter={applyFilter}
      clearFilter={clearFilter}
      filterOptions={
        <Stack>
          <Select label="Status" name="status" control={control} options={options} />
        </Stack>
      }
    />
  );
};

export { Filter };
