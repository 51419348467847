import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import commissions from '../../../../assets/commissions/commissions.svg';

const EmptyStateReport: FC = () => {
  return (
    <Stack height="350px" alignItems="center" justifyContent="center">
      <img width="180px" src={commissions} alt="Commissions" />
      <Typography
        sx={{
          fontFamily: 'Sora',
          fontSize: 24,
          lineHeight: '32px',
          textAlign: 'center',
        }}
      >
        There are not Commissions yet
      </Typography>
    </Stack>
  );
};

export { EmptyStateReport };
