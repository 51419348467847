import { IStatusPointer } from '../../../components/ProjectInvestors';

const statusDef: { [key: string]: string } = {
  not_payable: 'Not payable',
  paid: 'Paid',
  pending: 'Pending',
};
const statusColors: { [key: string]: IStatusPointer['status'] } = {
  not_payable: 'error',
  paid: 'success',
  pending: 'default',
};

export { statusColors, statusDef };
