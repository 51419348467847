import { Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { IconHome } from '@tabler/icons';
import { FC } from 'react';

import HomeImg from '../../assets/img/home_image.svg';
import { ActionsButtons } from '../../components/ActionsButtons';
import { Gray, Primary } from '../../theme/tokens/colors'; //eslint-disable-line
import { IHomeProps } from './home.d';

const Home: FC<IHomeProps> = ({ userName, quickActions, welcomeText }) => {
  return (
    <>
      <Stack direction="row" alignItems="baseline" spacing={1} mt={1} mb={5}>
        <IconHome color={Primary[500]} />
        <Typography
          variant="h1"
          sx={{
            hyphens: 'auto',
            wordBreak: 'break-word',
          }}
          data-testid="test-home-title"
        >
          {`Hi, ${userName}`}
        </Typography>
      </Stack>
      <Grid container columns={{ sm: 8, xl: 10, xs: 4 }} spacing={4}>
        <Grid item sm={8} xl={3} xs={4} sx={{ textAlign: 'center' }}>
          <img src={HomeImg} alt="Home" width="100%" style={{ maxWidth: 318 }} />
        </Grid>
        <Grid item sm={8} xl={7} xs={4} sx={{ alignSelf: 'center' }}>
          <Typography data-testid="test-welcome-text">{welcomeText}</Typography>
        </Grid>
      </Grid>
      <Paper
        sx={{
          border: `1px solid ${Gray[300]}`,
          borderRadius: 2,
          boxShadow: 0,
          mt: 5,
        }}
      >
        <Stack px={3} py={2} direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: ({ palette }) => palette.primary.main }}
          >
            Quick actions
          </Typography>
        </Stack>
        <Divider variant="fullWidth" />
        <ActionsButtons actions={quickActions} actionsName="quick_actions" />
      </Paper>
    </>
  );
};

export { Home };
