import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { IconX } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';

import { Gray } from '../../theme/tokens/colors'; // eslint-disable-line
import { getLabelsByNames } from './getLabelsByNames';
import { IIncompleteDialog } from './IncompleteDialog.d';

export const IncompleteDialog: FC<IIncompleteDialog> = ({ display, onClose, fields, title }) => {
  const [open, setOpen] = useState(display);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    setOpen(display);
  }, [display]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: {
            sm: '392px !important',
            lg: '664px !important', // eslint-disable-line
          },
        },
      }}
      fullWidth
    >
      <Box p="56px 24px 40px" pt={{ sm: '46px' }} position="relative">
        <Box position="absolute" top="11px" right="11px">
          <IconButton aria-label="Close" size="medium" onClick={handleClose}>
            <IconX color={Gray[900]} size={26} />
          </IconButton>
        </Box>

        <Typography variant="h3" fontWeight="bold" color="#000" mb={1}>
          {title}
        </Typography>
        <div>
          <Typography variant="body1" mb={4}>
            The following information is incomplete:{' '}
          </Typography>
          <Box mb={4}>
            <ul style={{ margin: 0, paddingLeft: '29px' }}>
              {getLabelsByNames(fields).map((label: string) => (
                <Typography variant="body1" component="li" key={label}>
                  {label}
                </Typography>
              ))}
            </ul>
          </Box>
          <Typography variant="body1">
            *Please make sure you have completed all the project information before activating it.
          </Typography>
        </div>

        <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap" mt={4}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              borderRadius: '100px',
              fontWeight: 600,
              lineHeight: '24px',
              minHeight: '40px',
            }}
            onClick={handleClose}
            color="secondary"
            fullWidth={isMobile}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
