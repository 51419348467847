const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatDateLetters = (date?: Date): string => {
  if (!date) {
    return '';
  }
  const instanceDate = new Date(date);
  return `${
    monthNames[instanceDate.getMonth()]
  } ${instanceDate.getDate()}, ${instanceDate.getFullYear()}`;
};
