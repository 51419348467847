/* eslint-disable sort-keys */
import { Box, Link, List, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { blockchainAreas, ITokensDistribution } from '../../../api/blockchain';
import { MobileTableCard } from '../../../components/MobileTableCard';
import { OverlayLoader } from '../../../components/OverlayLoader';
import { Paginator } from '../../../components/Paginator';
import { TokensEmptyState } from '../../../components/ProjectDetails/TokensEmptyState';
import { SortSelectorFilter } from '../../../components/SortSelectorFilter';
import { Table } from '../../../components/Table';
import { TSortOrder } from '../../../components/TableHeaderTitle';
import { PROJECT_STATUSES } from '../../../helpers/constants';
import { Gray, Interactive, Primary } from '../../../theme/tokens/colors'; // eslint-disable-line
import { TableHeader } from './TableHeader';
import { ITokensProps } from './Tokens.d';

const sortOptions = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Tokens',
    value: 'tokens',
  },
  {
    label: 'Wallet type',
    value: 'walletType',
  },
  {
    label: 'Whitelist status',
    value: 'whitelistStatus',
  },
];

const columns: GridColDef[] = [
  { field: 'name', flex: 2, headerName: 'Legal Name' },
  { field: 'tokens', flex: 1, headerAlign: 'right', headerName: 'Tokens', type: 'number' },
  { field: 'walletType', flex: 1, headerName: 'Wallet type' },
  { field: 'whitelistStatus', flex: 1, headerName: 'Whitelist Status' },
  {
    field: 'url',
    flex: 2,
    headerName: 'Address',
    renderCell: ({ value }) => (
      <Link href={value} target="_blank" color="text.secondary" underline="hover">
        {value.split(/\?a=/g)[1]}
      </Link>
    ),
    sortable: false,
  },
];

const Tokens: FC<ITokensProps> = ({
  contractAddress,
  distributions,
  lastDateOfActivity,
  status,
}) => {
  const pageLimit = 10;
  const isCreatingTokens = status === PROJECT_STATUSES.CREATING_TOKENS;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const [investors, setInvestors] = useState<ITokensDistribution[]>([]);
  const [investorsCount, setInvestorsCount] = useState<number>(distributions.length);
  const [currentPage, setCurrentPage] = useState(0);
  const { promiseInProgress: loading } = usePromiseTracker({
    area: blockchainAreas.getProjectTokens,
  });

  const urlContractAddress = `${process.env.REACT_APP_BLOCKCHAIN_EXPLORER}/address/${contractAddress}`;
  const titleDateLastActivity = `Date of last activity: ${
    lastDateOfActivity && format(new Date(lastDateOfActivity), 'Pp')
  }`;

  const createMobileTableData = () => {
    return investors.map((dist) => ({
      id: dist.id,
      data: {
        'Legal Name': dist.name,
        Tokens: dist.tokens,
        'Wallet type': dist.walletType,
        'Whitelist status': dist.whitelistStatus,
        Address: (
          <Link href={dist.url} target="_blank" color="text.secondary" underline="hover">
            {dist.address}
          </Link>
        ),
      },
    }));
  };

  const transformToLowerCase = (value: string | number) => {
    if (typeof value === 'string') {
      return value.toLocaleLowerCase();
    }
    return value;
  };

  const handleSortTable = (alias: keyof ITokensDistribution, sortOrderBy: TSortOrder) => {
    const sorted = [...investors];
    sorted.sort((a, b) => {
      const first = transformToLowerCase(a[alias]);
      const second = transformToLowerCase(b[alias]);
      if (first > second) {
        return sortOrderBy === 'ASC' ? 1 : -1;
      }
      if (first < second) {
        return sortOrderBy === 'DESC' ? 1 : -1;
      }
      return 0;
    });
    setInvestors(sorted);
  };

  const createDownloadData = () => {
    const data = distributions.map((item: ITokensDistribution) => {
      const { name, whitelistStatus, walletType, tokens, url } = item;
      return {
        'Legal Name': name,
        'Wallet type': walletType,
        'Whitelist status': whitelistStatus,
        Tokens: tokens,
        Address: url,
      };
    });
    const extraData = [
      `Contract address: ${urlContractAddress}`,
      titleDateLastActivity.replace(',', ''),
      '',
    ];
    return { data, extraData };
  };

  const search = (value: string) => {
    const result = distributions.filter((dist) =>
      dist.name.toLowerCase().includes(value.toLowerCase())
    );
    setInvestorsCount(result.length);
    setInvestors(result);
  };

  const changePage = (newPage: number) => {
    const initialIndex = pageLimit * newPage;
    const finalIndex = initialIndex + pageLimit;
    setInvestors(distributions.slice(initialIndex, finalIndex));
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (distributions.length > 0 && !isMobile) {
      setInvestors(distributions);
    } else {
      setInvestors(distributions.slice(0, pageLimit));
      setInvestorsCount(distributions.length);
    }
  }, [distributions, isMobile]);

  return (
    <>
      {!isCreatingTokens && (
        <Box
          mt={3}
          mb={2}
          p={3}
          sx={{
            bgcolor: Gray[100],
            border: 1,
            borderColor: Gray[300],
            borderRadius: 2,
          }}
        >
          <Stack direction={{ md: 'row', xs: 'column' }} spacing={1}>
            <Typography variant="body1" color={Interactive.secondary}>
              <strong>Contract address:</strong>
            </Typography>
            <Typography
              variant="body1"
              color={Primary[500]}
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
                textDecoration: 'none',
                wordWrap: 'break-word',
              }}
              component="a"
              href={urlContractAddress}
              target="_blank"
            >
              <strong>{contractAddress}</strong>
            </Typography>
          </Stack>
        </Box>
      )}

      <Typography variant="h3" py={3}>
        Investors currently holding tokens
      </Typography>

      {!isCreatingTokens && (
        <Typography variant="body1" sx={{ wordWrap: 'break-word' }} mt={-1} pb={3}>
          {titleDateLastActivity}
        </Typography>
      )}

      {!isMobile && (
        <Table
          pageSize={10}
          rowsPerPageOptions={[10]}
          area={blockchainAreas.getProjectTokens}
          columns={columns}
          rows={investors}
          components={{ NoRowsOverlay: TokensEmptyState, Toolbar: TableHeader }}
          componentsProps={{
            noRowsOverlay: { creating: isCreatingTokens },
            toolbar: {
              creating: isCreatingTokens,
              downLoadData: createDownloadData(),
              search: search,
            },
          }}
        />
      )}
      {isMobile && (
        <>
          <TableHeader
            search={search}
            creating={isCreatingTokens}
            downLoadData={createDownloadData()}
            mobileSort={
              <SortSelectorFilter
                options={sortOptions}
                resetActiveSort={() => null}
                onChange={handleSortTable}
                activeSort={''}
                defaultValue={'Name'}
              />
            }
          />
          {loading && (
            <Box
              position="relative"
              minHeight="240px"
              borderRadius={2}
              border={`1px solid ${Gray[300]}`}
            >
              <OverlayLoader theme="container" area={blockchainAreas.getProjectTokens} />
            </Box>
          )}
          {!loading && investors.length === 0 ? (
            <TokensEmptyState creating={isCreatingTokens} />
          ) : (
            <List>
              {createMobileTableData().map((row) => (
                <MobileTableCard key={row.id} data={row.data} />
              ))}
            </List>
          )}
          <Paginator
            count={investorsCount}
            limit={pageLimit}
            page={currentPage}
            setPage={changePage}
          />
        </>
      )}
    </>
  );
};

export { Tokens };
