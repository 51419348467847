/* eslint-disable sort-keys */
import { Box, capitalize, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { IInvestmentProps, IProjectsInvestment, projectAreas } from '../../../api/projects';
import { DownloadButton } from '../../../components/DownloadButton';
import { InvestmentsEmptyState } from '../../../components/InvestmentsEmptyState';
import { OverlayLoader } from '../../../components/OverlayLoader';
import { Paginator } from '../../../components/Paginator';
import {
  ITransaction,
  ProjectInvestorsTable,
  TableHeader,
} from '../../../components/ProjectInvestors';
import { InvestmentCard } from '../../../components/ProjectInvestors/InvestmentCard';
import { InvestmentTableRow } from '../../../components/ProjectInvestors/InvestmentTableRow';
import {
  AverageInvestment,
  FundingStatus,
  InvestorsExceptions,
  RaiseStatus,
} from '../../../components/ProjectMetrics';
import { IObjGeneric } from '../../../helpers/csvExport';
import { Gray } from '../../../theme/tokens/colors'; // eslint-disable-line
import { IReportProps } from './Report.d';

const Report: FC<IReportProps> = ({
  metrics,
  investments,
  investmentsFilters,
  setInvestmentsFilters,
  handleTransactionStatus,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const { promiseInProgress: projectInvestmentsPromise } = usePromiseTracker({
    area: projectAreas.getProjectInvestments,
  });
  const [expendedInvestor, setExpendedInvestor] = useState('');
  const [investmentsFormated, setInvestmentsFormated] = useState<IObjGeneric[]>([]);
  const expandInvestor = (investor: string) => () => {
    setExpendedInvestor(investor === expendedInvestor ? '' : investor);
  };
  const hasAtLeastOneInvestment = investments.investments.length > 0;
  const showEmptyResultsState =
    !projectInvestmentsPromise && hasAtLeastOneInvestment && investments?.investments?.length === 0;
  const setPage = (newPage: number) => {
    setInvestmentsFilters({ ...investmentsFilters, page: newPage });
  };

  const formatInvestments = (arr: IInvestmentProps['investments']): IObjGeneric[] => {
    const data: IObjGeneric[] = [];
    arr.forEach((item: IProjectsInvestment) => {
      item.transactions.forEach((transaction: ITransaction) => {
        data.push({
          'Date': format(new Date(transaction.date), 'MM/dd/yyyy HH:mm'),
          'Legal Name': item.fullName,
          'Email': item.email,
          'Phone': item.phone,
          'Location': item.location,
          'Type': transaction.type.toUpperCase(),
          'Transaction Amount ($)': transaction.amount,
          'Transaction Fees ($)': transaction.fee,
          'Transaction Total Amount ($)': transaction.amount + transaction.fee,
          'Transaction Status': capitalize(transaction.status ?? '').replace('_', ' '),
          'Is Manual': transaction.isManual ? 'Yes' : 'No',
          'Whitelist Status': capitalize((item.whitelisted ?? '').toLowerCase()).replace('_', ' '),
          'TrackingRef':  transaction.trackingRef,
          'Origin Address' : transaction.sourceAddress,
          "Contract": transaction.transferId
        });
      });
    });
    return data;
  };

  useEffect(() => {
    const investmentsArr = investments?.investments;
    if (investmentsArr && investmentsArr.length) {
      setInvestmentsFormated(formatInvestments(investmentsArr));
    }
  }, [investments]);

  return (
    <>
      <Stack
        direction="row"
        spacing={3}
        sx={{ bgcolor: 'background.paper', mr: -3, overflow: 'auto' }}
      >
        <Box
          width={726}
          sx={{
            border: 1,
            borderColor: Gray[300],
            borderRadius: 2,
            padding: 3,
          }}
        >
          <Stack direction="row">
            <Box sx={{ borderRight: `1px solid ${Gray[300]}` }} pr={2}>
              <FundingStatus metrics={metrics} />
            </Box>
            <Box pl={3}>
              <RaiseStatus metrics={metrics} />
            </Box>
          </Stack>
        </Box>
        <Box>
          <AverageInvestment metrics={metrics} />
        </Box>
        <Box pr={3}>
          <InvestorsExceptions metrics={metrics} />
        </Box>
      </Stack>
      <Typography variant="h3" mb={3} mt={5}>
        Project investors
      </Typography>
      <ProjectInvestorsTable>
        <Box
          py={2}
          px={{ xs: 0, sm: 2 }} //eslint-disable-line
          sx={{
            borderBottom: ({ palette }) => ({ xs: 'none', sm: `1px solid ${palette.grey[300]}` }), //eslint-disable-line
          }}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <DownloadButton
            data={investmentsFormated}
            fileName={`transactions_report_${format(new Date(), 'MM/dd/yyyy')}`}
            disabled={Boolean(!investmentsFormated.length)}
          />
        </Box>
        {!isMobile && (
          <TableHeader
            headings={[
              'Legal Name',
              'Whitelist status',
              'Location',
              'Investment (USD)',
              'Date',
              'Transaction status',
            ]}
          />
        )}
        {!hasAtLeastOneInvestment && <InvestmentsEmptyState withoutInvestors />}
        {showEmptyResultsState && <InvestmentsEmptyState />}
        {investments?.investments?.map((investor) =>
          isMobile ? (
            <InvestmentCard
              key={investor.userId}
              onClick={expandInvestor(investor.userId)}
              isExpanded={investor.userId === expendedInvestor}
              reqTransactionStatus={handleTransactionStatus}
              {...investor}
            />
          ) : (
            <InvestmentTableRow
              key={investor.userId}
              onClick={expandInvestor(investor.userId)}
              isExpanded={investor.userId === expendedInvestor}
              reqTransactionStatus={handleTransactionStatus}
              {...investor}
            />
          )
        )}
        <Paginator
          count={investments?.count ?? 0}
          limit={investmentsFilters?.limit}
          page={investmentsFilters?.page}
          setPage={setPage}
        />
        <OverlayLoader theme="container" area={projectAreas.getProjectInvestments} />
      </ProjectInvestorsTable>
    </>
  );
};

export { Report };
