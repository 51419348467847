/* eslint-disable sort-keys */
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';

import { DownloadButton } from '../../../components/DownloadButton';
import { SearchBox } from '../../../components/SearchBox'; //eslint-disable-line
import { ITableHeader } from './Tokens.d';

const TableHeader: FC<ITableHeader> = ({ mobileSort, creating, downLoadData, search }) => {
  return (
    <Box
      px={{ xs: 0, sm: 2 }}
      py={2}
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={2}
      sx={{ borderBottom: ({ palette }) => ({ xs: 'none', sm: `1px solid ${palette.grey[300]}` }) }}
    >
      <Box width={{ xs: '100%', sm: '50%' }}>
        <SearchBox onSubmit={search} onClear={() => search('')} />
      </Box>
      {mobileSort}
      <DownloadButton
        data={downLoadData.data}
        fileName={`tokens_report_${format(new Date(), 'MM/dd/yyyy')}`}
        disabled={creating}
        extraData={downLoadData.extraData}
      />
    </Box>
  );
};
export { TableHeader };
