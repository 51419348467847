import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

import { Interactive } from '../../theme/tokens/colors';

const InvestorsExceptionsTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow placement={'top'} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: Interactive.secondary,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Interactive.secondary,
    maxWidth: 140,
  },
});

export { InvestorsExceptionsTooltip };
