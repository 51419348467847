import { Typography } from '@mui/material';
import { FC } from 'react';

import { Logo } from '../../components/Logo';
import { SimplePageContainer } from '../../components/SimplePageContainer';

const ErrorPage: FC = () => {
  return (
    <SimplePageContainer>
      <Logo mode="light" />
      <Typography variant="h1" mt={4} mb={1}>
        An error has occurred
      </Typography>
      <Typography variant="caption">
        If you need more information, contact the Growie admin.
      </Typography>
    </SimplePageContainer>
  );
};

export { ErrorPage };
