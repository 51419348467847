import { Alert, AlertTitle, Button, Snackbar, SvgIcon, Typography } from '@mui/material';
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconCircleX,
  IconInfoCircle,
  IconX,
} from '@tabler/icons';
import { FC, useContext, useEffect } from 'react';

import { toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { Error, Primary, Success, Warning } from '../../theme/tokens/colors';

const Toast: FC = () => {
  const colors: { [key: string]: string } = {
    error: Error[500],
    info: Primary[500],
    success: Success[500],
    warning: Warning[300],
  };
  const { isToastOpen, toastProps, closeToast } = useContext(toastContext);

  useEffect(() => {
    const closeTimer = setTimeout(closeToast, 5000);
    return () => clearTimeout(closeTimer);
  }, [closeToast]);

  return (
    <Snackbar
      open={isToastOpen}
      anchorOrigin={{ horizontal: toastProps?.horizontalPosition ?? 'center', vertical: 'top' }}
      sx={{
        '.MuiPaper-root': {
          borderRadius: '8px',
        },
        mr: toastProps?.mr,
        zIndex: (theme) => theme.zIndex.drawer + 5,
      }}
    >
      <Alert
        data-testid="toast"
        icon={
          (toastProps?.severity === 'success' && (
            <IconCircleCheck fontSize="inherit" color={Success[700]} />
          )) ||
          (toastProps?.severity === 'error' && (
            <IconCircleX fontSize="inherit" color={Error[600]} />
          )) ||
          (toastProps?.severity === 'info' && (
            <IconInfoCircle fontSize="inherit" color={Primary[700]} />
          )) ||
          (toastProps?.severity === 'warning' && (
            <IconAlertTriangle fontSize="inherit" color={Warning[600]} />
          ))
        }
        severity={toastProps?.severity}
        sx={{
          '.MuiAlert-message': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
          border: `1px solid ${colors[toastProps?.severity]}`,
          width: toastProps?.width,
        }}
        action={
          <Button aria-label="close" size="small" onClick={closeToast} color="inherit">
            <Typography
              variant="caption"
              color="inherit"
              mr={1}
              fontWeight={600}
              sx={{
                fontSize: {
                  sm: 13,
                },
              }}
            >
              Close
            </Typography>
            <SvgIcon fontSize="small" color="inherit">
              <IconX />
            </SvgIcon>
          </Button>
        }
      >
        {toastProps?.title && (
          <AlertTitle
            data-testid="toast-title"
            color="inherit"
            sx={{
              fontWeight: 600,
              marginBottom: 0,
            }}
          >
            {toastProps?.title}
          </AlertTitle>
        )}
        {toastProps?.message && (
          <Typography variant="body2" color="inherit" data-testid="toast-message">
            {toastProps?.message}
          </Typography>
        )}
      </Alert>
    </Snackbar>
  );
};

export { Toast };
