import { Step, StepLabel, Stepper as MuiStepper } from '@mui/material';
import { FC } from 'react';

import { IStepperProps } from './Stepper.d';
import { StepIconStyles, StepperConnector } from './Stepper.styles';

const Stepper: FC<IStepperProps> = ({ steps, ...rest }) => {
  const active = steps.findIndex((step) => step.active);

  return (
    <MuiStepper
      role="group"
      aria-label="progress"
      activeStep={active}
      connector={<StepperConnector />}
      {...rest}
    >
      {steps.map(({ label }, i) => (
        <Step key={`${i}-${label}`}>
          <StepLabel
            sx={{
              '.MuiStepLabel-label.MuiStepLabel-label': {
                color: 'primary.main',
                fontWeight: 'bold',
              },
            }}
            StepIconProps={StepIconStyles}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export { Stepper };
