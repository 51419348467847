import { Stack, Typography } from '@mui/material';

import EmptyInvestorsImg from '../../assets/investors/no-holdings.png';

const EmptyCommissionsPerProject = () => {
  return (
    <Stack height="350px" alignItems="center" justifyContent="center">
      <img width="220px" src={EmptyInvestorsImg} alt="There are no commissions per projects configured for this broker." />
      <Typography mt={3} variant="h2" color="text.secondary">
        There are no commissions per projects configured for this broker.
      </Typography>
    </Stack>
  );
};

export { EmptyCommissionsPerProject };
