import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { getInvestorInitials } from '../../helpers/fullName';
import { Primary } from '../../theme/tokens/colors'; //eslint-disable-line
import { IUserAvatar } from './UserDescriptionCard.d';

const UserAvatar: FC<IUserAvatar> = ({ legalName, sx }) => {
  return (
    <Box display="flex" alignItems="center" sx={sx}>
      <Box
        width={{ sm: 126, xs: 70 }}
        height={{ sm: 126, xs: 70 }}
        display="grid"
        bgcolor={Primary[200]}
        fontSize={{ sm: 32, xs: 28 }}
        fontWeight="bold"
        color="background.paper"
        flexShrink="0"
        mr={5}
        sx={{ borderRadius: '50%', placeContent: 'center' }}
      >
        {getInvestorInitials(legalName)}
      </Box>
      <Stack>
        <Typography variant="h3">{legalName}</Typography>
        <Typography variant="caption" fontWeight="bold">
          Legal name
        </Typography>
      </Stack>
    </Box>
  );
};
export { UserAvatar };
