import { Button } from '@mui/material';
import { IconDownload } from '@tabler/icons';
import { FC } from 'react';

import { csvExport } from '../../helpers/csvExport'; // eslint-disable-line
import { IDownloadButton } from './DownloadButton.d';

export const DownloadButton: FC<IDownloadButton> = ({ data, fileName, disabled, extraData }) => {
  return (
    <Button
      variant="outlined"
      startIcon={<IconDownload size={19} />}
      aria-haspopup="true"
      data-testid="download-button"
      onClick={() => csvExport(data, fileName, extraData)}
      sx={{
        borderRadius: '100px',
        fontSize: 14,
        height: '32px',
        lineHeight: '20px',
        py: '1px',
      }}
      disabled={disabled}
    >
      Download
    </Button>
  );
};
