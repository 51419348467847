import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { onboardingAreas } from '../../api/onBoarding';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { Logo } from '../../components/Logo';
import { SimplePageContainer } from '../../components/SimplePageContainer'; //eslint-disable-line
import { IProps } from './Expired.d';

const Expired: FC<IProps> = ({ resendEmail, mailSent, email }) => {
  const nav = useNavigate();

  return (
    <SimplePageContainer>
      <Logo mode="light" />
      <Typography variant="h1" mt={4} mb={mailSent ? 5 : 2}>
        {mailSent ? 'The email has been sent' : 'The temporary password has expired'}
      </Typography>
      <Typography variant="body1" mb={mailSent ? 5 : 3}>
        {mailSent ? (
          <>
            A temporary password has been send to the email{' '}
            <Box component="span" fontWeight={600}>
              {email}
            </Box>
            , please check your email.
          </>
        ) : (
          'The temporary password for creating your Growie password expires after 24 hours. Request temporary password link to finish your account creation.'
        )}
      </Typography>
      {mailSent ? (
        <Button variant="contained" onClick={() => nav('/login')} fullWidth>
          Login
        </Button>
      ) : (
        <ButtonSubmit area={onboardingAreas.resend} onClick={resendEmail}>
          <Box
            component="span"
            sx={{
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            Request another temporary password
          </Box>
        </ButtonSubmit>
      )}
    </SimplePageContainer>
  );
};

export { Expired };
