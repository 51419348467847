import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IRequireValidation } from './requireValidation.d';

const useRequireValidation = (): IRequireValidation => {
  const [isValidationModalOpen, setIsValidationModalOpen] = useState<boolean>(false);
  const [isValidationRequired, setIsValidationRequired] = useState<boolean>(false);
  const [navigationRoute, setNavigationRoute] = useState<string>('');
  const nav = useNavigate();

  const showValidationOnClick = (event: MouseEvent, route: string) => {
    if (isValidationRequired) {
      event.preventDefault();
      setNavigationRoute(route);
      setIsValidationModalOpen(true);
    }
  };

  const showValidationOnNavigation = (route: string) => {
    if (isValidationRequired) {
      setIsValidationModalOpen(true);
      setNavigationRoute(route);
    } else {
      nav(route);
    }
  };

  const clearValidation = () => {
    setIsValidationRequired(false);
    setIsValidationModalOpen(false);
    setNavigationRoute('');
  };

  return {
    clearValidation,
    isValidationModalOpen,
    isValidationRequired,
    navigationRoute,
    setIsValidationModalOpen,
    setIsValidationRequired,
    showValidationOnClick,
    showValidationOnNavigation,
  };
};

export { useRequireValidation };
