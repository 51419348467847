import { differenceInMinutes } from 'date-fns';

import { IRefreshTypes } from '../api/main'; //eslint-disable-line
import { decodeJWT } from './decodeJWT';

const refreshPercentage = 50;
export const calculateIfNeedToRefresh = (
  accessToken: string,
  tokenValidity: number
): IRefreshTypes => {
  const { exp } = decodeJWT(accessToken);
  const remaining = differenceInMinutes(new Date(exp * 1000), new Date());
  const validityInMintues = (tokenValidity * 1000) / 60000;
  const percentageToRefresh = (remaining * 100) / validityInMintues;
  if (percentageToRefresh <= 0) {
    return 'expired';
  }
  if (percentageToRefresh <= refreshPercentage) {
    return 'needToRefresh';
  }
  return 'active';
};
