import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IconChevronDown } from '@tabler/icons';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import { blockchainAreas } from '../../api/blockchain';
import { IUserData } from '../../api/session';
import { PROJECT_STATUSES } from '../../helpers/constants';
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext';
import { IProjectStatus, TContractStatus } from '../../pages/ProjectDetail';
import { Primary } from '../../theme/tokens/colors';
import { StatusChip } from '../StatusChip'; // eslint-disable-line
import { ConfirmDialog } from './ConfirmDialog';
import { getOptions } from './getOptions';
import { IOption, IProjectStatusBar, IValidationData } from './ProjectStatusBar.d';
import { PopperStyled } from './ProjectStatusBar.styled';
import { validateContractStatus } from './validateContract';
import { statusRequireValidation } from './validateStatus';

export const ProjectStatusBar: FC<IProjectStatusBar> = ({
  status,
  category,
  handleStatusChange,
  handleHideProject,
  handlePauseContract,
  hidden,
  metrics,
  contractStatus,
  projectName,
}) => {
  const nav = useNavigate();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const authData = useContext(authContext);
  const { promiseInProgress: loadingSmartContractStatus } = usePromiseTracker({
    area: blockchainAreas.getSmartContractStatus,
  });
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [temporalNextStatus, setTemporalNextStatus] = useState<IProjectStatus>();
  const [validationModalData, setValidationModalData] = useState<IValidationData>();
  const currentRoles = useMemo(() => (authData?.userData as IUserData)?.roles || [], [authData]);
  const options = getOptions(status, category, hidden, contractStatus).filter((item: IOption) =>
    havePermission(currentRoles, item.onlyFor)
  );
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const handleToggle = () => {
    setDisplayOptions((prevOpen) => !prevOpen);
  };

  const disableActions = (statusCode?: IProjectStatus, contract?: boolean) => {
    return (
      ((contract || statusCode === PROJECT_STATUSES.PROJECT_COMPLETED) &&
        (contractStatus?.includes('loading') || loadingSmartContractStatus)) ||
      (statusCode === PROJECT_STATUSES.CREATING_TOKENS && !metrics?.receivedInvestment)
    );
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if ((event.target as HTMLElement).localName === 'body') {
      return;
    }
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setDisplayOptions(false);
  };

  const changeStatus = (newStatus: IProjectStatus) => {
    const res = statusRequireValidation(status, newStatus, contractStatus, metrics?.exceptions);
    if (res) {
      setValidationModalData(res);
      setShowValidation(true);
      setTemporalNextStatus(newStatus);
      return;
    }
    if (newStatus === PROJECT_STATUSES.CREATING_TOKENS) {
      return nav('create-tokens', { state: { projectName } });
    }
    if (newStatus === PROJECT_STATUSES.PROJECT_COMPLETED) {
      return nav('complete-project');
    }
    handleStatusChange(newStatus);
  };

  const pauseContract = (contract: TContractStatus) => {
    setTemporalNextStatus(undefined);
    const res = validateContractStatus(contract);
    if (res) {
      setValidationModalData(res);
      setShowValidation(true);
    } else {
      handlePauseContract();
    }
  };

  const alertDialogMainAction = (confirm: boolean) => {
    if (!confirm) {
      return handleCloseDialog(false);
    }
    if (temporalNextStatus === PROJECT_STATUSES.PROJECT_COMPLETED) {
      handlePauseContract();
      setShowValidation(false);
      return;
    }
    handleCloseDialog(confirm);
  };

  const onClickMenuItem =
    (newStatus?: IProjectStatus, hideAction?: boolean, contract?: TContractStatus) => () => {
      if (newStatus) {
        changeStatus(newStatus);
      }
      if (hideAction !== undefined) {
        handleHideProject(hideAction);
      }
      if (contract) {
        pauseContract(contract);
      }
      handleToggle();
    };

  const handleCloseDialog = (confirm: boolean) => {
    if (confirm && temporalNextStatus) {
      handleStatusChange(temporalNextStatus);
    }
    if (confirm && validationModalData?.buttonLabel) {
      handlePauseContract();
    }
    setShowValidation(false);
  };

  useEffect(() => {
    const onResize = () => {
      if (displayOptions) {
        setDisplayOptions(false);
      }
    };

    if (displayOptions) {
      window.addEventListener('resize', onResize);
      window.addEventListener('orientationchange', onResize);
    } else {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('orientationchange', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('orientationchange', onResize);
    };
  }, [displayOptions]);

  return (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <StatusChip
          status={status}
          hidden={hidden}
          minWidth="250px"
          width={{ xs: '100%', lg: 'auto' }} //eslint-disable-line
        />
        {!!options.length && (
          <>
            {isDesktop ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                ml={{ xs: 2, lg: 4 }} //eslint-disable-line
              >
                {options.map((item: IOption, index: number) => {
                  const {
                    icon: Icon,
                    title,
                    status: statusCode,
                    hidden: hideAction,
                    contract,
                  } = item;
                  return (
                    <Button
                      key={`${statusCode}-${index}`}
                      variant="text"
                      startIcon={<Icon size={18} />}
                      onClick={onClickMenuItem(statusCode, hideAction, contract)}
                      size="medium"
                      color="secondary"
                      disabled={disableActions(statusCode, contract)}
                      sx={{
                        fontSize: 14,
                        lineHeight: '20px',
                        mr: 3,
                      }}
                    >
                      {title}
                    </Button>
                  );
                })}
              </Box>
            ) : (
              <Box
                ml={{ xs: 2, lg: 4 }} //eslint-disable-line
              >
                <IconButton
                  onClick={handleToggle}
                  color="primary"
                  size="small"
                  sx={{
                    border: `1px solid ${Primary[700]}`,
                    borderRadius: 1,
                    flexShrink: 0,
                    padding: 0,
                  }}
                  ref={anchorRef}
                  aria-label="Open Options Menu"
                >
                  <IconChevronDown color={Primary[500]} size={22} />
                </IconButton>
              </Box>
            )}
          </>
        )}
      </Box>

      {!!options.length && !isDesktop && (
        <PopperStyled
          open={displayOptions}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right top',
              }}
            >
              <Paper
                sx={({ palette }) => ({
                  border: `1px solid ${palette.grey[300]}`,
                  borderRadius: '8px',
                })}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={displayOptions}>
                    {options.map((item: IOption, index: number) => {
                      const {
                        icon: Icon,
                        title,
                        status: statusCode,
                        hidden: hideAction,
                        contract,
                      } = item;
                      return (
                        <MenuItem
                          key={`${statusCode}-${index}`}
                          onClick={onClickMenuItem(statusCode, hideAction, contract)}
                          disabled={disableActions(statusCode, contract)}
                          sx={({ palette }) => ({
                            '&:active': {
                              color: palette.secondary.main,
                              fontWeight: 600,
                            },
                            fontWeight: 300,
                          })}
                        >
                          <Icon size={16} />
                          <Box component="span" ml={1}>
                            {title}
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </PopperStyled>
      )}

      <ConfirmDialog
        theme={validationModalData?.text ? undefined : 'small'}
        display={showValidation}
        onClose={alertDialogMainAction}
        confirmButtonLabel={validationModalData?.buttonLabel}
        buttons={
          temporalNextStatus === PROJECT_STATUSES.CREATING_TOKENS && (
            <Button
              sx={{ mt: 4 }}
              fullWidth
              variant="contained"
              onClick={() => setShowValidation(false)}
            >
              Close
            </Button>
          )
        }
      >
        <Typography variant="h3" fontWeight="bold" color="primary" mb={1}>
          {validationModalData?.title}
        </Typography>
        <div>
          <Typography variant="body1" mb={4}>
            {validationModalData?.subtitle}
          </Typography>
          <Typography variant="body1">{validationModalData?.text}</Typography>
        </div>
      </ConfirmDialog>
    </>
  );
};
