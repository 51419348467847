import { IUser } from '../components/PageContent';

export const userRoles = [
  { alias: 'financial', label: 'Financial officer' },
  { alias: 'marketing', label: 'Marketing' },
  { alias: 'superAdmin', label: 'Super admin' },
  { alias: 'compliance', label: 'Compliance officer' },
  { alias: 'systemAdmin', label: 'System admin' },
  { alias: 'legal', label: 'Legal' },
];

export const beautifierUserRoles = (rawRoles: IUser['roles']): string[] => {
  return userRoles.filter((user) => rawRoles.includes(user.alias)).map((user) => user.label);
};
