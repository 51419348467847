import { FC } from 'react';

import { commissionsAreas } from '../../../../api/commissions';
import { ButtonSubmit } from '../../../../components/ButtonSubmit';
import { IButtonProps } from '../Commissions.d';

const PayButton: FC<IButtonProps> = ({ isDisabled, onPaySubmit }) => {
  return (
    <>
      <ButtonSubmit
        area={commissionsAreas.updateCommissionStatus}
        disabled={isDisabled}
        onClick={onPaySubmit}
        sx={{
          fontSize: 14,
          height: 32,
          width: 10,
        }}
        variant="contained"
      >
        Pay
      </ButtonSubmit>
    </>
  );
};

export { PayButton };
