import { Typography } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import {
  getPayoutsReportDetail,
  IReportInvestor,
  payoutsAreas,
  setPayoutsReportUserStatus,
} from '../../api/payouts';
import { OverlayLoader } from '../../components/OverlayLoader';
import { ConfirmationPaymentModal } from '../../components/PayoutsReportDetail';
import { isRequestError } from '../../helpers/isRequestError';
import { quantityBeautifierStandard } from '../../helpers/quantityBeautifier';
import { IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { PayoutReportDetail } from './PayoutReportDetail';
import { IReportFilters, ISelection } from './PayoutReportDetail.d';
import { paymentMethodDef, statusDef } from './statusDefinition';

const initialFilterValues = { payment_method: '', status: '' };

const PayoutReportDetailPresenter: FC = () => {
  const { reportId } = useParams();
  const [investorsReport, setInvestorsReport] = useState<IReportInvestor[]>([]);
  const [filteredInvestors, setFilteredInvestors] = useState<IReportInvestor[]>([]);
  const [search, setSearch] = useState<string>('');
  const { control, handleSubmit, reset, watch } = useForm<IReportFilters>({
    defaultValues: initialFilterValues,
  });
  const status = watch('status');
  const payment_method = watch('payment_method');
  const [selection, setSelection] = useState<ISelection[]>([]);
  const setPayoutsReportUserStatusController = useAbortRequest();
  const getPayoutsReportDetailController = useAbortRequest();
  const { openToast } = useContext(toastContext);
  const [showSetPaymentConfirmationModal, setShowSetPaymentConfirmationModal] = useState(false);
  const [currentPayment, setCurrentPayment] = useState<number>(0);
  const [showNotAllowedModal, setShowNotAllowedModal] = useState(false);
  const [newPaymentStatus, setNewPaymentStatus] = useState<string>();
  const { state } = useLocation();
  const navState = state as { date: string; projectName: string };
  const date = navState?.date;
  const projectName = navState?.projectName ?? '';
  const formattedDate = date ? format(new Date(date), 'MM/dd/yyyy') : '';

  const handleSelection = (array: GridSelectionModel) => {
    setSelection(array.map(a => ({ id: a as string, payoutAccount: investorsReport.find(i => i.id === a)?.payout_account === 'Yes' ? true : false })));
  }

  const reqReportInvestorsDetail = useCallback(async () => {
    try {
      const reportList = await getPayoutsReportDetail(
        reportId ?? '',
        getPayoutsReportDetailController.signal
      );
      setInvestorsReport(reportList);
      setFilteredInvestors(reportList);
    } catch (error) {
      const err = error as IServerError;
      openToast({
        message: err.message,
        severity: 'error',
        title: err.title || 'Something went wrong',
      });
    }
  }, [getPayoutsReportDetailController.signal, openToast, reportId]);

  const searchInvestor = (value: string, list: IReportInvestor[]) => {
    return list.filter((investor) => investor.name?.toLowerCase().includes(value.toLowerCase()));
  };

  const filterInvestors = (form: IReportFilters, list: IReportInvestor[]) => {
    return list.filter((investor) => {
      if (form.payment_method && form.status) {
        return (investor.payment_method === form.payment_method || (investor.payment_method === 'ACH' && form.payment_method === 'WIRE'))
        && investor.status === form.status;
      }
      if (form.payment_method) {
        return investor.payment_method === form.payment_method || (investor.payment_method === 'ACH' && form.payment_method === 'WIRE');
      }
      if (form.status) {
        return investor.status === form.status;
      }
      return investor;
    });
  };

  const applyFilter = (form: IReportFilters) => {
    const searched = searchInvestor(search, investorsReport);
    const filtered = filterInvestors(form, searched);
    setFilteredInvestors(filtered);
  };

  const applySearch = (value: string) => {
    setSearch(value);
    const filtered = filterInvestors({ payment_method, status }, investorsReport);
    const searched = searchInvestor(value, filtered);
    setFilteredInvestors(searched);
  };

  const resetFilters = () => {
    reset(initialFilterValues);
    applyFilter(initialFilterValues);
  };

  const resetSearch = () => applySearch('');

  const formatExportFile = () => {
    return investorsReport.map((investor) => ({
      'Legal Name': investor.name ?? '',
      'Tokens': investor.tokens.toFixed(2),
      'Total to distribute': investor.total.toFixed(2),
      'Payment date': investor.payment_date ?? '', //eslint-disable-line
      'Status': statusDef[investor.status],
      'Payment method': paymentMethodDef[investor.payment_method] ?? '', //eslint-disable-line
      'Payout account': investor.payout_account ? 'Yes' : 'No',
    }));
  };

  const reqPayoutsReportUserStatus = async (paymentStatus: string) => {
    const { data, status: statusCode } = await setPayoutsReportUserStatus(
      selection.map(s => s.id),
      paymentStatus,
      reportId ?? '',
      setPayoutsReportUserStatusController.signal
    );
    setShowSetPaymentConfirmationModal(false);

    if (!isRequestError(statusCode)) {
      openToast({
        message:
          'Your payout/s were successfully requested. Status for each transaction will be updated when we receive confirmation.',
        severity: 'success',
        title: 'Successful status update',
      });
    } else {
      const error = data as IServerError;
      openToast({
        message: error.message,
        severity: 'error',
        title: error.title || 'Something went wrong',
      });
    }
    setSelection([]);
    reqReportInvestorsDetail();
  };

  const validatePaymentStatus = useCallback(
    (payoutStatus: 'blacklisted' | 'paid_offline' | 'not_payable' | 'failed'): boolean =>
      filteredInvestors
        .filter((i) => selection.find(selections => selections.id === i.id))
        .some((i) => i.status === payoutStatus),
    [filteredInvestors, selection]
  );
  const hasBlacklistedSelected = useMemo(
    () => validatePaymentStatus('blacklisted'),
    [validatePaymentStatus]
  );
  const hasPaidOfflineSelected = useMemo(
    () => validatePaymentStatus('paid_offline'),
    [validatePaymentStatus]
  );
  const hasNotPayableSelected = useMemo(
    () => validatePaymentStatus('not_payable'),
    [validatePaymentStatus]
  );
  const hasNotPayoutAccount = useMemo(
    () => selection.some(s => s.payoutAccount === false),
    [selection]
  );
  const hasFailedSelected = useMemo(() => validatePaymentStatus('failed'), [validatePaymentStatus]);

  const handleSetPayment = (newStatus: string) => {
    if (
      (newStatus === 'PAY' && hasPaidOfflineSelected) ||
      (newStatus === 'PAY' && hasNotPayableSelected) ||
      (newStatus === 'PAY' && hasNotPayoutAccount) ||
      (newStatus === 'PENDING' && hasBlacklistedSelected) ||
      (newStatus === 'PENDING' && hasFailedSelected)
    ) {
      return setShowNotAllowedModal(true);
    }
    if (newStatus === 'PAY') {
      const selectedPayouts = filteredInvestors.filter((investor) =>
        selection.find(selections => selections.id === investor.id)
      );
      const totalPayment = selectedPayouts.reduce((acc, curr) => acc + curr.total, 0);
      setCurrentPayment(totalPayment);
    }
    setNewPaymentStatus(newStatus);
    setShowSetPaymentConfirmationModal(true);
  };

  useEffect(() => {
    reqReportInvestorsDetail();
  }, [reqReportInvestorsDetail]);

  return (
    <>
      <PayoutReportDetail
        reportDate={formattedDate}
        exportReport={formatExportFile}
        control={control}
        applyFilter={handleSubmit(applyFilter)}
        handleSearch={applySearch}
        investors={filteredInvestors}
        clearFilters={resetFilters}
        clearSearch={resetSearch}
        selection={selection}
        handleSelection={handleSelection}
        handleSetPayment={handleSetPayment}
        projectName={projectName}
      />
      <ConfirmationPaymentModal
        title="Are you sure you want to continue?"
        subtitle={
          <>
            {hasBlacklistedSelected && (
              <Typography>
                You are about to make payments to at least one investor that is not whitelisted.
              </Typography>
            )}
            {newPaymentStatus === 'PAY' ? (
              <Typography>
                You'll pay <strong>{quantityBeautifierStandard(currentPayment, true)}</strong> to{' '}
                <strong>{selection.length}</strong> selected investors.
              </Typography>
            ) : (
              <Typography>You’ll change the payment status for selected investors.</Typography>
            )}
          </>
        }
        primaryButtonText="Cancel"
        secondaryButtonText={newPaymentStatus === 'PAY' ? 'Pay' : 'Change status'}
        onClose={() => setShowSetPaymentConfirmationModal(false)}
        open={showSetPaymentConfirmationModal ?? false}
        finishWorking={() => setShowSetPaymentConfirmationModal(false)}
        mainAction={() => {
          reqPayoutsReportUserStatus(newPaymentStatus ?? '');
        }}
      />
      <ConfirmationPaymentModal
        title="This operation is not allowed"
        subtitle={
          <>
            {hasBlacklistedSelected && (
              <Typography>
                You have selected at least one investor that is <strong>not whitelisted</strong>,
                please remove them from your selection and try again.
              </Typography>
            )}
            {hasFailedSelected && (
              <Typography>
                You have selected investors with <strong>failed</strong> payments, deselect those
                investors and make payments again.
              </Typography>
            )}
            {hasPaidOfflineSelected && !hasNotPayableSelected && (
              <Typography>
                You have selected at least one investor with <strong>offline payments</strong>,
                please deselect them and try again.
              </Typography>
            )}
            {hasNotPayableSelected && !hasPaidOfflineSelected && (
              <Typography>
                You have selected at least one investor with <strong>no payout method</strong>{' '}
                configured, please deselect them and try again.
              </Typography>
            )}
            {hasNotPayableSelected && hasPaidOfflineSelected && (
              <Typography>
                You have selected at least one investor with <strong>offline payments</strong> and{' '}
                <strong>no payout method</strong> configured, please deselect them and try again.
              </Typography>
            )}
            {hasNotPayoutAccount && (
              <Typography>
                You have selected at least one investor without a <strong>payout account</strong>, please deselect them and try again.
              </Typography>
            )}
          </>
        }
        primaryButtonText="Cancel"
        onClose={() => setShowNotAllowedModal(false)}
        open={showNotAllowedModal ?? false}
        finishWorking={() => setShowNotAllowedModal(false)}
      />
      <OverlayLoader area={payoutsAreas.getPayoutsReportDetail} theme="main" />
    </>
  );
};
export { PayoutReportDetailPresenter };
