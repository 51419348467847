import { Box, Grid, Typography } from '@mui/material';

import investmentBillSvg from '../../assets/project-details/investment-bill.svg';
import { Gray } from '../../theme/tokens/colors';

export const EmptyReports: React.FC = () => {
  return (
    <Grid
      container
      item
      justifyContent="center"
      py={{ lg: 0, md: 0, sm: '7.0rem', xs: 3 }}
      mb={{ lg: 1, xs: 0 }}
    >
      <Grid item xs="auto">
        <Box component="img" alt="" src={investmentBillSvg} mr={3} />
      </Grid>
      <Grid item xs="auto" mb={{ sm: 0, xs: '12.4rem' }}>
        <Box maxWidth={{ sm: '241px', xs: 'initial' }}>
          <Typography
            variant="body1"
            fontWeight="600"
            color={Gray[500]}
            textAlign={{ sm: 'initial', xs: 'center' }}
          >
            {'There are no reports yet'}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="300"
            color={Gray[500]}
            maxWidth='240px'
            textAlign={{ sm: 'initial', xs: 'center' }}
            mt={1}
          >
            {'Reports can be uploaded after Tokens Delivered project state.'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
