import { LinearProgress as MUILinear, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Primary } from '../../theme/tokens/colors';

const LinearProgress = styled(MUILinear)(({ theme }) => ({
  borderRadius: 5,
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: Primary[300],
    borderRadius: 5,
  },
}));

export { LinearProgress };
