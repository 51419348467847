import { Switch as SwitchMUI } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Gray, Primary } from '../../theme/tokens/colors'; //eslint-disable-line

export const Switch = styled(SwitchMUI)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${Primary[500]};
  }
  & .MuiSwitch-switchBase {
    color: ${Gray[600]};
  }
  & .MuiSwitch-track {
    background-color: ${Gray[300]};
    opacity: 1;
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${Gray[300]};
    opacity: 1;
  }
  & .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked:hover {
    background-color: ${Primary[500] + '22'};
  }
`;
