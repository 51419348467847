import { responseChecker } from "../../helpers/responseChecker"; // eslint-disable-line

import { IResendEmailReturn } from "./onboarding.d";

export const resendConfirmationEmail = ( data: IResendEmailReturn ): IResendEmailReturn => {
  responseChecker<IResendEmailReturn>(data, ['message', 'id']);
  const { message, id } = data;
  return {
    id,
    message,
  }
}