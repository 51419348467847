import { TContractStatus } from '../../pages/ProjectDetail'; //eslint-disable-line
import { IValidationData } from './ProjectStatusBar.d';

const pausedContractValidationText: IValidationData = {
  buttonLabel: 'Pause contract',
  subtitle: 'Are you sure you want to pause the smart contract?',
  text: 'Secondary Market operations will be paused until contract is resumed.',
  title: 'Pause smart contract',
};
const activeContractValidationText: IValidationData = {
  buttonLabel: 'Resume contract',
  subtitle: 'Are you sure you want to resume the smart contract?',
  text: 'Secondary Market operations will be enabled.',
  title: 'Resume smart contract',
};

const statusValidation: { [key in TContractStatus]?: IValidationData | null } = {
  active: activeContractValidationText,
  active_loading: null,
  paused: pausedContractValidationText,
  paused_loading: null,
};

const validateContractStatus = (contractStatus: TContractStatus) =>
  statusValidation[contractStatus];

export { validateContractStatus };
