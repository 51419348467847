import { Box, Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Stepper } from '../../components/Stepper'; //eslint-disable-line
import { ICreateTokens } from './CreateTokens.d';

const CreateTokens: FC<ICreateTokens> = ({ children, steps, validateOnPageLeave, projectName }) => {
  const large = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { id } = useParams();

  return (
    <Stack justifyContent="space-between">
      <Button
        color="secondary"
        onClick={validateOnPageLeave}
        startIcon={<IconArrowLeft />}
        sx={{ alignSelf: 'flex-start', fontSize: '12px', mb: 4 }}
      >
        Back to project detail
      </Button>
      <Breadcrumbs
        current="Create tokens"
        linksList={[
          { label: 'Home', path: '/' },
          { label: 'Projects', path: '/projects' },
          { label: 'Project details', path: `/projects/${id}` },
        ]}
      />
      <Typography variant="h2" fontWeight="bold" my={3}>
        Create and distribute tokens for {projectName}
      </Typography>
      <Stack direction={large ? 'row' : 'column'} justifyContent="space-between">
        <Box mb={3}>
          <Stepper
            steps={steps}
            alternativeLabel={large ? false : true}
            orientation={large ? 'vertical' : 'horizontal'}
          />
        </Box>
        <Box
          sx={{ width: { xs: '100%', md: '75%' } }} //eslint-disable-line
        >
          {children}
        </Box>
      </Stack>
    </Stack>
  );
};

export { CreateTokens };
