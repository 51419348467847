import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { IReturnError, reportError } from '../../helpers/reportError';
import { ICreatePasswordReq } from '../../pages/RecoverPassword';
import { IMessage, internalUserApi, IReturnType } from '../main'; // eslint-disable-line
import { areas } from './areas';

const recoverPassword = async (email: string): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      internalUserApi.post('users/forgotPassword', { email }),
      areas.emailForm
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const settingPassword = async (
  params: ICreatePasswordReq,
  signal: AbortSignal
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      internalUserApi.post('users/confirmForgotPassword', { ...params }, { signal }),
      areas.codeForm
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

export { recoverPassword, settingPassword };
