import { AppBar, Box, Stack, Theme, Toolbar, useMediaQuery } from '@mui/material';
import { FC, useContext } from 'react';

import { IAuth } from '../../api/session';
import { isRequestError } from '../../helpers/isRequestError';
import { authContext } from '../../hooks/useAuth/authContext';
import { toastContext } from '../../hooks/useToast';
import { AvatarName } from '../AvatarName';
import { HeaderMenuButton } from '../HeaderMenuButton';
import { Logo } from '../Logo';
import { LogoutButton } from '../LogoutButton'; // eslint-disable-line
import { IProps } from './Header.d';

const Header: FC<IProps> = ({
  toggleNavbar,
  withoutMenu,
  headerHeight,
  openNavbar = false,
  toLogout,
}) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { logout, auth } = useContext(authContext);
  const { openToast, closeToast } = useContext(toastContext);
  const onLogout = async () => {
    closeToast();
    const authFn = auth as IAuth;
    const { data, status } = await toLogout(authFn.accessToken, authFn.refreshToken);
    if (isRequestError(status)) {
      openToast({
        message: 'There was a technical error in the application, please log in again.',
        severity: 'warning',
        title: data.message,
        width: isTablet ? 593 : 'auto',
      });
    } else {
      logout(true);
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack direction="row" alignItems="center">
            {!withoutMenu && (
              <HeaderMenuButton
                openNavbar={openNavbar}
                toggleNavbar={toggleNavbar}
                headerHeight={headerHeight}
              />
            )}
            <Logo />
          </Stack>
          {!withoutMenu && (
            <Stack direction="row" alignItems="center">
              <Box mr={2}>
                <AvatarName />
              </Box>
              <LogoutButton onClick={onLogout} />
            </Stack>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
export { Header };
