import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import expired from '../../assets/session-expired/expired.svg';
import { authContext } from '../../hooks/useAuth/authContext';
import { Gray } from '../../theme/tokens/colors';

export const SessionExpired: FC = () => {
  const nav = useNavigate();
  const { logout } = useContext(authContext);
  const logoutSended = useRef<boolean>(false);

  useEffect(() => {
    if (!logoutSended.current) {
      logoutSended.current = true;
      logout(true);
    }
  }, [logout]);

  return (
    <Box
      mx="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      pt={{
        xs: '58px',
        sm: '72px', //eslint-disable-line
        xl: '244px', //eslint-disable-line
      }}
      pb={{
        xs: '78px',
        sm: '150px', //eslint-disable-line
        xl: '300px', //eslint-disable-line
      }}
      px={{
        xs: '24px', //eslint-disable-line
        sm: '32px', //eslint-disable-line
        md: 0, //eslint-disable-line
      }}
    >
      <Grid
        justifyContent="center"
        alignItems="center"
        columns={{
          xs: 4,
          sm: 8, //eslint-disable-line
          md: 12, //eslint-disable-line
        }}
        columnSpacing={{ md: 4 }}
        container
      >
        <Grid
          item
          xs={4}
          sm={6.5}
          md={4.8}
          mb={{
            xs: 6,
            md: 0, //eslint-disable-line
          }}
        >
          <Typography variant="h1" color={Gray[700]} mb={3}>
            Your session has expired
          </Typography>

          <Typography variant="body1" color="#000" mb={3}>
            You’ve been inactive for some time, for security reasons we have finished your session.
          </Typography>

          <Button variant="contained" onClick={() => nav('/login')}>
            Go to Login
          </Button>
        </Grid>

        <Grid item xs={4} sm={7} md={4.65} sx={{ textAlign: 'center' }}>
          <img src={expired} alt="Session Expired" width="100%" style={{ maxWidth: '451px' }} />
        </Grid>
      </Grid>
    </Box>
  );
};
