import { IconButton, SvgIcon } from '@mui/material';
import { IconMenu2, IconX } from '@tabler/icons';
import { FC } from 'react';

import { Primary } from '../../theme/tokens/colors'; //eslint-disable-line
import { IProps } from './HeaderMenuButton.d';

const HeaderMenuButton: FC<IProps> = ({ openNavbar, toggleNavbar, headerHeight }) => {
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label={openNavbar ? 'close' : 'menu'}
      size="large"
      onClick={toggleNavbar}
      sx={{
        '&:hover': {
          backgroundColor: Primary[700],
        },
        backgroundColor: Primary[500],
        borderRadius: 0,
        height: headerHeight,
        ml: { xs: -2, sm: -3 }, //eslint-disable-line
        mr: 2,
        width: headerHeight,
      }}
    >
      {openNavbar ? (
        <SvgIcon sx={{ color: 'background.paper' }}>
          <IconX />
        </SvgIcon>
      ) : (
        <IconMenu2 />
      )}
    </IconButton>
  );
};

export { HeaderMenuButton };
