/* eslint-disable sonarjs/no-duplicate-string */
import { GridSelectionModel } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { IInvestor } from '../../components/PageContent';
import { arrayPartition } from '../../helpers';
import { isRequestError } from '../../helpers/isRequestError';
import { IReturnError, reportError } from '../../helpers/reportError';
import { getInvestorDetail } from '../investors';
import { IReturnType, payoutsApi, tokensApi } from '../main'; //eslint-disable-line
import { areas } from './areas';
import { decoratePayoutsReportInvestor } from './decorators';
import {
  IApprovePayoutsPlan,
  ICalculatePayoutsPlan,
  IPayoutsPlan,
  IPayoutsReport,
  IReportInvestor,
  IReportInvestorResponse,
} from './payouts.d';

const getPayoutsReport = async (
  id: string,
  signal: AbortSignal
): Promise<AxiosResponse<IReportInvestorResponse[]>> => {
  try {
    return await tokensApi.get('payouts-report', { params: { reportId: id }, signal });
  } catch (error) {
    const err = error as Error;
    throw new Error(err.message);
  }
};

const getPayoutsReportDetail = (id: string, signal: AbortSignal): Promise<IReportInvestor[]> => {
  return trackPromise(
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await getPayoutsReport(id, signal);
        const userPromises = data.map((user) => getInvestorDetail(user.userId, signal));
        const res = await Promise.all(userPromises);
        if (res.some((response) => isRequestError(response.status))) {
          throw new Error('Network Error');
        }
        const users = res.map((user) => user.data) as IInvestor[];
        resolve(decoratePayoutsReportInvestor(data, users));
      } catch (error) {
        reject(error);
      }
    }),
    areas.getPayoutsReportDetail
  );
};

const MAX_USER_PAYMENT_STATUS_HANDLING = 25;
const setPayoutsReportUserStatus = async (
  userIds: GridSelectionModel,
  newStatus: string,
  reportId: string,
  signal: AbortSignal
): Promise<IReturnType<string> | IReturnError> => {
  try {
    const userIdsPartition = arrayPartition(userIds, MAX_USER_PAYMENT_STATUS_HANDLING);
    const req = userIdsPartition.map((chunk: GridSelectionModel) =>
      payoutsApi.put(
        'payouts-report',
        {
          newStatus: newStatus,
          reportId: reportId,
          userIds: chunk,
        },
        { signal }
      )
    );
    await trackPromise(Promise.all(req), areas.setPayoutsReportUserStatus);
    return { data: 'OK', status: 200 };
  } catch (error) {
    return reportError(error);
  }
};

const calculatePayoutsPlan = async (
  id: string,
  value: number,
  signal: AbortSignal
): Promise<IReturnType<ICalculatePayoutsPlan> | IReturnError> => {
  try {
    const res: AxiosResponse<ICalculatePayoutsPlan> = await trackPromise(
      tokensApi.post(
        'payouts-plan',
        {
          amountToDistribute: value,
          projectId: id,
        },
        { signal }
      ),
      areas.getPayoutsPlan
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getPayoutsPlan = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<IPayoutsPlan[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IPayoutsPlan[]> = await trackPromise(
      payoutsApi.get('payouts-plan', { params: { projectId: id }, signal }),
      areas.getPayoutsPlan
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const approvePayoutsPlan = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<IApprovePayoutsPlan> | IReturnError> => {
  try {
    const res: AxiosResponse<IApprovePayoutsPlan> = await trackPromise(
      tokensApi.post('payouts-plan/approve', { projectId: id }, { signal }),
      areas.approvePayoutsPlan
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getPayoutsReportsList = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<IPayoutsReport[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IPayoutsReport[]> = await trackPromise(
      tokensApi.get('payouts-report', { params: { projectId: id }, signal }),
      areas.getPayoutsReportsList
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

export {
  approvePayoutsPlan,
  calculatePayoutsPlan,
  getPayoutsPlan,
  getPayoutsReportDetail,
  getPayoutsReportsList,
  setPayoutsReportUserStatus,
};
