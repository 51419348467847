import { Box, capitalize, Collapse, Link, Stack, SvgIcon, Typography } from '@mui/material';
import { IconPlus } from '@tabler/icons';
import { format } from 'date-fns';
import { FC, useContext, useMemo } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';

import { INVESTMENT_STATUSES, PROJECT_STATUSES } from '../../helpers/constants';
import { havePermission } from '../../helpers/havePermission';
import { formatDollars } from '../../helpers/quantityBeautifier';
import { authContext } from '../../hooks/useAuth/authContext';
import { IProjectOutletContext } from '../../pages/ProjectDetail/ProjectDetail.d';
import { CopyClipboard } from '../CopyClipboard'; //eslint-disable-line
import { ITableRowDetail, TransactionActions } from './';

const InvestmentDetailCard: FC<ITableRowDetail> = ({
  isExpanded,
  transactions,
  email,
  phone,
  userId,
  transactionStatus,
  whitelisted,
}) => {
  const TEXT_COLOR = 'text.primary';
  const { userData } = useContext(authContext);
  const currentRoles = useMemo(() => userData?.roles || [], [userData]);
  const userCanRefund = havePermission(currentRoles, ['financial', 'superAdmin']);
  const { status } = useOutletContext<IProjectOutletContext>();

  return (
    <Collapse in={isExpanded} sx={{ bgcolor: 'secondary.light' }}>
      <Box p={2}>
        <Typography mb={1} fontWeight="bold" variant="body1" fontSize="14px" color={TEXT_COLOR}>
          Contact info:
        </Typography>
        <CopyClipboard value={email ?? ''} />
        {phone && <CopyClipboard value={phone ?? ''} />}
        <Link
          mt={{ xs: 0, sm: 2 }} //eslint-disable-line
          component={RouterLink}
          to={`/investors/${userId}`}
          display="flex"
          alignItems="center"
          color="secondary"
          underline="hover"
        >
          See investor detail
          <SvgIcon color="secondary" fontSize="small">
            <IconPlus />
          </SvgIcon>
        </Link>
      </Box>
      <Typography
        variant="body1"
        fontSize="14px"
        color="text.primary"
        fontWeight="bold"
        pb={2}
        pl={2}
        sx={{ borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}` }}
      >
        Transactions
      </Typography>
      {transactions.map((transaction) => (
        <Box
          key={transaction.transferId}
          sx={{ borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}` }}
        >
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              Amount:
            </Typography>
            <Typography>{formatDollars(transaction.amount, true)}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              Fees:
            </Typography>
            <Typography>{formatDollars(transaction.fee, true)}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              Total Amount:
            </Typography>
            <Typography>{formatDollars(transaction.amount + transaction.fee, true)}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              Type:
            </Typography>
            <Typography>{capitalize(transaction.type)}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              Date:
            </Typography>
            <Typography>{format(new Date(transaction.date), 'MM/dd/yyyy HH:mm')}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              {transaction.type === 'crypto' ? 'Source Address:' : 'Tracking Reference:'}
            </Typography>
            <Typography component="span" pr={2} sx={{ overflowWrap: 'anywhere' }}>
              {transaction.type === 'crypto' ? transaction.sourceAddress : transaction.trackingRef}
            </Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              Manual Investment:
            </Typography>
            <Typography component="span" pr={2}>
              {transaction.isManual ? 'Yes' : 'No'}
            </Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              Status:
            </Typography>
            <Typography>{capitalize(transaction.status ?? '').replace('_', ' ')}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="40% 60%" py={1} pl={2}>
            <Typography fontWeight="bold" fontSize="12px" sx={{ color: TEXT_COLOR }}>
              Actions:
            </Typography>
            {userCanRefund && (
              <Stack direction="row" spacing={1} mr={1}>
                {transaction.status === INVESTMENT_STATUSES.RECEIVED &&
                  [PROJECT_STATUSES.DRAFT, PROJECT_STATUSES.ACTIVE, PROJECT_STATUSES.PAUSED, PROJECT_STATUSES.CLOSED, PROJECT_STATUSES.CANCELED, PROJECT_STATUSES.COMPLETED].includes(
                    status as PROJECT_STATUSES
                  ) &&
                  (whitelisted === 'unconfirmed' ||
                    transaction.isManual ||
                    process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true') && (
                    <TransactionActions
                      status={INVESTMENT_STATUSES.TO_REFUND}
                      area={`setTransactionStatus/${transaction.transferId}-to_refund`}
                      onClick={() => transactionStatus(transaction.transferId, INVESTMENT_STATUSES.TO_REFUND)}
                    />
                  )}
                {transaction.status === INVESTMENT_STATUSES.TO_REFUND && (
                  <>
                    {process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'false' &&
                      !transaction.isManual && (
                        <TransactionActions
                          variant="contained"
                          status={INVESTMENT_STATUSES.REFUND_INPROCESS}
                          area={`setTransactionStatus/${transaction.transferId}-refund_inprocess`}
                          onClick={() =>
                            transactionStatus(transaction.transferId, INVESTMENT_STATUSES.REFUND_INPROCESS)
                          }
                        />
                      )}
                    <TransactionActions
                      status={INVESTMENT_STATUSES.MANUALLY_REFUNDED}
                      area={`setTransactionStatus/${transaction.transferId}-manually_refunded`}
                      onClick={() => transactionStatus(transaction.transferId, INVESTMENT_STATUSES.MANUALLY_REFUNDED)}
                    />
                  </>
                )}
                {(transaction.status === INVESTMENT_STATUSES.COMMITTED ||
                  transaction.status === INVESTMENT_STATUSES.PENDING ||
                  transaction.status === INVESTMENT_STATUSES.DELAYED) &&
                  (transaction.isManual ||
                    process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true') && (
                    <TransactionActions
                      status={INVESTMENT_STATUSES.RECEIVED}
                      area={`setTransactionStatus/${transaction.transferId}-received`}
                      onClick={() => transactionStatus(transaction.transferId, INVESTMENT_STATUSES.RECEIVED)}
                    />
                  )}
              </Stack>
            )}
          </Box>
        </Box>
      ))}
    </Collapse>
  );
};

export { InvestmentDetailCard };
