import { AxiosResponse } from 'axios';
import CryptoJS from 'crypto-js';
import { trackPromise } from 'react-promise-tracker';

import { messageDecorator } from '../../helpers/messageDecorator';
import { IReturnError,reportError } from '../../helpers/reportError';
import { ILoginFormFields } from '../../pages/Login';
import { ICreatePasswordReq } from '../../pages/Onboarding';
import { IMessage, internalUserApi, IReturnType } from '../main'; // eslint-disable-line
import { areas } from './areas';
import {
  resendConfirmationEmail as resendConfirmationEmailDecorator
} from './decorators';
import { IResendEmailReturn } from './onboarding.d';

const createPassword = async (
  createPasswordReq: ICreatePasswordReq,
  signal: AbortSignal
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const req = {
      ...createPasswordReq,
      newPassword: CryptoJS.AES.encrypt(
        createPasswordReq.newPassword,
        createPasswordReq.email
      ).toString(),
      password: CryptoJS.AES.encrypt(
        createPasswordReq.password,
        createPasswordReq.email
      ).toString(),
    };
    const res: AxiosResponse<IMessage> = await trackPromise(
      internalUserApi.post('/users/createPassword', req, { signal }),
      areas.createPassword
    );
    return {
      data: messageDecorator(res.data),
      status: res.status
    };
  } catch (error) {
    return reportError(error);
  }
};

const resendConfirmationEmail = async (
  email: string,
  signal: AbortSignal
): Promise<IReturnType<IResendEmailReturn> | IReturnError> => {
  try {
    const res = await trackPromise(
      internalUserApi.post('/users/resendEmail', { adminUser: { email } }, { signal }),
      areas.resend
    );
    return {
      data: resendConfirmationEmailDecorator(res.data),
      status: res.status
    };
  } catch (error) {
    return reportError(error);
  }
};

const validateTempPassword = async (
  validateData: ILoginFormFields,
  signal: AbortSignal
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      internalUserApi.post('/users/verifyPassword', validateData, { signal })
    );
    return {
      data: messageDecorator(res.data),
      status: res.status
    };
  } catch (error) {
    return reportError(error);
  }
};

export { createPassword, resendConfirmationEmail, validateTempPassword };
