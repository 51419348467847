import {
  IconBriefcase,
  IconBuilding,
  IconBuildingCommunity,
  IconReportMoney,
  IconUserPlus,
  IconUsers,
} from '@tabler/icons';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IActions } from '../../components/ActionsButtons';
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext'; //eslint-disable-line
import { Home } from './home';
import { IWelcomeText } from './home.d';

const welcomeTexts: IWelcomeText[] = [
  {
    role: ['superAdmin'],
    text: 'You are the Super admin in the Growie Admin tool, here you can create and manage users, investors, and projects. These are some quick actions that will help you:',
  },
  {
    role: ['systemAdmin'],
    text: 'You are the System admin in the Growie Admin tool, here you can manage users, view investors, and projects. These are some quick actions that will help you:',
  },
  {
    role: ['compliance'],
    text: 'You are the Compliance officer in the Growie Admin tool, here you can view users, manage investors, and projects. These are some quick actions to help you:',
  },
  {
    role: ['financial'],
    text: 'You are the Financial officer in the Growie Admin tool, here you can view projects. These are some quick actions to help you:',
  },
  {
    role: ['legal'],
    text: 'You are the Legal officer in the Growie Admin tool, here you can view investor details and projects. These are some quick actions to help you:',
  },
  {
    role: ['marketing'],
    text: 'You are the Marketing officer in the Growie Admin tool, here you can view projects. These are some quick actions to help you:',
  },
];

const HomePresenter: FC = () => {
  const nav = useNavigate();
  const { userData } = useContext(authContext);
  const currentRoles = useMemo(() => userData?.roles || [], [userData]);
  const [currentWelcomeText, setCurrentWelcomeText] = useState('');
  const rawQuickActions: IActions[] = [
    {
      action: 'create_user',
      icon: IconUserPlus,
      id: 1,
      justForRoles: ['superAdmin', 'systemAdmin'],
      name: 'Create users',
      onClick: () => nav('/users', { state: { creatingUser: true } }),
    },
    {
      action: 'view_users',
      icon: IconUsers,
      id: 2,
      justForRoles: ['compliance', 'superAdmin', 'systemAdmin'],
      name: 'View users',
      onClick: () => nav('/users'),
    },
    {
      action: 'view_investors',
      icon: IconBriefcase,
      id: 3,
      justForRoles: ['superAdmin', 'legal', 'compliance', 'financial', 'marketing'],
      name: 'View investors',
      onClick: () => nav('/investors'),
    },
    {
      action: 'view_projects',
      icon: IconBuilding,
      id: 4,
      justForRoles: ['superAdmin', 'systemAdmin', 'marketing', 'legal', 'compliance', 'financial'],
      name: 'View projects',
      onClick: () => nav('/projects'),
    },
    {
      action: 'create_projects',
      icon: IconBuildingCommunity,
      id: 5,
      justForRoles: ['superAdmin', 'marketing', 'financial'],
      name: 'Create projects',
      onClick: () => nav('/projects', { state: { creatingProject: true } }),
    },
    {
      action: 'view_investments',
      icon: IconReportMoney,
      id: 6,
      justForRoles: ['superAdmin', 'financial'],
      name: 'View investments',
      onClick: () => nav('/investments', { state: { creatingProject: true } }),
    },
  ];

  const quickActions = rawQuickActions.filter((item) =>
    havePermission(currentRoles, item.justForRoles)
  );

  useEffect(() => {
    const currentWelcomeMessage = () => {
      if (currentRoles.length > 1) {
        return 'You have multiple roles in the Growie Admin tool. These are some quick actions to help you:';
      } else if (currentRoles.length === 1) {
        const welcomeText = welcomeTexts.filter((item) => item.role[0] === (currentRoles[0] || ''));
        return welcomeText[0]?.text;
      } else {
        return '';
      }
    };
    setCurrentWelcomeText(currentWelcomeMessage());
  }, [currentRoles]);

  return (
    <Home
      userName={`${userData?.firstName} ${userData?.lastName}` ?? 'No name'}
      quickActions={quickActions}
      welcomeText={currentWelcomeText}
    />
  );
};

export { HomePresenter };
