import { Box, capitalize, Collapse, Link, Stack, SvgIcon, Typography } from '@mui/material';
import { IconPlus } from '@tabler/icons';
import { format } from 'date-fns';
import { FC, useContext, useMemo } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';

import { INVESTMENT_STATUSES, PROJECT_STATUSES } from '../../helpers/constants';
import { havePermission } from '../../helpers/havePermission';
import { formatDollars } from '../../helpers/quantityBeautifier';
import { authContext } from '../../hooks/useAuth/authContext';
import { IProjectOutletContext } from '../../pages/ProjectDetail/ProjectDetail.d';
import { Primary } from '../../theme/tokens/colors';
import { CopyClipboard } from '../CopyClipboard'; //eslint-disable-line
import type { ITableRowDetail } from '.'; //eslint-disable-line
import { TransactionActions } from './TransactionActions'; //eslint-disable-line

const TableRowDetail: FC<ITableRowDetail> = ({
  isExpanded,
  transactions,
  email,
  phone,
  userId,
  transactionStatus,
  whitelisted,
}) => {
  const transactionHeadings = [
    'Amount (USD)',
    'Fees',
    'Total Amount',
    'Type',
    'Date',
    'TrackingRef / Source Address',
    'Manual investment',
    'Status',
    'Contract',
    'Actions',
  ];
  const SECONDARY_LIGHT_COLOR = 'secondary.light';
  const { userData } = useContext(authContext);
  const { status } = useOutletContext<IProjectOutletContext>();
  const currentRoles = useMemo(() => userData?.roles || [], [userData]);
  const userCanRefund = havePermission(currentRoles, ['financial', 'superAdmin']);

  return (
    <Collapse in={isExpanded}>
      <Box display="grid" sx={{ borderTop: ({ palette }) => `1px solid ${palette.grey[300]}` }}>
        <Box sx={{ bgcolor: SECONDARY_LIGHT_COLOR }} />
        <Box
          gridColumn={{ xs: '1/-1', md: '4/-1' }} //eslint-disable-line
          data-testid={`transactions-${userId}`}
        >
          <Box
            px={2}
            sx={{
              alignItems: 'center',
              bgcolor: SECONDARY_LIGHT_COLOR,
              borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
              borderTop: ({ palette }) => ({ xs: `1px solid ${palette.grey[300]}`, md: 'none' }), //eslint-disable-line
              color: 'text.primary',
              display: 'flex',
              flexDirection: 'row',
              fontWeight: '600',
              justifyContent: 'space-between',
              justifyItems: 'center',
            }}
          >
            <Typography
              py={2}
              component="div"
              variant="body2"
              sx={{
                bgcolor: SECONDARY_LIGHT_COLOR,
                borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
                borderTop: ({ palette }) => ({ xs: `1px solid ${palette.grey[300]}`, md: 'none' }), //eslint-disable-line
                color: 'text.primary',
                fontWeight: '600',
              }}
            >
              Transactions
            </Typography>
            <CopyClipboard value={email ?? ''} />
            {phone && <CopyClipboard value={phone ?? ''} />}
            <Link
              component={RouterLink}
              to={`/investors/${userId}`}
              display="flex"
              alignItems="center"
              color="secondary"
              underline="hover"
            >
              See investor detail
              <SvgIcon color="secondary" fontSize="small">
                <IconPlus />
              </SvgIcon>
            </Link>
          </Box>
          <Box
            px={2}
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 2fr"
            sx={{
              bgcolor: { xs: SECONDARY_LIGHT_COLOR, sm: 'background.paper' }, //eslint-disable-line
              borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
            }}
          >
            {transactionHeadings.map((heading) => (
              <Typography key={heading} component="span" fontSize={12} fontWeight="bold">
                {heading}
              </Typography>
            ))}
          </Box>
          {transactions.map((transaction) => (
            <Box
              key={transaction.transferId}
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 2fr"
              p={2}
              sx={{
                '&:last-of-type': {
                  borderBottom: 'none',
                },
                bgcolor: { xs: SECONDARY_LIGHT_COLOR, sm: 'background.paper' }, //eslint-disable-line
                borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
              }}
            >
              <Typography component="span" pr={2}>
                {formatDollars(transaction.amount, true)}
              </Typography>
              <Typography component="span" pr={2}>
                {formatDollars(transaction.fee, true)}
              </Typography>
              <Typography component="span" pr={2}>
                {formatDollars(transaction.amount + transaction.fee, true)}
              </Typography>
              <Typography component="span" pr={2}>
                {capitalize(transaction.type)}
              </Typography>
              <Typography component="span" pr={2}>
                {format(new Date(transaction.date), 'MM/dd/yyyy HH:mm')}
              </Typography>
              <Typography component="span" pr={2} sx={{ overflowWrap: 'anywhere' }}>
                {transaction.type === 'crypto'
                  ? transaction.sourceAddress
                  : transaction.trackingRef}
              </Typography>
              <Typography component="span" pr={2}>
                {transaction.isManual ? 'Yes' : 'No'}
              </Typography>
              <Typography component="span" pr={2}>
                {capitalize(transaction.status.toLowerCase()).replace('_', ' ')}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  '&:hover': {
                    color: Primary[500],
                    textDecoration: 'underline',
                  },
                  textDecoration: 'none',
                  wordWrap: 'break-word',
                }}
                component="a"
                href={`https://dsoplatform.eversign.com/documents/${transaction.transferId}`}
                target="_blank"
              >
                Contract
              </Typography>
              {userCanRefund && (
                <Stack direction="column" spacing={1} mr={1}>
                  {transaction.status === INVESTMENT_STATUSES.RECEIVED &&
                    [PROJECT_STATUSES.DRAFT, PROJECT_STATUSES.ACTIVE, PROJECT_STATUSES.PAUSED, PROJECT_STATUSES.CLOSED, PROJECT_STATUSES.CANCELED, PROJECT_STATUSES.COMPLETED].includes(
                      status as PROJECT_STATUSES
                    ) &&
                    (whitelisted === 'unconfirmed' ||
                      transaction.isManual ||
                      process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true') && (
                      <TransactionActions
                        status={INVESTMENT_STATUSES.TO_REFUND}
                        area={`setTransactionStatus/${transaction.transferId}-to_refund`}
                        onClick={() => transactionStatus(transaction.transferId, INVESTMENT_STATUSES.TO_REFUND)}
                      />
                    )}
                  {transaction.status === INVESTMENT_STATUSES.TO_REFUND && (
                    <>
                      {process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'false' &&
                        !transaction.isManual && (
                          <TransactionActions
                            variant="contained"
                            status={INVESTMENT_STATUSES.REFUND_INPROCESS}
                            area={`setTransactionStatus/${transaction.transferId}-refund_inprocess`}
                            onClick={() =>
                              transactionStatus(transaction.transferId, INVESTMENT_STATUSES.REFUND_INPROCESS)
                            }
                          />
                        )}
                      <TransactionActions
                        status={INVESTMENT_STATUSES.MANUALLY_REFUNDED}
                        area={`setTransactionStatus/${transaction.transferId}-manually_refunded`}
                        onClick={() =>
                          transactionStatus(transaction.transferId, INVESTMENT_STATUSES.MANUALLY_REFUNDED)
                        }
                      />
                    </>
                  )}
                  {(transaction.status === INVESTMENT_STATUSES.COMMITTED ||
                    transaction.status === INVESTMENT_STATUSES.PENDING ||
                    transaction.status === INVESTMENT_STATUSES.DELAYED) &&
                    (transaction.isManual ||
                      process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true') && (
                      <>
                        <TransactionActions
                          status={INVESTMENT_STATUSES.RECEIVED}
                          area={`setTransactionStatus/${transaction.transferId}-received`}
                          onClick={() => transactionStatus(transaction.transferId, INVESTMENT_STATUSES.RECEIVED)}
                        />
                        <TransactionActions
                          status={INVESTMENT_STATUSES.REJECTED}
                          area={`setTransactionStatus/${transaction.transferId}-rejected`}
                          onClick={() => transactionStatus(transaction.transferId, INVESTMENT_STATUSES.REJECTED)}
                        />
                      </>
                    )}
                </Stack>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Collapse>
  );
};

export { TableRowDetail };
