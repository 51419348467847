import { Chip, SvgIcon, Theme, useMediaQuery } from '@mui/material';
import { IconCircleX } from '@tabler/icons';
import { FC } from 'react';

import { IChip, IFilterChips } from './FilterChips.d';

export const FilterChips: FC<IFilterChips> = ({ list, onDelete }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleOnDelete = (item: IChip) => {
    onDelete(item);
  };

  return (
    <>
      {list.map((item: IChip) => (
        <Chip
          key={item.select}
          label={item.label}
          color="primary"
          onDelete={() => handleOnDelete(item)}
          sx={{
            '.MuiChip-deleteIcon': {
              color: 'rgba(255, 255, 255, 0.6)',
            },
            fontWeight: 600,
            marginTop: isMobile ? 1 : 0,
          }}
          deleteIcon={
            <SvgIcon>
              <IconCircleX data-testid="delete-icon" />
            </SvgIcon>
          }
        />
      ))}
    </>
  );
};
