import { joiResolver } from '@hookform/resolvers/joi'; //eslint-disable-line
import { FormHelperText, Stack, Typography } from '@mui/material'; //eslint-disable-line
import { Box } from '@mui/system';
import { IconUserCircle } from '@tabler/icons';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { userRoles } from '../../helpers/beautifierUserRoles';
import { authContext } from '../../hooks/useAuth/authContext';
import UsersContext, { IUsersContextValue } from '../../pages/Users/context';
import { ButtonSubmit } from '../ButtonSubmit';
import { Chip } from '../Chip';
import { Switch } from '../Switch';
import { InputField } from '../InputField';
import { IUser } from '../PageContent'; //eslint-disable-line
import { areasUsers } from '../../api/users';

import { ICreateUserForm, IFormData, IUserRole } from './CreateUserForm.d'; //eslint-disable-line
import { createUserFormSchema, updateUserFormSchema } from './CreateUserForm.schema';

const initValues = {
  active: true,
  email: '',
  firstName: '',
  lastName: '',
  roles: [],
};

export const CreateUserForm: FC<ICreateUserForm> = ({
  createUser: reqCreateUser,
  editUser: reqEditUser,
}) => {
  const { modalCreateUser, users } = useContext(UsersContext) as IUsersContextValue;
  const [editingUser, setEditingUser] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm<IFormData>({
    defaultValues: initValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: joiResolver(editingUser ? updateUserFormSchema : createUserFormSchema),
    shouldFocusError: true,
  });
  const [overlaySecure, setOverlaySecure] = useState<boolean>(false);

  const { userData } = useContext(authContext);
  const selectedRoles = watch('roles');
  const userStatus = watch('active');

  const onSubmit = async (data: IFormData) => {
    setOverlaySecure(true);
    if (!editingUser) {
      const goodRequest = await reqCreateUser(data);
      if (goodRequest) {
        reset(initValues);
      } else {
        setOverlaySecure(false);
      }
    } else {
      const goodRequest = await reqEditUser(modalCreateUser.editUser as string, data);
      if (!goodRequest) {
        setOverlaySecure(false);
      }
    }
  };

  const allowedRoles = useMemo((): IUserRole[] => {
    const checkRoles = [...userRoles];
    if (!userData?.roles.includes('superAdmin')) {
      return userRoles.filter((role) => role.alias !== 'superAdmin');
    }
    return checkRoles;
  }, [userData?.roles]);

  useEffect(() => {
    setEditingUser(modalCreateUser.editUser ? true : false);
  }, [modalCreateUser]);

  useEffect(() => {
    const idUser = modalCreateUser.editUser;
    if (idUser && modalCreateUser.display) {
      const userFind = users.find((item: IUser) => item.id === idUser);
      if (userFind) {
        const { active, email, firstName, lastName, roles } = userFind;
        setValue('active', active);
        setValue('firstName', firstName);
        setValue('lastName', lastName);
        setValue('roles', roles);
        if (editingUser) {
          setValue('email', email);
          unregister('email');
        }
      }
    }
  }, [editingUser, modalCreateUser, unregister, users, setValue]);

  useEffect(() => {
    setOverlaySecure(false);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label="Name"
        inputProps={{
          ...register('firstName'),
          'data-testid': 'first-name',
        }}
        helperText={errors.firstName?.message}
        error={!!errors.firstName}
        fullWidth
      />

      <Box height="28px"></Box>

      <InputField
        label="Last Name"
        inputProps={{
          ...register('lastName'),
          'data-testid': 'last-name',
        }}
        helperText={errors.lastName?.message}
        error={!!errors.lastName}
        fullWidth
      />

      <Box height="28px"></Box>

      <InputField
        disabled={editingUser}
        label="Email"
        inputProps={
          editingUser
            ? { 'data-testid': 'email' }
            : { ...register('email'), 'data-testid': 'email' }
        }
        helperText={errors.email?.message}
        error={!!errors.email}
        fullWidth
      />

      <Box height="56px"></Box>
      <Typography variant="h3">Roles</Typography>
      <Box height="20px"></Box>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none',
            m: 0,
            px: 0,
          }}
          component="ul"
          data-testid="test-roles-options"
        >
          {allowedRoles.map((role) => {
            return (
              <Chip
                alias={role.alias}
                label={role.label}
                key={role.alias}
                selected={selectedRoles.includes(role.alias)}
                register={register}
              />
            );
          })}
        </Box>
        <FormHelperText sx={{ ml: '14px' }} error>
          {errors?.roles ? 'You must select at least one role.' : ''}
        </FormHelperText>
      </Stack>
      {editingUser && (
        <>
          <Box height="28px"></Box>
          <Stack direction="row" alignItems="center">
            <Switch id="active" checked={userStatus} {...register('active')} />
            <label htmlFor="active">
              <Typography component="span" sx={{ userSelect: 'none' }}>
                Active user
              </Typography>
            </label>
          </Stack>
        </>
      )}

      <Box position="relative" mt="40px">
        <ButtonSubmit
          startIcon={<IconUserCircle />}
          area={areasUsers.reqUser}
          data-testid="test-main-action-button"
        >
          {editingUser ? 'Save' : 'Create'} user
        </ButtonSubmit>
        {overlaySecure && (
          <Box position="absolute" top={0} left={0} width="100%" height="100%"></Box>
        )}
      </Box>
    </form>
  );
};
