import { IInvestor } from '../../components/PageContent';
import { SUMSUB_STATUS } from '../../helpers/constants';
import { IFABrokerInvestments } from '../../pages/FABrokerDetail/FABrokers.d';
import { IFilterParams } from '../../pages/Investors';
import { IProject } from '../../pages/ProjectDetail';
// eslint-disable-next-line import/order
import { IInvestorDetail } from '../investors/investors.d';
import {
  IFABrokerRaw,
  IFABrokersFilters,
  IFABrokersInvestorsRaw,
  IFABrokersRows,
  IInvestmentsListResponse,
  IInvestorsPerBroker,
  IReferrersResponse,
} from './brokers.d';

const decorateInvestmentsList = (
  data: IInvestmentsListResponse[],
  investors: IInvestorDetail[],
  projects: IProject[]
): IFABrokerInvestments[] => {
  return data.map((investment) => {
    const investorData = investors.find((investor) => investment.investorId === investor.id);
    const projectData = projects.find((project) => investment.projectId === project.projectId);
    return {
      commission: investment.commissionAmt,
      date: investment.investmentDate,
      id: investment.investorId,
      investment: investment.investmentAmt,
      investor: investorData?.legalName ?? '',
      paymentDate: investment.paidAt,
      project: `${projectData?.projectTitle}`,
      projectId: investment.projectId,
      rate: investment.commissionPercent,
      status: investment.commissionStatus.toLowerCase(),
    };
  });
};

const decorateInvestorsPerFaBroker = (
  data: IFABrokersInvestorsRaw[],
  investors: IInvestorDetail[]
): IInvestorsPerBroker[] => {
  return data.map((investor) => {
    const investorData = investors.find((inv) => inv.id === investor.investorId);
    return {
      id: investor.investorId,
      investment: investor.totalInvested,
      investor: investorData?.fullName ?? '',
      isEnabled: investorData?.isEnabled ?? true,
      joining_date: investor.createdAt,
      legalName: investorData?.legalName ?? '',
    };
  });
};

const decorateFaBrokersList = (
  commissions: IFABrokerRaw[],
  investors: IInvestor[],
  referrers: IReferrersResponse[]
): IFABrokersRows[] => {
  return investors.map((investor) => {
    const foundCommission = commissions.find((commission) => commission.userId === investor.id);
    const foundReferrer = referrers.find( referrer => referrer.brokerId === investor.id);
    const isBroker = investor.groups?.some((group) => group === 'broker');
    return {
      applicantId: investor.sumsub?.applicantId || '',
      commissionRate: foundCommission?.commissionPercent.userCommission.amount ?? 0,
      creationDate: investor.createdDate,
      detailsLink: `${isBroker ? 'broker' : 'financial-advisor'}/${investor.id}`,
      email: investor.email,
      id: investor.id,
      isEnabled: investor.isEnabled ? 'Active' : 'Inactive',
      legalName: investor.legalName,
      location: investor.location,
      name: `${investor.firstName} ${investor.lastName}`,
      referralId: foundReferrer?.referralCode || '',
      sumsubStatus: investor.sumsub?.status || SUMSUB_STATUS.NOT_INITIATED,
    };
  });
};

const decorateFilters = ({ page, status, search, sortBy, role }: IFABrokersFilters) => {
  const filters: IFilterParams = {
    limit: 10,
    page: page + 1,
  };
  const filterBy = [];
  if (status) {
    filterBy.push(`isEnabled:${status === 'active' ? true : false}`);
  }
  if (search) {
    filterBy.push(`name:${search}`);
  }
  if (sortBy) {
    filters.sortBy = sortBy;
  }
  filterBy.push(`role:${role}`);
  filters.filterBy = filterBy.join();
  return filters;
};

export {
  decorateFaBrokersList,
  decorateFilters,
  decorateInvestmentsList,
  decorateInvestorsPerFaBroker,
};
