import { FC } from 'react';

import { useAuth } from '.'; //eslint-disable-line
import { authContext } from './authContext'; //eslint-disable-line

const AuthProvider: FC = ({ children }) => {
  const authMethod = useAuth();
  return <authContext.Provider value={authMethod}>{children}</authContext.Provider>;
};
export { AuthProvider };
