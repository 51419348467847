import { GridSelectionModel } from '@mui/x-data-grid';

const arrayPartition = (selection: GridSelectionModel, size: number) => {
  const data = [...selection];
  const chunk = [];
  while (data.length > 0) {
    chunk.push(data.splice(0, size));
  }
  return chunk;
};

export { arrayPartition };
