const areas = {
  completeProject: 'completeProject',
  createProject: 'createProject',
  duplicateProject: 'duplicateProject',
  get: 'getProjects',
  getProjectDetail: 'getProjectDetail',
  getProjectInvestments: 'getProjectInvestments',
  getProjectLegalDocuments: 'getProjectLegalDocuments',
  getProjectNames: 'getProjectNames',
  refundTransaction: 'refundTransaction',
  updateProjectDetail: 'updateProjectDetail',
};

export { areas };
