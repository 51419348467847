/* eslint-disable sonarjs/no-duplicate-string */
import { Theme, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';

import {
  deleteFinancialReport,
  getFinancialReports,
  getPayoutsMetrics,
  setFinancialReport,
} from '../../../api/blockchain/blockchain';
import { BankAccountForm } from '../../../components/ProjectsBankAccount';
import { WalletForm } from '../../../components/ProjectsWallet';
import { dollarsFormat } from '../../../helpers';
import { PROJECT_STATUSES } from '../../../helpers/constants';
import { isRequestError } from '../../../helpers/isRequestError';
import { IServerError } from '../../../helpers/reportError';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { toastContext } from '../../../hooks/useToast';
// eslint-disable-next-line import/order
import {
  IFinancialReport,
  IFinancialReportResponse,
  IFormOutletContext,
  IProjectOutletContext,
  PROJECT_TYPES,
} from '../ProjectDetail.d';
import { Financial } from './Financial';
import { IFinancialInformation, IPayoutsMetrics } from './Financial.d';

const initialValues: IFinancialInformation = {
  'financialInformation.actualAnnualReturn': '',
  'financialInformation.annualCash': '',
  'financialInformation.annualInvestment': '',
  'financialInformation.assetManagementFee': '',
  'financialInformation.investmentBreakpoint': '',
  'financialInformation.maximumInvestmentPercentage': '',
  'financialInformation.minimumInvestment': '',
  'financialInformation.percentOverage': '',
  'financialInformation.placementFee': '',
  'financialInformation.totalReturnInvestment': '',
  'financialInformation.totalTargetedInvestment': '',
  'financialInformation.upfrontFee': '',
};

const FinancialPresenter: FC = () => {
  const { id } = useParams();
  const { watch, setValue } = useFormContext();
  const { validStatus, setShowEditButton, financialRole } = useOutletContext<IFormOutletContext>();
  const { status } = useOutletContext<IProjectOutletContext>();
  const canEdit = validStatus && financialRole;

  const actualAnnualReturn = watch('financialInformation.actualAnnualReturn');
  const annualCash = watch('financialInformation.annualCash');
  const annualInvestment = watch('financialInformation.annualInvestment');
  const maximumInvestmentPercentage = watch('financialInformation.maximumInvestmentPercentage');
  const minimumInvestment = watch('financialInformation.minimumInvestment');
  const percentOverage = watch('financialInformation.percentOverage');
  const totalReturnInvestment = watch('financialInformation.totalReturnInvestment');
  const totalTargetedInvestment = watch('financialInformation.totalTargetedInvestment');
  const upfrontFee = watch('financialInformation.upfrontFee');
  const placementFee = watch('financialInformation.placementFee');
  const assetManagementFee = watch('financialInformation.assetManagementFee');
  const investmentBreakpoint = watch('financialInformation.investmentBreakpoint');
  const projectId = watch('projectId');

  const [values, setValues] = useState<IFinancialInformation>(initialValues);
  const [payoutsMetrics, setPayoutsMetrics] = useState<IPayoutsMetrics>();
  const [reports, setReports] = useState<IFinancialReportResponse[]>([]);
  const getPayoutsMetricsController = useAbortRequest();
  const setFinancialReportController = useAbortRequest();
  const getFinancialReportsController = useAbortRequest();
  const showFinancialMetrics = [
    PROJECT_STATUSES.TOKENS_DELIVERED,
    PROJECT_STATUSES.DEVELOPMENT_COMPLETED,
    PROJECT_STATUSES.PROJECT_COMPLETED,
  ].includes(status as PROJECT_STATUSES);
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const horizontalPosition = isTablet ? 'right' : 'center';
  const width = isTablet ? 467 : 448;
  const { openToast } = useContext(toastContext);

  const onChangeHandleDecimal = (event: ChangeEvent<HTMLInputElement>, projectType?: string) => {
    const value = event.target.value;
    const targetName = event.currentTarget.name;
    const LOANS_REGEX = /^$|^(?:[0-9](?:\.\d{0,2})?|[0-9](?:\.\d{0,2})?)$/g;
    const UP_TO_100_REGEX = /^$|^(?:\d{1,2}(?:\.\d{0,2})?|100(?:\.0{0,2})?)$/g
    const DEFAULT_REGEX = /^$|^\d{0,2}(\.\d{0,2})?$/g;
    const isValidValue = (regex: RegExp) => regex.test(value);
    let isValid = false;

    if (projectType) {
      if (projectType === PROJECT_TYPES.LOANS.toLocaleLowerCase()) {
        isValid = isValidValue(LOANS_REGEX);
      } else {
        isValid = isValidValue(UP_TO_100_REGEX);
      }
    } else {
      isValid = isValidValue(DEFAULT_REGEX);
    }

    if (isValid) {
      setValues({ ...values, [targetName]: value });
      return value;
    }
  };

  const handleMoreThan100Percent = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^$|^\d{0,3}(\.\d{0,2})?$/g.test(value)) {
      setValues({
        ...values,
        [event.target.name]: value,
      });
      return value;
    }
  };

  const onChangeHandleDolars = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let newValue = value;

    if (/^[0-9\b,]+$/.test(value)) {
      newValue = dollarsFormat(value);
    } else {
      if (value) {
        return;
      }
    }

    setValues({
      ...values,
      [event.target.name]: newValue,
    });
    return newValue;
  };

  const dolarsCleaned = (formated: string): number | string => {
    const newValue = Number(formated.toString().replace(/,/g, ''));
    if (newValue) {
      return newValue;
    }
    return 0;
  };

  const reqPayoutsMetrics = useCallback(async () => {
    const { data, status: statusCode } = await getPayoutsMetrics(
      id as string,
      getPayoutsMetricsController.signal
    );
    if (!isRequestError(statusCode)) {
      const response = data as IPayoutsMetrics;
      setPayoutsMetrics(response);
      setValue('financialInformation.actualAnnualReturn', response?.actualAnnualReturn);
    }
    return { data, status: statusCode };
  }, [id, getPayoutsMetricsController.signal, setValue]);

  const reqGetFinancialReports = async () => {
    const { data, status: statusCode } = await getFinancialReports(
      projectId,
      getFinancialReportsController.signal
    );

    if (!isRequestError(statusCode)) {
      setReports(data as IFinancialReportResponse[]);
    } else {
      openToast({
        horizontalPosition,
        message:
          'There was an error getting the financial reports. Please try again later or contact Admin',
        severity: 'error',
        title: 'Error getting financial reports',
        width,
      });
    }
  };

  const removeReport = async (filename: string) => {
    const { status: statusCode } = await deleteFinancialReport(
      id ?? '',
      filename,
      setFinancialReportController.signal
    );
    if (!isRequestError(statusCode)) {
      openToast({
        horizontalPosition,
        message: 'The financial report was removed successfully.',
        severity: 'success',
        title: 'Removed financial report',
        width,
      });
      await reqGetFinancialReports();
    } else {
      openToast({
        horizontalPosition,
        message:
          'There was an error removing the financial report. Please try again later or contact Admin',
        severity: 'error',
        title: 'Error getting financial reports',
        width,
      });
    }
  };

  useEffect(() => {
    setValues({
      'financialInformation.actualAnnualReturn': actualAnnualReturn,
      'financialInformation.annualCash': annualCash,
      'financialInformation.annualInvestment': annualInvestment,
      'financialInformation.assetManagementFee': assetManagementFee,
      'financialInformation.investmentBreakpoint': investmentBreakpoint,
      'financialInformation.maximumInvestmentPercentage': maximumInvestmentPercentage,
      'financialInformation.minimumInvestment': dollarsFormat(minimumInvestment),
      'financialInformation.percentOverage': percentOverage,
      'financialInformation.placementFee': placementFee,
      'financialInformation.totalReturnInvestment': totalReturnInvestment,
      'financialInformation.totalTargetedInvestment': dollarsFormat(totalTargetedInvestment),
      'financialInformation.upfrontFee': upfrontFee,
    });
  }, [
    actualAnnualReturn,
    annualCash,
    annualInvestment,
    assetManagementFee,
    investmentBreakpoint,
    maximumInvestmentPercentage,
    minimumInvestment,
    percentOverage,
    placementFee,
    totalReturnInvestment,
    totalTargetedInvestment,
    upfrontFee,
  ]);

  useEffect(() => {
    setShowEditButton(canEdit);
  }, [canEdit, setShowEditButton]);

  useEffect(() => {
    if (showFinancialMetrics) {
      reqPayoutsMetrics();
    }
  }, [reqPayoutsMetrics, showFinancialMetrics, status]);

  useEffect(() => {
    (async () => {
      if (projectId) {
        reqGetFinancialReports();
      }
    })();
  }, [projectId]);

  const formatFilesReq = (fileList: FileList, reportDate: Date): IFinancialReport => {
    const file = fileList.item(0) as File;
    return { date: reportDate, file: file };
  };

  const handleReports = async (e: React.ChangeEvent<HTMLInputElement>, reportDate: Date) => {
    if (e.target?.files && e.target.files[0].type !== 'application/pdf') {
      openToast({
        horizontalPosition,
        message: 'Please upload a pdf report',
        severity: 'error',
        title: 'Error on file format',
        width,
      });
      e.target.value = '';
      e.target.files = null;
      return;
    }
    if (e.target?.files && e.target.files[0].size >= 1024 * 1024 * 4.3) {
      openToast({
        horizontalPosition,
        message: 'Please upload a pdf report that weights less than 4.3 MB',
        severity: 'error',
        title: 'Max size reached',
        width,
      });
      e.target.value = '';
      e.target.files = null;
      return;
    }
    const financialReport = e.target.files ? formatFilesReq(e.target.files, reportDate) : undefined;
    if (financialReport) {
      const { status: statusCode, data } = await setFinancialReport(
        projectId,
        financialReport,
        setFinancialReportController.signal
      );
      if (!isRequestError(statusCode)) {
        openToast({
          horizontalPosition,
          message: 'The financial report was saved successfully.',
          severity: 'success',
          title: 'Saved financial report',
          width,
        });
        reqGetFinancialReports();
      } else {
        openToast({
          horizontalPosition,
          message:
            (data as IServerError)?.message ||
            'There was an error saving the financial report. Please try again later or contact Admin',
          severity: 'error',
          title: 'Error saving financial report',
          width,
        });
      }
      e.target.value = '';
      e.target.files = null;
    }
  };

  return (
    <>
      <Financial
        status={status}
        canEdit={canEdit}
        financialRole={financialRole}
        values={values}
        payoutsMetrics={payoutsMetrics}
        dolarsCleaned={dolarsCleaned}
        onChangeHandleDecimal={onChangeHandleDecimal}
        onChangeHandleDolars={onChangeHandleDolars}
        showFinancialMetrics={showFinancialMetrics}
        handleMoreThan100Percent={handleMoreThan100Percent}
        onUploadReports={handleReports}
        onRemoveReport={removeReport}
        reports={reports}
      />
      <BankAccountForm />
      <WalletForm />
    </>
  );
};

export { FinancialPresenter };
