import { IconButton, Stack, SvgIcon } from '@mui/material';
import { IconCopy } from '@tabler/icons';
import { FC, useState } from 'react';

import { TextWithTooltip } from '../TextWithTooltip';
import { Tooltip } from '../Tooltip';

interface ICopyClipboardProps {
  value: string;
}

const CopyClipboard: FC<ICopyClipboardProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Stack flexWrap="nowrap" direction="row">
      <TextWithTooltip variant="body1">{value}</TextWithTooltip>
      <Tooltip open={copied} title="Copied!">
        <IconButton onClick={copyToClipboard} sx={{ p: 0 }}>
          <SvgIcon sx={{ cursor: 'pointer' }} fontSize="small" color="secondary">
            <IconCopy />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
export { CopyClipboard };
