export const toBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve) => {
      let baseURL = "";
      // Make new FileReader
      const reader = new FileReader();
  
      // Convert the file to base64 text
      reader.readAsDataURL(file);
  
      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result as string;
        resolve(baseURL.split(',')[1]);
      };
    });
  };