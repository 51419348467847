import { createContext, Dispatch, SetStateAction } from 'react';

// eslint-disable-next-line import/order
import { IUser } from '../../components/PageContent';
import { IFilterParams } from './Users.d';

interface IModalCreateUser {
  editUser?: string;
  display: boolean;
}


export interface IUsersContextValue {
  lastPage: boolean;
  isLoading: boolean;
  filterParams: IFilterParams;
  setFilterParams: Dispatch<SetStateAction<IFilterParams>>;
  modalCreateUser: IModalCreateUser;
  setModalCreateUser: Dispatch<SetStateAction<IModalCreateUser>>;
  setUsers: Dispatch<SetStateAction<IUser[]>>;
  users: IUser[];
}

const UsersContext = createContext<IUsersContextValue | null>(null);

export default UsersContext;
