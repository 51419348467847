import { Box, Link, List, Skeleton, Typography } from '@mui/material';
import { IconFileText } from '@tabler/icons';
import { FC, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { BorderGray } from '../../theme/tokens/borders';
// eslint-disable-next-line import/order
import { Gray } from '../../theme/tokens/colors';
import { IAttachedReportsProps } from './AttachedReports.d';
import { AttachedReportsItem } from './AttachedReportsItem';
import { EmptyReports } from './EmptyReports';

export const AttachedReports: FC<IAttachedReportsProps> = ({ reports, area, onRemoveReport }) => {
  const limit = 4;
  const [expand, setExpand] = useState<boolean>(false);
  const { promiseInProgress: isLoading } = usePromiseTracker({ area });

  const onClick = () => {
    setExpand(!expand);
  };

  return (
    <>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          height="262px"
          width="100%"
          sx={{ border: { sm: BorderGray[300], xs: 'none' }, borderRadius: '1.2rem' }}
        />
      )}
      {!isLoading && (
        <Box
          padding="4rem 2.4rem 2.4rem 2.4rem"
          border={{ md: BorderGray[300], xs: 'none' }}
          borderRadius={{ md: '1.2rem', xs: 0 }}
          bgcolor={Gray[50]}
        >
          <Box display="flex" alignItems="center" mb={4}>
            <IconFileText size="2.4rem" color={Gray[1000]} />
            <Typography variant="h3" ml={1}>
              Reports
            </Typography>
          </Box>
          {reports.length ? (
            <>
              <List data-testid="list" sx={{ padding: 0 }}>
                {reports.slice(0, expand ? reports.length : limit).map((report, index) => (
                  <AttachedReportsItem {...report} key={index} onRemoveReport={onRemoveReport}/>
                ))}
              </List>
              {reports.length > limit && (
                <Link
                  variant="body2"
                  onClick={onClick}
                  sx={{ cursor: 'pointer', fontWeight: '600', textDecoration: 'none' }}
                >
                  {expand ? 'viewLess' : 'viewMore'}
                </Link>
              )}
            </>
          ) : (
            <EmptyReports />
          )}
        </Box>
      )}
    </>
  );
};
