import Joi from 'joi';

const messagesBuilder = (labelName: string) => {
  return {
    'string.empty': labelName + ' is required',
    'string.max': labelName + ' length must be less than or equal to {{#limit}} characters long',
    'string.pattern.base': labelName + ' does not accept special characters',
  };
};

const nameAndLastName = (labelName: string) => {
  return Joi.string()
    .trim()
    .regex(/^[a-zA-Z\u00C0-\u017F\-'\s]+$/)
    .max(30)
    .required()
    .messages(messagesBuilder(labelName));
};

const validators = {
  active: Joi.boolean().required(),
  firstName: nameAndLastName('The Name'),
  lastName: nameAndLastName('The Last Name'),
  roles: Joi.array().items(Joi.string()).min(1).required().messages({
    'any.invalid': 'You must select at least one role.',
  }),
};

export const createUserFormSchema = Joi.object({
  ...validators,
  email: Joi.string()
    .min(3)
    .max(64)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      ...messagesBuilder('The Email'),
      'string.email': 'The Email must be a valid email',
    }),
});

export const updateUserFormSchema = Joi.object(validators);
