import { Box, Theme, Typography, useMediaQuery } from '@mui/material'; //eslint-disable-line
import React, { FC } from 'react';

import { Gray } from '../../theme/tokens/colors';
import { Paginator } from '../Paginator';
import { formatDollars, millionFormat } from '../../helpers/quantityBeautifier';
import { formatDate } from '../../helpers';
import { getProjectStatusData } from '../StatusChip';
import noHoldings from '../../assets/investors/no-holdings.png';
import { OverlayLoader } from '../OverlayLoader';
import { areasInvestors } from '../../api/investors';

import { Header } from './Header';
import { ITransaction, ITransactionsTable } from './TransactionsTable.d';
import { CardTransaction } from './CardTransaction';
import { RowTransaction } from './RowTransaction';

export const TransactionsTable: FC<ITransactionsTable> = ({
  count,
  items,
  limit,
  page,
  setPage,
  tokensData,
}) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{ border: isTablet ? `1px solid ${Gray[300]}` : '' }}
      borderRadius={2}
      bgcolor={isTablet ? '#fff' : 'transparent'}
      overflow="hidden"
      position="relative"
    >
      {isTablet && <Header />}
      {items.length ? (
        <>
          {items.map((item: ITransaction, index: number) => {
            const statusData = getProjectStatusData(item.status);
            const statusObj = {
              ...statusData,
              title: statusData.title === 'Undefined' ? item.status : statusData.title,
            };
            const tokens = tokensData.find((token) => token.projectId === item.project.id)?.tokens;
            const data = {
              date: item.date && formatDate(item.date),
              investment: formatDollars(item.investment, true),
              project: {
                id: item.project.id,
                title: item.project.title,
              },
              status: statusObj,
              value: tokens ? millionFormat(tokens) : 'N/A',
            };

            return (
              <React.Fragment key={`${item.project}-${index}`}>
                {isTablet ? (
                  <RowTransaction odd={index % 2 === 0} {...data} />
                ) : (
                  <CardTransaction mb={4} {...data} />
                )}
              </React.Fragment>
            );
          })}
          <Paginator count={count} limit={limit} page={page} setPage={setPage} />
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" py={7}>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <img src={noHoldings} alt="No Holdings" width="187px" style={{ display: 'block' }} />
            <Typography variant="h2" mt={3} color="grey.700" textAlign="center">
              The investor hasn’t invested in any project.
            </Typography>
          </Box>
        </Box>
      )}
      <OverlayLoader area={areasInvestors.getTransactions} theme="container" />
    </Box>
  );
};
