import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { IInvestor } from '../../components/PageContent';
import { PROJECT_STATUSES } from '../../helpers/constants';
import { toBase64 } from '../../helpers/encode64';
import { isRequestError } from '../../helpers/isRequestError';
import { IReturnError, IServerError, reportError } from '../../helpers/reportError';
import { createQueryString } from '../../helpers/URLHelpers';
import { IInvestorTokens, IWallet } from '../../pages/InvestorDetail';
import { TContractStatus } from '../../pages/ProjectDetail';
import { IPayoutsMetrics } from '../../pages/ProjectDetail/Financial/Financial.d';
import { IFinancialReport, IFinancialReportResponse } from '../../pages/ProjectDetail/ProjectDetail.d';
import { getInvestorDetail } from '../investors';
import { getInvestorsDetails } from '../investors/investors';
import { IInvestorDetail } from '../investors/investors.d';
import { IReturnType, payoutsApi, projectsApi, tokensApi, walletApi } from '../main';
import { updateProjectDetail } from '../projects';
import { confirmTwoFactorAuth, IAuth } from '../session'; // eslint-disable-line
import { areas } from './areas';
import {
  IApprovePlanRequest,
  ICreateTokenRequest,
  ICreateTokensResponse,
  IGetSmartContractStatus,
  IInvestorTokensResponse,
  IProjectTokens,
  IProjectTokensRes,
  ISetFinancialReportRes,
  ISetMetricsRes,
  ITokenItem,
  ITokensResponse,
  IWalletResponse,
} from './blockchain.d';
import {
  decorateInvestorTokens,
  decorateTheSmartContractStatus,
  decorateWallet,
  mergeInvestorsTokensPerProject,
  mergeTokensAndUsers,
} from './decorators';

const createTokensPlan = async (id: string, signal: AbortSignal): Promise<void> => {
  try {
    return await tokensApi.post(
      'distribution-plan',
      { projectId: id, tokenPrice: 1 },
      { signal }
    );
  } catch (error) {
    const err = error as Error;
    throw new Error(err.message);
  }
};

const getTokensPlan = async (
  id: string,
  signal: AbortSignal
): Promise<AxiosResponse<ITokensResponse[]>> => {
  try {
    return await tokensApi.get('distribution-plan', { params: { projectId: id }, signal });
  } catch (error) {
    const err = error as Error;
    throw new Error(err.message);
  }
};

const createPlan = async (id: string, signal: AbortSignal): Promise<ITokenItem[]> => {
  return trackPromise(
    new Promise(async (resolve, reject) => {
      try {
        await createTokensPlan(id, signal);
        const { data } = await getTokensPlan(id, signal);
        const newUserPromises = data.map((user) => getInvestorDetail(user.userId, signal));
        const res = await Promise.all(newUserPromises);
        if (res.some((response) => isRequestError(response.status))) {
          throw new Error('Network Error');
        }
        const newUsers = res.map((user) => user.data) as IInvestor[];
        resolve(mergeTokensAndUsers(data, newUsers));
      } catch (error) {
        reject(error);
      }
    }),
    areas.createPlan
  );
};

const verifyTokens = async (
  projectId: string,
  verifyRequest: ICreateTokenRequest,
  signal: AbortSignal
): Promise<IReturnType<ICreateTokensResponse> | IReturnError> => {
  try {
    const res: AxiosResponse<ICreateTokensResponse> = await trackPromise(
      projectsApi.put(`projects/${projectId}/crypto`, verifyRequest, { signal }),
      areas.verifyTokens
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const createTokens = async (
  projectId: string,
  createRequest: ICreateTokenRequest,
  signal?: AbortSignal
): Promise<void> => {
  return projectsApi.post(`projects/${projectId}/crypto`, createRequest, { signal });
};

const approveTokensPlans = async (
  approveRequest: IApprovePlanRequest,
  signal?: AbortSignal
): Promise<void> => {
  return tokensApi.post('distribution-plan/approve', approveRequest, { signal });
};

const fullyCreateTokens = async (
  projectId: string,
  session: string,
  code: string,
  userId: string,
  createTokensFields: ICreateTokenRequest,
  signal: AbortSignal
) => {
  const { data, status } = await confirmTwoFactorAuth(session, code, userId);
  if (isRequestError(status)) {
    const error = data as IServerError;
    throw new Error(error.message);
  }
  await approveTokensPlans(
    {
      projectId: projectId,
      tokenName: createTokensFields.name,
      tokenSymbol: createTokensFields.symbol,
    },
    signal
  );
  await createTokens(projectId, createTokensFields, signal);
  await updateProjectDetail(projectId, { status: PROJECT_STATUSES.CREATING_TOKENS }, signal);
  return data as IAuth;
};

const getWallets = async (
  ids: string[],
  signal: AbortSignal
): Promise<IReturnType<IWallet[]> | IReturnError> => {
  try {
    const queryString = createQueryString({ query: ids });
    const url = `wallets?${queryString}`;
    const res: AxiosResponse<IWalletResponse[]> = await trackPromise(
      walletApi.get(url, { signal }),
      areas.getWallets
    );
    return {
      data: decorateWallet(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getInvestorTokens = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<IInvestorTokens[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IInvestorTokensResponse[]> = await trackPromise(
      payoutsApi.get('tokens', { params: { investorId: id }, signal }),
      areas.getInvestorTokens
    );
    return {
      data: decorateInvestorTokens(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getProjectTokens = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<IProjectTokens> | IReturnError> => {
  try {
    const res = await payoutsApi.get<IProjectTokensRes>('tokens', {
      params: { projectId: id },
      signal,
    });
    const investors = await getInvestorsDetails(res.data.balances.map(balance => balance.investorId), signal);

    return {
      data: {
        contractAddress: res.data.contractAddress,
        distributions: mergeInvestorsTokensPerProject(res.data.balances, investors.data as IInvestorDetail[]),
        lastDateOfActivity: res.data.lastDateOfActivity,
      },
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getPayoutsMetrics = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<IPayoutsMetrics> | IReturnError> => {
  try {
    const res: AxiosResponse<IPayoutsMetrics> = await trackPromise(
      payoutsApi.get('payouts', { params: { projectId: id }, signal }),
      areas.getPayoutsMetrics
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const setMetrics = async (
  id: string,
  percent: number,
  signal: AbortSignal,
): Promise<IReturnType<ISetMetricsRes> | IReturnError> => {
  try {
    const res: AxiosResponse<ISetMetricsRes> = await trackPromise(
      payoutsApi.put(
        'payouts/metrics',
        { actualAnnualReturn: percent, projectId: id },
        { signal }
      ),
      areas.setMetrics
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const setFinancialReport = async (
  id: string,
  financialReport: IFinancialReport,
  signal: AbortSignal,
): Promise<IReturnType<ISetFinancialReportRes> | IReturnError> => {
  const json = {
    "date": financialReport.date.toString(),
    "file": await toBase64(financialReport.file),
    "fileName": financialReport.file.name
  }

  try {
    const res: AxiosResponse<ISetFinancialReportRes> = await trackPromise(
      payoutsApi.post(
        'payouts/reports/' + id,
        json,
        { signal }
      ),
      areas.setFinancialReport
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getFinancialReports = async (
  id: string,
  signal: AbortSignal,
): Promise<IReturnType<IFinancialReportResponse[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IFinancialReportResponse[]> = await trackPromise(
      payoutsApi.get(
        'payouts/reports/' + id,
        { signal }
      ),
      areas.getFinancialReports
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const deleteFinancialReport = async (
  projectId: string,
  reportName: string,
  signal: AbortSignal
): Promise<AxiosResponse<void>> => {
  try {
    return await payoutsApi.delete(`payouts/reports/${projectId}/${reportName}`, { signal });
  } catch (error) {
    const err = error as Error;
    throw new Error(err.message);
  }
};

const getSmartContractStatus = async (
  projectId: string,
  signal: AbortSignal
): Promise<IReturnType<TContractStatus> | IReturnError> => {
  try {
    const res = await trackPromise(
      tokensApi.get<IGetSmartContractStatus>('token/status', {
        params: { projectId },
        signal,
      }),
      areas.getSmartContractStatus
    );
    return {
      data: decorateTheSmartContractStatus(
        res.data.status,
        res.data.deployed.toLowerCase() as 'destroy_in_progress' | 'destroyed'
      ),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const pauseSmartContract = async (projectId: string, pause: boolean, signal: AbortSignal) => {
  try {
    await tokensApi.put('tokens', { pause, projectId }, { signal });
  } catch (error) {
    return reportError(error);
  }
};

export {
  approveTokensPlans,
  createPlan,
  createTokens,
  createTokensPlan,
  deleteFinancialReport,
  fullyCreateTokens,
  getFinancialReports,
  getInvestorTokens,
  getPayoutsMetrics,
  getProjectTokens,
  getSmartContractStatus,
  getTokensPlan,
  getWallets,
  pauseSmartContract,
  setFinancialReport,
  setMetrics,
  verifyTokens,
};
