
import {
  IInvestmentsResponse,
} from './investments.d';


export const decorateInvestments = (data: IInvestmentsResponse): IInvestmentsResponse => {
  const { items, pages, page, count, paginationToken } = data;
  return {
    count,
    items,
    lastPage: !paginationToken?  true : false,
    page,
    pages,
    paginationToken,
  };
};
