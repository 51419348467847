import { Box, capitalize, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { INVESTMENT_STATUSES } from '../../helpers/constants';
import { formatDollars } from '../../helpers/quantityBeautifier'; //eslint-disable-line
import { ConfirmationPaymentModal } from '../PayoutsReportDetail/ConfirmationPaymentModal'; //eslint-disable-line
import { IInvestmentTableRow, IStatusPointer, ITransaction } from './'; //eslint-disable-line
import { CardRow } from './CardRows';
import { ExpandButton } from './ExpandButton';
import { InvestmentDetailCard } from './InvestmentDetailCard'; //eslint-disable-line

const InvestmentCard: FC<IInvestmentTableRow> = ({
  onClick,
  isExpanded = false,
  totalInvested,
  location,
  fullName,
  status,
  transactions,
  email,
  phone,
  userId,
  whitelisted,
  reqTransactionStatus,
}) => {
  const investmentStatus = {
    committed: 'warning',
    delayed: 'error',
    manually_refunded: 'success',
    pending: 'warning',
    received: 'success',
    refund_inprocess: 'success',
    refunded: 'success',
    rejected: 'error',
    to_refund: 'warning',
  };
  const whitelistStatus = {
    confirmed: 'success',
    unconfirmed: 'warning',
  };
  const [warning, setWarning] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState({status: '', transaction: '' });

  useEffect(() => {
    const hasReceivedTransactions = (transaction: ITransaction) =>
      transaction.status === INVESTMENT_STATUSES.RECEIVED;
    setWarning(transactions.find(hasReceivedTransactions) ? whitelisted === 'unconfirmed' : false);
  }, [transactions, whitelisted]);

  const handleReqTransaction = (transaction: string, status:string) => {
    setTransactionStatus({ status, transaction });
    setShowConfirmationModal(true);
  }

  return (
    <Box
      sx={{
        '&:last-of-type': { mb: 0 },
        border: ({ palette }) => `1px solid ${palette.grey[300]}`,
        borderRadius: 2,
        mb: 4,
      }}
      role="row"
      data-testid={userId}
      aria-expanded={isExpanded ? true : false}
    >
      <CardRow name="Name" value={fullName} warning={warning} />
      <CardRow
        name="Whitelist status"
        value={capitalize(whitelisted ?? '')}
        warning={warning}
        status={whitelistStatus[whitelisted] as IStatusPointer['status']}
      />
      <CardRow name="Location" value={location} warning={warning} />
      <CardRow name="Investment" value={`${formatDollars(totalInvested, true)} USD`} warning={warning} />
      <CardRow
        name="Transaction status"
        value={capitalize(status ?? '').replace('_', ' ')}
        status={investmentStatus[status] as IStatusPointer['status']}
        warning={warning}
      />
      <InvestmentDetailCard
        isExpanded={isExpanded}
        email={email}
        phone={phone}
        userId={userId}
        transactions={transactions}
        transactionStatus={handleReqTransaction}
        whitelisted={whitelisted}
      />
      <ConfirmationPaymentModal
        title="Are you sure you want to continue?"
        subtitle={
          <Typography>
            You are about to change the investment status.
          </Typography>
        }
        primaryButtonText="Cancel"
        secondaryButtonText='Change status'
        onClose={() => setShowConfirmationModal(false)}
        open={showConfirmationModal ?? false}
        finishWorking={() => setShowConfirmationModal(false)}
        mainAction={() => {setShowConfirmationModal(false); reqTransactionStatus(transactionStatus.transaction, transactionStatus.status)}}
      />
      <ExpandButton expanded={isExpanded} handleExpand={onClick} />
    </Box>
  );
};

export { InvestmentCard };
