import { Box, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import QRCode from 'react-qr-code';

import { sessionAreas } from '../../api/session';
import appstoreImage from '../../assets/img/appstore.png';
import playstoreImage from '../../assets/img/playstore.png';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { InputField } from '../../components/InputField';
import { SimplePageContainer } from '../../components/SimplePageContainer'; //eslint-disable-line
import { TwoFAProps } from './TwoFA.d';

const TwoFA: FC<TwoFAProps> = ({ register, errors, validateCode, secretCode, isOTPRegistered }) => {
  const authenticatorName = `${process.env.REACT_APP_AUTHENTICATOR_NAME}` || '';
  const secret = `otpauth://totp/${authenticatorName}?secret=${secretCode}`;

  return (
    <SimplePageContainer>
      <Typography variant="h1" mt={4} mb={5}>
        Security verification
      </Typography>
      {!isOTPRegistered && (
        <Box>
          <Typography variant="body1" mb={3}>
            To further secure your account you will need to set up 2nd Factor Authentication. Please
            follow the steps below:
          </Typography>
          <Typography variant="body1" color="text.primary" fontWeight="bold">
            1. Download Google Authenticator:
          </Typography>
          <Stack direction="row" alignItems="center" gap={3} mt={3} mb={4}>
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
            >
              <img width="115px" height="37px" src={playstoreImage} alt="" />
            </Link>
            <Link
              target="_blank"
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            >
              <img width="115px" height="37px" src={appstoreImage} alt="" />
            </Link>
          </Stack>
          <Typography variant="body1" color="text.primary" fontWeight="bold">
            2. Link your account:
          </Typography>
          <Typography variant="body1" mb={3}>
            Open the Google Authenticator app and scan the QR:
          </Typography>
          <Box display="grid" sx={{ placeContent: 'center' }}>
            <QRCode
              role="img"
              title="qr code to link second factor authentication"
              aria-label="qr code to link second factor authentication"
              value={secret}
              size={90}
            />
          </Box>
          <Typography variant="body1" mb={1} mt={3}>
            or enter the following code in your Google Authenticator app.
          </Typography>
          <Typography
            variant="h3"
            textAlign="center"
            color="text.secondary"
            sx={{ wordBreak: 'break-all' }}
          >
            {secretCode}
          </Typography>
          <Typography variant="body1" color="text.primary" fontWeight="bold" mt={4} mb={3}>
            Enter the temporary code shown in your Google Authenticator app
          </Typography>
        </Box>
      )}
      {isOTPRegistered && (
        <Typography variant="body1" mb={4}>
          Enter your temporary Google Authenticator code:
        </Typography>
      )}
      <form onSubmit={validateCode}>
        <InputField
          {...register('twoFaCode')}
          fullWidth
          label="Code"
          placeholder="e.g. 919 345"
          error={!!errors['twoFaCode']}
          helperText={errors['twoFaCode']?.message}
        />
        <Box gap={4} mt={7}>
          <ButtonSubmit area={sessionAreas.verifyTwoFA}>
            {isOTPRegistered ? 'Validate code' : 'Submit'}
          </ButtonSubmit>
        </Box>
      </form>
    </SimplePageContainer>
  );
};
export { TwoFA };
