import { IReturnError, reportError } from '../../helpers/reportError';
import { IWallet } from '../../pages/InvestorDetail/InvestorDetail.d';
import { getWallets } from '../blockchain';
import { getInvestorsDetails } from '../investors/investors';
import { IInvestorDetail } from '../investors/investors.d';
import { IReturnType } from '../main';
import { commissionsApi } from '../main/main'; //eslint-disable-line
import {
  ICommissions,
  ICommissionsRaw,
  IUpdateCommissionStatus,
  IUpdateCommissionStatusResponse,
} from './commissions.d';
import { decorateCommissionsPerProject } from './decorators';

const getCommissions = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<ICommissions[]> | IReturnError> => {
  try {
    const res = await commissionsApi.get<ICommissionsRaw[]>('/commissions', {
      params: { projectId: id },
      signal,
    });
    const userIds = res.data.map((commission) => commission.userId);
    if (userIds.length) {
      const [investors, whitelist] = await Promise.all([
        getInvestorsDetails(userIds, signal),
        getWallets(userIds, signal),
      ]);
      return {
        data: decorateCommissionsPerProject(
          res.data,
          investors?.data as IInvestorDetail[],
          whitelist?.data as IWallet[]
        ),
        status: 200,
      };
    } else {
      return {
        data: [],
        status: 200,
      };
    }
  } catch (error) {
    return reportError(error);
  }
};

const updateCommissionStatus = async (
  req: IUpdateCommissionStatus
): Promise<IReturnType<IUpdateCommissionStatusResponse> | IReturnError> => {
  try {
    await commissionsApi.post('/commissions', {
      commissionStatus:
        req.commissionStatus === 'PAYMENT_IN_PROCESS' ? 'IN_PROGRESS' : req.commissionStatus,
      projectId: req.projectId,
      userId: req.userId,
    });
    return { data: { message: 'OK' }, status: 200 };
  } catch (error) {
    return reportError(error);
  }
};

export { getCommissions, updateCommissionStatus };
