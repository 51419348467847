import { FC } from 'react';

import { faBrokersAreas } from '../../../api/brokers';
import { EmptySearch } from '../../../components/EmptyStates';
import { Table } from '../../../components/Table';
import { IProps } from '../BrokersAndFA.d';
import { Mobile } from '../Mobile';
import { TableHeader } from '../TableHeader';

const Brokers: FC<IProps> = ({
  columns,
  rows,
  isDesktop,
  filter,
  searchBar,
  goToDetails,
  applySort,
  rowCount,
  onPageChange,
  currentPage,
  defaultCommission,
}) => {
  return (
    <>
      {isDesktop ? (
        <Table
          sortingMode="server"
          paginationMode="server"
          onSortModelChange={applySort}
          area={faBrokersAreas.getFABrokersList}
          columns={columns}
          rows={rows ?? []}
          rowCount={rowCount}
          pageSize={10}
          page={currentPage}
          onPageChange={onPageChange}
          rowsPerPageOptions={[10]}
          components={{ NoRowsOverlay: EmptySearch, Toolbar: TableHeader }}
          componentsProps={{
            toolbar: { filter, searchBar },
          }}
        />
      ) : (
        <Mobile
          rows={rows}
          filter={filter}
          searchBar={searchBar}
          goToDetails={goToDetails}
          onPageChange={onPageChange}
          applySort={applySort}
          rowCount={rowCount}
          page={currentPage}
          defaultCommission={defaultCommission}
        />
      )}
    </>
  );
};

export { Brokers };
