import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

const LicencePause: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33464 4.9974C3.33464 3.15645 4.82702 1.66406 6.66797 1.66406H15.8346C17.2153 1.66406 18.3346 2.78335 18.3346 4.16406C18.3346 5.54477 17.2153 6.66406 15.8346 6.66406H15.0013V15.8307C15.0013 17.2114 13.882 18.3307 12.5013 18.3307H5.0013C3.16035 18.3307 1.66797 16.8383 1.66797 14.9974V14.1641C1.66797 13.7038 2.04106 13.3307 2.5013 13.3307H3.33464V4.9974ZM3.33464 14.9974C3.33464 15.9179 4.08083 16.6641 5.0013 16.6641H10.1436C10.0514 16.4034 10.0013 16.1229 10.0013 15.8307V14.9974H3.33464ZM12.5013 16.6641C12.9615 16.6641 13.3346 16.291 13.3346 15.8307V4.16406C13.3346 3.87187 13.3848 3.59138 13.4769 3.33073H6.66797C5.74749 3.33073 5.0013 4.07692 5.0013 4.9974V13.3307H10.8346C11.2949 13.3307 11.668 13.7038 11.668 14.1641V15.8307C11.668 16.291 12.0411 16.6641 12.5013 16.6641ZM15.8346 3.33073C15.3744 3.33073 15.0013 3.70383 15.0013 4.16406V4.9974H15.8346C16.2949 4.9974 16.668 4.6243 16.668 4.16406C16.668 3.70383 16.2949 3.33073 15.8346 3.33073Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 6.60243C6.875 6.29561 7.12373 6.04688 7.43056 6.04688H7.98611C8.29294 6.04688 8.54167 6.29561 8.54167 6.60243V9.93576C8.54167 10.2426 8.29294 10.4913 7.98611 10.4913H7.43056C7.12373 10.4913 6.875 10.2426 6.875 9.93576V6.60243ZM7.98611 6.60243L7.43056 6.60243V9.93576H7.98611V6.60243Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43186 6.2526C7.24009 6.2526 7.08464 6.40806 7.08464 6.59983V9.93316C7.08464 10.1249 7.24009 10.2804 7.43186 10.2804H7.98741C8.17918 10.2804 8.33464 10.1249 8.33464 9.93316V6.59983C8.33464 6.40806 8.17918 6.2526 7.98741 6.2526H7.43186ZM6.66797 6.59983C6.66797 6.17794 7.00997 5.83594 7.43186 5.83594H7.98741C8.4093 5.83594 8.7513 6.17794 8.7513 6.59983V9.93316C8.7513 10.355 8.4093 10.697 7.98741 10.697H7.43186C7.00997 10.697 6.66797 10.355 6.66797 9.93316V6.59983ZM7.43186 6.39149L7.98741 6.39149C8.04267 6.39149 8.09566 6.41344 8.13473 6.45251C8.1738 6.49158 8.19575 6.54457 8.19575 6.59983V9.93316C8.19575 10.0482 8.10247 10.1415 7.98741 10.1415H7.43186C7.3168 10.1415 7.22352 10.0482 7.22352 9.93316V6.59983C7.22352 6.48477 7.3168 6.39149 7.43186 6.39149ZM7.64019 6.80816V9.72483H7.77908V6.80816H7.64019Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 6.60243C9.375 6.29561 9.62373 6.04688 9.93056 6.04688H10.4861C10.7929 6.04688 11.0417 6.29561 11.0417 6.60243V9.93576C11.0417 10.2426 10.7929 10.4913 10.4861 10.4913H9.93056C9.62373 10.4913 9.375 10.2426 9.375 9.93576V6.60243ZM10.4861 6.60243L9.93056 6.60243V9.93576H10.4861V6.60243Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93186 6.2526C9.74009 6.2526 9.58464 6.40806 9.58464 6.59983V9.93316C9.58464 10.1249 9.74009 10.2804 9.93186 10.2804H10.4874C10.6792 10.2804 10.8346 10.1249 10.8346 9.93316V6.59983C10.8346 6.40806 10.6792 6.2526 10.4874 6.2526H9.93186ZM9.16797 6.59983C9.16797 6.17794 9.50997 5.83594 9.93186 5.83594H10.4874C10.9093 5.83594 11.2513 6.17794 11.2513 6.59983V9.93316C11.2513 10.355 10.9093 10.697 10.4874 10.697H9.93186C9.50997 10.697 9.16797 10.355 9.16797 9.93316V6.59983ZM9.93186 6.39149L10.4874 6.39149C10.5427 6.39149 10.5957 6.41344 10.6347 6.45251C10.6738 6.49158 10.6957 6.54457 10.6957 6.59983V9.93316C10.6957 10.0482 10.6025 10.1415 10.4874 10.1415H9.93186C9.8168 10.1415 9.72352 10.0482 9.72352 9.93316V6.59983C9.72352 6.48477 9.8168 6.39149 9.93186 6.39149ZM10.1402 6.80816V9.72483H10.2791V6.80816H10.1402Z"
      />
    </SvgIcon>
  );
};
export { LicencePause };
