import { Box, Button, List, Stack } from '@mui/material';
import { IconEye } from '@tabler/icons';
import format from 'date-fns/format';
import { FC, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { faBrokersAreas, IFABrokersRows } from '../../api/brokers';
import { EmptySearch } from '../../components/EmptyStates';
import { MobileTableCard } from '../../components/MobileTableCard';
import { OverlayLoader } from '../../components/OverlayLoader';
import { Paginator } from '../../components/Paginator/Paginator';
import { StatusPointer } from '../../components/ProjectInvestors';
import { SortSelectorFilter } from '../../components/SortSelectorFilter';
import { TSortOrder } from '../../components/TableHeaderTitle';
// eslint-disable-next-line import/order
import { SUMSUB_STATUS, SUMSUB_URL } from '../../helpers/constants';
import { ReactComponent as SumSubLogo } from './../../assets/img/sumsub_logo.svg';
import { IMobileProps } from './BrokersAndFA.d';

const Mobile: FC<IMobileProps> = ({
  rows,
  filter,
  searchBar,
  goToDetails,
  onPageChange,
  applySort,
  rowCount,
  page,
  defaultCommission,
}) => {
  const [activeSort, setActiveSort] = useState<keyof IFABrokersRows>();
  const { promiseInProgress: loading } = usePromiseTracker({
    area: faBrokersAreas.getFABrokersList,
  });

  const mobileAdapter = () => {
    return rows.map((row) => ({
      action: (
        <Stack direction="row" justifyContent="center">
          <Button
            onClick={() => goToDetails(row.id)}
            size="small"
            variant="text"
            color="secondary"
            startIcon={<IconEye />}
          >
            View details
          </Button>
          <Button
            onClick={() => window.open(SUMSUB_URL + row.applicantId)}
            size="small"
            variant="text"
            color="secondary"
            disabled={row.sumsubStatus === SUMSUB_STATUS.NOT_INITIATED}
            sx={{
              '&.Mui-disabled': {
                pointerEvents: 'auto',
              },
            }}
            startIcon={
              <SumSubLogo
                width={24}
                fillOpacity={row.sumsubStatus === SUMSUB_STATUS.NOT_INITIATED ? 0.3 : 1}
              />
            }
          >Go to SumSub</Button>
        </Stack>
      ),
      data: {
        Name: row.name,
        'Legal Name': row.legalName, // eslint-disable-line
        Email: row.email, // eslint-disable-line
        'Creation Date': format(new Date(row.creationDate), 'MM/dd/yyyy'), // eslint-disable-line
        Status: (
          <Stack direction="row" alignItems="center" gap={1}>
            <StatusPointer status={row.isEnabled === 'Active' ? 'success' : 'default'} />
            {row.isEnabled}
          </Stack>
        ),
        Location: row.location, // eslint-disable-line
        'Commission Rate': `${row.commissionRate === 0 ? defaultCommission : row.commissionRate}%`, // eslint-disable-line
      },
      id: row.id,
    }));
  };

  const sortOptions = [
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Status',
      value: 'isEnabled',
    },
  ];

  const handleSort = (alias: keyof IFABrokersRows, sortOrderBy: TSortOrder) => {
    setActiveSort(alias);
    applySort([{ field: alias, sort: sortOrderBy }]);
  };

  const resetSort = () => {
    applySort([]);
    setActiveSort(undefined);
  };

  return (
    <>
      <Stack px={3}>
        <Stack gap={3}>
          {searchBar}
          <Stack direction="row" justifyContent="space-between">
            <SortSelectorFilter
              options={sortOptions}
              resetActiveSort={resetSort}
              onChange={handleSort}
              activeSort={activeSort || ''}
              defaultValue={sortOptions[0].label}
            />
            {filter}
          </Stack>
          {loading && (
            <Box
              position="relative"
              sx={{
                border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                borderRadius: 2,
                minHeight: 200,
              }}
            >
              <OverlayLoader theme="container" area={faBrokersAreas.getFABrokersList} />
            </Box>
          )}
          {rows.length === 0 && !loading && <EmptySearch />}
          {rows.length > 0 && !loading && (
            <List>
              {mobileAdapter().map((row) => (
                <MobileTableCard key={row.id} data={row.data} action={row.action} />
              ))}
            </List>
          )}
        </Stack>

        <Paginator count={rowCount} limit={10} page={page} setPage={onPageChange} />
      </Stack>
    </>
  );
};

export { Mobile };
