import { Grid, Typography, TypographyProps } from '@mui/material'; //eslint-disable-line
import { FC } from 'react';

import { Gray } from '../../theme/tokens/colors';

const styles: TypographyProps<'div'> = {
  color: '#000',
  fontWeight: 600,
  sx: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  variant: 'body2',
};

export const Header: FC = () => {
  return (
    <Grid
      alignItems="center"
      justifyContent="center"
      columns={10}
      columnSpacing={4}
      sx={{
        borderBottom: `1px solid ${Gray[300]}`,
        minHeight: '48px',
      }}
      container
    >
      <Grid item sm={2}>
        <Typography component="div" pl={2} {...styles}>
          Project
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography component="div" {...styles}>
          Original investment (USD)
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography component="div" {...styles}>
          Date
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography component="div" {...styles}>
          Current value (Tokens)
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography component="div" pr={2} {...styles}>
          Project Status
        </Typography>
      </Grid>
    </Grid>
  );
};
