import { Box } from '@mui/material';
import { FC } from 'react';

import { Gray } from '../../theme/tokens/colors';

const SimplePageContainer: FC = ({ children }) => {
  return (
    <Box
      bgcolor="background.paper"
      maxWidth={500}
      boxShadow={1}
      sx={{
        border: `1px solid ${Gray[300]}`,
        boxShadow: 0,
        borderRadius: { xs: 0, sm: 2 }, //eslint-disable-line
        height: { xs: '100%', sm: 'auto' }, //eslint-disable-line
        minWidth: { xs: '100%', sm: '500px' }, //eslint-disable-line
        overflow: 'auto',
        p: { xs: 3, sm: 6 }, //eslint-disable-line
        py: { xs: 10, sm: 6 }, //eslint-disable-line
      }}
    >
      {children}
    </Box>
  );
};

export { SimplePageContainer };
