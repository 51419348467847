import { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IUserData } from '../../api/session';
import { getUsers } from '../../api/users/users';
import { IUsersResponse } from '../../api/users/users.d';
import { IUser } from '../../components/PageContent';
import { havePermission } from '../../helpers/havePermission';
import { isRequestError } from '../../helpers/isRequestError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { authContext } from '../../hooks/useAuth/authContext';
// eslint-disable-next-line import/order
import { IFilterParams } from '../Investors';
import UsersContext, { IUsersContextValue } from './context';
import { Users } from './Users';

export const UsersPresenter: FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [modalCreateUser, setModalCreateUser] = useState({ display: false });
  const getUsersController = useAbortRequest();
  const [lastPage, setLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    limit: 10,
    page: 1,
  });

  const { state } = useLocation();
  const navState = state as { creatingUser: boolean };
  const auth = useContext(authContext);
  const nav = useNavigate();

  const reqUsers = async () => {
    setIsLoading(true);
    const { data, status } = await getUsers(
      { ...filterParams, page: filterParams.page },
      getUsersController.signal
    );

    if (!isRequestError(status)) {
      const res = data as IUsersResponse;
      setUsers(res.users);
      setLastPage(res.lastPage);
    }
    setIsLoading(false);
  };

  const value: IUsersContextValue = {
    filterParams,
    isLoading,
    lastPage,
    modalCreateUser,
    setFilterParams,
    setModalCreateUser,
    setUsers,
    users,
  };

  useEffect(() => {
    reqUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersController.signal, filterParams]);

  useEffect(() => {
    if (navState?.creatingUser) {
      setModalCreateUser({ display: true });
      window.history.replaceState(null, '');
      const currentRoles = (auth?.userData as IUserData)?.roles || [];
      if (!havePermission(currentRoles, ['systemAdmin', 'superAdmin'])) {
        nav('/not-found');
      }
    }
  }, [navState, auth, nav]);

  return (
    <UsersContext.Provider value={value}>
      <Users />
    </UsersContext.Provider>
  );
};
