import { Box } from '@mui/material';
import { FC } from 'react';

import { SearchBox } from '../../../../components/SearchBox';
import { ISearchProps } from '../Commissions.d';

const Search: FC<ISearchProps> = ({ applySearch, clearSearch }) => {
  return (
    <Box width={{ lg: '50%', md: '50%', sm: 'auto', xs: '100%' }}>
      <SearchBox onSubmit={applySearch} onClear={clearSearch} />
    </Box>
  );
};

export { Search };
