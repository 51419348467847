import { List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { IconCircleCheck, IconInfoCircle } from '@tabler/icons';
import { FC } from 'react';

import { Error, Gray, Success } from '../../theme/tokens/colors'; // eslint-disable-line
import { IProps } from './PasswordRules.d';

const feedbackColor = (valid: boolean | undefined) => {
  switch (valid) {
    case undefined:
      return Gray[700];
    case true:
      return Success[500];
    case false:
      return Error[500];
  }
};

export const PasswordRules: FC<IProps> = ({ anchorEl, open, rules }) => {
  const anchor = anchorEl?.current;

  if (!open) {
    return null;
  }

  return (
    <Paper
      sx={{
        '&:before': {
          bgcolor: 'secondary.light',
          border: '1px solid transparent',
          borderBottom: 'none',
          borderColor: 'secondary.main',
          borderRight: 'none',
          content: '""',
          display: 'block',
          height: 15,
          left: 15,
          position: 'absolute',
          top: -1,
          transform: 'translateY(-50%) rotate(45deg)',
          width: 15,
        },
        backgroundColor: 'secondary.light',
        border: '1px solid transparent',
        borderColor: 'secondary.main',
        borderRadius: 2,
        boxShadow: 0,
        left: anchor?.offsetLeft,
        p: 2,
        position: 'absolute',
        top: anchor?.offsetTop + anchor?.offsetHeight + 16,
        width: { xs: anchor?.clientWidth, md: 250 }, //eslint-disable-line
        zIndex: 'tooltip',
      }}
    >
      <Typography variant="caption" fontWeight="bold">
        Password must have:
      </Typography>
      <List sx={{ pb: 0 }}>
        {rules.map((rule) => (
          <ListItem sx={{ p: 0 }} key={rule.text}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              {rule?.valid ? (
                <IconCircleCheck color={feedbackColor(rule?.valid)} size={20} />
              ) : (
                <IconInfoCircle color={feedbackColor(rule?.valid)} size={20} />
              )}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                color: `${feedbackColor(rule?.valid)}`,
                fontSize: '14px',
                lineHeight: '20px',
              }}
              primary={rule.text}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
