const areas = {
  connectInvestor: 'connectInvestor',
  getDefaultCommission: 'getDefaultCommission',
  getFABrokerDetail: 'getFABrokerDetail',
  getFABrokersList: 'getFABrokersList',
  getInvestmentsList: 'getInvestmentsList',
  getInvestorPerFaBroker: 'getInvestorPerFaBroker',
  getReferrerAssociatedIds: 'getReferrerAssociatedIds',
  getReferrersByIds: 'getReferrersByIds',
  getUserCommissions: 'getUserCommissions',
  setCommissionPerProject: 'setCommissionPerProject',
  setCommissionRate: 'setCommissionRate',
};

export { areas };
