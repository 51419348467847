// eslint-disable-next-line import/order
import { IToastProps } from '../hooks/useToast/useToast.d';
import { API_ERRORS } from './constants';
import { IServerError } from './reportError';

export const handleApiErrors = (err: IServerError, openToast: (props: IToastProps) => void) => {
  switch (err.code) {
    case API_ERRORS.BLACKLISTED_BROKER_EXCEPTION:
      openToast({ message: 'The broker you are trying to change rate is Blacklisted', severity: 'error', title: 'Blacklisted broker' });
      break;
    case API_ERRORS.NOT_AUTHORIZED_EXCEPTION:
      openToast({ message: err.message, severity: 'error', title: 'Not Authorized'  });
      break;
    default:
      openToast({ message: err.message || 'Contact dev team', severity: 'error', title: err.title || 'Something went wrong' });
  }
};
