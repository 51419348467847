import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { IInvestor } from '../../components/PageContent';
import { IReturnError, reportError } from '../../helpers/reportError';
import { IFilterParams } from '../../pages/Investors';
import { investorsExternalUsers, IReturnType, projectsApi } from '../main'; // eslint-disable-line
import { areas } from './areas';
import { decorateInvestors, decorateUsers,transactions as decorateTransactions } from './decorators';
import {
  IInvestorDetail,
  IInvestorDetailsRes,
  IInvestorResponse,
  ITransactionsParams,
  ITransactionsResponse,
  ITransactionsResponseRaw,
} from './investors.d';

const parseObjToQuery = (obj: { [key: string]: string }) => {
  let query = '';
  const keys = Object.keys(obj);
  keys.forEach((key, i) => {
    if (i === keys.length - 1) {
      query += `${key}=${obj[key]}`;
    } else {
      query += `${key}=${obj[key]}&`;
    }
  });
  return query;
};

let paginationTokens: { [key: string]: string } = {};
const getInvestors = async (
  req: IFilterParams,
  signal: AbortSignal
): Promise<IReturnType<IInvestorResponse> | IReturnError> => {
  const token = req.page ? paginationTokens?.[req.page] : null;
  let params = { ...req };
  const reqPage = req?.page ?? 1;

  // resets the saved pagination tokens when a new req from page 1 starts.
  if (reqPage === 1) {
    paginationTokens = {};
  }
  // add the pagination token as a request param if the requested page is not the first one and there are no filters.
  if (token && req?.page !== 1) {
    params = { ...params, paginationToken: token };
  }
  // delete the pagination token when there is the role or name filter.
  if (req?.filterBy?.includes('role') || req?.filterBy?.includes('name')) {
    delete params.paginationToken;
  }
  // delete the page param when the request has pagination token.
  if (params.paginationToken) {
    delete params.page;
  }

  try {
    const res: AxiosResponse<IInvestorResponse> = await trackPromise(
      investorsExternalUsers.get('external-users', { params, signal }),
      areas.getInvestors
    );
    if (res?.data?.paginationToken) {
      paginationTokens[reqPage + 1] = parseObjToQuery(res.data.paginationToken);
    }
    return {
      data: decorateInvestors(res.data),
      status: res.status,
    };
  } catch (error) {
    paginationTokens = {};
    return reportError(error);
  }
};

const getInvestorsInfo = async (
  signal: AbortSignal
): Promise<IReturnType<IInvestor[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IInvestor[]> = await trackPromise(
      investorsExternalUsers.get(`external-users/info`, { signal }),
      areas.getInvestorInfo
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getInvestorDetail = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<IInvestor> | IReturnError> => {
  try {
    const res: AxiosResponse<IInvestor> = await trackPromise(
      investorsExternalUsers.get(`external-users/${id}`, { signal }),
      areas.getInvestorDetail
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getInvestorsDetails = async (
  users: string[],
  signal: AbortSignal
): Promise<IReturnType<IInvestorDetail[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IInvestorDetailsRes> = await trackPromise(
      investorsExternalUsers.post(`external-users`, { users }, { signal }),
      areas.getInvestorsDetails
    );
    return {
      data: decorateUsers(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getTransactions = async (
  params: ITransactionsParams,
  signal: AbortSignal
): Promise<IReturnType<ITransactionsResponse> | IReturnError> => {
  try {
    const res: AxiosResponse<ITransactionsResponseRaw> = await trackPromise(
      projectsApi.get('investments/investors', { params, signal }),
      areas.getTransactions
    );
    return {
      data: decorateTransactions(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const editInvestor = async (
  id: string,
  enabled: boolean,
  signal?: AbortSignal
): Promise<IReturnType<string> | IReturnError> => {
  try {
    const res: AxiosResponse<string> = await trackPromise(
      investorsExternalUsers.put(
        `external-users/${id}`,
        {
          status: enabled,
        },
        { signal }
      ),
      areas.editInvestor
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const editInvestorMode = async (
  id: string,
  enabled: boolean,
  signal?: AbortSignal
): Promise<IReturnType<string> | IReturnError> => {
  try {
    const res: AxiosResponse<string> = await trackPromise(
      investorsExternalUsers.put(
        `external-users/${id}`,
        {
          preview: enabled,
        },
        { signal }
      ),
      areas.editInvestor
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

export {
  editInvestor,
  editInvestorMode,
  getInvestorDetail,
  getInvestors,
  getInvestorsDetails,
  getInvestorsInfo,
  getTransactions,
};
