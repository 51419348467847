import { Drawer, Grid, Theme, Typography, useMediaQuery } from '@mui/material'; //eslint-disable-line
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { IconCircleX } from '@tabler/icons';
import { FC, useContext, useEffect, useState } from 'react';

import { isRequestError } from '../../helpers/isRequestError';
import { createUser, updateUser } from '../../api/users/users';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { toastContext } from '../../hooks/useToast';
import UsersContext, { IUsersContextValue } from '../../pages/Users/context';
import { Breadcrumbs } from '../Breadcrumbs';
import { CreateUserForm, ICreateUserData, IFormData, IUpdateUserData } from '../CreateUserForm'; //eslint-disable-line
import { ICreateUserModal } from './CreateUserModal.d'; //eslint-disable-line
import { Interactive } from '../../theme/tokens/colors'; //eslint-disable-line
import { IServerError } from '../../helpers/reportError';

export const CreateUserModal: FC<ICreateUserModal> = ({ display = false, onClose }) => {
  const [open, setOpen] = useState<boolean>(display);
  const { openToast } = useContext(toastContext);
  const { modalCreateUser, setUsers, users } = useContext(
    UsersContext
  ) as IUsersContextValue;
  const [titleModal, setTitleModal] = useState<string>('Create User');
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const createUserController = useAbortRequest();
  const horizontalPosition = isTablet ? 'right' : 'center';
  const width = isTablet ? 467 : 448;

  const handleOnClose = () => {
    setOpen(false);
    onClose();
  };

  const reqCreateUser = async (formData: IFormData): Promise<boolean> => {
    try {
      const { email, firstName: givenName, lastName, roles } = formData;
      const payload: ICreateUserData = {
        adminUser: {
          email,
          givenName,
          lastName,
          roles,
        },
      };
      const { data, status } = await createUser(payload, createUserController.signal);
      if (!isRequestError(status)) {
        openToast({
          horizontalPosition,
          message:
            'The new user will receive an email to set up a password and complete the user creation in the Reach platform.',
          severity: 'success',
          title: data.message,
          width,
        });
        handleOnClose();
        return true;
      } else {
        openToast({
          horizontalPosition,
          severity: 'info',
          title: data.message,
          width,
        });
        return false;
      }
    } catch (err) {
      openToast({
        horizontalPosition,
        message: 'Please check the information and try again',
        severity: 'error',
        title: 'Something went wrong',
        width,
      });
      return false;
    }
  };

  const reqEditUser = async (idUser: string, formData: IFormData): Promise<boolean> => {
    const { firstName: givenName, lastName, roles, active } = formData;
    const payload: IUpdateUserData = {
      adminUser: {
        active,
        givenName,
        lastName,
        roles,
      },
    };
    const { data, status } = await updateUser(idUser, payload, createUserController.signal);
    if (!isRequestError(status)) {
      openToast({
        horizontalPosition,
        message: data?.message,
        severity: 'success',
        title: '',
        width,
      });
      const updatedUsers = users.map((user) =>
        user.id === idUser
          ? { ...user, active, firstName: givenName, lastName, roles: roles }
          : user
      );
      setUsers(updatedUsers);
      handleOnClose();
      return true;
    } else {
      const err = data as IServerError;
      openToast({
        horizontalPosition,
        message: err.message,
        severity: 'error',
        title: 'Something went wrong',
        width,
      });
      return false;
    }
  };

  useEffect(() => {
    if (modalCreateUser.display) {
      const title = modalCreateUser.editUser ? 'Edit User' : 'Create User';
      setTitleModal(title);
    }
  }, [modalCreateUser]);

  useEffect(() => {
    setOpen(display);
  }, [display]);

  return (
    <>
      <Drawer
        open={open}
        anchor={isDesktop ? 'right' : 'bottom'}
        onClose={handleOnClose}
        data-testid="test-create-user-modal"
        sx={{
          width: {
            xs: '100%',
          },
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        PaperProps={{
          sx: { width: { xs: '100%', lg: '512px' }, height: { xs: '90%', lg: '100%' } }, //eslint-disable-line
        }}
      >
        <Grid alignItems="center" justifyContent="center" columns={{ xs: 4 }} py="36px" container>
          <Grid
            item
            xs={4}
            md="auto"
            width={{ md: '100%' }}
            px={{ md: '32px', xs: '24px' }}
            mt="auto"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Breadcrumbs
                current={titleModal}
                linksList={[
                  { active: false, label: 'Home', path: '/' },
                  { active: false, label: 'Users', path: '/users' },
                ]}
              />
              <Button
                onClick={handleOnClose}
                endIcon={<IconCircleX size={20} />}
                variant="text"
                sx={{ fontSize: '1.2rem', fontWeight: 600, textTransform: 'capitalize' }}
              >
                Close
              </Button>
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            md="auto"
            sm={3}
            width={{ md: '100%' }}
            px={{ md: '40px', xs: '24px' }}
            mb="auto"
          >
            <Box height="41px"></Box>

            <Box mb="37px">
              <Typography
                component="h5"
                fontSize="28px"
                fontWeight={700}
                color={Interactive.secondary}
                data-testid="test-create-user-title"
              >
                {titleModal}
              </Typography>
            </Box>

            <CreateUserForm editUser={reqEditUser} createUser={reqCreateUser} />

            <Box height="24px"></Box>

            <Button color="secondary" onClick={handleOnClose} fullWidth>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};
