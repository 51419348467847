import { Box, capitalize, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { blockchainAreas } from '../../api/blockchain';
import { areasInvestors } from '../../api/investors';
import { OverlayLoader } from '../../components/OverlayLoader';
import { formatDateLetters } from '../../helpers';
import { SUMSUB_STATUS, SUMSUB_URL } from '../../helpers/constants';
import { IReportWhitelistProps } from '../../pages/InvestorDetail/InvestorDetail.d';
import { Gray, Interactive } from '../../theme/tokens/colors';

const ReportWhitelist: FC<IReportWhitelistProps> = ({ basicData, walletData }) => {
  return (
    <Stack direction="column" spacing={4}>
      <Paper
        sx={{
          bgcolor: 'background.paper',
          border: `1px solid ${Gray[300]}`,
          borderRadius: 2,
          boxShadow: 0,
          minHeight: 184,
          p: 4,
          position: 'relative',
        }}
      >
        <Stack direction="column" spacing={3}>
          <Typography
            variant="body1"
            component="span"
            sx={{ color: Interactive.secondary }}
            fontWeight={600}
          >
            KYC - AML report
          </Typography>
          <Typography variant="body1" component="div" noWrap>
            <Box component="span" fontWeight={600}>
              Status:{' '}
            </Box>
            {basicData?.sumsub?.status !== undefined
              ? capitalize(basicData.sumsub.status.toLowerCase()).replace('_', ' ')
              : ''}
            <br />
            {basicData?.sumsub?.lastModifiedDate && (
              <Box component="span">
                <strong>Last modified:</strong>{' '}
                {formatDateLetters(basicData?.sumsub?.lastModifiedDate)}
              </Box>
            )}
          </Typography>
          {basicData?.sumsub?.status !== SUMSUB_STATUS.NOT_INITIATED && (
            <a href={SUMSUB_URL + basicData?.sumsub?.applicantId} rel="noreferrer" target="_blank">
              Go to SumSub
            </a>
          )}
        </Stack>

        <OverlayLoader area={areasInvestors.getInvestorDetail} theme="container" />
      </Paper>
      <Paper
        sx={{
          bgcolor: 'background.paper',
          border: `1px solid ${Gray[300]}`,
          borderRadius: 2,
          boxShadow: 0,
          minHeight: 184,
          p: 4,
          position: 'relative',
        }}
      >
        <Stack direction="column" spacing={3}>
          <Typography
            variant="body1"
            component="span"
            sx={{ color: Interactive.secondary }}
            fontWeight={600}
          >
            Wallet Whitelist
          </Typography>
          <Typography variant="body1" component="div" noWrap>
            <Box component="span" fontWeight={600}>
              Status:{' '}
            </Box>
            {walletData?.whitelistStatus}
          </Typography>
        </Stack>
        <OverlayLoader area={blockchainAreas.getWallets} theme="container" />
      </Paper>
    </Stack>
  );
};

export { ReportWhitelist };
