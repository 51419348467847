import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useContext } from 'react';

import { authContext } from '../../hooks/useAuth/authContext';

export const AvatarName: FC = () => {
  const auth = useContext(authContext);
  const theme = useTheme();
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'));

  const firstName = auth?.userData?.firstName ?? 'N';
  const lastName = auth?.userData?.lastName ?? 'A';

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        width={32}
        height={32}
        borderRadius="50%"
        bgcolor="secondary.main"
        display="flex"
        justifyContent="center"
        alignItems="center"
        textTransform="uppercase"
      >
        <Typography variant="body1" component="span" color="#fff" fontWeight={600}>
          {`${firstName.substring(0, 1)}${lastName.substring(0, 1)}`}
        </Typography>
      </Box>
      {breakpointLg && (
        <Typography variant="body2" ml={1} color="#fff" component="span">
          {`${firstName} ${lastName}`}
        </Typography>
      )}
    </Box>
  );
};
