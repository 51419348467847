import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { useOutletContext, useParams } from 'react-router-dom';

import {
  commissionsAreas,
  decorateStatusLabels,
  getCommissions,
  ICommissions,
} from '../../../api/commissions';
import { updateCommissionStatus } from '../../../api/commissions/commissions';
import { ClosingPayoutsModal, ConfirmationPayoutsModal } from '../../../components/CreatePayouts';
import { PROJECT_STATUSES } from '../../../helpers/constants';
import { isRequestError } from '../../../helpers/isRequestError';
import { quantityBeautifierStandard } from '../../../helpers/quantityBeautifier';
import { IServerError } from '../../../helpers/reportError';
import { useAbortRequest } from '../../../hooks/useAbortRequest/useAbortRequest';
import { toastContext } from '../../../hooks/useToast';
import { IProjectOutletContext } from '../ProjectDetail.d'; // eslint-disable-line
import { Commissions } from './Commissions';
import { ICommissionsFiltersProps } from './Commissions.d';

const initialFilterValues = { commissionStatus: '', paymentMethod: '' };
const CommissionsPresenter: FC = () => {
  const { id } = useParams();
  const commissionsListController = useAbortRequest();
  const { openToast } = useContext(toastContext);
  const { status: projectStatus } = useOutletContext<IProjectOutletContext>();
  const [search, setSearch] = useState<string>('');
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [commissions, setCommissions] = useState<ICommissions[]>([]);
  const [alertModal, setAlertModal] = useState<
    | 'notPayable'
    | 'notAllowed'
    | 'notFailed'
    | 'confirmation'
    | 'confirmationNotPayable'
    | 'confirmationBlacklisted'
    | 'pay'
    | 'notPending'
    | 'payBlacklisted'
    | null
  >(null);
  const [filteredCommissions, setFilteredCommissions] = useState<ICommissions[]>([]);
  const { control, handleSubmit, reset, watch } = useForm<ICommissionsFiltersProps>({
    defaultValues: initialFilterValues,
  });
  const commissionStatus = watch('commissionStatus');
  const paymentMethod = watch('paymentMethod');
  const toggleMenu = () => setOpenMenu(!openMenu);
  const selectedStatus = useRef<ICommissions['status']>('PENDING');

  const hideAlertModal = () => {
    setAlertModal(null);
    setOpenMenu(false);
    setSelectedRows([]);
  };

  const filter = (form: ICommissionsFiltersProps, list: ICommissions[]): ICommissions[] => {
    return list.filter((row) => {
      if (form.commissionStatus && form.paymentMethod) {
        return (
          (row.paymentMethod === form.paymentMethod ||
            (row.paymentMethod === 'ACH' && form.paymentMethod === 'WIRE')) &&
          row.status === form.commissionStatus
        );
      }
      if (form.paymentMethod) {
        return (
          row.paymentMethod === form.paymentMethod ||
          (row.paymentMethod === 'ACH' && form.paymentMethod === 'WIRE')
        );
      }
      if (form.commissionStatus) {
        return row.status === form.commissionStatus;
      }
      return row;
    });
  };

  const find = (query: string, list: ICommissions[]): ICommissions[] => {
    return list.filter((row) => row.associate.toLowerCase().includes(query.toLowerCase()));
  };

  const onSubmit = (form: ICommissionsFiltersProps) => {
    const found = find(search, commissions);
    const filtered = filter(form, found);
    setFilteredCommissions(filtered);
  };

  const onClearFilter = () => {
    reset(initialFilterValues);
    onSubmit(initialFilterValues);
  };

  const applySearch = (query: string) => {
    setSearch(query);
    const filtered = filter({ commissionStatus, paymentMethod }, commissions);
    const found = find(query, filtered);
    setFilteredCommissions(found);
  };

  const clearSearch = () => {
    applySearch('');
  };

  const getSelectedRows = useCallback(() => {
    return filteredCommissions.filter((commission) => selectedRows.includes(commission.id));
  }, [filteredCommissions, selectedRows]);

  const calculateTotalToPay = useMemo(() => {
    const list = getSelectedRows();
    const total = list.reduce((acc, curr) => acc + curr.commission, 0);
    return { investors: list.length, total };
  }, [getSelectedRows]);

  const getSelectedRowsId = () => {
    const rows = getSelectedRows();
    return rows.map((row) => row.id);
  };

  const validateManualPayment = (status: ICommissions['status']) => {
    selectedStatus.current = status;
    toggleMenu();
    const rows = getSelectedRows();
    if (rows.some((row) => row.status === 'BLACKLISTED') && status === 'PAID') {
      return setAlertModal('confirmationBlacklisted');
    }
    if (rows.some((row) => row.status === 'FAILED') && status === 'PENDING') {
      return setAlertModal('notFailed');
    }
    return setAlertModal('confirmation');
  };

  const confirmManualPayment = async () => {
    const ids = getSelectedRowsId();
    const { status, data } = await trackPromise(
      updateCommissionStatus({
        commissionStatus: selectedStatus.current,
        projectId: id as string,
        userId: ids,
      }),
      commissionsAreas.updateCommissionStatus
    );
    if (!isRequestError(status)) {
      hideAlertModal();
      setTimeout(() => setCommissionsList(), 1000);
      openToast({
        message: `Commission's payment was set as ${decorateStatusLabels(selectedStatus.current)}`,
        severity: 'success',
      });
    } else {
      const error = data as IServerError;
      if (error.message.includes('Invalid request')) {
        hideAlertModal();
        openToast({
          message: `Commissions remain in the same status.`,
          severity: 'info',
          title: 'The status did not change',
        });
      }
    }
  };

  const validateAutomaticPay = async () => {
    const rows = getSelectedRows();
    if (rows.some((row) => row.status === 'PAID_OFFLINE')) {
      return setAlertModal('notAllowed');
    }
    if (rows.some((row) => row.status === 'BLACKLISTED')) {
      return setAlertModal('payBlacklisted');
    }
    if (
      rows.some((row) => row.status === 'NOT_PAYABLE') ||
      rows.some((row) => !row.payoutAccount)
    ) {
      return setAlertModal('notPayable');
    }
    setAlertModal('pay');
  };

  const confirmAutomaticPay = async () => {
    const ids = getSelectedRowsId();
    const paymentStatus = 'PAYMENT_IN_PROCESS';
    const { status } = await trackPromise(
      updateCommissionStatus({
        commissionStatus: paymentStatus,
        projectId: id as string,
        userId: ids,
      }),
      commissionsAreas.updateCommissionStatus
    );
    if (!isRequestError(status)) {
      hideAlertModal();
      setCommissionsList();
      openToast({
        message: `Commission's payment is currently being processed by your bank. Refresh this page after 10 minutes to see changes`,
        severity: 'success',
      });
    }
  };

  const generateReport = () => {
    return commissions?.map((row) => ({
      Associate: row.associate,
      '(USD) Commission': row.commission, // eslint-disable-line
      '% Rate': row.rate, // eslint-disable-line
      'Payment Method': row.paymentMethod === 'ACH' ? 'WIRE' : row.paymentMethod,
      'Payout Account': row.payoutAccount ? 'Yes' : 'No',
      'Payment Date': row.paymentDate && format(new Date(row.paymentDate), 'MM/dd/yyyy HH:mm'), // eslint-disable-line
      'Commission Status': decorateStatusLabels(row.status), // eslint-disable-line
    }));
  };

  const disableCommissionPayment =
    [
      PROJECT_STATUSES.DRAFT,
      PROJECT_STATUSES.ACTIVE,
      PROJECT_STATUSES.PAUSED,
      PROJECT_STATUSES.CANCELED,
      PROJECT_STATUSES.COMPLETED,
      PROJECT_STATUSES.ARCHIVED,
    ].includes(projectStatus as PROJECT_STATUSES) || getSelectedRows().length === 0;

  const setCommissionsList = useCallback(async () => {
    const { status, data } = await trackPromise(
      getCommissions(id as string, commissionsListController.signal),
      commissionsAreas.getCommissions
    );
    if (!isRequestError(status)) {
      const response = data as ICommissions[];
      setCommissions(response);
      setFilteredCommissions(response);
    }
  }, [id, commissionsListController.signal]);

  useEffect(() => {
    setCommissionsList();
  }, [setCommissionsList]);

  return (
    <>
      <Commissions
        applyFilter={handleSubmit(onSubmit)}
        applySearch={applySearch}
        clearFilter={onClearFilter}
        clearSearch={clearSearch}
        commissions={filteredCommissions}
        control={control}
        generateReport={generateReport}
        handleItemSelection={validateManualPayment}
        isDisabled={disableCommissionPayment}
        onPaySubmit={validateAutomaticPay}
        openMenu={openMenu}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        toggleMenu={toggleMenu}
      />
      <ConfirmationPayoutsModal
        title="Are you sure you want to continue?"
        area={commissionsAreas.updateCommissionStatus}
        subtitle={
          <>
            {alertModal === 'payBlacklisted' && (
              <Typography variant="body1">
                You are about to make payments to at least one assosiate that is not whitelisted.
              </Typography>
            )}
            <Typography variant="body1">
              You'll pay <strong>$ {quantityBeautifierStandard(calculateTotalToPay.total)}</strong>{' '}
              to <strong>{calculateTotalToPay.investors}</strong> selected associates.
            </Typography>
          </>
        }
        primaryButtonText="Cancel"
        secondaryButtonText="Pay"
        onClose={hideAlertModal}
        open={['payBlacklisted', 'pay'].includes(alertModal ?? '')}
        finishWorking={hideAlertModal}
        mainAction={confirmAutomaticPay}
      />
      <ConfirmationPayoutsModal
        title="Are you sure you want to continue?"
        area={commissionsAreas.updateCommissionStatus}
        subtitle={
          <>
            {alertModal === 'confirmationBlacklisted' && (
              <Typography variant="body1">
                You are about to make payments to at least one assosiate that is not whitelisted.
              </Typography>
            )}
            {alertModal === 'confirmationNotPayable' && (
              <Typography variant="body1">
                You are about to make payments to at least one assosiate that doesn't have payouts
                account associated.
              </Typography>
            )}
            <Typography variant="body1">
              You will change the payment status for the selected associates.
            </Typography>
          </>
        }
        primaryButtonText="Cancel"
        secondaryButtonText="Change Status"
        onClose={hideAlertModal}
        open={['confirmation', 'confirmationBlacklisted', 'confirmationNotPayable'].includes(
          alertModal ?? ''
        )}
        finishWorking={hideAlertModal}
        mainAction={confirmManualPayment}
      />
      <ClosingPayoutsModal
        title="This operation is not allowed"
        subtitle={
          <>
            {['notPayable', 'notPending'].includes(alertModal ?? '') && (
              <Typography>
                You have selected associates that{' '}
                <strong>don't have payouts account associated</strong>, deselect those associates
                and make payments again.
              </Typography>
            )}
            {alertModal === 'notAllowed' && (
              <Typography>
                You have selected associates with <strong>offline</strong> payments, deselect those
                associates and make payments again.
              </Typography>
            )}
            {alertModal === 'notFailed' && (
              <Typography>
                You have selected associates with <strong>failed</strong> payments, please remove
                them from your selection and try again.
              </Typography>
            )}
          </>
        }
        primaryButtonText="Cancel"
        onClose={hideAlertModal}
        open={['notAllowed', 'notFailed', 'notPayable', 'notPending'].includes(alertModal ?? '')}
        finishWorking={hideAlertModal}
      />
    </>
  );
};

export { CommissionsPresenter };
