import { Link, ListItemIcon, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { requireValidationContext } from '../../hooks/useRequireValidationOnLeave'; //eslint-disable-line
import { IProps } from './NavbarItem.d';
import { StyledMenuItem } from './NavbarItem.styled';

const NavbarItem: FC<IProps> = ({ icon: Icon, path, name, pathname, closeNavbar }) => {
  const { showValidationOnClick } = useContext(requireValidationContext);
  return (
    <StyledMenuItem
      styledselected={(pathname === path).toString()}
      onClick={closeNavbar}
      selected={pathname === path}
      disableRipple
    >
      <Link
        sx={{ alignItems: 'center', px: 3, py: 2 }}
        display="flex"
        width={1}
        component={RouterLink}
        underline="none"
        to={path}
        onClick={(event) => showValidationOnClick(event, path)}
      >
        <ListItemIcon color="inherit">
          <Icon />
        </ListItemIcon>
        <Typography
          component="span"
          color="inherit"
          variant="body1"
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          {name}
        </Typography>
      </Link>
    </StyledMenuItem>
  );
};

export { NavbarItem };
