import axios from 'axios';

import { IReturnError } from './reportError.d';

export const reportError = (error: unknown): IReturnError => {
  if (axios.isAxiosError(error)) {
    const err = error;
    return {
      data: err.response?.data ?? { message: err.message },
      status: err.response?.status ?? 400,
    };
  } else {
    const err = error as Error;
    return { data: { error: err.name, message: err.message }, status: 0 };
  }
};
