import { Stack, Theme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import {
  IconBriefcase,
  IconBuilding,
  IconCompass,
  IconHistory,
  IconHome,
  IconReportMoney,
  IconUser,
} from '@tabler/icons';
import { FC, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { IUserData } from '../../api/session'; //eslint-disable-line
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext';
import { RequireValidationProvider } from '../../hooks/useRequireValidationOnLeave';
import { ToastProvider } from '../../hooks/useToast';
import { Header } from '../Header';
import { Navbar } from '../Navbar';
import { INavbarItem } from '../NavbarItem/NavbarItem.d';
import { Toast } from '../Toast'; //eslint-disable-line
import { IProps } from './Layout.d';
import { MainContainer } from './Layout.styled';

const Layout: FC<IProps> = ({ isAuth, toLogout }) => {
  const headerHeight = 56;

  const { pathname, state } = useLocation();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [openNavbar, setOpenNavbar] = useState<boolean>(false);
  const navState = state as { logged: boolean };
  const { userData, tokenExpired, setTokenExpired } = useContext(authContext);
  const nav = useNavigate();

  const toggleNavbar = () => {
    setOpenNavbar(!openNavbar);
    window.sessionStorage.setItem('openNavbar', String(!openNavbar));
  };

  const closeNavbarOnNavItemClick = () => {
    if (!isDesktop) {
      setOpenNavbar(false);
    }
  };

  const navItems = (): INavbarItem[] => {
    const currentRoles = (userData as IUserData)?.roles || [];
    const navbarItems = [
      {
        icon: IconHome,
        name: 'Home',
        path: '/',
      },
      {
        icon: IconReportMoney,
        justForRoles: ['superAdmin', 'financial'],
        name: 'Investments',
        path: '/investments',
      },
      {
        icon: IconBuilding,
        name: 'Projects',
        path: '/projects',
      },
      {
        icon: IconBriefcase,
        justForRoles: ['legal', 'compliance', 'superAdmin', 'financial', 'marketing'],
        name: 'Investors',
        path: '/investors',
      },
      {
        icon: IconCompass,
        justForRoles: ['superAdmin', 'financial', 'legal', 'marketing'],
        name: 'Brokers & FAs',
        path: '/brokers-and-financial-advisors',
      },
      {
        icon: IconUser,
        justForRoles: ['systemAdmin', 'compliance', 'superAdmin'],
        name: 'Users',
        path: '/users',
      },
    ];

    if (process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'false') {
      navbarItems.push({
        icon: IconHistory,
        justForRoles: ['superAdmin', 'financial'],
        name: 'Refunds',
        path: '/refunds',
      });
    }

    return navbarItems.filter((item: INavbarItem) => {
      if (!item.justForRoles) {
        return true;
      }
      return currentRoles.length ? havePermission(currentRoles, item.justForRoles) : true;
    });
  };

  useEffect(() => {
    if (isDesktop && navState?.logged) {
      setOpenNavbar(true);
      window.sessionStorage.setItem('openNavbar', 'true');
      window.history.replaceState(null, '');
    }
  }, [isDesktop, navState]); //eslint-disable-line

  useEffect(() => {
    if (isDesktop) {
      const navBarOpen = window.sessionStorage.getItem('openNavbar');
      navBarOpen && setOpenNavbar(navBarOpen === 'true' ? true : false);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (tokenExpired && !pathname.includes('login')) {
      nav('/session-expired', { replace: true });
      setTokenExpired(false);
    }
  }, [tokenExpired, nav, setTokenExpired, pathname]);

  return (
    <RequireValidationProvider>
      <ToastProvider>
        <Header
          withoutMenu={!isAuth}
          headerHeight={headerHeight}
          openNavbar={openNavbar}
          toggleNavbar={toggleNavbar}
          toLogout={toLogout}
        />
        {!isAuth ? (
          <Box
            component="main"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={`calc(100vh - ${headerHeight}px)`}
            flex={1}
          >
            <Outlet />
          </Box>
        ) : (
          <Stack direction="row" justifyContent="center">
            <Navbar
              pathname={pathname}
              isDesktop={isDesktop}
              openNavbar={openNavbar}
              closeNavbar={closeNavbarOnNavItemClick}
              navItems={navItems()}
              headerHeight={headerHeight}
            />
            <MainContainer
              headerheight={headerHeight}
              open={openNavbar}
              sx={{
                backgroundColor: 'background.default',
                maxWidth: 1360,
                overflow: 'hidden',
                p: { xs: 3, sm: 4, lg: 5, xl: 0 }, // eslint-disable-line
                px: { xl: openNavbar ? 5 : 0 },
                py: { xl: 5 },
              }}
              component="main"
            >
              <Outlet />
            </MainContainer>
          </Stack>
        )}
        <Toast />
      </ToastProvider>
    </RequireValidationProvider>
  );
};

export { Layout };
