import { Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { ITableHeaderProps } from './Commissions.d';

const TableHeader: FC<ITableHeaderProps> = ({
  downloadButton,
  filter,
  payButton,
  paymentStatusButton,
  search,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {!isDesktop ? (
        <Stack borderBottom={1} borderColor="grey.300" gap={4} p={2}>
          {search}

          <Stack direction="row" justifyContent="space-between" height={32}>
            {paymentStatusButton}

            {payButton}
          </Stack>

          <Stack direction="row" justifyContent="space-between" height={32}>
            {filter}

            {downloadButton}
          </Stack>
        </Stack>
      ) : (
        <Stack
          borderBottom={1}
          borderColor="grey.300"
          direction="row"
          justifyContent="space-between"
          gap={{ md: 2, sm: 2 }}
          p={1.5}
        >
          {search}

          <Stack direction="row" gap={2} height={32} pt={0.7}>
            {paymentStatusButton}

            {filter}

            {downloadButton}

            {payButton}
          </Stack>
        </Stack>
      )}
    </>
  );
};
export { TableHeader };
