import { IReturnError, reportError } from '../../helpers/reportError';
import { IReturnType, treasuryApi } from '../main'; //eslint-disable-line
import { decorateRefunds } from './decorator';
import { IRefunds, IRefundsRes } from './refunds.d';

let nextPageToken = {};
const getRefunds = async (signal: AbortSignal): Promise<IReturnType<IRefunds[]> | IReturnError> => {
  try {
    const { data, status } = await treasuryApi.get<IRefundsRes>('refunds', {
      params: nextPageToken,
      signal,
    });
    if (data?.LastEvaluatedKey) {
      nextPageToken = data.LastEvaluatedKey;
    } else {
      nextPageToken = {};
    }
    return { data: decorateRefunds(data.Items), status };
  } catch (error) {
    return reportError(error);
  }
};

const getMissingInvestors = async (signal: AbortSignal) => {
  if (Object.keys(nextPageToken).length === 0) {
    return [];
  } else {
    const { data } = await getRefunds(signal);
    return data as IRefunds[];
  }
};

export { getMissingInvestors, getRefunds };
