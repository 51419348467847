import { Typography } from '@mui/material';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getInvestorDetail } from '../../api/investors';
import { IReturnType } from '../../api/main';
import { payoutsAreas } from '../../api/payouts';
import {
  approvePayoutsPlan,
  calculatePayoutsPlan,
  getPayoutsPlan,
} from '../../api/payouts/payouts';
import { IApprovePayoutsPlan } from '../../api/payouts/payouts.d';
import { ClosingPayoutsModal, ConfirmationPayoutsModal } from '../../components/CreatePayouts';
import { OverlayLoader } from '../../components/OverlayLoader';
import { IInvestor } from '../../components/PageContent';
import { dollarsFormat, formatToken } from '../../helpers';
import { isRequestError } from '../../helpers/isRequestError';
import { IReturnError, IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { requireValidationContext } from '../../hooks/useRequireValidationOnLeave';
import { toastContext } from '../../hooks/useToast'; // eslint-disable-line
import { CreatePayoutsReport } from './CreatePayoutsReport';
import { IPayoutsListDecoratedItem, IPayoutsListItem } from './CreatePayoutsReport.d';

const CreatePayoutsReportPresenter: FC = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const navState = state as { projectName: string };
  const projectName = navState?.projectName;
  const [payoutsList, setPayoutsList] = useState<IPayoutsListDecoratedItem[]>([]);
  const [calculationValue, setCalculationValue] = useState<string>('');
  const [lastCalculatedValue, setLastCalculatedValue] = useState<string>('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const calculatePayoutsController = useAbortRequest();
  const getPayoutsPlanController = useAbortRequest();
  const approvePayoutsPlanController = useAbortRequest();
  const { openToast } = useContext(toastContext);

  const {
    clearValidation,
    isValidationModalOpen,
    navigationRoute,
    setIsValidationModalOpen,
    setIsValidationRequired,
    showValidationOnNavigation,
  } = useContext(requireValidationContext);

  const leavePage = () => {
    clearValidation();
    nav(navigationRoute);
  };

  const goToPayouts = () => showValidationOnNavigation(`/projects/${id}/payouts`);

  const getInvestorsNames = (
    data: IPayoutsListItem[]
  ): Promise<(IReturnError | IReturnType<IInvestor>)[]> => {
    const reqInvestors = data.map((investor) =>
      getInvestorDetail(investor.investorId, getPayoutsPlanController.signal)
    );
    return Promise.all(reqInvestors);
  };

  const reqPayoutsPlan = async () => {
    const { data, status } = await getPayoutsPlan(id as string, getPayoutsPlanController.signal);
    if (!isRequestError(status)) {
      const payoutsRes = data as IPayoutsListItem[];
      const payoutsInvestors = await trackPromise(
        getInvestorsNames(payoutsRes),
        payoutsAreas.getPayoutsPlan
      );
      const payoutsListDecorated = payoutsRes.map((investor: IPayoutsListItem | IReturnError) => {
        const payoutInvestor = investor as IPayoutsListItem;
        const currentInvestor = payoutsInvestors.find((i) => {
          const investorId = (i as IReturnType<IInvestor>).data.id;
          return investorId === payoutInvestor.investorId;
        });
        if (currentInvestor) {
          const investorData = (currentInvestor as IReturnType<IInvestor>).data;
          return {
            amountToReceive: payoutInvestor.amountToReceive,
            id: payoutInvestor.investorId,
            name: investorData.legalName,
            tokens: formatToken(payoutInvestor.tokens),
          };
        } else {
          return {
            amountToReceive: payoutInvestor.amountToReceive,
            id: payoutInvestor.investorId,
            name: '',
            tokens: formatToken(payoutInvestor.tokens),
          };
        }
      });
      setPayoutsList(payoutsListDecorated);
    } else {
      const error = data as IServerError;
      openToast({
        message: error.message,
        severity: 'error',
        title: error.title as string,
      });
    }
  };

  const reqCalculatePayouts = async () => {
    const earningsValue = Number(
      calculationValue?.toString().replace(/ /g, '').split(',').join('')
    );
    if (earningsValue) {
      setLastCalculatedValue(calculationValue);
      const { data, status } = await calculatePayoutsPlan(
        id as string,
        earningsValue,
        calculatePayoutsController.signal
      );
      if (!isRequestError(status)) {
        reqPayoutsPlan();
      } else {
        const error = data as IServerError;
        openToast({
          message: error.message,
          severity: 'error',
          title: error.title as string,
        });
      }
    }
  };

  const confirmReport = () => setShowConfirmationModal(true);

  const handleConfirmation = async () => {
    const { data, status } = await approvePayoutsPlan(
      id as string,
      approvePayoutsPlanController.signal
    );
    if (!isRequestError(status)) {
      nav(`/projects/${id}/payouts-report/${(data as IApprovePayoutsPlan).reportId}`, {
        state: { projectName },
      });
    } else {
      const error = data as IServerError;
      openToast({
        message: error.message,
        severity: 'error',
        title: error.title as string,
      });
    }
  };

  const onChangeHandlePayoutsCalculator = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let newValue = value;
    if (/^[0-9\b,]+$/.test(value)) {
      if (value.length <= 15) {
        newValue = dollarsFormat(value);
      } else if (value) {
        return;
      }
    } else {
      if (value) {
        return;
      }
    }
    setCalculationValue(newValue);
  };

  useEffect(() => {
    setIsValidationRequired(true);

    return () => {
      setIsValidationRequired(false);
    };
  }, [setIsValidationRequired]);

  return (
    <>
      <CreatePayoutsReport
        payouts={payoutsList}
        calculationValue={calculationValue}
        onChangeHandlePayoutsCalculator={onChangeHandlePayoutsCalculator}
        goToPayouts={goToPayouts}
        calculatePayouts={reqCalculatePayouts}
        confirmReport={confirmReport}
        setPayoutsList={setPayoutsList}
        projectName={projectName}
      />
      <ClosingPayoutsModal
        title="Exit payouts report creation"
        subtitle="Are you sure you want to leave this process?"
        primaryButtonText="No"
        secondaryButtonText="Yes"
        onClose={() => setIsValidationModalOpen(false)}
        open={isValidationModalOpen ?? false}
        finishWorking={() => setIsValidationModalOpen(false)}
        mainAction={leavePage}
      />
      <ConfirmationPayoutsModal
        title="Are you sure to continue?"
        area={payoutsAreas.approvePayoutsPlan}
        subtitle={
          <Typography variant="body1">
            You will create a payout report with <strong>${lastCalculatedValue}</strong> to
            distribute between <strong>{payoutsList.length}</strong> investors.
          </Typography>
        }
        primaryButtonText="Cancel"
        secondaryButtonText="Create report"
        onClose={() => setShowConfirmationModal(false)}
        open={showConfirmationModal ?? false}
        finishWorking={() => setShowConfirmationModal(false)}
        mainAction={handleConfirmation}
      />
      <OverlayLoader area={payoutsAreas.getPayoutsPlan} theme="main" />
    </>
  );
};
export { CreatePayoutsReportPresenter };
