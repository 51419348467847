import { Box } from '@mui/material';
import { FC } from 'react';

const ProjectInvestorsTable: FC = ({ children }) => {
  return (
    <Box
      role="table"
      data-testid="project-investors-table"
      sx={({ palette }) => ({
        border: { xs: 'none', sm: `1px solid ${palette.grey[300]}` }, //eslint-disable-line
        borderRadius: 2,
        boxShadow: 0,
        position: 'relative',
        overflow: { xs: 'visible', sm: 'hidden' }, //eslint-disable-line
      })}
    >
      {children}
    </Box>
  );
};

export { ProjectInvestorsTable };
