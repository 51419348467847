import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

// import { IInvestment } from '../../components/PageContent';
import { IReturnError, reportError } from '../../helpers/reportError';
import { IFilterParams } from '../../pages/Investors';
import { IReturnType, projectsApi } from '../main'; // eslint-disable-line
import { areas } from './areas';
import { decorateInvestments } from './decorators';
import {
  IInvestmentsResponse,
} from './investments.d';

const parseObjToQuery = (obj: { [key: string]: string }) => {
  let query = '';
  const keys = Object.keys(obj);
  keys.forEach((key, i) => {
    if (i === keys.length - 1) {
      query += `${key}=${obj[key]}`;
    } else {
      query += `${key}=${obj[key]}&`;
    }
  });
  return query;
};

let paginationTokens: { [key: string]: string } = {};
const getInvestments = async (
  req: IFilterParams,
  signal: AbortSignal
): Promise<IReturnType<IInvestmentsResponse> | IReturnError> => {
  const token = req.page ? paginationTokens?.[req.page] : null;
  let params = { ...req };
  const reqPage = req?.page ?? 1;

  // add the pagination token as a request param if there are no filters.
  if (token) {
    params = { ...params, paginationToken: token };
  }
  
  // delete the page param when the request has pagination token.
  if (params.paginationToken) {
    delete params.page;
  }

  try {
    const res: AxiosResponse<IInvestmentsResponse> = await trackPromise(
      projectsApi.get('investments', { params, signal }),
      areas.getInvestments
    );
    if (res?.data?.paginationToken) {
      paginationTokens[reqPage + 1] = parseObjToQuery(res.data.paginationToken);
    }
    return {
      data: decorateInvestments(res.data),
      status: res.status,
    };
  } catch (error) {
    paginationTokens = {};
    return reportError(error);
  }
};


export {
  getInvestments,
};
