import { Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { PROJECT_STATUSES } from '../../helpers/constants';
import {
  IFormOutletContext,
  IProjectOutletContext,
} from '../../pages/ProjectDetail/ProjectDetail.d';
import { Gray } from '../../theme/tokens/colors';
// eslint-disable-next-line import/order
import { InputEndAdornment } from '../InputField/InputEndAdornment';
import { IOption } from '../Select';
import { Select } from '../Select/Select';

export const WalletForm: React.FC = () => {
  const { status } = useOutletContext<IProjectOutletContext>();
  const { financialRole, setShowEditButton } = useOutletContext<IFormOutletContext>();
  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const canEdit = [PROJECT_STATUSES.DRAFT, PROJECT_STATUSES.ACTIVE, PROJECT_STATUSES.PAUSED].includes(status as PROJECT_STATUSES) && financialRole;
  const networkOptions: IOption[] = [{ label: 'ETH', value: 'ETH' }];
  const currencyOptions: IOption[] = [{ label: 'USDC', value: 'USDC' }];

  useEffect(() => {
    setShowEditButton(canEdit);
  }, [canEdit, setShowEditButton]);

  return (
    <>
      <Divider sx={{ mb: 5, mt: 7 }} />
      <Grid item xs={12} container spacing={4}>
        <Grid item md={12} xs={12}>
          <Typography variant="h1" color={Gray[800]}>
            Wallet Information
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputEndAdornment
            label="Address"
            adornment=""
            inputProps={{
              maxLength: 42,
              ...register('beneficiaryWallet.address', { required: true }),
            }}
            error={!!errors?.beneficiaryWallet?.address}
            helperText={errors?.beneficiaryWallet?.address?.message}
            value={watch('beneficiaryWallet.address')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Select
            fullWidth
            label="Network"
            options={networkOptions}
            control={control}
            name="beneficiaryWallet.network"
            value={watch('beneficiaryWallet.network')}
            helperText={errors?.beneficiaryWallet?.network?.message}
            error={!!errors?.beneficiaryWallet?.network}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Select
            fullWidth
            label="Currency"
            options={currencyOptions}
            control={control}
            name="beneficiaryWallet.currency"
            value={watch('beneficiaryWallet.currency')}
            helperText={errors?.beneficiaryWallet?.currency?.message}
            error={!!errors?.beneficiaryWallet?.currency}
            disabled={!canEdit}
          />
        </Grid>
      </Grid>
    </>
  );
};
