import { Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { PROJECT_STATUSES } from '../../helpers/constants';
import { IFormOutletContext, IProjectOutletContext } from '../../pages/ProjectDetail/ProjectDetail.d';
import { Gray } from '../../theme/tokens/colors';
// eslint-disable-next-line import/order
import { InputEndAdornment } from '../InputField/InputEndAdornment';

export const BankAccountForm: React.FC = () => {
  const { status } = useOutletContext<IProjectOutletContext>();
  const { financialRole, setShowEditButton } = useOutletContext<IFormOutletContext>();
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext();
  
  const canEdit = [PROJECT_STATUSES.DRAFT, PROJECT_STATUSES.ACTIVE, PROJECT_STATUSES.PAUSED].includes(status as PROJECT_STATUSES) && financialRole;
  
  useEffect(() => {
    setShowEditButton(canEdit);
  }, [canEdit, setShowEditButton]);
  
  return (
    <>
      <Divider sx={{ mb: 5, mt: 7 }} />
      <Grid item container
      xs={12}
      spacing={4} //eslint-disable-line
      >
        <Grid item md={12} xs={12}>
          <Typography variant="h1" color={Gray[800]}>
            Bank Information
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputEndAdornment
            label="Beneficiary Name"
            adornment=''
            inputProps={{
              ...register('beneficiaryBank.beneficiaryName', { required: true }),
            }}
            error={!!errors?.beneficiaryBank?.beneficiaryName}
            helperText={errors?.beneficiaryBank?.beneficiaryName?.message}
            value={watch('beneficiaryBank.beneficiaryName')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 55,
               ...register('beneficiaryBank.beneficiaryAddress', { required: true })
            }}
            error={!!errors.beneficiaryBank?.beneficiaryAddress}
            helperText={errors.beneficiaryBank?.beneficiaryAddress?.message}
            label={'Beneficiary Address'}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 35,
               ...register('beneficiaryBank.bankName', { required: true })
            }}
            error={!!errors.beneficiaryBank?.bankName}
            helperText={errors.beneficiaryBank?.bankName?.message}
            label={'Bank Name'}
            value={watch('beneficiaryBank.bankName')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 35,
               ...register('beneficiaryBank.bankAddress', { required: true })
            }}
            error={!!errors.beneficiaryBank?.bankAddress}
            helperText={errors.beneficiaryBank?.bankAddress?.message}
            label={'Bank Address'}
            value={watch('beneficiaryBank.bankAddress')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 35,
               ...register('beneficiaryBank.bankCity', { required: true })
            }}
            error={!!errors.beneficiaryBank?.bankCity}
            helperText={errors.beneficiaryBank?.bankCity?.message}
            label={'Bank City'}
            value={watch('beneficiaryBank.bankCity')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 2,
               ...register('beneficiaryBank.bankCountry', { required: true })
            }}
            error={!!errors.beneficiaryBank?.bankCountry}
            helperText={errors.beneficiaryBank?.bankCountry?.message}
            label={'Bank Country'}
            value={watch('beneficiaryBank.bankCountry')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 16,
               ...register('beneficiaryBank.bankPostalCode', { required: true })
            }}
            error={!!errors.beneficiaryBank?.bankPostalCode}
            helperText={errors.beneficiaryBank?.bankPostalCode?.message}
            label={'Zip'}
            value={watch('beneficiaryBank.bankPostalCode')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 16,
               ...register('beneficiaryBank.accountNumber', { required: true })
            }}
            error={!!errors.beneficiaryBank?.accountNumber}
            helperText={errors.beneficiaryBank?.accountNumber?.message}
            label={'Account Number'}
            value={watch('beneficiaryBank.accountNumber')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 16,
               ...register('beneficiaryBank.routingNumber', { required: true })
            }}
            error={!!errors.beneficiaryBank?.routingNumber}
            helperText={errors.beneficiaryBank?.routingNumber?.message}
            label={'Routing Number'}
            value={watch('beneficiaryBank.routingNumber')}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <InputEndAdornment
            adornment=''
            inputProps={{
                maxLength: 16,
               ...register('beneficiaryBank.swiftCode', { required: true })
            }}
            error={!!errors.beneficiaryBank?.swiftCode}
            helperText={errors.beneficiaryBank?.swiftCode?.message}
            label={'SWIFT / BIC code'}
            value={watch('beneficiaryBank.swiftCode')}
            disabled={!canEdit}
          />
        </Grid>
      </Grid>
    </>
  );
};
