import { Box, Stack, Typography } from '@mui/material';
import { FC, useEffect } from 'react';

import { recoverPasswordAreas } from '../../api/recoverPassword';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { InputField } from '../../components/InputField';
import { Logo } from '../../components/Logo';
import { SimplePageContainer } from '../../components/SimplePageContainer'; //eslint-disable-line
import { IRecoverPassword } from './RecoverPassword.d';

export const RecoverPassword: FC<IRecoverPassword> = ({
  errors,
  register,
  submitRecovery,
  reset,
}) => {
  useEffect(() => {
    reset({
      email: '',
    });
  }, [reset]);

  return (
    <SimplePageContainer>
      <Logo mode="light" />
      <Typography my={4} variant="h1" mb={5}>
        Recover password
      </Typography>
      <Typography variant="body1" mb="31px">
        Enter your email to recieve a link and change your password to get back into your account.
      </Typography>
      <form onSubmit={submitRecovery}>
        <Stack spacing={5}>
          <InputField
            label="Email"
            error={!!errors['email']}
            helperText={errors['email']?.message}
            inputProps={{
              'data-testid': 'recover-password',
            }}
            {...register('email')}
          />
        </Stack>
        <Box mt={6}>
          <ButtonSubmit area={recoverPasswordAreas.emailForm} type="submit">
            Send mail
          </ButtonSubmit>
        </Box>
      </form>
    </SimplePageContainer>
  );
};
