import { Box, Button, Paper, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconBriefcase, IconChartPie2, IconEditCircle, IconInfoCircle } from '@tabler/icons';
import { FC, useContext, useState } from 'react';

import { faBrokersAreas } from '../../../api/brokers';
import { CommissionsModal } from '../../../components/CommissionsModal';
import { OverlayLoader } from '../../../components/OverlayLoader';
import { Tooltip } from '../../../components/Tooltip';
import { havePermission } from '../../../helpers/havePermission';
import { authContext } from '../../../hooks/useAuth/authContext'; //eslint-disable-line
import { IHeaderProps } from './Header.d';

const Header: FC<IHeaderProps> = ({
  title,
  brokers,
  commission,
  financialAdvisors,
  changeCommission,
}) => {
  const [openCommissionModal, setOpenCommissionModal] = useState(false);
  const toggleCommissionModal = () => setOpenCommissionModal(!openCommissionModal);
  const theme = useTheme();
  const extraSmall = useMediaQuery(theme.breakpoints.up('xs'));
  const small = useMediaQuery(theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme.breakpoints.up('md'));
  const isBrokerTab = title.includes('Brokers');
  const { userData } = useContext(authContext);
  const financialAndMarketingRole = havePermission(userData?.roles ?? [], ['financial', 'marketing', 'superAdmin']);

  const width = (): number => {
    if (extraSmall) {
      return 200;
    }
    if (small) {
      return 206;
    }
    if (medium) {
      return 260;
    }
    return 0;
  };

  const titleDisplay = title.split(' ').length <= 3 ? 7 : 4;
  return (
    <>
      <Stack
        direction="row"
        gap={3}
        mb={{ lg: 4, md: 4, sm: 3, xs: 3 }}
        mt={{ lg: 5, md: 5, sm: 3, xs: 3 }}
        mx={{ lg: 3, md: 3, sm: 4, xs: 3 }}
        sx={{ overflowX: 'auto' }}
        data-testid="test-page-metrics"
      >
        <Paper
          elevation={0}
          sx={{
            border: ({ palette }) => `1px solid ${palette.grey[300]}`,
            borderRadius: 2,
          }}
        >
          <Box sx={{ mb: 4, mt: 3, mx: 3, width }}>
            <Typography
              fontFamily="Sora"
              fontWeight={700}
              fontSize={18}
              color="text.primary"
              mb={{
                lg: titleDisplay,
                md: titleDisplay,
                sm: title.split(' ').length <= 3 ? 10 : 7,
                xs: 7,
              }}
            >
              {title}
            </Typography>

            <Stack direction="row" gap={1.25}>
              <SvgIcon sx={{ color: 'text.secondary' }}>
                <IconBriefcase />
              </SvgIcon>
              <Typography fontFamily="Sora" fontWeight={700} fontSize={24} color="text.primary">
                {isBrokerTab ? brokers : financialAdvisors}
              </Typography>
            </Stack>
          </Box>
        </Paper>

        <Paper
          elevation={0}
          sx={{
            border: ({ palette }) => `1px solid ${palette.grey[300]}`,
            borderRadius: 2,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              mb: 4,
              mt: 3,
              mx: 3,
              width,
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" mb={3.5}>
              <Stack>
                <Typography fontFamily="Sora" fontWeight={700} fontSize={18} color="text.primary">
                  Default Commission Rate
                </Typography>
              </Stack>
              <Tooltip
                placement="right"
                title={`The default commission rate is assigned by default when ${
                  isBrokerTab ? 'Brokers' : 'Financial Advisors'
                } join the platformo`}
              >
                <SvgIcon color="disabled">
                  <IconInfoCircle />
                </SvgIcon>
              </Tooltip>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap={1.25} alignItems="center">
                <SvgIcon sx={{ color: 'text.secondary' }}>
                  <IconChartPie2 />
                </SvgIcon>
                <Typography fontFamily="Sora" fontWeight={700} fontSize={24} color="text.primary">
                  {commission}%
                </Typography>
              </Stack>

              <Button
                color="secondary"
                startIcon={<IconEditCircle />}
                onClick={toggleCommissionModal}
                disabled={!financialAndMarketingRole}
                sx={{
                  fontFamily: 'Sora',
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                Modify
              </Button>
            </Stack>
          </Box>
          <OverlayLoader theme="container" area={faBrokersAreas.getDefaultCommission} />
        </Paper>
      </Stack>
      <CommissionsModal
        display={openCommissionModal}
        currentCommissionsRate={commission}
        onClose={toggleCommissionModal}
        title={`${brokers ? 'Brokers' : 'Financial Advisor'} Default`}
        path={`${brokers ? 'Brokers' : 'Financial Advisor'}`}
        subtitle="The Default Commission rate is the one assigned by default when users join the platform."
        handleCommission={changeCommission}
      />
    </>
  );
};

export { Header };
