import { Box, Button, Typography } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons';
import { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { requireValidationContext } from '../../hooks/useRequireValidationOnLeave';
import { Breadcrumbs } from '../Breadcrumbs'; //eslint-disable-line
import { ICreatePayoutsHeader } from './CreatePayouts.d';

const CreatePayoutsHeader: FC<ICreatePayoutsHeader> = ({ projectName }) => {
  const { id } = useParams();
  const { showValidationOnNavigation } = useContext(requireValidationContext);
  const goToPayouts = () => showValidationOnNavigation(`/projects/${id}/payouts`);
  return (
    <>
      <Box mb={4}>
        <Button
          color="secondary"
          onClick={goToPayouts}
          startIcon={<IconArrowLeft />}
          sx={{ fontSize: '12px' }}
        >
          Back to Payouts
        </Button>
      </Box>
      <Breadcrumbs
        current="Create payouts reports"
        linksList={[
          { label: 'Home', path: '/' },
          { label: 'Projects', path: '/projects' },
          { label: 'Project details', path: `/projects/${id}` },
        ]}
      />
      <Box pt={4}>
        <Typography variant="h2" fontWeight={700}>
          Payouts calculation for {projectName}
        </Typography>
      </Box>
    </>
  );
};

export { CreatePayoutsHeader };
