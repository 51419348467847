import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { Logo } from '../Logo'; //eslint-disable-line
import { IOverlayLoader } from './OverlayLoader.d';

const useStyles = makeStyles({
  overlay: {
    overflow: 'hidden',
  },
});

const OverlayLoader: FC<IOverlayLoader> = ({ area, theme = 'main', isLoading = false }) => {
  const classes = useStyles();
  const themeDs = useTheme();
  const trackerArea = area ? { area } : {};
  const circularSx = theme === 'main' ? { color: themeDs.palette.background.paper } : {};
  const backgroundColor = theme === 'main' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.8)';
  const circularSize = theme === 'main' ? 80 : 48;
  const wrapperPosition = theme === 'main' ? 'fixed' : 'absolute';

  const { promiseInProgress } = usePromiseTracker(trackerArea);

  useEffect(() => {
    if (theme === 'main') {
      const method = promiseInProgress ? 'add' : 'remove';
      document.body.classList[method](classes.overlay);
    }
  }, [promiseInProgress, classes.overlay, theme]);

  return (
    <>
      {(promiseInProgress || isLoading) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          position={wrapperPosition}
          width="100%"
          height="100%"
          minHeight="150px"
          top={0}
          left={0}
          sx={{
            backgroundColor,
            borderRadius: theme === 'container' ? 2 : 0,
          }}
          zIndex="drawer"
        >
          <CircularProgress sx={circularSx} color="secondary" size={circularSize} />
          {theme === 'main' && (
            <Box mt={4}>
              <Logo />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export { OverlayLoader };
