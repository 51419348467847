import { Chip as ChipMUI } from '@mui/material';
import { FC } from 'react';

import { IChip } from './Chip.d';
import { ListItem } from './Chip.styled';

export const Chip: FC<IChip> = ({ alias, label, selected, register }) => {
  return (
    <ListItem>
      <ChipMUI
        label={label}
        variant={selected ? 'filled' : 'outlined'}
        color="primary"
        component="label"
        htmlFor={`roles-${label}`}
        sx={{
          '&:hover': { cursor: 'pointer' },
          border: '1px solid #000 !important',
          boxSizing: 'border-box',
          fontWeight: 600,
        }}
      />
      <input
        type="checkbox"
        id={`roles-${label}`}
        {...register('roles')}
        value={`${alias}`}
        style={{ display: 'none' }}
        checked={selected}
        readOnly
      />
    </ListItem>
  );
};
