/* eslint-disable sort-keys */
import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { getUniqueArray } from '../../helpers/getUniqueArray';
import { isRequestError } from '../../helpers/isRequestError';
import { IReturnError, IServerError, reportError } from '../../helpers/reportError';
import {
  ICommissions,
  IFABroker,
  IFABrokerInvestments,
} from '../../pages/FABrokerDetail/FABrokers.d';
import { IInvestorResponse } from '../investors';
import { getInvestors, getInvestorsDetails } from '../investors/investors';
import { IInvestorDetail } from '../investors/investors.d';
import { IMessage, investorsExternalUsers, IReturnType } from '../main';
import { faBrokerApi } from '../main/main';
import { getProjectDetailsFromList } from '../projects'; //eslint-disable-line
import { areas } from './areas';
import {
  ICommissionsResponse,
  ICommissionsValue,
  IConnectInvestorBody,
  IDisconnectInvestorBody,
  IFABrokerRaw,
  IFABrokerResponse,
  IFABrokersFilters,
  IFABrokersInvestorsRaw,
  IInvestmentsListResponse,
  IInvestorsPerBroker,
  IPaginatedFABrokers,
  IReferrersResponse,
  IUpdateCommissionReq,
  IUpdateCommissionResponse,
} from './brokers.d';
import {
  decorateFaBrokersList,
  decorateFilters,
  decorateInvestmentsList,
  decorateInvestorsPerFaBroker,
} from './decorators';


const connectInvestor = async (body: IConnectInvestorBody, signal: AbortSignal) => {
  try {
    const { data, status, }: AxiosResponse = await trackPromise(
      faBrokerApi.put('association', { ...body }, { signal }),
      areas.connectInvestor
    );
    return { status: status, data: data };
  } catch (error) {
    return reportError(error);
  }
};

const disconnectInvestor = async (body: IDisconnectInvestorBody) => {
  try {
    await faBrokerApi.delete('association', { data: body });
    return { status: 200 };
  } catch (error) {
    return reportError(error);
  }
};

const getFABrokers = async (
  filters: IFABrokersFilters,
  signal: AbortSignal
): Promise<IReturnType<IPaginatedFABrokers> | IReturnError> => {
  try {
    const params = decorateFilters(filters);
    const { data, status } = await getInvestors(params, signal);
    const { data: dataReferrers, status: statusReferrers } = await getReferrersByIds(
      (data as IInvestorResponse).investors.map((i) => i.id),
      signal
    );
    const response = await faBrokerApi.get<IFABrokerRaw[]>(`commission?role=${filters.role}`, {
      signal,
    });
    if (isRequestError(status) || isRequestError(statusReferrers)) {
      const err = data as IServerError;
      throw new Error(err.message);
    }
    const investors = data as IInvestorResponse;
    return {
      data: {
        count: investors.count,
        investors: decorateFaBrokersList(
          response.data,
          investors.investors,
          dataReferrers as IReferrersResponse[]
        ),
        page: investors.page,
        pages: investors.pages,
      },
      status: 200,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getFABrokerDetail = async (
  id: string,
  referralCode: string,
  signal: AbortSignal,
): Promise<IReturnType<IFABroker> | IReturnError> => {
  try {
    const { data, status }: AxiosResponse<IFABrokerResponse> = await trackPromise(
      investorsExternalUsers.get(`external-users/${id}`, { signal }),
      areas.getFABrokerDetail
    );
    return {
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        legalName: data.legalName,
        role:
          data.groups.find((r: string) => r === 'broker') ??
          data.groups.find((r) => r === 'fa') ??
          '',
        userData: {
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          location: data.location,
          phone: `${data?.dialCode} ${data.phoneNumber}`,
          status: data.isEnabled ? 'Active' : 'Inactive',
          referralCode: referralCode,
        },
      },
      status: status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getUserCommissions = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<ICommissions> | IReturnError> => {
  try {
    const { data, status }: AxiosResponse<ICommissionsResponse> = await trackPromise(
      faBrokerApi.get('commissions/summary', { params: { userId: id }, signal }),
      areas.getUserCommissions
    );
    return {
      data: data,
      status: status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getDefaultCommission = async (): Promise<IReturnType<ICommissionsValue> | IReturnError> => {
  try {
    const req = [
      faBrokerApi.get('/default-commission?role=broker'),
      faBrokerApi.get('/default-commission?role=fa'),
    ];
    const response = await trackPromise(Promise.all(req), areas.getDefaultCommission);
    const broker = response[0].data.percent;
    const fa = response[1].data.percent;
    return { data: { broker, fa }, status: 200 };
  } catch (error) {
    return reportError(error);
  }
};

const updateDefaultCommission = async (
  req: IUpdateCommissionReq
): Promise<IReturnType<IUpdateCommissionResponse> | IReturnError> => {
  try {
    await trackPromise(
      faBrokerApi.post('default-commission', {
        percent: req.commissionPercent,
        role: req.role,
      }),
      areas.setCommissionRate
    );
    return { data: { message: 'OK' }, status: 200 };
  } catch (error) {
    return reportError(error);
  }
};

const setCommissionRate = async (
  userId: string,
  rate: number,
  signal: AbortSignal
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      faBrokerApi.put('commission', { percent: rate, userId: userId }, { signal }),
      areas.setCommissionRate
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const setCommissionPerProject = async (
  userId: string,
  projectId: string,
  rate: number,
  signal: AbortSignal
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      faBrokerApi.put(
        'commission',
        { percent: rate, projectId: projectId, userId: userId },
        { signal }
      ),
      areas.setCommissionPerProject
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const slicePerPage = <T>(arr: T[], page: number) => {
  const pageLimit = 10;
  const initial = page * pageLimit;
  const final = initial + pageLimit;
  return arr.slice(initial, final);
};

let investmentInvestorsIds: string[] = [];
let investmentProjectsIds: string[] = [];
let commissions: IInvestmentsListResponse[] = [];
const getInvestmentsList = async (
  id: string,
  page: number,
  signal: AbortSignal
): Promise<IReturnType<IFABrokerInvestments[]> | IReturnError> => {
  try {
    if (page === 0) {
      const res = await faBrokerApi.get<IInvestmentsListResponse[]>('commissions/investments', {
        params: { userId: id },
        signal,
      });
      commissions = res.data;
      investmentInvestorsIds = commissions.map((investor) => investor.investorId);
      investmentProjectsIds = commissions.map((project) => project.projectId);
    }
    const investorsPerPage = getUniqueArray(slicePerPage(investmentInvestorsIds, page));
    const projectsPerPage = getUniqueArray(slicePerPage(investmentProjectsIds, page));
    const [investors, projects] = await Promise.all([
      getInvestorsDetails(investorsPerPage, signal),
      getProjectDetailsFromList(projectsPerPage, signal),
    ]);
    const results = decorateInvestmentsList(commissions, investors.data as IInvestorDetail[], projects);
    return {
      data: results,
      status: 200,
    };
  } catch (error) {
    return reportError(error);
  }
};

let faBrokersInvestorsIds: string[] = [];
let faBrokersInvestors: IFABrokersInvestorsRaw[] = [];
const getInvestorsPerBroker = async (
  id: string,
  page: number,
  signal: AbortSignal
): Promise<IReturnType<IInvestorsPerBroker[]> | IReturnError> => {
  try {
    if (page === 0) {
      const res = await faBrokerApi.get<IFABrokersInvestorsRaw[]>('commissions/investors', {
        params: { userId: id },
        signal,
      });
      faBrokersInvestors = res.data;
      faBrokersInvestorsIds = faBrokersInvestors.map((investor) => investor.investorId);
    }
    const uniqueInvestors = getUniqueArray(slicePerPage(faBrokersInvestorsIds, page));
    const investors = await getInvestorsDetails(uniqueInvestors, signal);
    const results = decorateInvestorsPerFaBroker(faBrokersInvestors, investors.data as IInvestorDetail[]);
    return {
      data: results,
      status: 200,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getReferrerAssociatedIds = async (
  investorsArray: string[],
  signal: AbortSignal
): Promise<IReturnType<IReferrersResponse[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IReferrersResponse[]> = await trackPromise(
      faBrokerApi.get('referrals', {
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        params: { investorIds: `["${investorsArray.reduce((f, s) => `${f}","${s}`)}"]` },
        signal,
      }),
      areas.getReferrerAssociatedIds
    );
    return { data: res.data, status: res.status };
  } catch (error) {
    return reportError(error);
  }
};

const getReferrersByIds = async (
  investorsArray: string[],
  signal: AbortSignal
): Promise<IReturnType<IReferrersResponse[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IReferrersResponse[]> = await trackPromise(
      faBrokerApi.get('referrers', {
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        params: { userIds: `["${investorsArray.reduce((f, s) => `${f}","${s}`)}"]` },
        signal,
      }),
      areas.getReferrersByIds
    );
    return { data: res.data, status: res.status };
  } catch (error) {
    return reportError(error);
  }
};

export {
  connectInvestor,
  disconnectInvestor,
  getDefaultCommission,
  getFABrokerDetail,
  getFABrokers,
  getInvestmentsList,
  getInvestorsPerBroker,
  getReferrerAssociatedIds,
  getReferrersByIds,
  getUserCommissions,
  setCommissionPerProject,
  setCommissionRate,
  updateDefaultCommission,
};
