import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import type { IProjectInvestorsHeader } from '.';

const TableHeader: FC<IProjectInvestorsHeader> = ({ headings }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="2fr repeat(5,1fr)"
      pl="76px"
      sx={{ borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}` }}
      py="14px"
      data-testid="project-investors-table-header"
    >
      {headings.map((heading, i) => (
        <Typography
          pr={2}
          key={`tableHeading-${heading}`}
          component="span"
          sx={{
            color: 'primary.main',
            fontSize: '14px',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          textAlign={i === 3 ? 'end' : 'start'}
        >
          {heading}
        </Typography>
      ))}
    </Box>
  );
};
export { TableHeader };
