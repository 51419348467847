import { FC } from 'react';

import { requireValidationContext } from './requireValidationContext';
import { useRequireValidation } from './useRequireValidation';

const RequireValidationProvider: FC = ({ children }) => {
  const requireValidationMethods = useRequireValidation();
  return (
    <requireValidationContext.Provider value={requireValidationMethods}>
      {children}
    </requireValidationContext.Provider>
  );
};
export { RequireValidationProvider };
