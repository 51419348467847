/* eslint-disable import/order */
import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { IOption } from '../../components/Select';
import { PROJECT_STATUSES } from '../../helpers/constants';
import { isRequestError } from '../../helpers/isRequestError';
import { messageDecorator } from '../../helpers/messageDecorator';
import { IReturnError, IServerError, reportError } from '../../helpers/reportError';
import { IWallet } from '../../pages/InvestorDetail';
import { IProject, IProjectForm } from '../../pages/ProjectDetail';
import { IFilterParams } from '../../pages/Projects';
import { IProjectNamesParams } from '../../pages/Projects/Projects.d';
import { getWallets } from '../blockchain';
import { getInvestorsDetails } from '../investors/investors';
import { IInvestorDetail } from '../investors/investors.d';
import { IMessage, IReturnType, projectsApi, projectsDocumentsApi, tokensApi } from '../main';
import { confirmTwoFactorAuth, IAuth } from '../session';
import {
  ICreateProjectReq,
  ICreateProjectReturn,
  IIncompleteFields,
  IInvestmentsParams,
  IProjectsInvestmentsResponse,
  IProjectsResponse,
  IStatus,
  TDocumentLegal,
} from '.';
import { areas } from './areas';
import {
  cleanFieldsUpdateProject,
  createProject as createProjectDecorator,
  getInvestmentsDecorator,
  getProjectDetail as getProjectDetailDecorator,
  getProjectLegalDocuments as getProjectLegalDocumentsDecorator,
  getProjects as getProjectsDecorator,
} from './decorators';
import { IDestroySmartContractReq, IProjectNames, IUpdateProjectStatus } from './projects.d';

const createProject = async (
  createProjectReq: ICreateProjectReq,
  signal: AbortSignal
): Promise<IReturnType<ICreateProjectReturn> | IReturnError> => {
  try {
    const res: AxiosResponse<ICreateProjectReturn> = await trackPromise(
      projectsApi.post('/projects', createProjectReq, { signal }),
      areas.createProject
    );
    return {
      data: createProjectDecorator(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const duplicateProject = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<string> | IReturnError> => {
  try {
    const res: AxiosResponse<string> = await trackPromise(
      projectsApi.post(`/projects/duplicate/${id}`, { signal }),
      areas.duplicateProject
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getProjectDetail = async (
  id: string,
  signal: AbortSignal
): Promise<IReturnType<IProject> | IReturnError> => {
  try {
    const res: AxiosResponse<IProject> = await projectsApi.get(`/projects/${id}`, { signal });

    const decoratedData = getProjectDetailDecorator(res.data);
    return {
      data: decoratedData,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getProjectDetailsFromList = async (projectIds: string[], signal: AbortSignal) => {
  const projectsReq = projectIds.map((project) => getProjectDetail(project, signal));
  const projectsRes = await Promise.all(projectsReq);
  if (isRequestError(projectsRes[0]?.status)) {
    throw new Error('Network error');
  }
  return projectsRes.map((res) => res.data) as IProject[];
};

const getProjects = async (
  params: IFilterParams,
  signal: AbortSignal
): Promise<IReturnType<IProjectsResponse> | IReturnError> => {
  try {
    const res: AxiosResponse<IProjectsResponse> = await trackPromise(
      projectsApi.get('/projects', { params, signal }),
      areas.get
    );
    return {
      data: getProjectsDecorator(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getProjectNames = async (
  params: IProjectNamesParams,
  signal: AbortSignal
): Promise<IReturnType<IProjectNames[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IProjectNames[]> = await trackPromise(
      projectsApi.get(`/projectNames?statuses=[${params.statuses}]`, { signal }),
      areas.getProjectNames
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const updateProjectDetail = async (
  id: string,
  data: IProjectForm | IUpdateProjectStatus,
  signal: AbortSignal
): Promise<IReturnType<IMessage | IIncompleteFields> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      projectsApi.put(`/projects/${id}`, cleanFieldsUpdateProject(data), { signal }),
      areas.updateProjectDetail
    );
    return {
      data: messageDecorator(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getProjectLegalDocuments = async (
  signal: AbortSignal
): Promise<IReturnType<TDocumentLegal[]> | IReturnError> => {
  try {
    const res: AxiosResponse<IOption[]> = await trackPromise(
      projectsDocumentsApi.get(`/contracts/templates`, { signal }),
      areas.getProjectLegalDocuments
    );
    return {
      data: getProjectLegalDocumentsDecorator(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getProjectInvestment = async (
  id: string,
  params: IInvestmentsParams,
  signal: AbortSignal
): Promise<IReturnType<IProjectsInvestmentsResponse> | IReturnError> => {
  try {
    const res = await projectsApi.get<IProjectsInvestmentsResponse>(`portfolio/${id}`, {
      params,
      signal,
    });
    const investorsReq = res.data.investments.map((investor) => investor.userId);
    const [investors, whitelist] = await Promise.all([
      getInvestorsDetails(investorsReq, signal),
      getWallets(investorsReq, signal),
    ]);
    return {
      data: getInvestmentsDecorator(res.data, investors.data as IInvestorDetail[], whitelist.data as IWallet[]),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const setTransactionStatus = async (
  transaction: string,
  data: IStatus,
  signal: AbortSignal
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res = await trackPromise(
      projectsApi.put(`investments/${transaction}`, data, { signal }),
      `setTransactionStatus/${transaction}-${data.status.toLowerCase()}`
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const destroySmartContract = async (
  req: IDestroySmartContractReq,
  signal: AbortSignal
): Promise<IReturnType<IAuth> | IReturnError> => {
  try {
    const { data, status } = await confirmTwoFactorAuth(req.session, req.code, req.userId);
    if (isRequestError(status)) {
      const error = data as IServerError;
      return { data: error, status };
    }
    await tokensApi.post('tokens/burn', { projectId: req.projectId }, { signal });
    await updateProjectDetail(req.projectId, { status: PROJECT_STATUSES.PROJECT_COMPLETED }, signal);
    const auth = data as IAuth;
    return { data: auth, status: 200 };
  } catch (error) {
    return reportError(error);
  }
};

export {
  createProject,
  destroySmartContract,
  duplicateProject,
  getProjectDetail,
  getProjectDetailsFromList,
  getProjectInvestment,
  getProjectLegalDocuments,
  getProjectNames,
  getProjects,
  setTransactionStatus,
  updateProjectDetail,
};
