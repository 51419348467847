import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import PayoutsCalculationImg from '../../assets/project-details/payouts-calculation.svg'; // eslint-disable-line

const PayoutsEmptyState: FC = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={{ lg: 12, sm: 6, xs: 4 }}>
      <Stack direction="column" spacing={5} alignItems="center" px={2}>
        <img
          src={PayoutsCalculationImg}
          alt="Payouts calculation"
          width="100%"
          style={{ maxWidth: 195 }}
        />
        <Typography
          variant="h2"
          color="text.secondary"
          fontWeight={400}
          sx={{ textAlign: 'center' }}
        >
          Here you can create a payout report to see how the dividends are going to be distributed
          to the investors.
        </Typography>
      </Stack>
    </Box>
  );
};

export { PayoutsEmptyState };
