import { IconButton, InputAdornment } from '@mui/material';
import { IconEye, IconEyeOff } from '@tabler/icons';
import { FC } from 'react';

import { IShowPassword } from './InputField.d';

const ShowPasswordIcon: FC<IShowPassword> = ({ type, showPassword, toogleShowPassword }) => {
  if (type === 'password') {
    return (
      <InputAdornment position="end">
        <IconButton aria-label="toggle password visibility" onClick={toogleShowPassword} edge="end">
          {showPassword ? <IconEye /> : <IconEyeOff />}
        </IconButton>
      </InputAdornment>
    );
  } else {
    return null;
  }
};

export { ShowPasswordIcon };
