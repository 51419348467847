import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { getPayoutsReportsList } from '../../../api/payouts/payouts';
import { IPayoutsReport } from '../../../api/payouts/payouts.d';
import { isRequestError } from '../../../helpers/isRequestError';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { IFormOutletContext } from '../ProjectDetail.d'; //eslint-disable-line
import { Payouts } from './Payouts';

const PayoutsPresenter: FC = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { contractStatus, projectName } = useOutletContext<IFormOutletContext>();
  const [isLoading, setIsLoading] = useState(false);

  const disableCreateReport = contractStatus !== 'paused';

  const goToCreateReport = () =>
    nav(`/projects/${id}/payouts-report/create`, { state: { projectName } });
  const [payoutReport, setPayoutReport] = useState<IPayoutsReport[]>([]);
  const getPayoutsReportsListController = useAbortRequest();

  const reqPayoutsReportsList = useCallback(async () => {
    setIsLoading(true);
    const { data, status } = await getPayoutsReportsList(
      id ?? '',
      getPayoutsReportsListController.signal
    );
    if (!isRequestError(status)) {
      setPayoutReport(data as IPayoutsReport[]);
    }
    setIsLoading(false);
  }, [getPayoutsReportsListController.signal, id]);

  useEffect(() => {
    reqPayoutsReportsList();
  }, [reqPayoutsReportsList]);

  return (
    <Payouts
      disabled={disableCreateReport}
      rows={payoutReport}
      goToCreateReport={goToCreateReport}
      projectName={projectName}
      isLoading={isLoading}
    />
  );
};

export { PayoutsPresenter };
