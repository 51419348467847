import styled from '@emotion/styled';
import { Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material';

import { Interactive } from '../../theme/tokens/colors'; // eslint-disable-line

const Tooltip = styled(({ className, placement = 'top', ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow placement={placement} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Interactive.secondary,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Interactive.secondary,
  },
  hyphens: 'auto',
}));

export { Tooltip };
