import { Stack } from '@mui/material';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';

import { InfoIcon } from '../../components/InfoIcon';
import { InputField } from '../../components/InputField'; //eslint-disable-line
import { IFormContext } from './CreateTokens.d';

const SetTokens: FC = () => {
  const { register, errors } = useOutletContext<IFormContext>();

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }} //eslint-disable-line
      gap={4}
    >
      <InputField
        labelVariant="uncontained"
        fullWidth
        {...register('name')}
        error={!!errors['name']}
        helperText={errors['name']?.message}
        label={
          <>
            Token Name
            <InfoIcon>The name should be related to the project, e.g. Miami House Token.</InfoIcon>
          </>
        }
      />
      <InputField
        labelVariant="uncontained"
        fullWidth
        {...register('symbol')}
        error={!!errors['symbol']}
        helperText={errors['symbol']?.message}
        label={
          <>
            Token Symbol
            <InfoIcon>
              The token symbol should be 3 or 4 characters long and be related to the token name,
              e.g. MHT (Miami House Token).
            </InfoIcon>
          </>
        }
      />
    </Stack>
  );
};

export { SetTokens };
