// eslint-disable-next-line simple-import-sort/imports
import { PROJECT_STATUSES } from '../../helpers/constants';
import { IProjectStatus, TContractStatus } from '../../pages/ProjectDetail';

import { IValidationData, TStatusValidation } from './ProjectStatusBar.d';

const activeValidationText: IValidationData = {
  subtitle: 'Do you want to open fundraising for this project?',
  text: 'The project will be published and Investors will be able to see it and invest on it.',
  title: 'Open fundraising',
};
const archivedValidationText: IValidationData = {
  subtitle: 'Are you sure you want to archive this project?',
  text: "This action can't be reversed and editing it will no longer be available.",
  title: 'Archive project',
};
const completedValidationText: IValidationData = {
  subtitle: 'Are you sure you want to mark this project to Fundraising completed?',
  text: 'Investors will no longer be able to invest on it.',
  title: 'Complete fundraising',
};
const developmentCompletedValidationText: IValidationData = {
  subtitle: 'Are you sure you want to change this project to completed? ',
  text: 'You will not be able to edit some information for this project.',
  title: 'Complete development',
};
const cancelValidationText: IValidationData = {
  subtitle:
    'Are you sure you want to cancel this project? Investors will no longer be able to invest in this project. All Received transactions should be marked as To Refund and all transactions with Pending, Committed or Delayed status will be marked as Rejected.',
  text: 'This action can´t be reversed.',
  title: 'Cancel project',
};
const closeContractValidationText: IValidationData = {
  subtitle: 'Do you want to close contract for this project?',
  title: 'Close contract',
};
const createTokensValidationText: IValidationData = {
  subtitle: 'Exceptions must be resolved to begin the tokenization process.',
  title: 'There are exceptions',
};
const completeProjectValidationText: IValidationData = {
  buttonLabel: 'Pause contract',
  subtitle: '',
  text: 'To complete the project, the smart contract has to be paused.',
  title: 'Contract must be paused',
};

const statusValidation: TStatusValidation = {
  active: activeValidationText,
  archived: archivedValidationText,
  canceled: cancelValidationText,
  closed: closeContractValidationText,
  completed: completedValidationText,
  creating: null,
  delivered: null,
  development_completed: developmentCompletedValidationText,
  draft: null,
  paused: null,
  project_completed: null,
};

const statusRequireValidation = (
  currentStatus: IProjectStatus,
  status: IProjectStatus,
  contractStatus: TContractStatus,
  exceptions?: number
) => {
  if (status === PROJECT_STATUSES.ACTIVE && currentStatus !== PROJECT_STATUSES.DRAFT) {
    return null;
  }
  if (exceptions && status === PROJECT_STATUSES.CREATING_TOKENS) {
    return createTokensValidationText;
  }
  if (contractStatus !== 'paused' && status === PROJECT_STATUSES.PROJECT_COMPLETED) {
    return completeProjectValidationText;
  }
  return statusValidation[status];
};

export { statusRequireValidation };
