import { Box, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, FocusEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { onboardingAreas } from '../../api/onBoarding';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { InputField } from '../../components/InputField';
import { Logo } from '../../components/Logo';
import { PasswordRules } from '../../components/PasswordRules';
import { SimplePageContainer } from '../../components/SimplePageContainer'; //eslint-disable-line
import { IProps } from './Onboarding.d';

const Onboarding: FC<IProps> = ({
  passwordRules,
  register,
  submitPassword,
  showRules,
  toggleRules,
  errors,
  validForm,
  description,
  code,
  areaSubmit,
  reset,
}) => {
  const { t } = useTranslation();
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const { ref, onBlur, ...rest } = register('password');
  const [codeValue, setCodeValue] = useState<string | number>('');

  const sharedRef = (element: HTMLInputElement) => {
    ref(element);
    passwordRef.current = element;
  };
  const sharedOnBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    onBlur(event);
    toggleRules();
  };
  const codeAreaSubmit = areaSubmit || onboardingAreas.createPassword;

  const codeHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const onlyNumbers = /^\d+$/.test(value);
    if ( value !== '' && !onlyNumbers) {
      return;
    }
    setCodeValue(value.trim());
  };

  useEffect(() => {
    if (reset) {
      reset({
        code: '',
        password: '',
        repeat_password: '',
      });
    }
  }, [reset]);

  return (
    <>
      <PasswordRules rules={passwordRules} anchorEl={passwordRef} open={showRules} />
      <SimplePageContainer>
        <Logo mode="light" />
        <Typography variant="h1" mt={4} mb={5}>
          Create a new password
        </Typography>
        <Typography variant="body1" mb={4} component="p">
          {description}
        </Typography>
        <form onSubmit={submitPassword}>
          <Stack spacing={5} mt={4}>
            {!!code && (
              <InputField
                id="code_user"
                label="Code"
                type="password"
                error={!!errors['code']}
                helperText={errors['code']?.message}
                value={codeValue}
                inputProps={{
                  ...register('code'),
                }}
                onChange={codeHandleChange}
              />
            )}
            <InputField
              id="create_password"
              label={t('onboarding.password')}
              placeholder={t('onboarding.password')}
              type="password"
              error={!!errors['password']}
              ref={sharedRef}
              onBlur={sharedOnBlur}
              onFocus={toggleRules}
              {...rest}
              />
            <InputField
              id="repeat_password"
              label={t('onboarding.repeatPassword')}
              placeholder={t('onboarding.repeatPassword')}
              type="password"
              error={!!errors['repeat_password']}
              helperText={errors['repeat_password']?.message}
              {...register('repeat_password')}
            />
          </Stack>
          <Box mt={6}>
            <ButtonSubmit area={codeAreaSubmit} type="submit" disabled={!validForm}>
              Create password
            </ButtonSubmit>
          </Box>
        </form>
      </SimplePageContainer>
    </>
  );
};

export { Onboarding };
