import './fonts.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { FC } from 'react';

import { adminTheme } from './theme';

export const ThemeProvider: FC = ({ children }) => {
  return (
    <MUIThemeProvider theme={adminTheme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};
