import { GridSortModel } from '@mui/x-data-grid';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';

import {
  faBrokersAreas,
  getDefaultCommission,
  getFABrokers,
  ICommissionsValue,
  IFABrokersFilters,
  IPaginatedFABrokers,
  IUpdateCommissionReq,
  updateDefaultCommission,
} from '../../api/brokers';
import { isRequestError } from '../../helpers/isRequestError';
import { useAbortRequest } from '../../hooks/useAbortRequest'; // eslint-disable-line
import { BrokersAndFA } from './BrokersAndFA';
import { IBrokersAndFAFilters } from './BrokersAndFA.d';

const defaultPages = { count: 0, investors: [], page: 0, pages: 0 };
const defaultFilters = { page: 0, role: 'broker', sortBy: 'date:DESC' };

const BrokersAndFAPresenter: FC = () => {
  const [tab, setTab] = useState<number>(0);
  const [brokers, setBrokers] = useState<IPaginatedFABrokers>(defaultPages);
  const [fa, setFA] = useState<IPaginatedFABrokers>(defaultPages);
  const [filters, setFilters] = useState<IFABrokersFilters>(defaultFilters);
  const [commissions, setCommissions] = useState<ICommissionsValue>({ broker: 0, fa: 0 });
  const faBrokersListController = useAbortRequest();
  const { control, handleSubmit, reset } = useForm<IBrokersAndFAFilters>({
    defaultValues: { status: '' },
  });

  const setDefaultCommission = useCallback(async () => {
    const { data, status } = await getDefaultCommission();
    if (!isRequestError(status)) {
      const res = data as ICommissionsValue;
      setCommissions(res);
    }
  }, []);

  const changeCommission =
    (role: IUpdateCommissionReq['role']) => async (newCommission: number) => {
      const { status } = await updateDefaultCommission({ commissionPercent: newCommission, role });
      if (!isRequestError(status)) {
        setCommissions({ ...commissions, [role]: newCommission });
      }
    };

  const setFABrokers = useCallback(async () => {
    const { status, data } = await trackPromise(
      getFABrokers(filters, faBrokersListController.signal),
      faBrokersAreas.getFABrokersList
    );
    if (!isRequestError(status)) {
      const response = data as IPaginatedFABrokers;
      tab ? setFA(response): setBrokers(response);
    }
  }, [tab, faBrokersListController.signal, filters]);

  const onChangeTab = (newTab: number) => {
    setTab(newTab);
    setFilters(() => ({ page: 0, role: tab ? 'broker' : 'fa', sortBy: 'date:DESC' }));
  };

  const onSort = (newModel: GridSortModel) => {
    const model = newModel[0];
    const sortBy = model && `${model.field}:${model.sort?.toUpperCase()}`;
    setFilters((filter) => ({ ...filter, sortBy }));
  };

  const onPageChange = (newPage: number) => {
    setFilters((filter) => ({ ...filter, page: newPage }));
  };

  const onFilter = (form: IBrokersAndFAFilters) => {
    setFilters((filter) => ({ ...filter, page: 0, status: form.status }));
  };

  const onSearch = (value: string) => {
    setFilters((filter) => ({ ...filter, page: 0, search: value }));
  };

  const onClear = () => {
    const updatedFilters = { ...filters, page: 0 };
    delete updatedFilters.search;
    setFilters(updatedFilters);
  };

  const onClearFilter = () => {
    const updatedFilters = { ...filters, page: 0 };
    delete updatedFilters.status;
    setFilters(updatedFilters);
    reset({ status: '' });
  };

  useEffect(() => {
    setFABrokers();
  }, [setFABrokers]);

  useEffect(() => {
    setDefaultCommission();
  }, [setDefaultCommission]);

  return (
    <BrokersAndFA
      totalFaBrokers={tab ? fa.count : brokers.count}
      applySort={onSort}
      onPageChange={onPageChange}
      applyFilter={handleSubmit(onFilter)}
      clearFilter={onClearFilter}
      applySearch={onSearch}
      clearSearch={onClear}
      brokersRows={brokers.investors}
      faRows={fa.investors}
      control={control}
      tab={tab}
      setTab={onChangeTab}
      brokerCommission={commissions.broker}
      FACommission={commissions.fa}
      changeBrokerCommission={changeCommission('broker')}
      changeFACommission={changeCommission('fa')}
      currentPage={filters.page}
    />
  );
};

export { BrokersAndFAPresenter };
