import { Button, Popover, Stack, Typography } from '@mui/material';
import { IconFilter } from '@tabler/icons';
import { FC, FormEvent, useRef, useState } from 'react';

import { IFilterPopoverProps } from './FilterPopover.d';

const FilterPopover: FC<IFilterPopoverProps> = ({
  applyFilter,
  clearFilter,
  filterOptions,
  disabled,
}) => {
  const filterAnchor = useRef<HTMLButtonElement>(null);
  const [openFilters, setOpenFilters] = useState(false);
  const toggleFilters = () => setOpenFilters(!openFilters);

  const onApplyFilters = (event: FormEvent<HTMLFormElement>) => {
    applyFilter(event);
    toggleFilters();
  };

  const onClearFilters = () => {
    clearFilter();
    toggleFilters();
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<IconFilter />}
        sx={{
          fontSize: 14,
          height: 32,
        }}
        ref={filterAnchor}
        onClick={toggleFilters}
        data-testid="test-filter-button"
        disabled={disabled}
      >
        Filter
      </Button>

      <Popover
        role="region"
        aria-label="filters"
        data-testid="test-filter-options"
        open={openFilters}
        onClose={toggleFilters}
        anchorEl={filterAnchor.current}
        PaperProps={{ sx: { borderRadius: 2, boxShadow: 2, p: 3, width: 316 } }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Typography variant="caption2">Filter options</Typography>

        <form onSubmit={onApplyFilters}>
          <Stack my={3} gap={4}>
            {filterOptions}
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="text"
              color="secondary"
              onClick={onClearFilters}
              data-testid="test-clear-filter-button"
            >
              Clear
            </Button>
            <Button
              type="submit"
              sx={{ fontSize: 14, ml: 2 }}
              variant="contained"
              data-testid="test-apply-filter-button"
            >
              Apply
            </Button>
          </Stack>
        </form>
      </Popover>
    </>
  );
};

export { FilterPopover };
