import { Box, Grid, Typography, Link } from '@mui/material'; //eslint-disable-line
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Gray } from '../../theme/tokens/colors';

import { ITransactionItem } from './TransactionsTable.d';
import { StatusLabel } from './StatusLabel';

export const RowTransaction: FC<ITransactionItem> = ({
  project,
  investment,
  date,
  value,
  status,
  odd,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="48px"
      bgcolor={odd ? 'grey.100' : '#fff'}
      sx={{ borderBottom: `1px solid ${Gray[300]}` }}
    >
      <Grid alignItems="center" justifyContent="center" columns={10} columnSpacing={4} container>
        <Grid item sm={2}>
          <Link
            component={RouterLink}
            to={`/projects/${project.id}`}
            underline="hover"
            display="block"
            variant="body1"
            fontWeight={300}
            color="secondary"
            pl={2}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {project.title}
          </Link>
        </Grid>
        <Grid item sm={2}>
          <Typography component="div" variant="body1" fontWeight={300}>
            {investment}
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography component="div" variant="body1" fontWeight={300}>
            {date}
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography component="div" variant="body1" fontWeight={300}>
            {value}
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <StatusLabel color={status.color} pr={2}>
            <Box
              component="div"
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {status.title}
            </Box>
          </StatusLabel>
        </Grid>
      </Grid>
    </Box>
  );
};
