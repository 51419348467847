import { Box } from '@mui/material';
import { FC } from 'react';

import { IStatusPointer } from './';

const StatusPointer: FC<IStatusPointer> = ({ status }) => {
  const statusColor: { [key: string]: string } = {
    default: 'grey.500',
    error: 'error.main',
    success: 'success.main',
    warning: 'warning.main',
  };
  return (
    <Box
      role="status"
      sx={{
        bgcolor: statusColor[status],
        borderRadius: '50%',
        flexShrink: 0,
        height: '6px',
        width: '6px',
      }}
    />
  );
};

export { StatusPointer };
