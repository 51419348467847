import { Stack, Typography } from '@mui/material';

import EmptyPayoutsImg from '../../assets/project-details/payouts-calculation.svg';

const EmptyPayouts = () => {
  return (
    <Stack height="260px" alignItems="center" justifyContent="center">
      <img width="150px" src={EmptyPayoutsImg} alt="there are no investors in this table" />
      <Typography textAlign="center" mt={3} variant="h2" color="text.secondary">
        There are not results for your search
      </Typography>
    </Stack>
  );
};

export { EmptyPayouts };
