import { IWallet } from '../../pages/InvestorDetail'; //eslint-disable-line
import { IInvestorDetail } from '../investors/investors.d';

import { ICommissions, ICommissionsRaw } from './commissions.d';

const decorateStatusLabels = (status: ICommissions['status']) => {
  const commissionStatusLabels = {
    BLACKLISTED: 'Blacklisted',
    FAILED: 'Failed',
    NOT_PAYABLE: 'Not payable',
    NOT_WHITELISTED: 'Not Whitelisted',
    PAID: 'Paid',
    PAID_OFFLINE: 'Paid offline',
    PAYMENT_IN_PROCESS: 'In process',
    PENDING: 'Pending',
  };
  return commissionStatusLabels[status];
};
const decorateMethodLabels = (method: ICommissions['paymentMethod']) => {
  const commissionMethodLabels = {
    ACH: 'Wire',
    CRYPTO: 'Crypto',
    MANUAL: 'Manual',
    WIRE: 'Wire',
  };
  if (method) {
    return commissionMethodLabels[method];
  }
  return 'None';
};

const decorateStatus = (
  status: ICommissionsRaw['commissionStatus'],
  method: ICommissionsRaw['paymentMethod'],
  isWhitelisted: string,
  payoutAccount: boolean
): ICommissions['status'] => {
  if (!payoutAccount && status === 'PENDING' && process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'false') {
    return 'NOT_PAYABLE';
  }
  if ((isWhitelisted === 'Blacklisting' || isWhitelisted === 'Blacklisted') && status === 'PENDING') {
    return 'BLACKLISTED';
  }
  if (isWhitelisted === 'Not whitelisted' && status === 'PENDING') {
    return 'NOT_WHITELISTED';
  }
  if (status === 'PAID' && method === 'OFFLINE') {
    return 'PAID_OFFLINE';
  }
  if (status === 'IN_PROGRESS') {
    return 'PAYMENT_IN_PROCESS';
  }
  return status;
};

const decorateMethod = (
  method: ICommissionsRaw['paymentMethod']
): ICommissions['paymentMethod'] | null => {
  if (!method) {
    return null;
  }
  if (method === 'OFFLINE') {
    return 'MANUAL';
  }
  return method;
};

const decorateCommissionsPerProject = (
  data: ICommissionsRaw[],
  investors: IInvestorDetail[],
  whitelist: IWallet[]
): ICommissions[] => {
  return data.map((commission, i) => {
    const investor = investors[i];
    const isWhitelisted = whitelist[i].whitelistStatus === 'Whitelisted';
    return {
      associate: investor.legalName,
      commission: commission.commissionAmt,
      id: commission.userId,
      isWhitelisted,
      paymentDate: commission.paidAt,
      paymentMethod: decorateMethod(commission.paymentMethod),
      payoutAccount: commission?.payoutAccount,
      rate: commission.commissionPercent,
      status: decorateStatus(
        commission.commissionStatus,
        commission.paymentMethod,
        whitelist[i].whitelistStatus,
        commission?.payoutAccount
      ),
    };
  });
};

export {
  decorateCommissionsPerProject,
  decorateMethodLabels,
  decorateStatusLabels
};
