import { Box,  Stack,  Switch,  Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { projectTypes } from '../../../components/CreateProjectSteps/dropdownsData';
import { DatePicker, InputField } from '../../../components/InputField';
// eslint-disable-next-line import/order
import { Select } from '../../../components/Select';
import { IBasic } from './Basic.d';

const Basic: FC<IBasic> = ({ canEdit }) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ xs: '1fr', lg: 'repeat(2, 1fr)' }} //eslint-disable-line
      columnGap={{ xs: 0, lg: 4 }} //eslint-disable-line
      rowGap={4}
    >
      <Typography
        gridColumn={{ xs: 'span 1', lg: 'span 2' }} //eslint-disable-line
        fontWeight="bold"
        variant="subtitle2"
      >
        Project description
      </Typography>
      <InputField
        id="create_project_name"
        label="Project name"
        fullWidth
        error={!!errors['projectTitle']}
        helperText={errors['projectTitle']?.message}
        {...register('projectTitle')}
        disabled={!canEdit}
      />
      <Select
        fullWidth
        name="projectType"
        label="Project type"
        control={control}
        options={projectTypes}
        error={!!errors['projectType']}
        helperText={errors['projectType']?.message}
        disabled={!canEdit}
      />
      <Stack direction="row" alignItems="center">
        <Controller
          control={control}
          name="isUs"
          defaultValue={true}
          render={({
            field: { onChange, value }
          }) => {
            return (
              <Switch
                checked={value}
                disabled={!canEdit}
                onChange={onChange}
              />
            );
          }}
        />
        <label htmlFor="active">
          <Typography component="span" sx={{ userSelect: 'none' }}>
            Is US project
          </Typography>
        </label>
      </Stack>
      <Box gridColumn={{lg: 'span 2', xs: 'span 1'}}>
        <Typography fontWeight="bold" variant="subtitle2">
          Dates
        </Typography>
      </Box>
      <DatePicker
        fullWidth
        control={control}
        name="closingDate"
        id="closingDate"
        label="Closing date"
        placeholder="e.g. January, 30, 2023"
        error={!!errors['closingDate']}
        helperText={errors['closingDate']?.message}
        disabled={!canEdit}
      />
      <InputField
        fullWidth
        id="investmentPeriod"
        label="Investment period"
        placeholder="e.g. 13 months"
        error={!!errors['investmentPeriod']}
        helperText={errors['investmentPeriod']?.message}
        {...register('investmentPeriod')}
        disabled={!canEdit}
      />
    </Box>
  );
};

export { Basic };
