import { IProjectStatus } from '../../pages/ProjectDetail';
import { Error, Gray, Primary,Success, Warning } from '../../theme/tokens/colors'; // eslint-disable-line
import { IStatusData } from './StatusChip.d';

export const getData = (status: IProjectStatus, hidden?: boolean): IStatusData => {
  const prexHidden = hidden ? ' (Hidden)' : '';
  const draftButtonProps = {
    bgcolor: Warning[600],
    code: 'D',
    color: Warning[500],
    title: 'Draft',
  };
  const activeButtonProps = {
    bgcolor: Success[700],
    code: 'FO',
    color: Success[500],
    title: 'Fundraising open',
  };
  const pausedButtonProps = {
    bgcolor: Gray[800],
    code: hidden ? 'FH' : 'FP',
    color: Gray[700],
    title: `Fundraising paused${prexHidden}`,
  };
  const canceledButtonProps = {
    bgcolor: Error[600],
    code: 'C',
    color: Error[500],
    title: 'Canceled',
  };
  const completedButtonProps = {
    bgcolor: Success[700],
    code: hidden ? 'FH' : 'F',
    color: Success[700],
    title: `Fundraising completed${prexHidden}`,
  };
  const archivedButtonProps = {
    bgcolor: Error[700],
    code: 'AR',
    color: Error[600],
    title: 'Archived',
  };
  const closedButtonProps = {
    bgcolor: Success[700],
    code: hidden ? 'CH' : 'CC',
    color: Success[700],
    title: `Contract closed${prexHidden}`,
  };
  const creatingButtonProps = {
    bgcolor: Primary[700],
    code: hidden ? 'HC' : 'CT',
    color: Primary[700],
    title: `Creating Tokens${prexHidden}`,
  };
  const deliveredButtonProps = {
    bgcolor: Primary[700],
    code: hidden ? 'TH' : 'TD',
    color: Primary[700],
    title: `Tokens delivered${prexHidden}`,
  };
  const projectCompletedButtonProps = {
    bgcolor: Success[500],
    code: hidden ? 'PH' : 'PC',
    color: Success[500],
    title: `Project completed${prexHidden}`,
  };
  const developmentCompletedButtonProps = {
    bgcolor: Success[500],
    code: hidden ? 'DH' : 'DC',
    color: Success[500],
    title: `Development completed${prexHidden}`,
  };
  const defaultButtonProps = {
    bgcolor: Gray[700],
    code: 'N/A',
    color: Gray[600],
    title: 'Undefined',
  };
  const statusButtons = {
    active: activeButtonProps,
    archived: archivedButtonProps,
    canceled: canceledButtonProps,
    closed: closedButtonProps,
    completed: completedButtonProps,
    creating: creatingButtonProps,
    delivered: deliveredButtonProps,
    development_completed: developmentCompletedButtonProps,
    draft: draftButtonProps,
    paused: pausedButtonProps,
    project_completed: projectCompletedButtonProps,
  };
  return statusButtons[status] || defaultButtonProps;
};
