import { capitalize } from '@mui/material';

import { IInvestor } from '../../components/PageContent';
import { formatToken } from '../../helpers';
import { IInvestorTokens, IWallet } from '../../pages/InvestorDetail';
import { TContractStatus } from '../../pages/ProjectDetail';
// eslint-disable-next-line import/order
import { IInvestorDetail } from '../investors/investors.d';
import {
  IInvestorTokensResponse,
  ITokenItem,
  ITokensBalanceRes,
  ITokensDistribution,
  ITokensResponse,
  IWalletResponse,
  TSmartContractStatus,
} from './blockchain.d';

const mergeTokensAndUsers = (tokens: ITokensResponse[], users: IInvestor[]): ITokenItem[] => {
  return users.map((user) => {
    const tokenAmount = tokens.find((token) => token.userId === user.id)?.amount;
    return {
      amount: formatToken(tokenAmount ?? '0'),
      id: user.id,
      name: user?.legalName,
    };
  });
};

const mergeInvestorsTokensPerProject = (
  balances: ITokensBalanceRes[],
  investors: IInvestorDetail[]
): ITokensDistribution[] => {
  return balances.map((balance) => {
    const user = investors.find((investor) => investor.id === balance.investorId);
    return {
      address: balance.walletAddress,
      id: balance.investorId,
      name: user?.legalName ?? '',
      tokens: formatToken(balance.amountOfTokens),
      url: `${process.env.REACT_APP_BLOCKCHAIN_EXPLORER}/address/${balance.walletAddress}?a=${balance.walletAddress}`,
      walletType: balance.walletType === 'BUILT_IN' ? 'Growie wallet' : 'Metamask',
      whitelistStatus: balance.walletStatus === 'WHITELISTED' ? 'Confirmed' : 'Unconfirmed',
    };
  });
};

const decorateWallet = (data: IWalletResponse[]): IWallet[] => {
  return data.map((wallet) => ({
    walletAssociatedDate: wallet?.associatedDate,
    walletId: wallet?.walletId,
    walletType:
      wallet?.walletType && (wallet?.walletType === 'BUILT_IN' ? 'Growie wallet' : 'Metamask'),
    whitelistStatus: capitalize(wallet?.status.toLowerCase() ?? 'Not whitelisted'),
  }));
};

const decorateInvestorTokens = (data: IInvestorTokensResponse[]): IInvestorTokens[] => {
  return data.map((p) => ({
    projectId: p.projectId,
    tokens: formatToken(p.tokens),
  }));
};

const decorateTheSmartContractStatus = (
  status: keyof typeof TSmartContractStatus,
  deployed: 'destroy_in_progress' | 'destroyed'
): TContractStatus => {
  if (deployed.includes('destroy')) {
    return deployed;
  }
  return TSmartContractStatus[status];
};

export {
  decorateInvestorTokens,
  decorateTheSmartContractStatus,
  decorateWallet,
  mergeInvestorsTokensPerProject,
  mergeTokensAndUsers,
};
