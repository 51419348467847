import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Button } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons';
import CryptoJS from 'crypto-js';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { recoverPassword, recoverPasswordAreas, settingPassword } from '../../api/recoverPassword';
import { IPasswordRule } from '../../components/PasswordRules';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { toastContext } from '../../hooks/useToast';
import { IPasswordForm, Onboarding, passwordRules } from '../Onboarding'; //eslint-disable-line
import { RecoverPassword } from './RecoverPassword';
import { ICreatePasswordReq, IRecoverPasswordFields } from './RecoverPassword.d';
import { createPasswordSchema, form as formSchema } from './schema';

export const RecoverPasswordPresenter: FC = () => {
  const { openToast, closeToast } = useContext(toastContext);
  const [email, setEmail] = useState<string>('');
  const [formDisplay, setFormDisplay] = useState(true);
  const nav = useNavigate();
  const controller = useAbortRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IRecoverPasswordFields>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: joiResolver(formSchema),
    shouldFocusError: true,
  });
  const [rules, setRules] = useState<IPasswordRule[]>(passwordRules);
  const [showRules, setShowRules] = useState<boolean>(false);
  const toggleRules = () => setShowRules(!showRules);

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: {
      errors: errorsPassword,
      isValid: isValidPassword,
      touchedFields: touchedFieldsPassword,
    },
    watch: watchPassword,
    reset: resetPassword,
  } = useForm<IPasswordForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: joiResolver(createPasswordSchema),
    shouldFocusError: true,
  });
  const passwordValue = watchPassword('password');

  const errorsHandle = (error: IServerError) => {
    if (error.message.includes('disabled')) {
      nav('/error');
      return;
    }
    openToast({
      message: error.message,
      severity: 'error',
      title: (error.title as string) || 'Something went wrong',
    });
  };

  const recoverPasswordHandle = async (dataForm: IRecoverPasswordFields) => {
    closeToast();
    setEmail(dataForm.email);
    const { data, status } = await recoverPassword(dataForm.email);
    if (isRequestError(status)) {
      errorsHandle(data as IServerError);
      return;
    }
    openToast({
      message: 'The email has been sent.',
      severity: 'success',
    });
    setFormDisplay(false);
  };

  const changePassword = async (dataFields: IPasswordForm) => {
    closeToast();
    const req: ICreatePasswordReq = {
      code: dataFields.code as string,
      email,
      newPassword: CryptoJS.AES.encrypt(dataFields.password, email).toString(),
    };
    const { data, status } = await settingPassword(req, controller.signal);
    if (isRequestError(status)) {
      if (status === 410) {
        nav('/expired', { state: { email } });
        return;
      }
      errorsHandle(data as IServerError);
      return;
    }
    nav('/login', {
      state: {
        toast: {
          message: 'Password successfully changed!',
          severity: 'success',
        },
      },
    });
  };

  const submitPassword = handleSubmitPassword(changePassword);

  const validateRule = useCallback(
    (rule: IPasswordRule, value: string) => {
      const updatedRule = { ...rule };
      if (!value && !touchedFieldsPassword.password) {
        updatedRule.valid = undefined;
        return updatedRule;
      }
      updatedRule.valid = updatedRule.rule.test(value);
      return updatedRule;
    },
    [touchedFieldsPassword.password]
  );

  useEffect(() => {
    setRules((currentRules) => currentRules.map((rule) => validateRule(rule, passwordValue)));
  }, [passwordValue, validateRule]);

  return (
    <>
      {formDisplay ? (
        <RecoverPassword
          errors={errors}
          submitRecovery={handleSubmit(recoverPasswordHandle)}
          register={register}
          reset={reset}
        />
      ) : (
        <div>
          <Box mb={3}>
            <Button
              color="secondary"
              startIcon={<IconArrowLeft />}
              sx={{ fontSize: '12px' }}
              onClick={() => setFormDisplay(true)}
            >
              Go back
            </Button>
          </Box>
          <Onboarding
            passwordRules={rules}
            register={registerPassword}
            submitPassword={submitPassword}
            errors={errorsPassword}
            showRules={showRules}
            toggleRules={toggleRules}
            validForm={isValidPassword}
            description="Enter the code received in the email and let’s set up a password so you can start using your Growie account."
            code={true}
            areaSubmit={recoverPasswordAreas.codeForm}
            reset={resetPassword}
          />
        </div>
      )}
    </>
  );
};
