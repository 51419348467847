import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getDefaultCommission,
  getUserCommissions,
  ICommissionsValue,
  setCommissionPerProject,
  setCommissionRate,
} from '../../../api/brokers';
import { handleApiErrors } from '../../../helpers/handleApiErrors';
import { isRequestError } from '../../../helpers/isRequestError';
import { IServerError } from '../../../helpers/reportError';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { toastContext } from '../../../hooks/useToast';
// eslint-disable-next-line import/order
import { ICommissions, ICommissionsPresenter } from '../FABrokers.d';
import { Commissions } from './Commissions';

const CommissionsPresenter: FC<ICommissionsPresenter> = ({ userName, role }) => {
  const { id: userId } = useParams();
  const setCommissionRateController = useAbortRequest();
  const getUserCommissionsController = useAbortRequest();
  const setCommissionPerProjectController = useAbortRequest();
  const [commissions, setCommissions] = useState<ICommissions>();
  const { openToast } = useContext(toastContext);

  const setCommissionRateFn = async (percent: number) => {
    const { data, status } = await setCommissionRate(
      userId as string,
      percent,
      setCommissionRateController.signal
    );
    if (!isRequestError(status)) {
      getUserCommissionsFn();
    } else {
      const error = data as IServerError;
      handleApiErrors(error, openToast)
    }
  };

  const getUserCommissionsFn = useCallback(async () => {
    const { data, status } = await getUserCommissions(
      userId as string,
      getUserCommissionsController.signal
    );
    if (!isRequestError(status)) {
      setCommissions(data as ICommissions);
      if ((data as ICommissions).commissionPercent === undefined && role) {
        const { data: defaultCommission, status: statusCode } = await getDefaultCommission();
        if (!isRequestError(statusCode)) {
          const res = defaultCommission as ICommissionsValue;
          const updatedComissions = {
            commissionPercent: res[role as keyof ICommissionsValue],
          };
          setCommissions(updatedComissions);
        }
      }
    } else {
      const error = data as IServerError;
      handleApiErrors(error, openToast)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserCommissionsController.signal, role, userId]);

  const setCommisionPerProjectFn = async (
    selectedProject: string,
    commissionForProject: number
  ) => {
    const { data, status } = await setCommissionPerProject(
      userId as string,
      selectedProject,
      commissionForProject,
      setCommissionPerProjectController.signal
    );
    if (!isRequestError(status)) {
      getUserCommissionsFn();
      openToast({
        message: 'Commissions saved successfully for the specified project of the current broker.',
        severity: 'success',
        title: 'Commissions saved successfully',
      });
    } else {
      const error = data as IServerError;
      handleApiErrors({...error, title: (error.title as string) || "Commission wasn't saved successfully. Contact tech team." }, openToast)
    }
  };

  useEffect(() => {
      getUserCommissionsFn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Commissions
      commissions={commissions as ICommissions}
      userName={userName}
      onReqHandleCommission={setCommissionRateFn}
      onReqHandleCommissionPerProject={setCommisionPerProjectFn}
    />
  );
};

export { CommissionsPresenter };
