import { FC, useEffect, useState } from 'react';

import { getReferrerAssociatedIds } from '../../api/brokers/brokers';
import { IReferrersResponse } from '../../api/brokers/brokers.d';
import { getInvestors } from '../../api/investors';
import { IInvestorResponse } from '../../api/investors/investors.d';
import { IInvestor } from '../../components/PageContent';
import { isRequestError } from '../../helpers/isRequestError';
import { useAbortRequest } from '../../hooks/useAbortRequest'; // eslint-disable-line
import { IFilterParams } from '.'; //eslint-disable-line
import InvestorsContext, { IInvestorsContextValue } from './context';
import { Investors } from './Investors'; //eslint-disable-line

export const InvestorsPresenter: FC = () => {
  const [investors, setInvestors] = useState<IInvestor[]>([]);
  const getInvestorsController = useAbortRequest();
  const getReferrerAssociatedIdController = useAbortRequest();
  const [lastPage, setLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    limit: 10,
    page: 1,
    sortBy: 'date:DESC',
  });

  const value: IInvestorsContextValue = {
    filterParams,
    investors,
    isLoading,
    lastPage,
    setFilterParams,
    setInvestors,
  };

  const reqInvestors = async () => {
    setIsLoading(true)
    const { data, status } = await getInvestors(
      { ...filterParams, page: filterParams.page },
      getInvestorsController.signal
    );
  
    if (!isRequestError(status)) {
      const res = data as IInvestorResponse;
      if (res.investors.length) {
        const referrerAssociatedIds = await reqReferrerAssociatedId(res.investors.map(inv => inv.id))
        const finalInvestors = res.investors.map(inv => {
          const matchReferrer = referrerAssociatedIds?.find(referrer => referrer.investorIds.includes(inv.id));
          return {
            ...inv,
            referralCode: matchReferrer ? `${matchReferrer?.referralCode},${matchReferrer?.brokerId}` : ''
          }
        })
        setInvestors(finalInvestors);
      } else {
        setInvestors(res.investors);
      }
      setLastPage(res.lastPage);
    }
    setIsLoading(false);
  };

  const reqReferrerAssociatedId = async (investorsIds: string[]) => {
    const { data, status } = await getReferrerAssociatedIds(
      investorsIds,
      getReferrerAssociatedIdController.signal
    );
  
    if (!isRequestError(status)) {
      return data as IReferrersResponse[];
    }
  };

  useEffect(() => {
    reqInvestors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInvestorsController.signal, filterParams]);

  return (
    <InvestorsContext.Provider value={value}>
      <Investors />
    </InvestorsContext.Provider>
  );
};
