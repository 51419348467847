import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';

import {
  faBrokersAreas,
  getDefaultCommission,
  getUserCommissions,
  ICommissionsValue,
} from '../../../api/brokers';
import { getInvestmentsList } from '../../../api/brokers/brokers';
import { isRequestError } from '../../../helpers/isRequestError';
import { IServerError } from '../../../helpers/reportError';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { toastContext } from '../../../hooks/useToast';
// eslint-disable-next-line import/order
import {
  ICommissions,
  IFABrokerInvestments,
  IInvestmentsPresenter,
} from '../FABrokers.d';
import { Investments } from './Investments';

const InvestmentsPresenter: FC<IInvestmentsPresenter> = ({
  role,
}) => {
  const { id: userId } = useParams();
  const getUserCommissionsController = useAbortRequest();
  const getInvestmentsListController = useAbortRequest();
  const [investments, setInvestments] = useState<IFABrokerInvestments[]>([]);
  const [commissions, setCommissions] = useState<ICommissions>();
  const { openToast } = useContext(toastContext);

  const reqGetUserCommissions = useCallback(async () => {
    const { data, status } = await getUserCommissions(
      userId as string,
      getUserCommissionsController.signal
    );
    if (!isRequestError(status)) {
      setCommissions(data as ICommissions);
      if ((data as ICommissions).commissionPercent === undefined && role) {
        const { data: defaultCommission, status: statusCode } = await getDefaultCommission();
        if (!isRequestError(statusCode)) {
          const res = defaultCommission as ICommissionsValue;
          const updatedComissions = {
            commissionPercent: res[role as keyof ICommissionsValue],
          };
          setCommissions(updatedComissions);
        }
      }
    } else {
      const error = data as IServerError;
      openToast({
        message: error.message,
        severity: 'error',
        title: (error.title as string) || 'Something went wrong',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserCommissionsController.signal, role, userId]);

  const reqInvesmentsList = useCallback(
    async (page: number) => {
      const { data, status } = await trackPromise(
        getInvestmentsList(userId as string, page, getInvestmentsListController.signal),
        faBrokersAreas.getInvestmentsList
      );
      if (!isRequestError(status)) {
        setInvestments(data as IFABrokerInvestments[]);
      }
    },
    [getInvestmentsListController.signal, userId]
  );

  const onPageChange = (newPage: number) => reqInvesmentsList(newPage);

  useEffect(() => {
    if (role) {
      reqGetUserCommissions();
      reqInvesmentsList(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <Investments
      commissions={commissions as ICommissions}
      investments={investments}
      onPageChange={onPageChange}
    />
  );
};

export { InvestmentsPresenter };
