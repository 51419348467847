/* eslint-disable sort-keys */
import {
  Box,
  Button,
  Link,
  List,
  Stack,
  SvgIcon,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { IconEye } from '@tabler/icons';
import { format } from 'date-fns';
import { FC, useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { areas as areasBroker } from '../../api/brokers/areas';
import { getInvestorsInfo } from '../../api/investors';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { EmptySearch } from '../../components/EmptyStates';
import { MobileTableCard } from '../../components/MobileTableCard';
import { OverlayLoader } from '../../components/OverlayLoader';
import { PageHeader } from '../../components/PageHeader';
import { Paginator } from '../../components/Paginator';
import { StatusPointer } from '../../components/ProjectInvestors';
import { Table } from '../../components/Table'; //eslint-disable-line
import { SUMSUB_STATUS, SUMSUB_URL } from '../../helpers/constants';
import { IObjGeneric } from '../../helpers/csvExport'; //eslint-disable-line
import { useAbortRequest } from '../../hooks/useAbortRequest'; // eslint-disable-line
import { ReactComponent as SumSubLogo } from './../../assets/img/sumsub_logo.svg';
import InvestorsContext from './context';
import { IFilterForm } from './Investors.d';
import { TableHeader } from './tableHeader';

export const Investors: FC = () => {
  const nav = useNavigate();
  const [search, setSearch] = useState('');
  const getInvestorsInfoController = useAbortRequest();
  const { investors, isLoading, filterParams, setFilterParams, lastPage } =
    useContext(InvestorsContext);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { touchedFields },
  } = useForm<IFilterForm>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const goInvestorDetail = useCallback((id: string) => () => nav(id), [nav]);
  const statusFilter = watch('status');
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 2,
    },
    {
      field: 'legalName',
      headerName: 'Legal Name',
      minWidth: 150,
      sortable: false,
      flex: 2,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 150,
      sortable: false,
      flex: 2,
    },
    {
      field: 'date',
      headerName: 'Creation date',
      minWidth: 150,
      valueFormatter: ({ value }) => value && format(new Date(value), 'MM/dd/yyyy'),
      flex: 1,
    },
    {
      field: 'isEnabled',
      headerName: 'Status',
      minWidth: 60,
      renderCell: ({ value }) => (
        <Box display="flex" alignItems="center" gap={1}>
          <StatusPointer status={value ? 'success' : 'default'} />
          <Typography noWrap>{value ? 'Active' : 'Inactive'}</Typography>
        </Box>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'Location',
      minWidth: 60,
      flex: 1,
    },
    {
      field: 'referralCode',
      headerName: 'Referral Code',
      minWidth: 60,
      sortable: false,
      flex: 1,
      renderCell: ({ value }) =>
        value && (
          <Link
            component={RouterLink}
            sx={{
              '&:hover': { textDecoration: 'underline' },
              cursor: 'pointer',
              display: 'flex',
              textDecoration: 'none',
            }}
            to={'/brokers-and-financial-advisors/broker/' + value.split(',')[1]}
          >
            <Typography>{value.split(',')[0]}</Typography>
          </Link>
        ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="View Details">
            <Button
              onClick={goInvestorDetail(params.row.id)}
              size="small"
              variant="text"
              color="secondary"
              sx={{
                '> span': {
                  margin: '0 1rem',
                },
                minWidth: '24px',
              }}
              startIcon={<IconEye />}
            ></Button>
          </Tooltip>
          <Tooltip
            title={
              params.row.sumsubStatus === SUMSUB_STATUS.NOT_INITIATED
                ? 'Not initiated'
                : 'Go to Sumsub'
            }
          >
            <Button
              onClick={() => window.open(SUMSUB_URL + params.row.applicantId)}
              size="small"
              variant="text"
              color="secondary"
              disabled={params.row.sumsubStatus === SUMSUB_STATUS.NOT_INITIATED}
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
                '> span': {
                  marginRight: '0 1rem',
                },
                minWidth: '24px',
              }}
              startIcon={
                <SumSubLogo
                  width={24}
                  fillOpacity={params.row.sumsubStatus === SUMSUB_STATUS.NOT_INITIATED ? 0.3 : 1}
                />
              }
            ></Button>
          </Tooltip>
        </>
      ),
      flex: 1,
    },
  ];

  const rows = investors.map((investor) => ({
    actions: 'asdfasd',
    date: investor.createdDate,
    email: investor.email,
    id: investor.id,
    legalName: investor.legalName,
    location: investor.location,
    name: `${investor.firstName} ${investor.lastName}`,
    isEnabled: investor.isEnabled,
    referralCode: investor.referralCode,
    applicantId: investor.sumsub?.applicantId,
    sumsubStatus: investor.sumsub?.status,
  }));

  const mobileRows = useCallback(
    () =>
      investors.map((investor) => ({
        action: (
          <Stack direction="row" justifyContent="center">
            <Button
              fullWidth
              color="secondary"
              size="small"
              sx={{
                width: '50%',
              }}
              onClick={goInvestorDetail(investor.id)}
              startIcon={
                <SvgIcon>
                  <IconEye />
                </SvgIcon>
              }
            >
              View details
            </Button>
            <Button
              onClick={() => window.open(SUMSUB_URL + investor.sumsub?.applicantId)}
              size="small"
              variant="text"
              color="secondary"
              disabled={investor.sumsub?.status === SUMSUB_STATUS.NOT_INITIATED}
              sx={{
                width: '50%',
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
              }}
              startIcon={
                <SumSubLogo
                  width={24}
                  fillOpacity={investor.sumsub?.status === SUMSUB_STATUS.NOT_INITIATED ? 0.3 : 1}
                />
              }
            >
              Go to Sumsub
            </Button>
          </Stack>
        ),
        data: {
          Name: `${investor.firstName} ${investor.lastName}`,
          'Legal Name': investor.legalName,
          Email: investor.email,
          'Creation date':
            investor.createdDate && format(new Date(investor.createdDate), 'MM/dd/yyyy'),
          Status: (
            <Box display="flex" alignItems="center" gap={1}>
              <StatusPointer status={investor.isEnabled ? 'success' : 'default'} />
              {investor.isEnabled ? 'Active' : 'Inactive'}
            </Box>
          ),
          Location: investor.location,
        },
        id: investor.id,
      })),
    [investors, goInvestorDetail]
  );

  const onFilter = (data: IFilterForm) => {
    const params = { ...filterParams, page: 1 };
    const filter = data.status === 'active' ? true : false;
    params.filterBy = search ? `isEnabled:${filter},name:${search}` : `isEnabled:${filter}`;
    setFilterParams(params);
  };

  const onClearFilter = () => {
    if (!touchedFields?.status) {
      return;
    }
    reset({ status: '' });
    const params = { ...filterParams, page: 1 };
    if (search) {
      params.filterBy = `name:${search}`;
      return setFilterParams(params);
    }
    delete params.filterBy;
    setFilterParams(params);
  };

  const onSort = (sortModel: GridSortModel) => {
    const model = sortModel[0];
    const params = { ...filterParams, page: 1 };
    if (model) {
      params.sortBy = `${model.field}:${model.sort?.toUpperCase()}`;
      return setFilterParams(params);
    }
    delete params.sortBy;
    setFilterParams(params);
  };

  const onSearch = (value: string) => {
    const params = { ...filterParams, page: 1 };
    setSearch(value);
    if (value) {
      params.filterBy =
        params.filterBy && !params.filterBy.includes('name')
          ? `${params.filterBy},name:${value}`
          : `name:${value}`;
      return setFilterParams(params);
    }
    if (statusFilter) {
      params.filterBy = `isEnabled:${statusFilter === 'active' ? true : false}`;
      return setFilterParams(params);
    }
    delete params.filterBy;
    setFilterParams(params);
  };

  const onPageChange = (newPage: number) => {
    const params = { ...filterParams, page: newPage };
    setFilterParams(params);
  };

  const investorData = async (): Promise<IObjGeneric[]> => {
    const data: IObjGeneric[] = [];

    const investorsInfo = await getInvestorsInfo(getInvestorsInfoController.signal);

    if (Array.isArray(investorsInfo.data)) {
      investorsInfo.data.map((investor) =>
        data.push({
          Country: investor.country || '',
          Email: investor.email || '',
          'First Name': investor.firstName || '',
          'Last Name': investor.lastName || '',
          'Legal Name': investor.legalName || '',
          Phone: investor.phone || '',
          'Sumsub Status': investor.sumsub?.status || '',
          'Wallet Status': investor.walletStatus || '',
          'Tax Residence Country': investor.taxResidenceCountry || '',
        })
      );
    }
    return data;
  };

  return (
    <>
      <Breadcrumbs current="Investors" linksList={[{ label: 'Home', path: '/' }]} />
      <PageHeader investors />
      {!isMobile && (
        <Table
          sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
          rows={rows}
          columns={columns}
          paginationMode="server"
          sortingMode="server"
          pageSize={10}
          rowCount={10000000}
          rowsPerPageOptions={[10]}
          isLoading={isLoading}
          area={areasBroker.getReferrerAssociatedIds}
          page={filterParams.page}
          onSortModelChange={onSort}
          onPageChange={onPageChange}
          pagination
          components={{
            Toolbar: TableHeader,
            NoResultsOverlay: EmptySearch,
          }}
          componentsProps={{
            toolbar: {
              onFilter: handleSubmit(onFilter),
              onSearch,
              onSort,
              control,
              onClearFilter,
              asyncDownloadButton: investorData,
            },
            pagination: {
              backIconButtonProps: { disabled: filterParams.page === 1 ? true : false },
              nextIconButtonProps: { disabled: lastPage },
            },
          }}
        />
      )}
      {isMobile && (
        <>
          <TableHeader
            onClearFilter={onClearFilter}
            onFilter={handleSubmit(onFilter)}
            onSearch={onSearch}
            onSort={onSort}
            control={control}
            asyncDownloadButton={investorData}
          />
          {isLoading && (
            <Box
              sx={{
                position: 'relative',
                border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                borderRadius: 2,
                minHeight: 300,
              }}
            >
              <OverlayLoader theme="container" area={areasBroker.getReferrerAssociatedIds} />
            </Box>
          )}
          {!isLoading && (
            <List>
              {mobileRows().map((row) => (
                <MobileTableCard key={row.id} data={row.data} action={row.action} />
              ))}
            </List>
          )}
          {!isLoading && investors.length === 0 && <EmptySearch />}
          <Paginator
            sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
            setPage={onPageChange}
            limit={10}
            count={10000000}
            page={filterParams?.page ?? 1}
            backIconButtonProps={{ disabled: filterParams.page === 1 ? true : false }}
            nextIconButtonProps={{ disabled: lastPage }}
          />
        </>
      )}
    </>
  );
};
