import { format } from 'date-fns';

import { IInvestor } from '../../components/PageContent';
import { formatToken } from '../../helpers'; // eslint-disable-line
import { IReportInvestor, IReportInvestorResponse } from './payouts.d';

const decoratePaymentStatus = (investor: IReportInvestorResponse): string => {
  if (!investor.payoutAccount && investor.paymentStatus === 'PENDING') {
    return process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true' ? 'pending' : 'not_payable';
  }
  if (investor.walletStatus === 'BLACKLISTED' && investor.paymentStatus === 'PENDING') {
    return 'blacklisted';
  }
  return investor.paymentStatus.toLowerCase();
};

const decoratePayoutsReportInvestor = (
  data: IReportInvestorResponse[],
  users: IInvestor[]
): IReportInvestor[] => {
  return data.map((investor, i) => ({
    id: investor.userId,
    name: users[i].legalName,
    payment_date: investor.dateOfPayment
      ? format(new Date(investor.dateOfPayment), 'MM/dd/yyyy')
      : '',
    payment_method: (investor.paymentMethod ?? '').toLowerCase(),
    payout_account: investor.payoutAccount ? 'Yes' : 'No',
    status: decoratePaymentStatus(investor),
    tokens: formatToken(investor.tokens),
    total: investor.amountToBePaid,
  }));
};

export { decoratePayoutsReportInvestor };
