import Joi from 'joi';

const createProjectSchema = Joi.object({
  closingDate: Joi.date().allow('').greater('now'),
  investmentPeriod: Joi.string().optional().allow(''),
  isUs: Joi.boolean().required(),
  projectTitle: Joi.string().required().messages({
    'string.empty': 'Project title is required',
  }),
  projectType: Joi.string().required().messages({
    'string.empty': 'Project type is required',
  }),
});

export { createProjectSchema };
