import { responseChecker } from "../../helpers/responseChecker"; 
import { IMessage } from "../main"; // eslint-disable-line
import { IAuth } from "./session.d";

export const login = ( data: IAuth ): IAuth => {
  responseChecker<IAuth>(data, ['expiresIn', 'accessToken', 'idToken', 'refreshToken', 'tokenType']);
  const { expiresIn, accessToken, idToken, refreshToken, tokenType } = data
  return {
    accessToken,
    expiresIn,
    idToken,
    refreshToken,
    tokenType,
  }
}

export const logout = ( data: IMessage ): IMessage => {
  responseChecker<IMessage>(data, ['message']);
  return { message: data.message }
}