import { Box, Button, Paper, Stack, SvgIcon, Typography } from '@mui/material';
import { IconAlertTriangle, IconArrowLeft, IconCircleCheck } from '@tabler/icons';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { projectAreas } from '../../api/projects';
import { sessionAreas } from '../../api/session';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { InputField } from '../../components/InputField'; //eslint-disable-line
import { ICompleteProjectProps } from './CompleteProject.d';

const CompleteProject: FC<ICompleteProjectProps> = ({
  register,
  errors,
  validatePassword,
  isAValidUser,
  completeProject,
  goProjectDetail,
}) => {
  const { id } = useParams();
  return (
    <>
      <Button
        color="secondary"
        onClick={goProjectDetail}
        startIcon={<IconArrowLeft />}
        sx={{ mb: 4 }}
      >
        Back to project
      </Button>
      <Breadcrumbs
        linksList={[
          { label: 'Home', path: '/' },
          { label: 'Projects', path: '/projects' },
          { label: 'Project detail', path: `/projects/${id}` },
        ]}
        current="Project complete project"
      />
      <Paper
        component="form"
        onSubmit={completeProject}
        elevation={0}
        sx={({ palette }) => ({
          border: `1px solid ${palette.grey[200]}`,
          borderRadius: 2,
          mt: { xs: 4, lg: 7 }, //eslint-disable-line
          mx: { xs: -3, md: 0 }, //eslint-disable-line
          px: { xs: 3, sm: 4, lg: 15 }, //eslint-disable-line
          py: { xs: 4, lg: 7 }, //eslint-disable-line
        })}
      >
        <Stack direction="row" alignItems="center" mb={4}>
          <SvgIcon color="secondary" sx={{ mr: 1 }}>
            <IconAlertTriangle />
          </SvgIcon>
          <Typography variant="h1">Complete projects</Typography>
        </Stack>
        <Typography>
          Are you sure you want to change this project to completed?
          <br />
          <br />
          The smart contract will be closed and the tokens will no longer be available, you will not
          be able to edit some information for this project nor create new payout reports.
          <br />
          <br />
          To confirm this process, enter and validate your password, then enter the temporary code
          shown in your Authenticator app:
        </Typography>
        <Typography
          variant="body1"
          color="text.primary"
          component="h2"
          fontWeight="bold"
          mt={5}
          mb={4}
        >
          To confirm the process, input your user password:
        </Typography>
        <Stack direction="row" alignItems="center" gap={4} flexWrap="wrap">
          <Box
            width={{ xs: '100%', md: '50%' }} //eslint-disable-line
          >
            <InputField
              {...register('password')}
              fullWidth
              type="password"
              label="Password"
              disabled={isAValidUser}
              error={!!errors['password']}
              helperText={errors['password']?.message}
              InputProps={
                isAValidUser
                  ? {
                      endAdornment: (
                        <SvgIcon color="success">
                          <IconCircleCheck />
                        </SvgIcon>
                      ),
                    }
                  : null
              }
            />
          </Box>
          {!isAValidUser && (
            <ButtonSubmit
              area={sessionAreas.login}
              type="button"
              onClick={validatePassword}
              variant="contained"
              fullWidth={false}
            >
              Validate
            </ButtonSubmit>
          )}
        </Stack>
        <Typography
          variant="body1"
          color="text.primary"
          component="h2"
          fontWeight="bold"
          mt={5}
          mb={4}
        >
          Enter the Authenticator temporary code:
        </Typography>
        <Box
          width={{ xs: '100%', md: '50%' }} //eslint-disable-line
        >
          <InputField
            {...register('code')}
            fullWidth
            disabled={!isAValidUser}
            label="Code"
            error={!!errors['code']}
            helperText={errors['code']?.message}
          />
        </Box>
        {isAValidUser && (
          <Stack direction="row" mt={5} gap={5} justifyContent="flex-end">
            <Button onClick={goProjectDetail} type="button">
              Cancel
            </Button>
            <ButtonSubmit fullWidth={false} area={projectAreas.completeProject}>
              Confirm
            </ButtonSubmit>
          </Stack>
        )}
      </Paper>
    </>
  );
};

export { CompleteProject };
