import { Box, TablePagination } from '@mui/material';
import { FC } from 'react';

import { Gray } from '../../theme/tokens/colors'; //eslint-disable-line
import { IPaginatorProps } from './Paginator.d';

export const Paginator: FC<IPaginatorProps> = ({
  count = 0,
  limit = 0,
  page = 1,
  setPage,
  sx,
  ...rest
}) => {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  return (
    <>
      <Box display={{ sm: 'none' }} height="55px"></Box>
      <Box
        position={{ sm: 'static', xs: 'fixed' }}
        bottom={0}
        left={0}
        width="100%"
        sx={{
          backgroundColor: '#fff !important',
          border: { sm: 0, xs: 1 },
          borderColor: { sm: 'transparent', xs: Gray[300] },
          borderRadius: { sm: 2, xs: 0 },
          zIndex: 'tooltip',
        }}
      >
        <TablePagination
          {...rest}
          sx={sx}
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
        />
      </Box>
    </>
  );
};
