import { Box, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { Gray, Primary } from '../../theme/tokens/colors'; //eslint-disable-line
import { IBarChartProps } from './BarChart.d';

export const BarChart: FC<IBarChartProps> = ({ data1, data2 }) => {
  const calcBar1Width = useMemo(() => (data1 >= 100 ? 100 : data1), [data1]);
  const calcBar2Width = useMemo(() => (data1 + data2 >= 100 ? 100 : data1 + data2), [data1, data2]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        sx={{ marginBottom: 2, position: 'relative', width: '100%' }}
      >
        <Box
          sx={{
            backgroundColor: Primary[300],
            borderRadius: 1,
            height: '20px',
            left: 0,
            position: 'absolute',
            top: 0,
            width: `${calcBar1Width}%`,
            zIndex: 3,
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: Primary[800],
            borderRadius: 1,
            height: '20px',
            left: 0,
            position: 'absolute',
            top: 0,
            width: `${calcBar2Width}%`,
            zIndex: 2,
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: Gray[300],
            borderRadius: 1,
            height: '20px',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        ></Box>
      </Stack>
      <Stack direction="row" justifyContent="space-evenly" sx={{ width: '100%' }}>
        <Box
          sx={{
            height: '20px',
            minWidth: 20,
            textAlign: 'right',
            width: `${calcBar1Width}%`,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: Primary[300],
            }}
          >
            <strong>{data1}%</strong>
          </Typography>
        </Box>
        <Box
          sx={{
            height: '20px',
            marginLeft: 0.5,
            minWidth: 20,
            textAlign: 'right',
            width: `${calcBar2Width - calcBar1Width}%`,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: Primary[800],
            }}
          >
            <strong>{data2}%</strong>
          </Typography>
        </Box>
        <Box
          sx={{
            height: '20px',
            textAlign: 'right',
            width: `${100 - calcBar2Width - calcBar1Width}%`,
          }}
        ></Box>
      </Stack>
    </>
  );
};
