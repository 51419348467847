/* eslint-disable import/order */
import { ReactElement, useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { logout } from '../api/session';
import { Layout } from '../components/Layout';
import { OverlayLoader } from '../components/OverlayLoader';
import { authContext } from '../hooks/useAuth/authContext';
import {
  CompleteProject,
  CreatePayoutsReportPresenter,
  CreateTokensPresenter,
  ErrorPage,
  ExpiredPresenter,
  FABrokerDetailPresenter,
  HomePresenter,
  InvestmentsPresenter,
  InvestorDetailPresenter,
  InvestorsPresenter,
  LoginPresenter,
  NotFound,
  OnboardingPresenter,
  PayoutReportDetailPresenter,
  ProjectDetailPresenter,
  ProjectsPresenter,
  RecoverPasswordPresenter,
  SessionExpired,
  TwoFAPresenter,
  UsersPresenter,
} from '../pages';
import { BrokersAndFAPresenter } from '../pages/BrokersAndFA/BrokersAndFA.presenter';
import { ConfirmTokens, ReviewInvestments, SetTokens } from '../pages/CreateTokens';
import { BasicPresenter } from '../pages/ProjectDetail/Basic';
import { CommissionsPresenter } from '../pages/ProjectDetail/Commissions';
import { EditDataPresenter } from '../pages/ProjectDetail/EditData';
import { FinancialPresenter } from '../pages/ProjectDetail/Financial';
import { LegalPresenter } from '../pages/ProjectDetail/Legal';
import { PayoutsPresenter } from '../pages/ProjectDetail/Payouts';
import { ReportPresenter } from '../pages/ProjectDetail/Report';
import { TokensPresenter } from '../pages/ProjectDetail/Tokens';
import { RefundsPresenter } from '../pages/Refunds';
import { LoggedRestricted, PrivateRoute } from './PrivateRoute';
import { ScrollToTop } from './scrollToTop';

const App = (): ReactElement => {
  const auth = useContext(authContext);
  const isAuth = auth?.auth?.accessToken;

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout isAuth={Boolean(isAuth)} toLogout={logout} />}>
            <Route index element={<PrivateRoute component={HomePresenter} />} />
            <Route
              path="users"
              element={
                <PrivateRoute
                  component={UsersPresenter}
                  justForRoles={['systemAdmin', 'compliance', 'superAdmin']}
                />
              }
            />
            <Route
              path="brokers-and-financial-advisors"
              element={
                <PrivateRoute
                  component={BrokersAndFAPresenter}
                  justForRoles={['superAdmin', 'financial', 'legal', 'marketing']}
                />
              }
            />
            { process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'false' &&
              <Route
              path="refunds"
              element={
                <PrivateRoute
                  component={RefundsPresenter}
                  justForRoles={['superAdmin', 'financial', 'marketing']}
                />
              }
            />
            }
            <Route
              path="investors"
              element={
                <PrivateRoute
                  component={InvestorsPresenter}
                  justForRoles={['legal', 'compliance', 'superAdmin', 'financial', 'marketing']}
                />
              }
            />
            <Route
              path="investors/:userId"
              element={
                <PrivateRoute
                  component={InvestorDetailPresenter}
                  justForRoles={['legal', 'compliance', 'superAdmin', 'financial', 'marketing']}
                />
              }
            />
            <Route
              path="investments"
              element={
                <PrivateRoute
                  component={InvestmentsPresenter}
                  justForRoles={['superAdmin', 'financial']}
                />
              }
            />
            <Route path="login" element={<LoggedRestricted component={LoginPresenter} />} />
            <Route path="twofactor" element={<LoggedRestricted component={TwoFAPresenter} />} />
            <Route
              path="onboarding"
              element={<LoggedRestricted component={OnboardingPresenter} />}
            />
            <Route path="expired" element={<LoggedRestricted component={ExpiredPresenter} />} />
            <Route path="error" element={<LoggedRestricted component={ErrorPage} />} />
            <Route path="projects" element={<PrivateRoute component={ProjectsPresenter} />} />
            <Route
              path="projects/:id"
              element={<PrivateRoute component={ProjectDetailPresenter} />}
            >
              <Route
                path="commissions"
                element={
                  <PrivateRoute
                    component={CommissionsPresenter}
                    justForRoles={['superAdmin', 'financial', 'legal', 'marketing']}
                  />
                }
              />
              <Route path="payouts" element={<PayoutsPresenter />} />
              <Route path="tokens" element={<TokensPresenter />} />
              <Route path="report" element={<ReportPresenter />} />
              <Route path="edit" element={<EditDataPresenter />}>
                <Route index element={<BasicPresenter />} />
                <Route path="financial" element={<FinancialPresenter />} />
                <Route path="legal" element={<LegalPresenter />} />
              </Route>
            </Route>
            <Route
              path="recover-password"
              element={<LoggedRestricted component={RecoverPasswordPresenter} />}
            />
            <Route
              path="projects/:id/complete-project"
              element={<PrivateRoute component={CompleteProject} />}
            />
            <Route
              path="projects/:id/create-tokens"
              element={<PrivateRoute component={CreateTokensPresenter} />}
            >
              <Route index element={<ReviewInvestments />} />
              <Route path="set-tokens" element={<SetTokens />} />
              <Route path="confirm" element={<ConfirmTokens />} />
            </Route>
            <Route
              path="recover-password"
              element={<LoggedRestricted component={RecoverPasswordPresenter} />}
            />
            <Route path="not-found" element={<PrivateRoute component={NotFound} />} />
            <Route
              path="projects/:id/payouts-report/create"
              element={
                <PrivateRoute
                  component={CreatePayoutsReportPresenter}
                  justForRoles={['superAdmin', 'financial']}
                />
              }
            />
            <Route
              path="projects/:id/payouts-report/:reportId"
              element={
                <PrivateRoute
                  component={PayoutReportDetailPresenter}
                  justForRoles={['superAdmin', 'financial', 'marketing']}
                />
              }
            />
            <Route
              path="brokers-and-financial-advisors/broker/:id"
              element={
                <PrivateRoute
                  component={FABrokerDetailPresenter}
                  justForRoles={['superAdmin', 'financial', 'legal', 'marketing']}
                />
              }
            />
            <Route
              path="brokers-and-financial-advisors/financial-advisor/:id"
              element={
                <PrivateRoute
                  component={FABrokerDetailPresenter}
                  justForRoles={['superAdmin', 'financial', 'legal', 'marketing']}
                />
              }
            />
            <Route path="session-expired" element={<SessionExpired />} />
            <Route path="*" element={<PrivateRoute component={NotFound} />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <OverlayLoader />
    </>
  );
};

export { App };
