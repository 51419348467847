import { Button, IconButton,Typography,useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import { IconLogout } from '@tabler/icons';
import { FC } from 'react';

import { ILogoutButton } from './LogoutButton.d';

export const LogoutButton: FC<ILogoutButton> = ( { onClick }) => {
  const theme = useTheme();
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      {
        breakpointLg
          ? (
            <Button variant="text" startIcon={<IconLogout color="#fff" />} onClick={ onClick }>
              <Typography variant="body1" color="#fff" component="span" fontWeight={300}>
                Logout
              </Typography>
            </Button>
          )
          : (
            <IconButton onClick={ onClick }>
              <IconLogout color="#fff" />
            </IconButton>
          )
      }
    </>
  );
};
