import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { IconUserCircle } from '@tabler/icons';
import { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { areas } from '../../api/brokers/areas';
import { connectInvestor } from '../../api/brokers/brokers';
import { havePermission } from '../../helpers/havePermission';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { authContext } from '../../hooks/useAuth/authContext';
import { toastContext } from '../../hooks/useToast';
import { ButtonSubmit } from '../ButtonSubmit';
// eslint-disable-next-line import/order
import { InputField } from '../InputField';
import { IAddReferral, IAddReferralForm } from './AddReferral.d';
import { addReferralSchema } from './AddReferral.schema';

export const AddReferral: FC<IAddReferral> = ({ investorId }) => {
  const { openToast } = useContext(toastContext);
  const { userData } = useContext(authContext);
  const financialAndMarketingRole = havePermission(userData?.roles ?? [], [
    'financial',
    'marketing',
    'superAdmin',
  ]);
  const addReferralController = useAbortRequest();

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const horizontalPosition = isTablet ? 'right' : 'center';
  const width = isTablet ? 467 : 448;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<IAddReferralForm>({
    defaultValues: { referral: '' },
    mode: 'onChange',
    resolver: joiResolver(addReferralSchema),
    shouldFocusError: true,
  });

  const onSubmit = async (data: IAddReferralForm) => {
    const res = await connectInvestor(
      {
        investorId: investorId,
        referralCode: data.referral,
      },
      addReferralController.signal
    );
    let message = 'Association cannot be completed. Contact support team for more info.';

    if (res.data === 'BlacklistedBrokerException') {
      message =
        'The broker is blacklisted. Association cannot be completed.';
    } else if (res.data === 'InvalidAssociation') {
      message = 'The user is already linked to a broker';
    } else if (res.status === 204) {
      message = 'Association successfully completed.';
    }

    openToast({
      horizontalPosition,
      message: message,
      severity: res.status !== 204 ? 'error' : 'success',
      title: 'Investor Association',
      width,
    });
  };

  return financialAndMarketingRole ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container display="flex" height='84px'>
        <Grid item xs={3}>
          <InputField
            label="Referral"
            inputProps={{
              ...register('referral'),
              'data-testid': 'referral',
              maxLength: '8',
            }}
            helperText={errors.referral?.message}
            error={!!errors.referral}
            fullWidth
          />
        </Grid>
        <Box position="relative" ml={2} mt={1} display="flex">
          <ButtonSubmit
            sx={{ height: 40 }}
            startIcon={<IconUserCircle />}
            data-testid="test-main-action-button"
            area={areas.connectInvestor}
            disabled={!isValid || !isDirty}
          >
            Link with Broker
          </ButtonSubmit>
        </Box>
      </Grid>
    </form>
  ) : null;
};
