import { joiResolver } from '@hookform/resolvers/joi';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { createPassword } from '../../api/onBoarding';
import { IPasswordRule } from '../../components/PasswordRules';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { toastContext } from '../../hooks/useToast';
import { ILoginFormFields } from '../Login'; //eslint-disable-line
import { ICreatePasswordReq, IPasswordForm } from '.'; //eslint-disable-line
import { Onboarding } from './Onboarding';
import { onboardingSchema, passwordRules } from './schema';

const OnboardingPresenter: FC = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { state } = useLocation();
  const navState = state as ILoginFormFields;
  const controller = useAbortRequest();
  const { openToast, closeToast } = useContext(toastContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    watch,
  } = useForm<IPasswordForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(onboardingSchema),
    shouldFocusError: true,
  });
  const passwordValue = watch('password');

  const [rules, setRules] = useState<IPasswordRule[]>(passwordRules);
  const [showRules, setShowRules] = useState<boolean>(false);
  const toggleRules = () => setShowRules(!showRules);

  const changePassword = async (newPassword: IPasswordForm) => {
    closeToast();
    const req: ICreatePasswordReq = {
      email: navState.email ?? '',
      newPassword: newPassword.password,
      password: navState.password ?? '',
    };
    const { data, status } = await createPassword(req, controller.signal);
    if (isRequestError(status)) {
      const error = data as IServerError;
      openToast({
        message: error.message,
        severity: 'error',
        title: 'Something went wrong',
      });
      return;
    }
    nav('/login', {
      state: {
        toast: {
          message: 'Password sucessfully created!',
          severity: 'success',
        },
      },
    });
  };
  const submitPassword = handleSubmit(changePassword);

  const validateRule = useCallback(
    (rule: IPasswordRule, value: string) => {
      const updatedRule = { ...rule };
      if (!value && !touchedFields.password) {
        updatedRule.valid = undefined;
        return updatedRule;
      }
      updatedRule.valid = updatedRule.rule.test(value);
      return updatedRule;
    },
    [touchedFields.password]
  );

  useEffect(() => {
    setRules((currentRules) => currentRules.map((rule) => validateRule(rule, passwordValue)));
  }, [passwordValue, validateRule]);

  return (
    <Onboarding
      passwordRules={rules}
      register={register}
      submitPassword={submitPassword}
      errors={errors}
      showRules={showRules}
      toggleRules={toggleRules}
      validForm={isValid}
      description={t('onboarding.greeting')}
    />
  );
};

export { OnboardingPresenter };
