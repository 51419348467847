/* eslint-disable sort-keys */
import { createTheme } from '@mui/material/styles';

import { Error, Gray, Interactive, Primary, Success, Warning } from './tokens/colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    caption2: React.CSSProperties;
    display1: React.CSSProperties;
    display2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    caption2?: React.CSSProperties;
    display1?: React.CSSProperties;
    display2?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption2: true;
    display1: true;
    display2: true;
  }
}

const fontHeaders = 'Sora';
const fontText = 'Readex Pro';
export const breakpoints = {
  xs: 320,
  sm: 600,
  md: 905,
  lg: 1240,
  xl: 1440,
};

export const colors = {
  default: Gray[700],
  disable: Gray[500],
  focus: Gray[900],
  primary: Interactive.secondary,
  secondary: Primary[500],
};

export const adminTheme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    background: {
      default: Gray[75],
      paper: Gray[50],
    },
    error: { main: Error[500] },
    grey: Gray,
    primary: {
      main: Interactive.secondary,
    },
    secondary: {
      dark: Primary[800],
      light: Primary[50],
      main: Primary[500],
    },
    success: { main: Success[500] },
    text: {
      disabled: Gray[500],
      primary: Gray[800],
      secondary: Gray[700],
    },
    warning: {
      main: Warning[500],
    },
  },
  shadows: [
    'none',
    '0px 1px 2px 1px rgba(137, 140, 197, 0.08), 0px 2px 10px 1px rgba(137, 140, 197, 0.14), 0px 1px 4px 1px rgba(137, 140, 197, 0.14)',
    '0px 7px 17px 5px rgba(137, 140, 197, 0.18), 0px 6px 12px 1px rgba(137, 140, 197, 0.14)',
    '0px 11px 15px -7px rgba(137, 140, 197, 0.2), 0px 16px 55px 8px rgba(137, 140, 197, 0.18), 0px 4px 24px -3px rgba(137, 140, 197, 0.15)',
    '0px 1px 2px 1px rgba(0, 0, 0, 0.08), 0px 2px 10px 1px rgba(0, 0, 0, 0.14), 0px 1px 4px 1px rgba(0, 0, 0, 0.14);',
    '0px 7px 17px 5px rgba(0, 0, 0, 0.18), 0px 6px 12px 1px rgba(0, 0, 0, 0.14);',
    '0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 16px 55px 8px rgba(0, 0, 0, 0.2), 0px 4px 24px -3px rgba(0, 0, 0, 0.15);',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          height: '56px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [`@media screen and (min-width:${breakpoints.sm}px)`]: {
            minHeight: '56px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: Gray[1000],
        },
        tooltip: {
          backgroundColor: Gray[1000],
          maxWidth: '300px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          borderRadius: '50px',
          boxShadow: 'none',
          fontSize: '1.6rem',
          textTransform: 'none',
          py: '8px',
        },
        sizeSmall: {
          fontSize: '1.2rem',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${Interactive.secondary}`,
            color: Interactive.secondary,
            '&:hover': {
              backgroundColor: Gray[200],
              borderColor: Interactive.secondary,
            },
            '&:disabled': {
              backgroundColor: Gray[50],
              color: Interactive.secondary,
              borderColor: Interactive.secondary,
              opacity: '0.4',
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: Primary[500],
            '&:hover:not(:disabled)': {
              backgroundColor: Primary[600],
            },
            '&:disabled': {
              color: Gray[50],
              backgroundColor: Gray[500],
            },
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          color: Primary[600],
        },
        standardSuccess: {
          color: Success[700],
        },
        standardError: {
          color: Error[600],
        },
      },
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: fontText,
    button: {
      fontSize: '1.6rem',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    body1: {
      fontFamily: fontText,
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      color: Gray[700],
    },
    body2: {
      fontFamily: fontText,
      fontSize: '1.4rem',
      lineHeight: '2rem',
      color: Gray[700],
    },
    caption: {
      color: colors.default,
      fontFamily: fontText,
      fontSize: '1.2rem',
      lineHeight: '2rem',
      fontWeight: 300,
    },
    caption2: {
      fontFamily: fontText,
      fontSize: '1.2rem',
      lineHeight: '2rem',
      fontWeight: 600,
    },
    h1: {
      [`@media screen and (min-width:${breakpoints.lg}px)`]: {
        fontSize: '3.2rem',
        lineHeight: '4.4rem',
      },
      color: colors.primary,
      fontFamily: fontHeaders,
      fontSize: '2.8rem',
      fontWeight: 700,
      lineHeight: '4rem',
    },
    h2: {
      color: colors.primary,
      fontFamily: fontHeaders,
      fontSize: '2.4rem',
      lineHeight: '3.2rem',
    },
    h3: {
      [`@media screen and (min-width:${breakpoints.lg}px)`]: {
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
      },
      color: colors.primary,
      fontFamily: fontHeaders,
      fontSize: '1.8rem',
      fontWeight: 700,
      lineHeight: '2.6rem',
    },
    subtitle1: {
      color: colors.primary,
      fontFamily: fontHeaders,
      fontSize: '2.4rem',
      lineHeight: '3.6rem',
    },
    subtitle2: {
      color: colors.primary,
      fontFamily: fontHeaders,
      fontSize: '2.0rem',
      lineHeight: '3.2rem',
    },
    display1: {
      color: colors.primary,
      fontFamily: fontHeaders,
      fontSize: '4.8rem',
      lineHeight: '6.4rem',
      [`@media screen and (min-width:${breakpoints.sm}px)`]: {
        fontSize: '8rem',
        lineHeight: '10.4rem',
      },
    },
    display2: {
      color: colors.primary,
      fontFamily: fontHeaders,
      fontSize: '3.2rem',
      fontWeight: 400,
      lineHeight: '4.4rem',
      [`@media screen and (min-width:${breakpoints.sm}px)`]: {
        fontSize: '4.8rem',
        lineHeight: '6.4rem',
      },
    },
  },
});
