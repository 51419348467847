import { Box, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { formatDollars } from '../../helpers/quantityBeautifier'; // eslint-disable-line
import { Gray, Interactive } from '../../theme/tokens/colors';
import { IMetricsProps } from '../CreateProjectSteps';

const FundingStatus: FC<IMetricsProps> = ({ metrics }) => {
  const [isTotalMoneyRaisedLong, setIsTotalMoneyRaisedLong] = useState(false);

  useEffect(() => {
    setIsTotalMoneyRaisedLong(metrics && metrics?.totalMoneyRaised >= 1000000000 ? true : false);
  }, [metrics]);

  return (
    <>
      <Box height={172} width={338} data-testid="funding-status">
        <Typography variant="h3" color={Interactive.secondary}>
          Funding status:
        </Typography>
        <Stack
          direction="row"
          sx={{
            marginBottom: 2,
            marginTop: 3,
            width: '100%',
          }}
        >
          <Box
            sx={{
              height: '20px',
              textAlign: 'left',
              width: '40%',
            }}
          >
            <Typography variant="body2" component="span">
              Money received:
            </Typography>
          </Box>
          <Box
            sx={{
              height: '20px',
              textAlign: 'right',
              width: '45%',
            }}
          >
            <Typography variant="body2" component="span" data-testid="money-received">
              <strong>{formatDollars(metrics?.receivedInvestment ?? 0)}</strong>
            </Typography>
          </Box>
          <Box
            sx={{
              height: '20px',
              textAlign: 'right',
              width: '15%',
            }}
          >
            <Typography variant="body2" component="span" data-testid="money-received-percentage">
              <strong>{metrics?.receivedPercentageRaised}%</strong>
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" sx={{ marginBottom: 2, width: '100%' }}>
          <Box
            sx={{
              height: '20px',
              textAlign: 'left',
              width: '40%',
            }}
          >
            <Typography variant="body2" component="span">
              Money committed:
            </Typography>
          </Box>
          <Box
            sx={{
              height: '20px',
              textAlign: 'right',
              width: '45%',
            }}
          >
            <Typography variant="body2" component="span" data-testid="money-committed">
              <strong>{formatDollars(metrics?.committedInvestment ?? 0)}</strong>
            </Typography>
          </Box>
          <Box
            sx={{
              height: '20px',
              textAlign: 'right',
              width: '15%',
            }}
          >
            <Typography variant="body2" component="span" data-testid="money-committed-percentage">
              <strong>{metrics?.committedPercentageRaised}%</strong>
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" sx={{ marginBottom: 2, width: '100%' }}>
          <Box
            sx={{
              height: '20px',
              textAlign: 'left',
              width: `${isTotalMoneyRaisedLong ? '15%' : '40%'}`,
            }}
          >
            <Typography variant="body2" component="span">
              <strong>{isTotalMoneyRaisedLong ? 'Raised:' : 'Total money raised:'}</strong>
            </Typography>
          </Box>
          <Box
            sx={{
              height: '20px',
              textAlign: 'right',
              width: `${isTotalMoneyRaisedLong ? '70%' : '45%'}`,
            }}
          >
            <Typography variant="h3" color={Gray[800]} data-testid="total-money-raised">
              <strong>{formatDollars(metrics?.totalMoneyRaised ?? 0)}</strong>
            </Typography>
          </Box>
          <Box
            sx={{
              height: '20px',
              textAlign: 'right',
              width: '15%',
            }}
          >
            <Typography
              variant="body2"
              component="span"
              data-testid="total-money-raised-percentage"
            >
              <strong>100%</strong>
            </Typography>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export { FundingStatus };
