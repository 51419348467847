import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { Gray } from '../../theme/tokens/colors'; //eslint-disable-line
import { IUserDataCard } from './UserDataCard.d';

const UserDataCard: FC<IUserDataCard> = ({ title, value, icon: Icon, action, sxStack }) => {
  return (
    <Box
      height={173}
      width="100%"
      px={3}
      py={4}
      sx={{
        border: 1,
        borderColor: Gray[300],
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 252,
      }}
    >
      <Typography variant="h3" component="span" color={Gray[800]}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{...sxStack}}>
          <Icon size={28} color={Gray[700]} style={{ alignSelf: 'center' }} />
          <Typography variant="h2" component="span" color={Gray[800]} fontWeight={700}>
            {value}
          </Typography>
        </Stack>
        {action ?? null}
      </Box>
    </Box>
  );
};

export { UserDataCard };
