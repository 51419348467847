import { Box, IconButton } from '@mui/material';
import { IconCircleX, IconSearch } from '@tabler/icons';
import { ChangeEvent, FC, FormEvent, KeyboardEvent, useState } from 'react';

import { Gray, Primary } from '../../theme/tokens/colors';
import { InputField } from '../InputField'; // eslint-disable-line
import { ISearchBox } from './SearchBox.d';
import { CancelButton } from './SearchBox.styled';

export const SearchBox: FC<ISearchBox> = ({ onSubmit, onClear, labelVariant, inputProps, placeholder}) => {
  const [value, setValue] = useState<string>('');
  const [cancelDisplay, setCancelDisplay] = useState<boolean>(false);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value;
    setValue(currentValue);
    if (!currentValue.length) {
      setCancelDisplay(false);
      onClear();
    } else {
      setCancelDisplay(true);
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit(value);
    }
  };

  const handleClickButton = () => {
    onSubmit(value);
  };

  const handleOnReset = () => {
    setCancelDisplay(false);
    setValue('');
    onClear();
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <Box position="relative" width="100%">
      <form onReset={handleOnReset} onSubmit={handleOnSubmit}>
        <InputField
          label="Search"
          size="small"
          type="text"
          fullWidth
          data-testid="test-searchbar"
          labelVariant={labelVariant}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          placeholder={placeholder}
          {...inputProps}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="search"
                color="inherit"
                sx={{
                  '&:hover': {
                    backgroundColor: Primary[500],
                    color: 'background.paper',
                  },
                  color: 'grey.700',
                  display: inputProps?.disabled ? 'none' : 'inherit',
                  padding: '4px',
                }}
                onClick={handleClickButton}
              >
                <IconSearch />
              </IconButton>
            ),
          }}
        />
        {cancelDisplay && (
          <CancelButton type="reset" aria-label="reset">
            <IconCircleX size={24} color={Gray[500]} />
          </CancelButton>
        )}
      </form>
    </Box>
  );
};
