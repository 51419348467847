import { SxProps } from '@mui/material';

import { Gray, Interactive } from '../../theme/tokens/colors'; // eslint-disable-line
import { TLabelBgColors } from './InputField.d';

const labelBackground: { [key: string]: string } = {
  contained: 'background.paper',
  uncontained: 'background.default',
};

const styles = (
  disabledEditable?: boolean,
  labelVariant: TLabelBgColors = 'contained'
): SxProps => {
  let base: SxProps = {
    '& .MuiFormHelperText-root': {
      mt: 1,
    },
    '& .MuiInputBase-root.Mui-focused': {
      color: Interactive.secondary,
      fontWeight: 600,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Gray[700],
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
    },
    '& .MuiSelect-select': {
      p: 0,
    },
    '& input::-ms-clear': {
      display: 'none',
    },
    '& label': {
      backgroundColor: disabledEditable ? 'transparent' : labelBackground[labelVariant],
      fontSize: '14px',
      px: 1,
      transform: 'translate(10px,-50%)',
    },
  };

  if (disabledEditable) {
    base = {
      ...base,
      fieldset: {
        borderStyle: 'dashed',
      },
    };
  }
  return base;
};

export { styles };
