import { Box, Stack, Switch, Typography } from '@mui/material';
import { FC } from 'react';

import { DatePicker, InputField } from '../InputField';
import { Select } from '../Select'; //eslint-disable-line
import { IBasicDataProps } from '.'; //eslint-disable-line
import { projectTypes } from './dropdownsData'; //eslint-disable-line

const BasicProjectData: FC<IBasicDataProps> = ({
  errors,
  register,
  control,
  isEditor,
  optionals = true,
  watch
}) => {

  const isUs = watch('isUs');

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ xs: '1fr', lg: 'repeat(2, 1fr)' }} //eslint-disable-line
      columnGap={{ xs: 0, lg: 4 }} //eslint-disable-line
      rowGap={4}
    >
      <Typography
        gridColumn={{ xs: 'span 1', lg: 'span 2' }} //eslint-disable-line
        fontWeight="bold"
        variant="subtitle2"
      >
        Project description
      </Typography>
      <InputField
        id="create_project_name"
        label="Project name"
        fullWidth
        error={!!errors['projectTitle']}
        helperText={errors['projectTitle']?.message}
        {...register('projectTitle')}
        disabled={!isEditor?.basic}
      />
      <Select
        fullWidth
        control={control}
        name="projectType"
        options={projectTypes}
        label="Project type"
        error={!!errors['projectType']}
        helperText={errors['projectType']?.message}
        disabled={!isEditor?.basic}
      />
      <Stack direction="row" alignItems="center">
        <Switch id="active" checked={isUs} control={control} {...register('isUs')} />
        <label htmlFor="active">
          <Typography component="span" sx={{ userSelect: 'none' }}>
            Is US project
          </Typography>
        </label>
      </Stack>
      {/* eslint-disable-next-line sort-keys*/}
      <Box gridColumn={{ xs: 'span 1', lg: 'span 2' }}>
        <Typography fontWeight="bold" variant="subtitle2">
          Dates
        </Typography>
      </Box>
      <DatePicker
        fullWidth
        control={control}
        name="closingDate"
        id="closingDate"
        label={
          <Typography variant="inherit">
            Closing date{' '}
            {optionals && (
              <Typography variant="inherit" component="span" color="text.disabled">
                (OPTIONAL)
              </Typography>
            )}
          </Typography>
        }
        placeholder="e.g. January, 30, 2023"
        error={!!errors['closingDate']}
        helperText={errors['closingDate']?.message}
        disabled={!isEditor?.basic}
      />
      <InputField
        fullWidth
        id="investmentPeriod"
        label={
          <Typography variant="inherit">
            Investment period{' '}
            {optionals && (
              <Typography variant="inherit" component="span" color="text.disabled">
                (OPTIONAL)
              </Typography>
            )}
          </Typography>
        }
        placeholder="e.g. 13 months"
        error={!!errors['investmentPeriod']}
        helperText={errors['investmentPeriod']?.message}
        {...register('investmentPeriod')}
        disabled={!isEditor?.basic}
      />
    </Box>
  );
};

export { BasicProjectData };
