import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

import { IMainContainerProps } from './Layout.d';

const MainContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<IMainContainerProps>(({ theme, open, headerheight }) => ({
  flexGrow: 1,
  marginLeft: 0,
  minHeight: `calc(100vh - ${headerheight}px)`,
  transition: theme.transitions.create(['margin', 'padding-left', 'padding-right'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    marginLeft: open ? 0 : '-207px',
  },
  [theme.breakpoints.up('xl')]: {
    marginLeft: open ? 0 : '-240px',
  },
  ...(open && {
    marginLeft: 0,
    transition: theme.transitions.create(['margin', 'padding-left', 'padding-right'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  }),
}));

export { MainContainer };
