import { Button, Grid, Paper, Stack, Switch, Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import { areasInvestors } from '../../api/investors';
import { OverlayLoader } from '../../components/OverlayLoader';
import { getInvestorInitials } from '../../helpers/fullName';
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext';
import { IBasicCardProps } from '../../pages/InvestorDetail/InvestorDetail.d';
import { Gray, Primary } from '../../theme/tokens/colors';

const BasicCard: FC<IBasicCardProps> = ({
  basicData,
  toggleBlockModal,
  investorIsEnabled,
  previewMode,
  switchPreviewMode,
}) => {
  const status = investorIsEnabled !== undefined && (investorIsEnabled ? 'Active' : 'Inactive');
  const { userData } = useContext(authContext);
  const currentRoles = useMemo(() => userData?.roles || [], [userData]);
  const userCanBlock = havePermission(currentRoles, ['compliance', 'superAdmin']);
  const isBroker = basicData?.groups?.some((group) => group === 'broker' || group === 'fa');

  return (
    <Paper
      sx={{
        bgcolor: 'background.paper',
        border: `1px solid ${Gray[300]}`,
        borderRadius: 2,
        boxShadow: 0,
        p: 4,
        position: 'relative',
      }}
    >
      <Grid
        container
        columns={{ xs: 4, sm: 8, lg: 10 }} //eslint-disable-line
      >
        <Grid item xs={4} sm={8} lg={5}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 2, sm: 4, lg: 6 }} //eslint-disable-line
            pb={{ xs: 4, lg: 0 }} //eslint-disable-line
          >
            <Typography
              variant="h1"
              component="h2"
              sx={{
                alignItems: 'center',
                bgcolor: Primary[200],
                borderRadius: '50%',
                color: 'background.paper',
                display: 'flex',
                flexShrink: 0,
                height: { sm: 126, xs: 70 },
                justifyContent: 'center',
                minWidth: 70,
                width: { sm: 126, xs: 70 },
              }}
            >
              {getInvestorInitials(basicData?.legalName)}
            </Typography>
            <Stack>
              <Typography variant="h3" component="h1" pr={2}>
                {basicData?.legalName}
              </Typography>
              <Typography variant="caption" mt={1} fontWeight="bold">
                Legal name
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={4} sm={5} lg={3} sx={{ alignSelf: 'center' }}>
          <Typography component="div">
            <strong>Name:</strong> {basicData?.firstName} {basicData?.lastName}
          </Typography>
          <Typography component="div">
            <strong>Email:</strong> {basicData?.email}
          </Typography>
          <Typography component="div">
            <strong>Status:</strong> {status}
          </Typography>
          <Typography component="div">
            <strong>Location:</strong> {basicData?.location}
          </Typography>
          <Typography component="div">
            <strong>Phone:</strong> {basicData?.dialCode} {basicData?.phoneNumber}
          </Typography>
          <Typography component="div">
            <strong>Is Broker/FA:</strong> {isBroker ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        {userCanBlock && (
          <Grid item xs={4} sm={3} lg={2} sx={{ textAlign: { sm: 'right', xs: 'center' } }}>
            <Stack gap={{ sm: 6, xs: 2 }}>
              <Stack direction="row" alignItems="center">
                <Switch
                  checked={previewMode}
                  onChange={switchPreviewMode}
                  name="previewMode"
                  color="secondary"
                />

                <Typography>Preview projects</Typography>
              </Stack>

              <Button
                onClick={toggleBlockModal}
                variant="outlined"
                sx={{ fontSize: 14 }}
                disabled={investorIsEnabled === undefined}
              >
                {investorIsEnabled ? 'Block user' : 'Enable user'}
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>

      <OverlayLoader area={areasInvestors.getInvestorDetail} theme="container" />
    </Paper>
  );
};

export { BasicCard };
