import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { calculateIfNeedToRefresh } from '../../helpers/secondsRemainingToExp'; //eslint-disable-line
import { accessTokenStatusObserver } from './accessTokenStatusObserver';

const internalUserEndpoint = process.env.REACT_APP_INTERNAL_USER_API;
const projectsEndpoint = process.env.REACT_APP_PROJECTS_API;
const investorsExternalUsersEndpoint = process.env.REACT_APP_INVESTORS_API;
const walletEndpoint = process.env.REACT_APP_WALLET_API;
const tokensEndpoint = process.env.REACT_APP_TOKENS_API;
const payoutsEndpoint = process.env.REACT_APP_PAYOUTS_API;
const faBrokerEndpoint = process.env.REACT_APP_FABROKER_API;
const commissionsEndpoint = process.env.REACT_APP_COMMISSIONS_API;
const treasuryEndpoint = process.env.REACT_APP_TREASURY_API;

let accessToken = '';
let tokenValidity = 300;
const validateToken = () => {
  if (accessToken) {
    const newAccessTokenStatus = calculateIfNeedToRefresh(accessToken, tokenValidity);
    accessTokenStatusObserver.notify(newAccessTokenStatus);
  }
};
// events when the token status is validated.
document.addEventListener('pointerdown', validateToken);
document.addEventListener('keydown', validateToken);
window.addEventListener('load', validateToken);

const createAxiosInstance = (url?: string) => {
  const api = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  api.interceptors.request.use((config: AxiosRequestConfig) => {
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-non-null-assertion
      config.headers!.Authorization = accessToken;
    }
    return config;
  });

  return api;
};

const internalUserApi = createAxiosInstance(internalUserEndpoint);
const projectsApi = createAxiosInstance(projectsEndpoint);
const projectsDocumentsApi = createAxiosInstance(projectsEndpoint); // this redundancy is temporary, until the backend team develops the document endpoint.
const investorsExternalUsers = createAxiosInstance(investorsExternalUsersEndpoint);
const walletApi = createAxiosInstance(walletEndpoint);
const tokensApi = createAxiosInstance(tokensEndpoint);
const payoutsApi = createAxiosInstance(payoutsEndpoint);
const faBrokerApi = createAxiosInstance(faBrokerEndpoint);
const commissionsApi = createAxiosInstance(commissionsEndpoint);
const treasuryApi = createAxiosInstance(treasuryEndpoint);

const setTokens = (value: string, validity: number) => {
  accessToken = value;
  tokenValidity = validity;
};

export {
  commissionsApi,
  faBrokerApi,
  internalUserApi,
  investorsExternalUsers,
  payoutsApi,
  projectsApi,
  projectsDocumentsApi,
  setTokens,
  tokensApi,
  treasuryApi,
  walletApi,
};
