import Joi from "joi";

const ReferralRegex = /^(BR|FA).{6,6}$/;

const addReferralSchema = Joi.object({
    referral: Joi.string().optional().allow('').regex(ReferralRegex).messages({
    'string.pattern.base': 'Invalid referral code.',
  }),
});

export { addReferralSchema };