import { Button } from '@mui/material';
import { IconMinus, IconPlus } from '@tabler/icons';
import { FC } from 'react';

interface IExpandButton {
  expanded: boolean;
  handleExpand: () => void;
}

const ExpandButton: FC<IExpandButton> = ({ expanded, handleExpand }) => {
  return (
    <Button
      disableTouchRipple
      fullWidth
      color="secondary"
      variant="text"
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        fontWeight: 'normal',
        pointerEvents: 'auto',
        py: '12px',
      }}
      onClick={handleExpand}
      endIcon={expanded ? <IconMinus /> : <IconPlus />}
    >
      {expanded ? 'Less Details' : 'More Details'}
    </Button>
  );
};

export { ExpandButton };
