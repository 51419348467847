import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, useContext, useState } from 'react';

import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext';
import { Gray } from '../../theme/tokens/colors';
// eslint-disable-next-line import/order
import { InputEndAdornment } from '../InputField/InputEndAdornment';
import { ICommissionsPerProject } from './CommissionsPerProject.d';

const CommissionsPerProject: FC<ICommissionsPerProject> = ({ title, projects, onSetCommision }) => {
  const [commissionForProject, setCommissionForProject] = useState(0);
  const [selectedProject, setSelectedProject] = useState('');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { userData } = useContext(authContext);
  const financialAndMarketingRole = havePermission(userData?.roles ?? [], ['financial', 'marketing', 'superAdmin']);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Remove any non-numeric characters
    const numericValue = value.replace(/[^0-9.]/g, '');

    // Split the value into integer and decimal parts
    const [integerPart, decimalPart = ''] = numericValue.split('.');

    // Limit the integer part to 100
    let limitedIntegerPart = integerPart.slice(0, 2);
    if (limitedIntegerPart === '') {
      limitedIntegerPart = '0';
    } else if (limitedIntegerPart.charAt(0) === '0') {
      limitedIntegerPart = limitedIntegerPart.slice(1);
    }
    const limitedValue = limitedIntegerPart + (decimalPart ? '.' + decimalPart.slice(0, 2) : '');

    e.target.value = limitedValue;
    setCommissionForProject(Number(e.target.value))
  }

  return (
    <Box
      height={isMobile ? '100%' : '173px'}
      px={3}
      py={4}
      sx={{
        border: 1,
        borderColor: Gray[300],
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 252,
        paddingBottom: 0,
      }}
    >
      <Typography variant="h3" component="span" color={Gray[800]}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? "column" : "row",
          justifyContent: 'space-between',
        }}
      >
        <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 5 : 1} alignItems="flex-start">
          <FormControl style={{ height: isMobile ? '100%': '84px', marginTop: isMobile ? '10px' : 'inherit', minWidth: 300 }}>
            <InputLabel htmlFor="projects">Projects</InputLabel>
            <Select
              variant="outlined"
              name="projects"
              label="Projects"
              value={selectedProject}
              inputProps={{ id: 'projects' }}
              disabled={!financialAndMarketingRole}
              onChange={(e: SelectChangeEvent) => setSelectedProject(e.target.value)}
            >
              {projects.map((item, i) => (
                <MenuItem value={`${item.value}`} key={`project-id-option-${i}`}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputEndAdornment
            label={<Typography variant="inherit">Commission</Typography>}
            adornment="%"
            sx={{width: '300px'}}
            inputProps={{
              max: 100,
              onChange: handleInputChange,
              sx: {
                '&::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
                paddingRight: '10px',
                textAlign: 'right',
              },
              type: 'number',
            }}
            disabled={!financialAndMarketingRole}
            helperText={'Commission for selected project'}
          />
          <Button
            onClick={() => onSetCommision(selectedProject, commissionForProject)}
            sx={{ height: '64px', mb: 0, pb: 1, }}
            color="secondary"
            disabled={!!!commissionForProject || !!!selectedProject || !financialAndMarketingRole}
            variant="text"
          >
            <Typography component="span" variant="caption2">
              Save
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export { CommissionsPerProject };
