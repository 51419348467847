import { Box, Button, Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';

import { CreatePayoutsHeader } from '../../components/CreatePayouts';
import { PayoutsEmptyState } from '../../components/CreatePayouts/PayoutsEmptyState';
import { InputEndAdornment } from '../../components/InputField';
import { Table } from '../../components/Table';
import { formatTokens, quantityBeautifierStandard } from '../../helpers/quantityBeautifier'; //eslint-disable-line
import { ICreatePayoutsReport } from './CreatePayoutsReport.d';

const columns: GridColDef[] = [
  { field: 'name', flex: 1, headerName: 'Legal Name' },
  {
    field: 'tokens',
    flex: 1,
    headerName: 'Tokens',
    type: 'number',
    valueFormatter: ({ value }) => value && formatTokens(value),
  },
  {
    field: 'amountToReceive',
    flex: 1,
    headerName: 'Total to distribute',
    type: 'number',
    valueFormatter: ({ value }) => value && quantityBeautifierStandard(value, true),
  },
];

const CreatePayoutsReport: FC<ICreatePayoutsReport> = ({
  payouts,
  calculationValue,
  onChangeHandlePayoutsCalculator,
  goToPayouts,
  calculatePayouts,
  confirmReport,
  projectName,
}) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        columns={{ lg: 12, sm: 8, xs: 4 }}
      >
        <Grid item xs={4} sm={8} lg={8}>
          <CreatePayoutsHeader projectName={projectName} />
          <Box my={3}>
            <Grid
              container
              // eslint-disable-next-line sort-keys
              columns={{ xs: 4, sm: 8, lg: 8 }}
              spacing={4}
            >
              <Grid item xs={4} sm={5} lg={4}>
                <InputEndAdornment
                  id="create_payout"
                  name="earnings"
                  label="Earnings to distribute"
                  placeholder="1,000,000.00"
                  adornment="USD"
                  onChange={onChangeHandlePayoutsCalculator}
                  labelVariant="uncontained"
                  value={calculationValue}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                lg={4}
                alignSelf="center"
                // eslint-disable-next-line sort-keys
                sx={{ textAlign: { xs: 'center', sm: 'left', lg: 'left' } }}
              >
                <Button variant="outlined" onClick={calculatePayouts} name="calculate payouts">
                  Calculate payouts
                </Button>
              </Grid>
            </Grid>
          </Box>
          {payouts?.length > 0 ? (
            <Box mr={{ lg: 0, xs: -4 }} pr={{ lg: 0, xs: 4 }}>
              <Table columns={columns} rows={payouts} rowsPerPageOptions={[10]} pageSize={10} />
            </Box>
          ) : (
            <PayoutsEmptyState />
          )}
          <Stack direction="row" spacing={3} alignItems="flex-end" justifyContent="flex-end" mt={4}>
            <Button variant="outlined" onClick={goToPayouts}>
              Cancel
            </Button>
            <Button variant="contained" onClick={confirmReport} disabled={!payouts?.length}>
              Confirm report
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export { CreatePayoutsReport };
