import { IUser } from '../../components/PageContent'; // eslint-disable-line
import { IUsersResponse } from './users.d';

export const usersDecorators = (data: IUsersResponse): IUsersResponse => {
  const { users, pages, page, count } = data;
  const usersClean = users.map((item: IUser) => {
    const { active, createDate, email, lastName, firstName, id, roles } = item;
    return {
      active,
      createDate,
      email,
      firstName,
      id,
      lastName,
      roles,
    };
  });
  return {
    count,
    lastPage: page === pages ? true : false,
    page,
    pages,
    users: usersClean,
  };
};
