import { Gray } from './colors';

export const BorderGray = {
  50: '1px solid' + Gray[50],
  100: '1px solid' + Gray[100],
  200: '1px solid' + Gray[200],
  300: '1px solid' + Gray[300],
  400: '1px solid' + Gray[400],
  500: '1px solid' + Gray[500],
  600: '1px solid' + Gray[600],
  700: '1px solid' + Gray[700],
  800: '1px solid' + Gray[800],
  900: '1px solid' + Gray[900],
};
