import { DesktopDatePicker as MUIDatePicker } from '@mui/lab';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { CalendarIcon, IDatePrickerProps, InputField } from '.';

const DatePicker: FC<IDatePrickerProps> = ({ control, name, label, disabled, ...rest }) => {
  const theme: Theme = useTheme();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = '' } }) => (
        <MUIDatePicker
          label={label}
          value={value}
          allowSameDateSelection
          onChange={(newValue: number | null) => onChange(newValue ?? '')}
          minDate={Date.now() + 1 * 24 * 60 * 60 * 1000}
          disabled={disabled}
          renderInput={(params) => <InputField {...params} {...rest} />}
          PaperProps={{
            style: {
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: '8px',
              boxShadow: theme.shadows[2],
            },
          }}
          components={{ OpenPickerIcon: CalendarIcon }}
        />
      )}
    />
  );
};

export { DatePicker };
