import Joi from 'joi';

import { emailJoi } from "../Login";
import { twinsPasswords } from '../Onboarding';

export const form = Joi.object({
  email: emailJoi,
});

export const createPasswordSchema = Joi.object({
  ...twinsPasswords,
  code: Joi.string().min(4).max(8).required().regex(/^\d+$/).messages({
    'string.empty': 'The code is a required field',
    'string.pattern.base': 'Invalid or incorrectly entered code',
  }),
});