import { css } from '@emotion/react';
import styled from '@emotion/styled';

const pi = Math.PI;
const radio = 80;

const dynamicStyleCircle = (props: { value: number; valueInit: number }) =>
  css`
    stroke-dasharray: ${2 * pi * radio * (props.value / 100)}
      ${2 * pi * radio * (1 - props.value / 100)};
    transform-origin: center center;
    stroke-dashoffset: 0;
    stroke-width: 20;
    transform: rotateZ(${-90 + (props.valueInit * 360) / 100}deg);
  `;

const dynamicStyleLabel = (props: { value: number; valueInit: number }) =>
  css`
    left: ${Math.round(
      radio * (1.0 + 1.35 * Math.sin(((props.value / 2 + props.valueInit) / 100) * 2 * pi))
    )}px;
    position: absolute;
    top: ${Math.round(
      radio * (1.05 - 1.3 * Math.cos(((props.value / 2 + props.valueInit) / 100) * 2 * pi))
    )}px;

    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  `;

export const StyledCircle = styled.circle`
  ${dynamicStyleCircle}
`;

export const StyledLabel = styled.span`
  ${dynamicStyleLabel}
`;
