const getLastPath = (path: string): string => path.substring(path.lastIndexOf('/') + 1);
interface Params {
  [key: string]: string | number | boolean | Array<string | number | boolean>;
}

const createQueryString = (params: Params): string => {
  const queryString = new URLSearchParams();

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      if (Array.isArray(value)) {
        value.forEach((item) => queryString.append(key, item.toString()));
      } else {
        queryString.append(key, value.toString());
      }
    }
  }

  return queryString.toString();
};

export { createQueryString, getLastPath };
