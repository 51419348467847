import Joi from 'joi';

const twinsPasswords = {
  password: Joi.string()
    .regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!#$%&'()*+,-.:;<=>?@[\]^_`{|}~"]).{12,}$/)
    .required(),
  repeat_password: Joi.string().equal(Joi.ref('password')).required().messages({
    'any.only': 'Passwords do not match',
  }),
};

const onboardingSchema = Joi.object(twinsPasswords);

const passwordRules = [
  { rule: /.{12,}/, text: 'At least 12 characters long' },
  { rule: /[A-Z]/, text: 'At least 1 uppercase' },
  { rule: /[a-z]/, text: 'At least 1 lowercase' },
  { rule: /\d/, text: 'At least 1 number' },
  { rule: /[!#$%&'()*+,-.:;<=>?@[\]^_`{|}~"]/, text: 'At least 1 special character: !#$%&()*+,-.:;<=>?@[]^_`{|}~"' },
];

export { onboardingSchema, passwordRules, twinsPasswords };
