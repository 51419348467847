import { Stack, Typography } from '@mui/material';

import EmptySearchImg from '../../assets/img/Empty_Search.svg';

const EmptySearch = () => {
  return (
    <Stack height="360px" alignItems="center" justifyContent="center">
      <img width="200px" src={EmptySearchImg} alt="there are no investors in this table" />
      <Typography mt={3} variant="h2" color="text.secondary">
        No matchs found, try with other combination.
      </Typography>
    </Stack>
  );
};

export { EmptySearch };
