import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { INavStateToast, IToastProps, IUseToast } from './useToast.d';

const useToast = (): IUseToast => {
  const { state } = useLocation();
  const navState = state as INavStateToast;
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastProps, setToastProps] = useState<IToastProps>({
    horizontalPosition: 'center',
    message: '',
    severity: 'error',
    title: '',
    width: 'auto',
  });

  const openToast = useCallback((toastData: IToastProps) => {
    setIsToastOpen(true);
    setToastProps(toastData);
  }, []);

  const closeToast = () => setIsToastOpen(false);

  useEffect(() => {
    if (navState?.severity) {
      openToast({
        message: navState.message,
        severity: navState.severity,
        title: navState.title,
      });
      window.history.replaceState(null, '');
    }
  }, [navState, openToast]);

  return { closeToast, isToastOpen, openToast, toastProps };
};

export { useToast };
