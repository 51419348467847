import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { IconCompass, IconEye } from '@tabler/icons';
import { format } from 'date-fns';
import { FC, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import { StatusPointer } from '../../components/ProjectInvestors';
import { Tab } from '../../components/Tab/Tab';
import { Tabs } from '../../components/Tabs/Tabs'; // eslint-disable-line
// eslint-disable-next-line import/order
import { SUMSUB_STATUS, SUMSUB_URL } from '../../helpers/constants';
import { ReactComponent as SumSubLogo } from './../../assets/img/sumsub_logo.svg';
import { Brokers } from './Brokers';
import { IBrokersAndFAProps } from './BrokersAndFA.d';
import { Filter } from './Filter';
import { FinancialAdvisors } from './FinancialAdvisors';
import { Header } from './Header';
import { Search } from './Search';

const title = 'Brokers & Financial Advisors';
const BrokersAndFA: FC<IBrokersAndFAProps> = ({
  totalFaBrokers,
  applyFilter,
  clearFilter,
  applySearch,
  clearSearch,
  changeBrokerCommission,
  changeFACommission,
  brokerCommission,
  FACommission,
  control,
  brokersRows,
  faRows,
  tab,
  setTab,
  applySort,
  onPageChange,
  currentPage,
}) => {
  const pickTab = (_event: SyntheticEvent<Element, Event>, newTab: number) => setTab(newTab);

  const nav = useNavigate();
  const view = tab ? 'financial-advisor' : 'broker';
  const defaultCommission = tab ? FACommission : brokerCommission;
  const goToDetails = (id: GridRowId) => nav(`${view}/${id}`);

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme.breakpoints.up('md'));
  const large = useMediaQuery(theme.breakpoints.up('lg'));

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 2,
      headerName: 'Name',
      minWidth: 150,
    },
    {
      field: 'legalName',
      flex: 2,
      headerName: 'Legal Name',
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'email',
      flex: 2,
      headerName: 'Email',
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'creationDate',
      flex: 1,
      headerName: 'Creation Date',
      minWidth: 60,
      sortable: false,
      type: 'date',
      valueFormatter: ({ value }) => value && format(new Date(value), 'MM/dd/yyyy'),
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'isEnabled',
      flex: 1,
      headerName: 'Status',
      minWidth: 60,
      renderCell: ({ value }) => (
        <Stack direction="row" alignItems="center" gap={1}>
          <StatusPointer status={value === 'Active' ? 'success' : 'default'} />
          {value}
        </Stack>
      ),
      sortable: false,
    },
    {
      field: 'location',
      flex: 1,
      headerName: 'Location',
      minWidth: 60,
    },
    {
      field: 'commissionRate',
      flex: 1,
      headerName: 'Commission Rate',
      minWidth: 60,
      sortable: false,
      type: 'number',
      valueFormatter: ({ value }) => {
        return `${value === 0 ? defaultCommission : value}%`;
      },
    },
    {
      field: 'referralId',
      flex: 1,
      headerName: 'Referral ID',
      minWidth: 60,
      sortable: false,
      type: 'string',
    },
    {
      field: 'actions',
      flex: 1,
      headerName: 'Actions',
      minWidth: 100,
      renderCell: (params) => (
        <>
          <Tooltip title="View Details">
            <Button
              onClick={() => goToDetails(params.row.id)}
              size="small"
              variant="text"
              color="secondary"
              sx={{
                '> span': {
                  margin: '0 1rem',
                },
                minWidth: '24px',
              }}
              startIcon={<IconEye />}
            ></Button>
          </Tooltip>
          <Tooltip
            title={
              params.row.sumsubStatus === SUMSUB_STATUS.NOT_INITIATED
                ? 'Not initiated'
                : 'Go to Sumsub'
            }
          >
            <Button
              onClick={() => window.open(SUMSUB_URL + params.row.applicantId)}
              size="small"
              variant="text"
              color="secondary"
              disabled={params.row.sumsubStatus === SUMSUB_STATUS.NOT_INITIATED}
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
                '> span': {
                  marginRight: '0 1rem',
                },
                minWidth: '24px',
              }}
              startIcon={
                <SumSubLogo
                  width={24}
                  fillOpacity={params.row.sumsubStatus === SUMSUB_STATUS.NOT_INITIATED ? 0.3 : 1}
                />
              }
            ></Button>
          </Tooltip>
        </>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <Breadcrumbs current={title} linksList={[{ active: true, label: 'Home', path: '/' }]} />

      <Stack direction="row" spacing={1.25} alignItems="center" sx={{ my: 4 }}>
        <SvgIcon color="secondary">
          <IconCompass />
        </SvgIcon>
        <Typography variant="h1" sx={{ fontSize: 32 }} data-testid="test-fa-brokers-title">
          {title}
        </Typography>
      </Stack>

      <Divider variant="fullWidth" sx={{ mb: 3 }} />

      <Paper
        elevation={0}
        sx={{
          border: ({ palette }) => `1px solid ${palette.grey[300]}`,
          borderRadius: { lg: 3, md: 3, sm: 0, xs: 0 },
          mb: { lg: 1, md: 1, sm: 0, xs: 0 },
          mx: { lg: 0, md: 0, sm: -6, xs: -3 },
        }}
      >
        <Box>
          <Tabs value={tab} onChange={pickTab} variant="fullWidth">
            <Tab label="Brokers" />
            <Tab label="Financial Advisors" />
          </Tabs>
        </Box>

        {tab === 0 && (
          <Header
            title="Number of Brokers"
            brokers={totalFaBrokers}
            changeCommission={changeBrokerCommission}
            commission={brokerCommission}
          />
        )}
        {tab === 1 && (
          <Header
            title="Number of Financial Advisors"
            financialAdvisors={totalFaBrokers}
            changeCommission={changeFACommission}
            commission={FACommission}
          />
        )}

        <Box sx={{ pb: { lg: 3, md: 3, sm: 6.375 }, px: { lg: 3, md: 3, sm: 4 } }}>
          {tab === 0 && (
            <Brokers
              currentPage={currentPage}
              applySort={applySort}
              columns={columns}
              rowCount={totalFaBrokers}
              rows={brokersRows}
              onPageChange={onPageChange}
              isDesktop={small || medium || large}
              filter={
                <Filter applyFilter={applyFilter} clearFilter={clearFilter} control={control} />
              }
              searchBar={<Search applySearch={applySearch} clearSearch={clearSearch} />}
              goToDetails={goToDetails}
              defaultCommission={brokerCommission}
            />
          )}
          {tab === 1 && (
            <FinancialAdvisors
              currentPage={currentPage}
              applySort={applySort}
              columns={columns}
              rowCount={totalFaBrokers}
              rows={faRows}
              onPageChange={onPageChange}
              isDesktop={small || medium || large}
              filter={
                <Filter applyFilter={applyFilter} clearFilter={clearFilter} control={control} />
              }
              searchBar={<Search applySearch={applySearch} clearSearch={clearSearch} />}
              goToDetails={goToDetails}
              defaultCommission={FACommission}
            />
          )}
        </Box>
      </Paper>
    </>
  );
};

export { BrokersAndFA };
