import { FC, useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useOutletContext, useParams } from 'react-router-dom';

import { blockchainAreas, getProjectTokens, IProjectTokens } from '../../../api/blockchain';
import { PROJECT_STATUSES } from '../../../helpers/constants';
import { isRequestError } from '../../../helpers/isRequestError';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { IProjectOutletContext } from '../ProjectDetail.d'; //eslint-disable-line
import { Tokens } from '.';

const TokensPresenter: FC = () => {
  const { id } = useParams();
  const projectTokensController = useAbortRequest();
  const [tokensData, setTokensData] = useState<IProjectTokens>({
    contractAddress: '',
    distributions: [],
    lastDateOfActivity: '',
  });
  const { status } = useOutletContext<IProjectOutletContext>();

  const getTokensList = useCallback(async () => {
    if ([PROJECT_STATUSES.TOKENS_DELIVERED, PROJECT_STATUSES.DEVELOPMENT_COMPLETED, PROJECT_STATUSES.PROJECT_COMPLETED].includes(status as PROJECT_STATUSES)) {
      const { data, status: statusCode } = await trackPromise(
        getProjectTokens(id ?? '', projectTokensController.signal),
        blockchainAreas.getProjectTokens
      );
      if (!isRequestError(statusCode)) {
        const res = data as IProjectTokens;
        setTokensData(res);
      }
    }
  }, [id, projectTokensController.signal, status]);

  useEffect(() => {
    getTokensList();
  }, [getTokensList]);

  return <Tokens status={status} {...tokensData} />;
};

export { TokensPresenter };
