import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { blockchainAreas } from '../../api/blockchain';
import NoWalletImg from '../../assets/investors/no-wallet.svg';
import { OverlayLoader } from '../../components/OverlayLoader';
import { formatDateLetters, quantityBeautifierStandard } from '../../helpers';
import { IInvestorProjectTokens, IWalletProps } from '../../pages/InvestorDetail/InvestorDetail.d';
import { Gray, Interactive, Primary, Success, Warning } from '../../theme/tokens/colors';
import { DonutChart } from '../DonutChart';
import { IDonutChart } from '../DonutChart/DonutChart.d';

const chartColors = [Warning[300], Primary[300], Success[700], Primary[700], Success[200]];

const Wallet: FC<IWalletProps> = ({ tokensData, walletData }) => {
  const [hasWallet, setHasWallet] = useState(true);
  const [tokensTotal, setTokensTotal] = useState(0);
  const [donutChartData, setDonutChartData] = useState<IDonutChart[]>([]);

  useEffect(() => {
    setHasWallet(walletData?.walletType ? true : false);
  }, [walletData]);

  useEffect(() => {
    const getDonutChartData = (tokens: IInvestorProjectTokens[]): IDonutChart[] => {
      const newTokensTotal = tokens?.reduce((acc, curr) => acc + curr.tokens, 0);
      setTokensTotal(newTokensTotal);
      return tokens?.map((project, index) => ({
        color: chartColors[index % chartColors.length],
        percentage: (project.tokens / newTokensTotal) * 100,
      }));
    };
    const newDonutChartData = getDonutChartData(tokensData);
    if (newDonutChartData) {
      setDonutChartData(newDonutChartData);
    }
  }, [tokensData]);

  return (
    <Paper
      sx={{
        bgcolor: 'background.paper',
        border: `1px solid ${Gray[300]}`,
        borderRadius: 2,
        boxShadow: 0,
        maxHeight: { lg: 380, sm: 'auto', xs: 'auto' },
        minHeight: 400,
        overflow: 'auto',
        p: 4,
        position: 'relative',
      }}
    >
      <Box mb={3}>
        <Typography
          variant="body1"
          component="span"
          sx={{ color: Interactive.secondary }}
          fontWeight={600}
        >
          Wallet
        </Typography>
      </Box>

      {hasWallet && (
        <Grid
          container
          columns={{
            xs: 4,
            sm: 8, // eslint-disable-line
            lg: 7, // eslint-disable-line
          }}
        >
          <Grid item xs={12} sm={12} lg={12}>
            <Stack direction="column" spacing={3} mb={2}>
              <Typography variant="body1" component="div">
                <Box fontWeight={600} component="span">
                  Type:{' '}
                </Box>
                {walletData?.walletType}
                <br />
                <Box fontWeight={600} component="span">
                  Address:{' '}
                </Box>
                {walletData?.walletId}
                <br />
                <Box fontWeight={600} component="span">
                  Associated:{' '}
                </Box>
                {formatDateLetters(walletData?.walletAssociatedDate)}
              </Typography>
            </Stack>
            {tokensData?.length > 0 && (
              <>
                <Grid container columns={4}>
                  <Grid item xs={3} sm={3} lg={3} mb={1} pr={1}>
                    {/*  eslint-disable-next-line sonarjs/no-duplicate-string */}
                    <Typography variant="caption" color="text.primary" fontWeight={600}>
                      Projects
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1} mb={1} sx={{ textAlign: 'right' }}>
                    <Typography variant="caption" color="text.primary" fontWeight={600}>
                      Tokens
                    </Typography>
                  </Grid>
                </Grid>
                {tokensData?.map((data, index) => (
                  <Grid container columns={4} key={`wallet-chart-row-${index}`}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      lg={3}
                      mb={1}
                      pr={1}
                      key={`wallet-chart-row-column-1-${index}`}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="start"
                        key={`wallet-chart-row-stack-${index}`}
                      >
                        <svg width="16" height="16" key={`wallet-chart-row-svg-${index}`}>
                          <line
                            x1="0"
                            y1="12"
                            x2="16"
                            y2="12"
                            strokeWidth="5"
                            stroke={`${donutChartData[index]?.color ?? ''}`}
                            key={`wallet-chart-row-line-${index}`}
                          />
                        </svg>
                        <Typography
                          variant="body1"
                          component="span"
                          key={`wallet-chart-text-label-${index}`}
                        >
                          {data.projectTitle}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      lg={1}
                      mb={1}
                      key={`wallet-chart-row-column-2-${index}`}
                      sx={{ textAlign: 'right' }}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                        key={`wallet-chart-text-value-${index}`}
                      >
                        {quantityBeautifierStandard(data.tokens)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
                <Grid container columns={4} pb={{ sm: 4, xl: 0, xs: 4 }}>
                  <Grid item xs={2} sm={2} lg={2} mb={1} pr={1}>
                    <Typography variant="caption" fontWeight={600}>
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} lg={2} mb={1} sx={{ textAlign: 'right' }}>
                    <Typography variant="body1" component="span">
                      {quantityBeautifierStandard(tokensTotal)}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {tokensData?.length > 0 ? (
            <Grid item xs={4} sm={8} lg={4}>
              <Grid container columns={8}>
                <Grid item xs={8} sm={4} lg={8}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={{
                      xs: 'auto',
                      md: 226, // eslint-disable-line
                    }}
                    mb={{
                      xs: 2,
                      lg: 0, // eslint-disable-line
                    }}
                    pl={{ lg: '80px' }}
                  >
                    <DonutChart data={donutChartData} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box alignContent="center" width="100%">
              <Stack direction="column" spacing={2} alignItems="center" pt={{ lg: 2 }}>
                <img src={NoWalletImg} alt="No Wallet" width="100%" style={{ maxWidth: 170 }} />
                <Typography variant="h2" sx={{ color: Gray[700], textAlign: 'center' }}>
                  The investor has no tokens in his wallet yet.
                </Typography>
              </Stack>
            </Box>
          )}
        </Grid>
      )}

      {!hasWallet && (
        <Stack direction="column" spacing={2} alignItems="center" pt={{ lg: 2 }}>
          <img src={NoWalletImg} alt="No Wallet" width="100%" style={{ maxWidth: 170 }} />
          <Typography variant="h2" sx={{ color: Gray[700], textAlign: 'center' }}>
            There isn’t a wallet associated to this account yet.
          </Typography>
        </Stack>
      )}

      <OverlayLoader area={blockchainAreas.getInvestorTokens} theme="container" />
    </Paper>
  );
};

export { Wallet };
