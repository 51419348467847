import { Box, Stack, Typography } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons';
import { FC } from 'react';

import { formatDollars } from '../../helpers/quantityBeautifier';
import { Error, Gray, Interactive, Success } from '../../theme/tokens/colors';
import { IMetricsProps } from '../CreateProjectSteps'; // eslint-disable-line
import { InvestorsExceptionsTooltip } from '.';

const InvestorsExceptions: FC<IMetricsProps> = ({ metrics }) => {
  return (
    <Box
      height={222}
      width={268}
      p={2}
      sx={{
        border: 1,
        borderColor: Gray[300],
        borderRadius: 2,
      }}
      data-testid="investors-exceptions"
    >
      <Stack direction="row">
        <Box>
          <Typography
            variant="h3"
            color={Interactive.secondary}
            sx={{ marginLeft: 1, marginTop: 1 }}
          >
            Investors with exceptions:
          </Typography>
          <Typography
            variant="h3"
            component="span"
            color={metrics.exceptions > 0 ? Error[500] : Success[500]}
            sx={{
              marginLeft: 1,
              marginTop: 0.5,
            }}
            data-testid="number-investors-with-exceptions"
          >
            {metrics.exceptions}
          </Typography>
          <Typography
            variant="h3"
            color={Interactive.secondary}
            sx={{ marginLeft: 1, marginTop: 3 }}
          >
            Amount:
          </Typography>
          <Typography
            variant="h3"
            component="span"
            color={metrics.whitelistAmount > 0 ? Error[500] : Success[500]}
            sx={{ marginLeft: 1, marginTop: 0.5 }}
            data-testid="amount-of-exceptions"
          >
            {metrics.whitelistAmount > 0 ? formatDollars(metrics?.whitelistAmount, true) : 0}
          </Typography>
        </Box>
        <InvestorsExceptionsTooltip title="These investors aren’t whitelisted and have confirmed transactions.">
          <Box height={24}>
            <IconInfoCircle size={24} color={Gray[500]} />
          </Box>
        </InvestorsExceptionsTooltip>
      </Stack>
    </Box>
  );
};
export { InvestorsExceptions };
