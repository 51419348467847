import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { Tooltip } from '../Tooltip'; //eslint-disable-line
import type { ITextWithTooltip } from './TextWithTooltip.d';

const TextWithTooltip: FC<ITextWithTooltip> = ({
  children,
  sx,
  component,
  gridColumn,
  ...rest
}) => {
  return (
    <Box gridColumn={gridColumn} sx={{ overflow: 'hidden', pr: 2, textOverflow: 'ellipsis' }}>
      <Tooltip title={children}>
        <Typography
          component={component ?? 'span'}
          {...rest}
          sx={{
            ...sx,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 'auto',
          }}
        >
          {children}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export { TextWithTooltip };
