import { Grid, Paper } from '@mui/material';
import { FC } from 'react';

import { areas } from '../../api/brokers/areas';
import { OverlayLoader } from '../OverlayLoader'; //eslint-disable-line
import { IUserDescriptionCard } from './UserDescriptionCard.d';

const UserDescriptionCard: FC<IUserDescriptionCard> = ({
  avatar,
  userData,
  blockButton,
  bankData,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        border: ({ palette }) => `1px solid ${palette.grey[300]}`,
        borderRadius: 2,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'space-between',
        p: 4,
      }}
    >
      <Grid
        container
        columns={{ xs: 4, sm: 8, lg: 10 }} //eslint-disable-line
        spacing={3}
      >
        <Grid item xs={4} sm={8} lg={5}>
          {avatar}
        </Grid>
        <Grid item xs={4} sm={5} lg={3} sx={{ alignSelf: 'center' }}>
          {userData}
        </Grid>
        {bankData && (
          <Grid item xs={4} sm={5} lg={3} sx={{ alignSelf: 'center' }}>
            {bankData}
          </Grid>
        )}
        <Grid
          item
          xs={4}
          sm={3}
          lg={2}
          sx={{ alignSelf: 'end', textAlign: { sm: 'right', xs: 'center' } }}
        >
          {blockButton ? blockButton : null}
        </Grid>
      </Grid>
      <OverlayLoader area={areas.getFABrokerDetail} theme="container" />
    </Paper>
  );
};
export { UserDescriptionCard };
