import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  confirmTwoFactorAuth,
  createTwoFactorCode,
  IAuth,
  ITwoFactorAuth,
  verifyTwoFactorAuth,
} from '../../api/session';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { authContext } from '../../hooks/useAuth/authContext'; //eslint-disable-line
import { TwoFA } from './TwoFA';
import { ITwoFAForm } from './TwoFA.d';

const TwoFAPresenter: FC = () => {
  const { state } = useLocation();
  const nav = useNavigate();
  const { session, userId, isOTPRegistered } = state as {
    session: string;
    userId: string;
    isOTPRegistered: boolean;
  };
  const { setAuth } = useContext(authContext);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ITwoFAForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const [secretCode, setSecretCode] = useState('');
  const [sessionCode, setSessionCode] = useState('');

  const invalidCodeError = 'Invalid code';

  const link2FA = async (code: string) => {
    const { data, status } = await verifyTwoFactorAuth(sessionCode, code, userId);
    if (isRequestError(status)) {
      setError('twoFaCode', { message: invalidCodeError });
    } else {
      const auth = data as IAuth;
      setAuth(auth);
      nav('/');
    }
  };

  const confirmOtp = async (code: string) => {
    const { data, status } = await confirmTwoFactorAuth(session, code, userId);
    if (!isRequestError(status)) {
      const auth = data as IAuth;
      setAuth(auth);
      nav('/');
      return;
    }
    const error = data as IServerError;
    if (error.message.includes(invalidCodeError)) {
      return setError('twoFaCode', { message: invalidCodeError });
    }
    nav('/', {
      state: {
        message: 'Your session has expired, you must log in again.',
        severity: 'error',
        title: 'Session expired',
      },
    });
  };

  const handleValidateCode = async (data: ITwoFAForm) => {
    if (isOTPRegistered) {
      confirmOtp(data.twoFaCode);
    } else {
      link2FA(data.twoFaCode);
    }
  };

  const requestSecretCode = useCallback(async () => {
    const { data, status } = await createTwoFactorCode(session);
    if (!isRequestError(status)) {
      const res = data as ITwoFactorAuth;
      setSecretCode(res.secretCode);
      setSessionCode(res.session);
    }
  }, [session]);

  useEffect(() => {
    if (!isOTPRegistered) {
      requestSecretCode();
    }
  }, [requestSecretCode, isOTPRegistered]);

  return (
    <TwoFA
      secretCode={secretCode}
      validateCode={handleSubmit(handleValidateCode)}
      register={register}
      errors={errors}
      isOTPRegistered={isOTPRegistered}
    />
  );
};
export { TwoFAPresenter };
