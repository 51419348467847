/* eslint-disable sort-keys */
import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getFABrokerDetail } from '../../api/brokers';
import { getReferrersByIds } from '../../api/brokers/brokers';
import { IReferrersResponse } from '../../api/brokers/brokers.d';
import { areasInvestors } from '../../api/investors';
import { editInvestor } from '../../api/investors/investors';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { toastContext } from '../../hooks/useToast'; // eslint-disable-line
import { FABrokerDetail } from './FABrokerDetail';
import { IFABroker } from './FABrokers.d';

const FABrokerDetailPresenter = () => {
  const { id: userId } = useParams();
  const [userDetail, setUserDetail] = useState<IFABroker>();
  const [userIsEnabled, setUserIsEnabled] = useState<boolean>(
    userDetail?.userData?.status === 'Active'
  );
  const getUserDetailController = useAbortRequest();
  const blockUserController = useAbortRequest();
  const [isBlockModalOpen, setIsBlockModalOpen] = useState<boolean>(false);
  const toggleBlockModal = () => setIsBlockModalOpen(!isBlockModalOpen);
  const { openToast } = useContext(toastContext);

  const reqUserDetail = useCallback(async () => {
    const { data: dataReferrers, status: statusReferrers } = await getReferrersByIds(
      [userId as string],
      getUserDetailController.signal
    );
    const { data, status } = await getFABrokerDetail(
      userId as string,
      (dataReferrers as IReferrersResponse[])[0].referralCode,
      getUserDetailController.signal,
    );

    if (!isRequestError(status) && !isRequestError(statusReferrers)) {
      setUserDetail({ ...(data as IFABroker) });
    }
  }, [getUserDetailController.signal, userId]);

  const blockUser = async () => {
    const id = userId ?? '';
    const { status, data } = await editInvestor(id, !userIsEnabled, blockUserController.signal);
    toggleBlockModal();
    if (!isRequestError(status)) {
      setUserIsEnabled(!userIsEnabled);
      reqUserDetail();
      openToast({
        message: `This user will ${
          userIsEnabled ? 'no longer' : 'now'
        }  have access to the platform.`,
        severity: 'success',
        title: `User ${userIsEnabled ? 'blocked' : 'enabled'} successfully!`,
      });
    } else {
      const error = data as IServerError;
      openToast({
        message: error.message,
        severity: 'error',
        title: (error.title as string) || 'Something went wrong',
      });
    }
  };

  useEffect(() => {
    reqUserDetail();
  }, [reqUserDetail]);

  useEffect(() => {
    setUserIsEnabled(userDetail?.userData?.status === 'Active');
  }, [userDetail?.userData?.status]);

  return (
    <>
      <FABrokerDetail userDetail={userDetail as IFABroker} toggleBlockModal={toggleBlockModal} />
      <Dialog
        open={isBlockModalOpen}
        onClose={toggleBlockModal}
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: 5,
            maxWidth: '270px',
            pb: 5,
            position: 'relative',
            pt: 7,
            px: 3,
          },
        }}
      >
        <IconButton
          color="primary"
          onClick={toggleBlockModal}
          sx={{ position: 'absolute', right: 10, top: 10 }}
        >
          <IconX />
        </IconButton>
        <Typography variant="h3">{userIsEnabled ? 'Block' : 'Enable'} user</Typography>
        <Typography variant="body1" mt={1}>
          Do you want to {userIsEnabled ? 'block' : 'enable'} this user?
        </Typography>
        <Stack sx={{ mt: 5 }}>
          <Button sx={{ mb: 3 }} color="secondary" onClick={toggleBlockModal}>
            No
          </Button>
          <ButtonSubmit area={areasInvestors.editInvestor} onClick={blockUser}>
            Yes
          </ButtonSubmit>
        </Stack>
      </Dialog>
    </>
  );
};
export { FABrokerDetailPresenter };
