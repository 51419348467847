/* eslint-disable sort-keys */
import { Box, Link, List, Theme, Typography, useMediaQuery } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { FC, useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { areas as areasBroker } from '../../api/brokers/areas';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { EmptySearch } from '../../components/EmptyStates';
import { MobileTableCard } from '../../components/MobileTableCard';
import { OverlayLoader } from '../../components/OverlayLoader';
import { PageHeader } from '../../components/PageHeader';
import { Paginator } from '../../components/Paginator';
import { Table } from '../../components/Table'; //eslint-disable-line
import InvestmentsContext from './context';
import { IFilterForm } from './Investments.d';
import { TableHeader } from './tableHeader';

export const Investments: FC = () => {
  const nav = useNavigate();
  const [search, setSearch] = useState('');
  const { investments, isLoading, filterParams, setFilterParams, lastPage } =
    useContext(InvestmentsContext);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { touchedFields },
  } = useForm<IFilterForm>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const goInvestorDetail = useCallback((id: string) => () => nav(id), [nav]);
  const statusFilter = watch('status');
  const dateFormat = 'MM/dd/yyyy'
  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Updated Date',
      minWidth: 120,
      sortable: true,
      flex: 1,
      valueFormatter: ({ value }) => value && format(new Date(value), dateFormat),
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      minWidth: 150,
      sortable: false,
      flex: 2,
      renderCell: ( params ) =>
        params && (
          <Link
            component={RouterLink}
            sx={{
              '&:hover': { textDecoration: 'underline' },
              cursor: 'pointer',
              display: 'flex',
              textDecoration: 'none',
            }}
            to={'/projects/' + params.row.projectId}
          >
            <Typography>{params.row.projectName}</Typography>
          </Link>
        )
    },
    {
      field: 'investmentStatus',
      headerName: 'Investment Status',
      minWidth: 60,
      sortable: true,
      flex: 2,
      valueFormatter: ({ value }) =>  value.includes('_') ? value.replace('_',' '):  value
    
    },
    {
      field: 'totalAmount',
      headerName: 'Total Amount',
      minWidth: 60,
      sortable: false,
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 60,
      sortable: false,
      flex: 1,
    },
    {
      field: 'fees',
      headerName: 'Fees',
      minWidth: 60,
      sortable: false,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'legalName',
      headerName: 'Legal Name',
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        params && (
          <Link
            component={RouterLink}
            sx={{
              '&:hover': { textDecoration: 'underline' },
              cursor: 'pointer',
              display: 'flex',
              textDecoration: 'none',
            }}
            to={'/investors/' + params.row.investorId}
          >
            <Typography>{params.row.legalName}</Typography>
          </Link>
        ),
    },
    {
      field: 'createdDate',
      headerName: 'Creation date',
      minWidth: 120,
      sortable: false,
      flex: 1,
      valueFormatter: ({ value }) => value && format(new Date(value), dateFormat),
    },
  ];

  const rows = investments.map((investment) => ({
    id: investment.transferId,
    amount: investment.amount,
    fees: investment.fee,
    totalAmount: investment.totalAmount,
    type: investment.transferType,
    investmentStatus: investment.status,
    projectId: investment.projectId,
    projectName: investment.projectName,
    legalName: investment.legalName,
    investorId: investment.investorId,
    createdDate: investment.dateTime,
    date: investment.updatedDate,
  }));

  const mobileRows = useCallback(
    () =>
    investments.map((investment) => ({
        data: {
          'Project Name': investment.projectName,
          'Legal Name': investment.legalName,
          'Updated date':
          investment.updatedDate && format(new Date(investment.updatedDate), dateFormat),
          'Creation date':
          investment.dateTime && format(new Date(investment.dateTime), dateFormat),
          'Investment Status': investment.status
        },
        id: investment.transferId,
      })),
    [investments, goInvestorDetail]
  );

  const onFilter = (data: IFilterForm) => {
    const params = { ...filterParams, page: 1 };
    const filter = data.status === 'active' ? true : false;
    params.filter = search ? `isEnabled:${filter},amount:${search}` : `isEnabled:${filter}`;
    setFilterParams(params);
  };

  const onClearFilter = () => {
    if (!touchedFields?.status) {
      return;
    }
    reset({ status: '' });
    const params = { ...filterParams, page: 1 };
    if (search) {
      params.filter = `amount:${search}`;
      return setFilterParams(params);
    }
    delete params.filter;
    setFilterParams(params);
  };

  const onSort = (sortModel: GridSortModel) => {
    const model = sortModel[0];
    const params = { ...filterParams, page: 1 };
    if (model) {
      params.sortBy = `${model.field}:${model.sort?.toUpperCase()}`;
      return setFilterParams(params);
    }
    delete params.sortBy;
    setFilterParams(params);
  };

  const onSearch = (value: string) => {
    const params = { ...filterParams, page: 1 };
    setSearch(value)
    if (!isNaN(parseInt(value))) {
      params.filter =
        params.filter && !params.filter.includes('name')
          ? `${params.filter},amount:${value}`
          : `amount:${value}`;
      return setFilterParams(params);
    }
    else if(isNaN(parseInt(value))&& value){
      params.filter =`amount: 0`
      return setFilterParams(params)
      
    }
    if (statusFilter) {
      params.filter = `isEnabled:${statusFilter === 'active' ? true : false}`;
      return setFilterParams(params);
    }
    delete params.filter;
    setFilterParams(params);
  };

  const onPageChange = (newPage: number) => {
    const params = { ...filterParams, page: newPage };
    setFilterParams(params);
  };

  return (
    <>
      <Breadcrumbs current="Investments" linksList={[{ label: 'Home', path: '/' }]} />
      <PageHeader investments />
      {!isMobile && (
        <Table
          sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
          rows={rows}
          columns={columns}
          paginationMode="server"
          sortingMode="server"
          pageSize={10}
          rowCount={10000000}
          rowsPerPageOptions={[10]}
          isLoading={isLoading}
          area={areasBroker.getReferrerAssociatedIds}
          page={filterParams.page}
          onSortModelChange={onSort}
          onPageChange={onPageChange}
          pagination
          components={{
            Toolbar: TableHeader,
            NoResultsOverlay: EmptySearch,
          }}
          componentsProps={{
            toolbar: {
              onFilter: handleSubmit(onFilter),
              onSearch,
              onSort,
              control,
              onClearFilter,
            },
            pagination: {
              backIconButtonProps: { disabled: filterParams.page === 1 ? true : false },
              nextIconButtonProps: { disabled: lastPage },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'date', sort: 'desc' }],
            },
          }}
          sortingOrder={['desc', 'asc', null]}
        />
      )}
      {isMobile && (
        <>
          <TableHeader
            onClearFilter={onClearFilter}
            onFilter={handleSubmit(onFilter)}
            onSearch={onSearch}
            onSort={onSort}
            control={control}
          />
          {isLoading && (
            <Box
              sx={{
                position: 'relative',
                border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                borderRadius: 2,
                minHeight: 300,
              }}
            >
              <OverlayLoader theme="container" area={areasBroker.getReferrerAssociatedIds} />
            </Box>
          )}
          {!isLoading && (
            <List>
              {mobileRows().map((row) => (
                <MobileTableCard key={row.id} data={row.data} />
              ))}
            </List>
          )}
          {!isLoading && investments.length === 0 && <EmptySearch />}
          <Paginator
            sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
            setPage={onPageChange}
            limit={10}
            count={10000000}
            page={filterParams?.page ?? 1}
            backIconButtonProps={{ disabled: filterParams.page === 1 ? true : false }}
            nextIconButtonProps={{ disabled: lastPage }}
          />
        </>
      )}
    </>
  );
};
