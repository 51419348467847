// eslint-disable-next-line import/order
import { IProjectStatus } from '../../pages/ProjectDetail';
import {
  IInvestorDetail,
  IInvestorDetailsRes,
  IInvestorResponse,
  ITransactionRaw,
  ITransactionsResponse,
  ITransactionsResponseRaw,
} from './investors.d';

export const transactions = (data: ITransactionsResponseRaw): ITransactionsResponse => {
  const { count, page, pages } = data;
  const decorated = data.investments.map((item: ITransactionRaw) => {
    const { project, amount, date, projectStatus, projectId } = item;
    return {
      date,
      investment: amount,
      project: {
        id: projectId,
        title: project,
      },
      status: projectStatus as IProjectStatus,
    };
  });
  return {
    count,
    page,
    pages,
    transactions: decorated,
  };
};

export const decorateInvestors = (data: IInvestorResponse): IInvestorResponse => {
  const { investors, pages, page, count, paginationToken } = data;
  return {
    count,
    investors,
    lastPage: (!paginationToken && page === pages) && !paginationToken ? true : false,
    page,
    pages,
    paginationToken,
  };
};

export const decorateUsers = (users: IInvestorDetailsRes): IInvestorDetail[] => 
   Object.keys(users).map(id => {
    return {
      ...users[id],
      id: id,
    };
});

