import { Alert, Box, Stack, SvgIcon, Typography } from '@mui/material';
import { IconAlertTriangle, IconCircleCheck } from '@tabler/icons';
import { FC, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { sessionAreas } from '../../api/session';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { InputField } from '../../components/InputField';
import { formatTokens } from '../../helpers/quantityBeautifier';
import { Warning } from '../../theme/tokens/colors'; //eslint-disable-line
import { IFormContext } from './CreateTokens.d';

const ConfirmTokens: FC = () => {
  const {
    canCreateTokens,
    register,
    watch,
    errors,
    totalTokens,
    tokensList,
    unregister,
    validatePassword,
  } = useOutletContext<IFormContext>();
  const name = watch('name');
  const symbol = watch('symbol');

  useEffect(() => {
    return () => {
      unregister('password');
      unregister('code');
    };
  }, [unregister]);

  return (
    <div>
      <Alert
        severity="warning"
        icon={<IconAlertTriangle />}
        sx={{
          border: ({ palette }) => `1px solid ${palette.warning.main}`,
          borderRadius: 2,
          color: Warning[600],
        }}
      >
        <strong>Are you sure you want to continue?</strong>
        <br />
        <br />
        This process cannot be reversed:
        <br />
        <br />
        Token name: <strong>{name}</strong>
        <br />
        Token Symbol: <strong>{symbol}</strong>
        <br />
        <br />
        <strong>
          {formatTokens(totalTokens)} {symbol}
        </strong>{' '}
        tokens will be issued to <strong>{tokensList.length}</strong> investors.
      </Alert>
      <Typography
        variant="body1"
        color="text.primary"
        component="h2"
        fontWeight="bold"
        mt={5}
        mb={4}
      >
        To confirm the creating token process input your user password:
      </Typography>
      <Stack direction="row" alignItems="center" gap={4}>
        <Box width="50%">
          <InputField
            {...register('password')}
            fullWidth
            labelVariant="uncontained"
            type="password"
            label="Password"
            disabled={canCreateTokens}
            error={!!errors['password']}
            helperText={errors['password']?.message}
            InputProps={
              canCreateTokens
                ? {
                    endAdornment: (
                      <SvgIcon color="success">
                        <IconCircleCheck />
                      </SvgIcon>
                    ),
                  }
                : null
            }
          />
        </Box>
        {!canCreateTokens && (
          <ButtonSubmit
            area={sessionAreas.login}
            type="button"
            onClick={validatePassword}
            variant="contained"
            fullWidth={false}
          >
            Validate
          </ButtonSubmit>
        )}
      </Stack>
      <Typography
        variant="body1"
        color="text.primary"
        component="h2"
        fontWeight="bold"
        mt={5}
        mb={4}
      >
        Enter the Authenticator temporary code:
      </Typography>
      <Box width="50%">
        <InputField
          {...register('code')}
          fullWidth
          disabled={!canCreateTokens}
          labelVariant="uncontained"
          label="Code"
          error={!!errors['code']}
          helperText={errors['code']?.message}
        />
      </Box>
    </div>
  );
};

export { ConfirmTokens };
