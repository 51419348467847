import { Button, Divider, Pagination, Stack, SvgIcon, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconBuilding } from '@tabler/icons';
import { FC, useContext } from 'react';

import { projectAreas } from '../../api/projects';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { BasicProjectData, CreateProjectModal } from '../../components/CreateProjectSteps';
import { ProjectsFilters } from '../../components/ProjectsFilters';
import { ProjectsList } from '../../components/ProjectsList'; // eslint-disable-line
import { IProjects, IProjectsContext } from '.'; // eslint-disable-line
import ProjectsContext from './ProjectsContext'; // eslint-disable-line

const Projects: FC<IProjects> = ({
  openCreateProject,
  toggleCreateProject,
  handleCreateProject,
  register,
  errors,
  watch,
  control,
  onPageChange,
  isCreator,
}) => {
  const { projects, totalPages, filterParams } = useContext(ProjectsContext) as IProjectsContext;

  return (
    <>
      <Breadcrumbs current="Projects" linksList={[{ label: 'Home', path: '/' }]} />
      <Stack
        mt={4}
        direction={{ xs: 'column', sm: 'row' }} //eslint-disable-line
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Typography variant="h1">
            <SvgIcon color="secondary" sx={{ mr: 1 }}>
              <IconBuilding />
            </SvgIcon>
            Projects
          </Typography>
        </Box>
        {isCreator && (
          <Button
            variant="contained"
            onClick={toggleCreateProject}
            fullWidth
            sx={{ mt: { xs: 2, md: 0 }, width: { sm: 248 } }} //eslint-disable-line
            startIcon={<IconBuilding />}
          >
            Create project
          </Button>
        )}
      </Stack>
      <Divider variant="middle" sx={{ mt: 2 }} />

      <CreateProjectModal isOpen={openCreateProject} close={toggleCreateProject}>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            pb: 10,
            px: { xs: 3, md: 11 }, //eslint-disable-line
          }}
          onSubmit={handleCreateProject}
        >
          <BasicProjectData
            register={register}
            errors={errors}
            watch={watch}
            control={control}
            isEditor={{ basic: isCreator }}
          />
          <Divider variant="middle" sx={{ mt: 9 }} />
          <Stack
            gridColumn={{ xs: 'span 1', lg: 'span 2' }} //eslint-disable-line
            direction={{ xs: 'column-reverse', lg: 'row' }} //eslint-disable-line
            justifyContent="flex-end"
            spacing={3} //eslint-disable-line
            mt={9}
          >
            <Button color="secondary" onClick={toggleCreateProject}>
              Cancel
            </Button>
            <Box width={{ lg: 230 }}>
              <ButtonSubmit
                area={projectAreas.createProject}
                variant="contained"
                startIcon={<IconBuilding />}
              >
                Create project
              </ButtonSubmit>
            </Box>
          </Stack>
        </Box>
      </CreateProjectModal>

      <Box mt={3} mb={2}>
        <ProjectsFilters />
      </Box>

      <ProjectsList list={projects} />

      {projects?.length > 0 && (
        <Pagination
          sx={{
            '.MuiPaginationItem-root': { bgcolor: 'background.paper' },
            '.MuiPaginationItem-root.Mui-selected': {
              bgcolor: 'background.paper',
              borderColor: 'secondary.main',
              color: 'secondary.main',
            },
            '.MuiPaginationItem-root.MuiPaginationItem-ellipsis': { bgcolor: 'background.default' },
            display: 'flex',
            justifyContent: 'center',
            mt: 9,
          }}
          boundaryCount={0}
          page={filterParams.page as number}
          color="secondary"
          onChange={onPageChange}
          count={totalPages}
          variant="outlined"
          size="large"
        />
      )}
    </>
  );
};

export { Projects };
