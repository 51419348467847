import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';

import { SearchBox } from '../../components/SearchBox';
import { SortSelectorFilter } from '../../components/SortSelectorFilter'; //eslint-disable-line
import { ITableHeaderProps } from './Investments.d';


const sortOptions = [

  {
    label: 'Updated Date',
    value: 'date',
  }, 
  {
    label: 'Transfer Type',
    value: 'type',
  },
  {
    label: 'Status',
    value: 'status ',
  },
  {
    label: 'Amount',
    value: 'amount ',
  }
  
];

const TableHeader = ({ onSort, onSearch }: ITableHeaderProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const handleSort = (param: string, order: string) => {
    onSort([{ field: param, sort: order as GridSortDirection }]);
  };
  const resetSort = () => onSort([]);

  return (
    <Stack
      direction="row"
      p={2}
      justifyContent="space-between"
      flexWrap="wrap"
      gap={2}
      mt={{ xs: 2, md: 0 }} //eslint-disable-line
      sx={{ borderBottom: ({ palette }) => (isMobile ? 'none' : `1px solid ${palette.grey[300]}`) }}
    >
      {/* eslint-disable-next-line */}
      <Box width={{ xs: '100%', sm: '50%' }}>
        <SearchBox
          labelVariant={isMobile ? 'uncontained' : 'contained'}
          placeholder='Insert Total Amount'
          onSubmit={onSearch}
          onClear={() => onSearch('')}
        />
      </Box>
      {isMobile && (
        <SortSelectorFilter
          options={sortOptions}
          onChange={handleSort}
          resetActiveSort={resetSort}
          defaultValue="Updated date"
        />
      )}
    </Stack>
  );
};
export { TableHeader };
