import { Box, Grid, Typography, Link } from '@mui/material'; //eslint-disable-line
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Gray } from '../../theme/tokens/colors';

import { ITransactionItem } from './TransactionsTable.d';
import { StatusLabel } from './StatusLabel';

export const CardTransaction: FC<ITransactionItem> = ({
  project,
  investment,
  date,
  value,
  status,
  ...rest
}) => {
  return (
    <Box borderRadius={2} bgcolor="#fff" sx={{ border: `1px solid ${Gray[300]}` }} {...rest}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="48px"
        sx={{ borderBottom: `1px solid ${Gray[300]}` }}
      >
        <Grid alignItems="center" justifyContent="center" columns={5} container>
          <Grid item xs={2}>
            <Typography component="div" variant="body2" fontWeight={600} color="#000" pl={2}>
              Project:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Link
              component={RouterLink}
              to={`/projects/${project.id}`}
              underline="hover"
              display="block"
              variant="body1"
              fontWeight={300}
              color="secondary"
              pr={2}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {project.title}
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="48px"
        sx={{ borderBottom: `1px solid ${Gray[300]}` }}
      >
        <Grid alignItems="center" justifyContent="center" columns={5} columnSpacing={1} container>
          <Grid item xs={2}>
            <Typography component="div" variant="body2" fontWeight={600} color="#000" pl={2}>
              Investment (USD):
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography component="div" variant="body1" fontWeight={300}>
              {investment}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="48px"
        sx={{ borderBottom: `1px solid ${Gray[300]}` }}
      >
        <Grid alignItems="center" justifyContent="center" columns={5} columnSpacing={1} container>
          <Grid item xs={2}>
            <Typography component="div" variant="body2" fontWeight={600} color="#000" pl={2}>
              Date:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography component="div" variant="body1" fontWeight={300}>
              {date}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="48px"
        sx={{ borderBottom: `1px solid ${Gray[300]}` }}
      >
        <Grid alignItems="center" justifyContent="center" columns={5} columnSpacing={1} container>
          <Grid item xs={2}>
            <Typography component="div" variant="body2" fontWeight={600} color="#000" pl={2}>
              Current value (Tokens):
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography component="div" variant="body1" fontWeight={300}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" minHeight="48px">
        <Grid alignItems="center" justifyContent="center" columns={5} columnSpacing={1} container>
          <Grid item xs={2}>
            <Typography component="div" variant="body2" fontWeight={600} color="#000" pl={2}>
              Project status:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <StatusLabel color={status.color}>{status.title}</StatusLabel>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
