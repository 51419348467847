import { MenuItem } from '@mui/material';
import { styled } from '@mui/system';

import { Gray, Primary } from '../../theme/tokens/colors'; //eslint-disable-line

const COLOR = Primary[500];

const StyledMenuItem = styled(MenuItem)<{ styledselected: string }>`
  padding: 0;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: ${(props) => (props.styledselected === 'true' ? COLOR : 'transparent')};
  &.Mui-selected {
    background-color: transparent;
    > a span {
      font-weight: 600;
    }
    & svg {
      color: ${COLOR};
    }
  }
  & > a {
    color: ${(props) => (props.styledselected === 'true' ? '#000' : Gray[700])};
  }
  & svg {
    color: ${(props) => (props.styledselected === 'true' ? COLOR : Gray[700])};
  }
  &:hover {
    background-color: transparent !important;

    &:before {
      content: '';
      display: block;
      width: 89%;
      height: 48px;
      background-color: ${Gray[200]};
      border-radius: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
`;

export { StyledMenuItem };
