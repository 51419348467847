import { useEffect, useRef } from 'react';

export const useAbortRequest = (): AbortController => {
  const controller = useRef(new AbortController());

  useEffect(() => {
    const signal = controller.current;
    return () => {
      signal.abort();
    };
  }, []);

  return controller.current;
};
