import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';

import { AsyncDownloadButton } from '../../components/DownloadButton'; //eslint-disable-line
import { FilterPopover } from '../../components/FilterPopover';
import { SearchBox } from '../../components/SearchBox';
import { Select } from '../../components/Select';
import { SortSelectorFilter } from '../../components/SortSelectorFilter'; //eslint-disable-line
import { ITableHeaderProps } from './Investors.d';

const options = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];
const sortOptions = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Creation date',
    value: 'date',
  },
  {
    label: 'Status',
    value: 'isEnabled',
  },
  {
    label: 'Location',
    value: 'location',
  },
];

const TableHeader = ({ onClearFilter, onSort, onSearch, control, onFilter, asyncDownloadButton }: ITableHeaderProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const handleSort = (param: string, order: string) => {
    onSort([{ field: param, sort: order as GridSortDirection }]);
  };
  const resetSort = () => onSort([]);

  return (
    <Stack
      direction="row"
      p={2}
      justifyContent="space-between"
      flexWrap="wrap"
      gap={2}
      mt={{ xs: 2, md: 0 }} //eslint-disable-line
      sx={{ borderBottom: ({ palette }) => (isMobile ? 'none' : `1px solid ${palette.grey[300]}`) }}
    >
      {/* eslint-disable-next-line */}
      <Box width={{ xs: '100%', sm: '50%' }}>
        <SearchBox
          labelVariant={isMobile ? 'uncontained' : 'contained'}
          onSubmit={onSearch}
          onClear={() => onSearch('')}
        />
      </Box>
      {isMobile && (
        <SortSelectorFilter
          options={sortOptions}
          onChange={handleSort}
          resetActiveSort={resetSort}
          defaultValue="Creation date"
        />
      )}
      <Stack direction="row" gap={2} height={32} pt={0.7}>
        <FilterPopover
          applyFilter={onFilter}
          clearFilter={onClearFilter}
          filterOptions={<Select label="Status" control={control} name="status" options={options} />}
        />
        <AsyncDownloadButton
          fileName={`Investors_information${new Date().toDateString()}`}
          getData={asyncDownloadButton}
          disabled={false}
        />
      </Stack>
    </Stack>
  );
};
export { TableHeader };
