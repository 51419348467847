import { Button, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { Primary } from '../../theme/tokens/colors'; //eslint-disable-line
import { IButtonSubmit } from './ButtonSubmit.d';

const ButtonSubmit: FC<IButtonSubmit> = ({
  children,
  area,
  startIcon,
  type = 'submit',
  ...rest
}) => {
  const { promiseInProgress } = usePromiseTracker({ area });

  return (
    <Button
      variant="contained"
      type={type}
      fullWidth
      startIcon={
        startIcon && promiseInProgress ? (
          <CircularProgress sx={{ ml: 1 }} size={20} color="inherit" />
        ) : (
          startIcon
        )
      }
      style={{ pointerEvents: promiseInProgress ? 'none' : 'auto' }}
      sx={{
        backgroundColor: ({ palette }) =>
          promiseInProgress ? Primary[600] : palette.secondary.main,
      }}
      {...rest}
    >
      {children}
      {!startIcon && promiseInProgress && (
        <CircularProgress sx={{ ml: 1 }} size={20} color="inherit" />
      )}
    </Button>
  );
};

export { ButtonSubmit };
