import { Box, capitalize, Stack, SvgIcon, SxProps, Typography } from '@mui/material';
import { IconChevronRight } from '@tabler/icons';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';

import { INVESTMENT_STATUSES } from '../../helpers/constants';
import { formatDollars } from '../../helpers/quantityBeautifier';
import { Error } from '../../theme/tokens/colors';
import { ConfirmationPaymentModal } from '../PayoutsReportDetail/ConfirmationPaymentModal';
import { Tooltip } from '../Tooltip'; //eslint-disable-line
import { IInvestmentTableRow, IStatusPointer, ITransaction, StatusPointer } from './'; //eslint-disable-line
import { TableRowDetail } from './TableRowDetail'; //eslint-disable-line

const InvestmentTableRow: FC<IInvestmentTableRow> = ({
  onClick,
  isExpanded = false,
  dateLastTransaction,
  totalInvested,
  location,
  fullName,
  status,
  transactions,
  email,
  phone,
  userId,
  whitelisted,
  reqTransactionStatus,
// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [warning, setWarning] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState({status: '', transaction: '' });

  const userTableStyles: SxProps = {
    color: `${warning ? Error[600] : 'primary'}`,
    overflow: 'hidden',
    pr: 2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  };
  const investmentStatus = {
    committed: 'warning',
    delayed: 'error',
    manually_refunded: 'success',
    pending: 'warning',
    received: 'success',
    refund_inprocess: 'success',
    refunded: 'success',
    rejected: 'error',
    to_refund: 'warning',
  };
  const whitelistStatus = {
    confirmed: 'success',
    unconfirmed: 'warning',
  };

  useEffect(() => {
    const hasReceivedTransactions = (transaction: ITransaction) =>
      transaction.status === INVESTMENT_STATUSES.RECEIVED;
    setWarning(transactions.find(hasReceivedTransactions) ? whitelisted === 'unconfirmed' : false);
  }, [transactions, whitelisted]);

  const handleReqTransaction = (transaction: string, status:string) => {
    setTransactionStatus({ status, transaction });
    setShowConfirmationModal(true);
  }

  return (
    <Box
      data-testid={userId}
      role="row"
      aria-expanded={isExpanded ? true : false}
      sx={{
        '&:last-of-type': { borderBottom: 'none' },
        '&:nth-of-type(odd)': { bgcolor: ({ palette }) => palette.grey[100] },
        borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
      }}
    >
      <Box
        display="grid"
        gridTemplateColumns="76px 2fr repeat(5,1fr)"
        py={2}
        onClick={onClick}
        sx={{ cursor: 'pointer' }}
      >
        <SvgIcon
          sx={{
            color: isExpanded ? 'secondary.main' : 'text.secondary',
            ml: 3,
            transform: isExpanded ? 'rotate(90deg)' : 'none',
            transition: 'all 0.2s',
          }}
        >
          <IconChevronRight />
        </SvgIcon>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Tooltip title={fullName ?? ''}>
            <Typography
              component="span"
              fontWeight={isExpanded ? 'bold' : 'normal'}
              sx={userTableStyles}
            >
              {fullName}
            </Typography>
          </Tooltip>
        </Box>
        <Stack direction="row" alignItems="center" gap={1} sx={userTableStyles}>
          <StatusPointer status={whitelistStatus[whitelisted] as IStatusPointer['status']} />
          <Typography
            component="div"
            fontWeight={isExpanded ? 'bold' : 'normal'}
            sx={userTableStyles}
          >
            {capitalize(whitelisted ?? '')}
          </Typography>
        </Stack>
        <Typography
          component="div"
          fontWeight={isExpanded ? 'bold' : 'normal'}
          sx={userTableStyles}
        >
          {location}
        </Typography>
        <Typography
          component="div"
          fontWeight={isExpanded ? 'bold' : 'normal'}
          textAlign="end"
          sx={userTableStyles}
        >
          {formatDollars(totalInvested, true)}
        </Typography>
        <Typography
          component="div"
          fontWeight={isExpanded ? 'bold' : 'normal'}
          sx={userTableStyles}
        >
          {format(new Date(dateLastTransaction), 'MM/dd/yyyy')}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1} sx={userTableStyles}>
          <StatusPointer status={investmentStatus[status] as IStatusPointer['status']} />
          <Typography
            fontWeight={isExpanded ? 'bold' : 'normal'}
            component="div"
            sx={{
              color: `${warning ? Error[600] : 'primary'}`,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {capitalize(status ?? '').replace('_', ' ')}
          </Typography>
        </Stack>
      </Box>
      <TableRowDetail
        isExpanded={isExpanded}
        email={email}
        phone={phone}
        userId={userId}
        transactions={transactions}
        transactionStatus={handleReqTransaction}
        whitelisted={whitelisted}
      />
      <ConfirmationPaymentModal
        title="Are you sure you want to continue?"
        subtitle={
          <Typography>
            You are about to change the investment status.
          </Typography>
        }
        primaryButtonText="Cancel"
        secondaryButtonText='Change status'
        onClose={() => setShowConfirmationModal(false)}
        open={showConfirmationModal ?? false}
        finishWorking={() => setShowConfirmationModal(false)}
        mainAction={() => {setShowConfirmationModal(false); reqTransactionStatus(transactionStatus.transaction, transactionStatus.status)}}
      />
    </Box>
  );
};

export { InvestmentTableRow };
