import { Box, Divider, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import { IconBriefcase, IconUser } from '@tabler/icons';
import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { IUserData } from '../../api/session';
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext';
import { CreateButton } from '../CreateButton'; //eslint-disable-line
import { IPageHeaderProps } from './PageHeader.d';

export const PageHeader: FC<IPageHeaderProps> = ({ users = false, investors = false, investments = false }) => {
  const authData = useContext(authContext);
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const secondaryColor = 'secondary.main';

  const currentRoles = useMemo(() => (authData?.userData as IUserData)?.roles || [], [authData]);

  useEffect(() => {
    if (currentRoles.length) {
      setIsCreator(havePermission(currentRoles, ['systemAdmin', 'superAdmin']));
    }
  }, [currentRoles]);

  return (
    <Box sx={{ alignContent: 'center', flexGrow: 1, pt: 4 }}>
      {users && (
        <>
          <Grid columns={{ lg: 12, sm: 8, xs: 4 }} container sx={{ p: { xs: 0 } }} spacing={3}>
            <Grid item xs={4} sm={4} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <SvgIcon sx={{ color: secondaryColor }}>
                  <IconUser />
                </SvgIcon>
                <Typography variant="h1" data-testid="test-users-title">
                  Users
                </Typography>
              </Stack>
            </Grid>

            {isCreator && (
              <Grid
                item
                xs={4}
                md={4}
                lg={4}
                sx={{ p: 0, textAlign: { sm: 'right', xs: 'left' } }}
                width={{ xs: '100%' }}
              >
                <CreateButton />
              </Grid>
            )}
          </Grid>
          <Divider sx={{ pt: 2 }} />
        </>
      )}
      {investors && (
        <>
          <Grid columns={{ lg: 12, sm: 8, xs: 4 }} container sx={{ p: { xs: 0 } }} spacing={3}>
            <Grid item xs={4} sm={4} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <SvgIcon sx={{ color: secondaryColor }}>
                  <IconBriefcase />
                </SvgIcon>
                <Typography variant="h1" data-testid="test-investors-title">
                  Investors
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 3, mt: 2 }} />
        </>
      )}
      {investments && (
        <>
          <Grid columns={{ lg: 12, sm: 8, xs: 4 }} container sx={{ p: { xs: 0 } }} spacing={3}>
            <Grid item xs={4} sm={4} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <SvgIcon sx={{ color: secondaryColor }}>
                  <IconBriefcase />
                </SvgIcon>
                <Typography variant="h1" data-testid="test-investors-title">
                  Investments
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 3, mt: 2 }} />
        </>
      )}
    </Box>
  );
};
