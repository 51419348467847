import { joiResolver } from '@hookform/resolvers/joi';
import { Theme, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  createProject,
  getProjects,
  ICreateProjectReq,
  ICreateProjectReturn,
  IProjectsResponse,
} from '../../api/projects';
import { ClosingProjectModal } from '../../components/CreateProjectSteps/ClosingProjectModal';
import { IProjectsListItem } from '../../components/ProjectsListItem';
import { havePermission } from '../../helpers/havePermission';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { authContext } from '../../hooks/useAuth/authContext';
import { INavStateToast, toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { IFilterParams, IProjectsContext } from '.'; //eslint-disable-line
import { Projects } from './Projects';
import ProjectsContext from './ProjectsContext';
import { createProjectSchema } from './schema';

const ProjectsPresenter: FC = () => {
  const nav = useNavigate();
  const controller = useAbortRequest();
  const getProjectsController = useAbortRequest();
  const { openToast } = useContext(toastContext);
  const [openCreateProject, setOpenCreateProject] = useState<boolean>(false);
  const [isClosingModalOpen, setIsClosingModalOpen] = useState<boolean>(false);
  const toggleCreateProject = () => setOpenCreateProject(!openCreateProject);
  const toggleClosingModal = () => setIsClosingModalOpen(!isClosingModalOpen);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), { noSsr: true });
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    watch,
    control,
  } = useForm<ICreateProjectReq>({
    defaultValues: {
      isUs: true,
      projectTitle: '',
      projectType: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: joiResolver(createProjectSchema),
    shouldFocusError: true,
  });
  const [projects, setProjects] = useState<IProjectsListItem[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    page: 1,
  });
  const { userData } = useContext(authContext);
  const currentRoles = useMemo(() => userData?.roles || [], [userData]);
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const { state } = useLocation();
  const navState = state as { creatingProject: boolean };

  const goToProjectDetail = (id: string, navigationState?: INavStateToast) =>
    nav(`/projects/${id}`, { state: navigationState });

  const handleCreateProject = async (projectData: ICreateProjectReq) => {
    const { data, status } = await createProject(projectData, controller.signal);
    if (isRequestError(status)) {
      const error = data as IServerError;
      openToast({
        horizontalPosition: isDesktop ? 'right' : 'center',
        message: error.message,
        mr: isDesktop ? 30 : 0,
        severity: 'error',
        title: 'Something went wrong',
        width: isDesktop ? 462 : 'auto',
      });
    } else {
      const res = data as ICreateProjectReturn;
      goToProjectDetail(res.projectId, {
        message: 'Enter the project and complete the info.',
        severity: 'success',
        title: 'Project created successfully!',
      });
    }
  };

  const verifyOnCloseAction = () => {
    if (Object.keys(dirtyFields).length > 0) {
      toggleClosingModal();
    } else {
      toggleCreateProject();
      reset();
    }
  };

  const deleteFormData = () => {
    reset();
    toggleCreateProject();
    toggleClosingModal();
  };

  const onPageChange = (_: ChangeEvent<unknown>, newPage: number) =>
    setFilterParams({ ...filterParams, page: newPage });

  const value: IProjectsContext = {
    filterParams,
    projects,
    setFilterParams,
    setProjects,
    totalPages,
  };

  const reqProjects = useCallback(async () => {
    const { data, status } = await getProjects(
      {
        ...filterParams,
        limit: isDesktop ? 9 : 8,
        page: filterParams.page,
      },
      getProjectsController.signal
    );
    if (!isRequestError(status)) {
      const projectsRes = data as IProjectsResponse;
      setProjects(projectsRes.projects);
      setTotalPages(projectsRes.pages);
    }
  }, [getProjectsController.signal, filterParams, isDesktop]);

  useEffect(() => {
    reqProjects();
  }, [reqProjects]);

  useEffect(() => {
    if (currentRoles.length) {
      setIsCreator(havePermission(currentRoles, ['financial', 'marketing', 'superAdmin']));
    }
  }, [currentRoles]);

  useEffect(() => {
    if (navState?.creatingProject) {
      setOpenCreateProject(true);
      window.history.replaceState(null, '');
      const userRoles = userData?.roles || [];
      if (!havePermission(userRoles, ['superAdmin', 'marketing', 'financial'])) {
        nav('/not-found');
      }
    }
  }, [navState, userData, nav]);

  return (
    <ProjectsContext.Provider value={value}>
      <Projects
        openCreateProject={openCreateProject}
        toggleCreateProject={verifyOnCloseAction}
        handleCreateProject={handleSubmit(handleCreateProject)}
        watch={watch}
        errors={errors}
        register={register}
        control={control}
        onPageChange={onPageChange}
        isCreator={isCreator}
      />
      <ClosingProjectModal
        title="Are you sure you want to leave the screen?"
        subtitle="If you continue with the action, the data will be lost."
        primaryButtonText="No"
        secondaryButtonText="Yes"
        open={isClosingModalOpen}
        mainAction={toggleClosingModal}
        onClose={toggleClosingModal}
        finishWorking={deleteFormData}
      />
    </ProjectsContext.Provider>
  );
};

export { ProjectsPresenter };
