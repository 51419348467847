const areas = {
  editInvestor: 'editInvestor',
  getInvestorDetail: 'getInvestorDetail',
  getInvestorInfo: 'getInvestorInfo',
  getInvestors: 'getInvestors',
  getInvestorsDetails: 'getInvestorsDetails',
  getTransactions: 'getTransactions',
};

export { areas };
