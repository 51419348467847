import { Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { INVESTMENT_STATUSES } from '../../helpers/constants';
import { Error, Interactive, Success, Warning } from '../../theme/tokens/colors'; //eslint-disable-line
import { ITransactionActionsProps } from '.';

const actionDef: { [key: string]: { borderColor: string; value: string } } = {
  manually_refunded: { borderColor: Error[600], value: 'Set as Refunded' },
  received: { borderColor: Success[500], value: 'Set as Received' },
  refund_inprocess: { borderColor: Warning[500], value: 'Refund' },
  rejected: { borderColor: Error[500], value: 'Set as Rejected' },
  to_refund: { borderColor: Warning[500], value: 'To refund' },
};

const TransactionActions: FC<ITransactionActionsProps> = ({ area, status, ...props }) => {
  const { promiseInProgress } = usePromiseTracker({ area });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const label = actionDef[status].value;
  const borderColor = actionDef[status].borderColor;

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <span
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
    >
      <Tooltip title={label} open={isTooltipVisible} placement="top" arrow>
        <Button
          variant="outlined"
          sx={{ borderColor: borderColor, height: 24, width: { lg: 126, sm: 90, xs: 126 } }}
          style={{ pointerEvents: promiseInProgress ? 'none' : 'auto' }}
          {...props}
        >
          {!promiseInProgress ? (
            <>
              <Typography
                variant="caption"
                noWrap
                sx={{
                  color: status === INVESTMENT_STATUSES.REFUND_INPROCESS ? 'white' : Interactive.secondary,
                  fontWeight: 600,
                }}
              >
                {label}
              </Typography>
            </>
          ) : (
            <CircularProgress sx={{ ml: 1 }} size={14} color="inherit" />
          )}
        </Button>
      </Tooltip>
    </span>
  );
};

export { TransactionActions };
