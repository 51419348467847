import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { IconArrowNarrowDown, IconChevronDown } from '@tabler/icons';
import { FC, useRef, useState } from 'react';

import { ISortOption, ISortSelectorFilter } from './SortSelectorFilter.d';

export const SortSelectorFilter: FC<ISortSelectorFilter> = ({
  options,
  onChange,
  resetActiveSort,
  defaultValue,
}) => {
  const sortAnchor = useRef(null);
  const [ascending, setAscending] = useState<boolean>(false);
  const [active, setActive] = useState<string>('');
  const [openSort, setOpenSort] = useState(false);

  const handleClick = (option: ISortOption) => {
    setOpenSort(false);
    const value = option?.value;
    if (value === active) {
      if (ascending) {
        resetActiveSort();
        setActive('');
      } else {
        onChange(value, 'ASC');
        setAscending(true);
      }
    } else {
      onChange(value, 'DESC');
      setAscending(false);
      setActive(value);
    }
  };

  const handleOpenSort = () => {
    setOpenSort(!openSort);
  };

  const handleSort = () => {
    setOpenSort(false);
  };

  return (
    <Box justifyContent="center" alignItems="center" display="flex">
      <Typography variant="body2" component="span" color="primary.main" noWrap>
        Sort by
      </Typography>
      <Button
        endIcon={<IconChevronDown size={16} />}
        onClick={handleOpenSort}
        ref={sortAnchor}
        sx={{ '&:hover': { bgcolor: 'transparent' } }}
      >
        {options.find((option) => option.value === active)?.label || defaultValue}

        <Box
          sx={{
            alignContent: 'center',
            mr: -0.5,
            mt: active !== '' && ascending ? -0.5 : 0,
            pl: active !== '' && ascending ? 0 : 0.5,
            pr: active !== '' && ascending ? 0.5 : 0,
            transform: active !== '' && ascending ? 'rotate(180deg)' : '',
          }}
        >
          <IconArrowNarrowDown size={16} />
        </Box>
      </Button>
      <Menu
        open={openSort}
        onClose={handleSort}
        anchorEl={sortAnchor.current}
        PaperProps={{ sx: { borderRadius: 2, boxShadow: 2 } }}
      >
        {options.map((option: ISortOption, index: number) => (
          <MenuItem
            value={option.value}
            key={`${option.value}-${index}`}
            onClick={() => handleClick(option)}
            sx={
              option.value === active
                ? {
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
                    },
                    backgroundColor: 'transparent !important',
                    color: 'secondary.main',
                    fontWeight: 'bold',
                  }
                : {}
            }
          >
            {option.label}
            <Box
              sx={{
                alignContent: 'center',
                pl: active === option.value && ascending ? 0 : 0.5,
                pr: active === option.value && ascending ? 0.5 : 0,
                pt: active === option.value && ascending ? 0 : 0.5,
                transform: active === option.value && ascending ? 'rotate(180deg)' : '',
              }}
            >
              <IconArrowNarrowDown size={16} />
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
