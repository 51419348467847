import { FC, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { resendConfirmationEmail } from '../../api/onBoarding';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { Expired } from './Expired';

const ExpiredPresenter: FC = () => {
  const controller = useAbortRequest();
  const [mailSent, setMailSent] = useState<boolean>(false);
  const { openToast, closeToast } = useContext(toastContext);
  const { state } = useLocation();
  const { email }  = state as { email: string };

  const resendEmail = async () => {
    closeToast();
    const { data, status } = await resendConfirmationEmail(email, controller.signal);
    if (isRequestError(status)) {
      const error = data as IServerError;
      openToast({
        message: error.message,
        severity: 'error',
        title: 'Something went wrong',
      });
    } else {
      setMailSent(true);
    }
  };

  return <Expired resendEmail={resendEmail} mailSent={mailSent} email={email} />;
};

export { ExpiredPresenter };
