import { Box } from '@mui/material';
import { FC } from 'react';

import { SearchBox } from '../../components/SearchBox/SearchBox'; // eslint-disable-line
import { ISearchBarProps } from './BrokersAndFA.d';

const Search: FC<ISearchBarProps> = ({ applySearch, clearSearch }) => {
  return (
    <Box width={{ md: '50%', xs: '100%' }}>
      <SearchBox onSubmit={applySearch} onClear={clearSearch} />
    </Box>
  );
};

export { Search };
