import { Box, Grid } from '@mui/material';
import { FC, useContext, useState } from 'react';

import { toCamelCase } from '../../helpers';
import { PROJECT_STATUSES } from '../../helpers/constants';
import { IFilterParams, IProjectsContext } from '../../pages/Projects';
import ProjectsContext from '../../pages/Projects/ProjectsContext';
import { projectTypes } from '../CreateProjectSteps/dropdownsData';
import { FilterChips, IChip } from '../FilterChips';
import {
  FilterOptionsModal,
  IFilterSelect,
  IFilterSelectOption,
  ISelectValues,
} from '../FilterOptionsModal';
import { SearchBox } from '../SearchBox'; //eslint-disable-line
import { SortSelectorFilter } from '../SortSelectorFilter';
import { TSortOrder } from '../TableHeaderTitle';

const filterByOptions: IFilterSelect[] = [
  {
    label: 'Status',
    options: [
      {
        label: 'Draft',
        value: PROJECT_STATUSES.DRAFT,
      },
      {
        label: 'Fundraising open',
        value: PROJECT_STATUSES.ACTIVE,
      },
      {
        label: 'Fundraising paused',
        value: PROJECT_STATUSES.PAUSED,
      },
      {
        label: 'Canceled',
        value: PROJECT_STATUSES.CANCELED,
      },
      {
        label: 'Fundraising completed',
        value: PROJECT_STATUSES.COMPLETED,
      },
      {
        label: 'Archived',
        value: PROJECT_STATUSES.ARCHIVED,
      },
      {
        label: 'Contract closed',
        value: PROJECT_STATUSES.CLOSED,
      },
      {
        label: 'Creating Tokens',
        value: PROJECT_STATUSES.CREATING_TOKENS,
      },
      {
        label: 'Tokens delivered',
        value: PROJECT_STATUSES.TOKENS_DELIVERED,
      },
      {
        label: 'Development completed',
        value: PROJECT_STATUSES.DEVELOPMENT_COMPLETED,
      },
      {
        label: 'Project completed',
        value: PROJECT_STATUSES.PROJECT_COMPLETED,
      },
    ],
  },
  {
    label: 'Project type',
    options: projectTypes,
  },
];

const sortOptions = [
  {
    label: 'Creation date',
    value: 'date',
  },
  {
    label: 'Amount confirmed',
    value: 'amountConfirmed',
  },
];

export const ProjectsFilters: FC = () => {
  const { filterParams, setFilterParams } = useContext(ProjectsContext) as IProjectsContext;
  const [chipsList, setChipsList] = useState<IChip[]>([]);
  const [modalFilters, setModalFilters] = useState<IFilterSelect[]>([...filterByOptions]);

  const getSelectOptionByLabel = (labelSelect: string, value: string): IChip => {
    const selectItem = modalFilters.filter(
      (item: IFilterSelect) => toCamelCase(item.label) === labelSelect
    );
    const options = selectItem[0].options.filter(
      (item: IFilterSelectOption) => item.value === value
    );
    return {
      label: options[0].label,
      select: labelSelect,
      value: options[0].value,
    };
  };

  const handleOnApplyFilterModal = (filtersModal: ISelectValues) => {
    const selects = Object.keys(filtersModal).filter((item: string) => filtersModal[item]);

    // creating  the chips
    const chips = selects.map((item: string) => getSelectOptionByLabel(item, filtersModal[item]));
    setChipsList(chips);

    // sending data for the request
    setFilterParams({
      ...filterParams,
      page: 1,
      ...filtersModal,
    });

    // saving the selects selected
    const selecteds = modalFilters.map((item: IFilterSelect) => {
      return {
        label: item.label,
        options: item.options.map((option: IFilterSelectOption) => {
          return {
            ...option,
            selected: filtersModal[toCamelCase(item.label)] === option.value,
          };
        }),
      };
    });
    setModalFilters(selecteds);
  };

  const handleClearFilterModal = () => {
    setChipsList([]);
    const newFilters = { ...filterParams };
    if (newFilters.status) {
      delete newFilters.status;
    }
    if (newFilters.projectType) {
      delete newFilters.projectType;
    }
    setFilterParams({
      ...newFilters,
      page: 1,
    });
  };

  const handleOnDeleteChip = (chip: IChip) => {
    setChipsList((list: IChip[]) => list.filter((item: IChip) => item.label !== chip.label));
    const selectValues = [...modalFilters].map((item: IFilterSelect) => {
      return {
        label: item.label,
        options: item.options.map((option: IFilterSelectOption) => {
          return {
            ...option,
            selected: toCamelCase(item.label) === chip.select ? false : option.selected,
          };
        }),
      };
    });
    setModalFilters(selectValues);

    const newFilters = { ...filterParams };
    delete newFilters[chip.select];
    setFilterParams({
      ...newFilters,
      page: 1,
    });
  };

  const handleOnSubmitSearch = (value: string) => {
    const currentValue = value.trim();
    if (!currentValue) {
      const newParams: IFilterParams = { ...filterParams };
      if (newParams.hasOwnProperty('search')) {
        delete newParams.search;
      }
      setFilterParams({
        ...newParams,
        page: 1,
      });
    } else {
      if (currentValue.length >= 3) {
        setFilterParams({
          ...filterParams,
          page: 1,
          search: currentValue,
        });
      }
    }
  };

  const handleChangeSort = (value: string, sortOrderBy: TSortOrder) => {
    let newSortBy;
    switch (value) {
      case 'date':
        newSortBy = sortOrderBy === 'ASC' ? 'oldest' : 'newest';
        break;
      case 'amountConfirmed':
        newSortBy = sortOrderBy === 'ASC' ? 'lowest' : 'highest';
        break;
      default:
        break;
    }
    setFilterParams({
      ...filterParams,
      limit: 10,
      page: 0,
      sortBy: `${newSortBy}`,
    });
  };

  const resetHeaderSort = () => {
    const newFilterParams: IFilterParams = {
      ...filterParams,
      limit: 10,
      page: 0,
    };
    delete newFilterParams.sortBy;
    setFilterParams(newFilterParams);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start">
      <Grid
        container
        columns={{ xs: 4, sm: 8, lg: 10 }} //eslint-disable-line
        mb={{
          xs: 2,
          lg: 3, //eslint-disable-line
        }}
        justifyContent={{
          xs: 'flex-end',
          lg: 'space-between', //eslint-disable-line
        }}
        flexWrap="wrap"
        alignItems="flex-start"
        columnSpacing={{ lg: 4 }}
      >
        {/* eslint-disable-next-line sort-keys*/}
        <Grid mb={{ xs: 3, lg: 0 }} xs={4} sm={8} lg={5} item>
          <SearchBox
            onSubmit={handleOnSubmitSearch}
            onClear={() => handleOnSubmitSearch('')}
            labelVariant="uncontained"
          />
        </Grid>

        <Grid xs={4} sm={8} lg={5} item>
          <Grid
            columns={{ xs: 4, sm: 8, lg: 5 }} //eslint-disable-line
            flexWrap="wrap"
            justifyContent="flex-end"
            flexDirection={{
              lg: 'row-reverse',
            }}
            alignItems="center"
            container
          >
            <Grid xs={4} sm={3} lg={1} item>
              <FilterOptionsModal
                onClear={handleClearFilterModal}
                onApply={handleOnApplyFilterModal}
                selects={modalFilters}
              />
            </Grid>
            <Grid
              xs={4}
              sm={8}
              lg={4}
              mt={{ xs: chipsList.length ? 2 : 1, lg: 0 }} //eslint-disable-line
              sx={{ textAlign: 'right' }}
              pr={{ lg: 3 }}
              item
            >
              <FilterChips onDelete={handleOnDeleteChip} list={chipsList} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SortSelectorFilter
        options={sortOptions}
        onChange={handleChangeSort}
        resetActiveSort={resetHeaderSort}
        defaultValue="Creation date"
      />
    </Box>
  );
};
