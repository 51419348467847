import { FC } from 'react';

import LogoImg from '../../assets/img/logo.svg';
import LightLogoImg from '../../assets/img/logo_light.svg'; //eslint-disable-line
import { IProps } from './Logo.d';

const Logo: FC<IProps> = ({ mode = 'dark' }) => {
  return <img src={mode === 'light' ? LightLogoImg : LogoImg} alt="Growie logo" />;
};

export { Logo };
