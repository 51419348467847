import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import TokensProcessingImg from '../../../assets/project-details/tokens-processing.svg'; // eslint-disable-line
import { EmptySearch } from '../../EmptyStates';

const TokensEmptyState: FC<{ creating: boolean }> = ({ creating }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Stack direction="column" spacing={2} alignItems="center" px={6}>
        {creating ? (
          <>
            <img src={TokensProcessingImg} alt="Tokens processing" width="150px" />
            <Typography variant="h2" color="text.secondary" sx={{ textAlign: 'center' }}>
              The tokens are being issued, this process can take some time. Please come back in a
              few minutes and refresh this page.
            </Typography>
          </>
        ) : (
          <EmptySearch />
        )}
      </Stack>
    </Box>
  );
};

export { TokensEmptyState };
