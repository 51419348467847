import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons';
import { FC } from 'react';

import { payoutsAreas } from '../../api/payouts';
import { ButtonSubmit } from '../ButtonSubmit'; //eslint-disable-line
import { IPayoutReportModal } from './PayoutReportDetail.d';

const ConfirmationPaymentModal: FC<IPayoutReportModal> = ({
  title,
  subtitle,
  open,
  onClose,
  mainAction,
  finishWorking,
  primaryButtonText,
  secondaryButtonText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-validate-closing"
      aria-describedby="modal-validate-closing"
      disableAutoFocus
      PaperProps={{
        sx: {
          borderRadius: 3,
          boxShadow: 4,
          p: 4,
          pt: 6,
        },
      }}
    >
      <IconButton
        sx={{ color: 'text.primary', position: 'absolute', right: 10, top: 10 }}
        onClick={onClose}
      >
        <IconX />
      </IconButton>
      <Typography variant="subtitle2" fontWeight="bold" mb={2}>
        {title}
      </Typography>
      {subtitle}
      <Stack
        direction={{ xs: 'column', sm: 'row' }} //eslint-disable-line
        mt={5}
        justifyContent="flex-end"
        spacing={2}
      >
        <Button color="secondary" onClick={finishWorking}>
          {primaryButtonText}
        </Button>
        {secondaryButtonText && (
          <ButtonSubmit
            area={payoutsAreas.setPayoutsReportUserStatus}
            color="secondary"
            onClick={mainAction}
            variant="contained"
            fullWidth={false}
          >
            {secondaryButtonText}
          </ButtonSubmit>
        )}
      </Stack>
    </Dialog>
  );
};

export { ConfirmationPaymentModal };
