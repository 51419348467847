import { IRefreshTypes, TFnAccessTokenObserver } from './main.d';

const accessTokenStatusSubject = () => {
  const methods: TFnAccessTokenObserver[] = [];
  const observe = (callback: TFnAccessTokenObserver) => methods.push(callback);
  const notify = (newStatus: IRefreshTypes) => methods.forEach((fn) => fn(newStatus));
  return { notify, observe };
};
const accessTokenStatusObserver = accessTokenStatusSubject();

export { accessTokenStatusObserver };
