import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { sessionAreas } from '../../api/session';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { InputField } from '../../components/InputField';
import { Logo } from '../../components/Logo';
import { SimplePageContainer } from '../../components/SimplePageContainer';
import { INavStateToast, toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { IProps } from './Login.d';

const Login: FC<IProps> = ({ errors, register, submitLogin }) => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { openToast } = useContext(toastContext);
  const { state } = useLocation();
  const navState = state as { toast: INavStateToast };

  useEffect(() => {
    if (navState && navState.toast) {
      openToast({ ...navState.toast });
    }
  }, [navState, openToast]);

  return (
    <SimplePageContainer>
      <Logo mode="light" />
      <Typography my={4} variant="h1">
        Welcome
      </Typography>
      <form onSubmit={submitLogin}>
        <Stack spacing={5}>
          <InputField
            id="login_email"
            label={t('login.email')}
            error={!!errors['email']}
            helperText={errors['email']?.message}
            placeholder={t('login.email')}
            {...register('email')}
          />
          <InputField
            id="login_password"
            label={t('login.password')}
            type="password"
            error={!!errors['password']}
            helperText={errors['password']?.message}
            placeholder={t('login.password')}
            {...register('password')}
          />
        </Stack>
        <Box mt={5}>
          <ButtonSubmit area={sessionAreas.login} type="submit">
            {t('login.login')}
          </ButtonSubmit>
        </Box>
      </form>
      <Button
        fullWidth
        size="small"
        color="secondary"
        sx={{ mt: 4 }}
        onClick={() => nav('/recover-password')}
      >
        {t('login.forgotPassword')}
      </Button>
    </SimplePageContainer>
  );
};

export { Login };
