const quantityBeautifier = (quantity: number): string => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(quantity);
};

const quantityBeautifierStandard = (quantity: number, withDecimals = false): string => {
  const formatter = Intl.NumberFormat('en', {
    maximumFractionDigits: withDecimals ? 2 : 0,
    minimumFractionDigits: 0,
    notation: 'standard',
  });
  return formatter.format(quantity);
};

const formatDollars = (value: number, withDecimals = false): string =>
  value.toLocaleString('en-US', {
    currency: 'USD',
    maximumFractionDigits: withDecimals ? 2 : 0,
    minimumFractionDigits: 0,
    style: 'currency',
  });

const formatTokens = (value: number): string =>
  value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

const millionFormat = (value: number): string =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export {
  formatDollars,
  formatTokens,
  millionFormat,
  quantityBeautifier,
  quantityBeautifierStandard,
};
