import { Stack } from '@mui/material';
import { FC } from 'react';

import { FilterPopover } from '../../../../components/FilterPopover';
import { Select } from '../../../../components/Select';
import { IFilterProps } from '../Commissions.d';

const filters = [
  {
    label: 'Payment Method',
    name: 'paymentMethod',
    options: [
      { label: 'Crypto', value: 'CRYPTO' },
      { label: 'Manual', value: 'MANUAL' },
      { label: 'Wire Transfer', value: 'WIRE'},
    ],
  },
  {
    label: 'Commission Status',
    name: 'commissionStatus',
    options: [
      { label: 'Failed', value: 'FAILED' },
      { label: 'Not Payable', value: 'NOT_PAYABLE' },
      { label: 'Paid Offline', value: 'PAID_OFFLINE' },
      { label: 'Paid', value: 'PAID' },
      { label: 'In Process', value: 'PAYMENT_IN_PROCESS' },
      { label: 'Pending', value: 'PENDING' },
      { label: 'Blacklisted', value: 'BLACKLISTED' },
    ],
  },
];

const Filter: FC<IFilterProps> = ({ applyFilter, clearFilter, control }) => {
  return (
    <>
      <FilterPopover
        applyFilter={applyFilter}
        clearFilter={clearFilter}
        filterOptions={
          <Stack gap={5}>
            <Select
              label={filters[0].label}
              name={filters[0].name}
              control={control}
              options={filters[0].options}
            />

            <Select
              label={filters[1].label}
              name={filters[1].name}
              control={control}
              options={filters[1].options}
            />
          </Stack>
        }
      />
    </>
  );
};

export { Filter };
