import { Button, Dialog, Grid, IconButton, ListItemText, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { IconDownload, IconFileText, IconX } from '@tabler/icons';
import { FC, useState } from 'react';

import { formatDate } from '../../helpers';
import { IFinancialReportResponse } from '../../pages/ProjectDetail/ProjectDetail.d';
import { Error, Gray, Primary } from '../../theme/tokens/colors';

export const AttachedReportsItem: FC<IFinancialReportResponse> = ({
  url,
  fileName,
  date,
  onRemoveReport,
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Grid
        direction="row"
        display="flex"
        data-testid="listItem"
        sx={{
          '&:hover': {
            backgroundColor: Gray[200],
          },
          backgroundColor: Gray[100],
          borderRadius: '8px',
          maxWidth: isMobile ? '300px' : 'inherit',
          mb: 2,
          padding: '24px 16px',
        }}
      >
        <IconFileText
          size="1.6rem"
          color={Gray[700]}
          style={{ marginBottom: 'auto', marginTop: '0.4rem' }}
        />
        <ListItemText
          disableTypography
          style={{ margin: 0 }}
          primary={
            <Typography
              data-testid="title"
              variant="body1"
              fontWeight="600"
              fontSize={{ sm: '1.6rem', xs: '1.4rem' }}
              lineHeight={{ sm: '2.4rem', xs: '2rem' }}
              color={Gray[700]}
              mx={1}
              overflow="hidden"
              textOverflow='ellipsis'
            >
              {fileName}
            </Typography>
          }
          secondary={
            <Typography
              data-testid="date"
              variant="body2"
              color={Gray[500]}
              fontWeight="300"
              ml="8px"
            >
              {formatDate(String(date))}
            </Typography>
          }
        />
        <IconButton href={url} target="_blank" download>
          <IconDownload size="2.4rem" color={Primary[600]} />
        </IconButton>
        <IconButton onClick={() => setOpen(true)}>
          <IconX size="2.4rem" color={Error[500]} />
        </IconButton>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-validate-closing"
        aria-describedby="modal-validate-closing"
        disableAutoFocus
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: 4,
            p: 4,
            pt: 6,
          },
        }}
      >
        <IconButton
          sx={{ color: 'text.primary', position: 'absolute', right: 10, top: 10 }}
          onClick={() => setOpen(false)}
        >
          <IconX />
        </IconButton>
        <Typography variant="subtitle2" fontWeight="bold" mb={2}>
          Removing financial report
        </Typography>
        <Typography variant="body1" fontWeight="bold" mb={2}>
          {`Are you sure you want to remove report ${fileName} ?`}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }} //eslint-disable-line
          mt={5}
          justifyContent="flex-end"
          spacing={2}
        >
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpen(false);
              onRemoveReport(fileName);
            }}
          >
            Yes
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};
