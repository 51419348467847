import { Box, Button, Link, List, Theme, Typography, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import format from 'date-fns/format';
import { FC, useContext, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link as RouterLink } from 'react-router-dom';

import { faBrokersAreas } from '../../../api/brokers';
import { EmptyInvestors } from '../../../components/EmptyStates';
import { MobileTableCard } from '../../../components/MobileTableCard';
import { Paginator } from '../../../components/Paginator';
import { StatusPointer } from '../../../components/ProjectInvestors';
import { ConfirmDialog } from '../../../components/ProjectStatusBar/ConfirmDialog';
import { Table } from '../../../components/Table';
import { havePermission } from '../../../helpers/havePermission';
import { formatDollars } from '../../../helpers/quantityBeautifier';
import { authContext } from '../../../hooks/useAuth/authContext';
import { IInvestorsTab } from '../FABrokers.d';


const Investors: FC<IInvestorsTab> = ({ investors, disconnectInvestor, onPageChange }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { promiseInProgress: loading } = usePromiseTracker({
    area: faBrokersAreas.getInvestorPerFaBroker,
  });
  const [tempDisconnected, setTempDisconnected] = useState<string>('');
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const [mobilepage, setMobilepage] = useState(0);
  const { userData } = useContext(authContext);
  const canEditRoles = havePermission(userData?.roles ?? [], ['marketing', 'legal']);

  const changePage = (newPage: number) => {
    setMobilepage(newPage);
    onPageChange(newPage);
  };

  const handleOpenDisconnectModal = (id: string) => {
    setOpenDisconnectModal(true);
    setTempDisconnected(id);
  };
  const toggleDisconnectModal = () => setOpenDisconnectModal(!openDisconnectModal);

  const columns: GridColDef[] = [
    {
      field: 'investor',
      flex: 2,
      headerName: 'Name',
      minWidth: 200,
      renderCell: (params) => (
        <Link
          component={RouterLink}
          to={`/investors/${params.row.id}`}
          color="secondary"
          underline="hover"
          sx={{ cursor: 'pointer' }}
          noWrap
        >
          {params.value}
        </Link>
      ),
      sortable: false,
    },
    {
      field: 'legalName',
      flex: 2,
      headerName: 'Legal Name',
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'joining_date',
      flex: 1,
      headerName: 'Joining Date',
      minWidth: 120,
      sortable: false,
      valueFormatter: ({ value }) => value && format(new Date(value), 'MM/dd/yyyy'),
    },
    {
      field: 'investment',
      flex: 1,
      headerAlign: 'right',
      headerName: 'Investment (USD)',
      minWidth: 200,
      sortable: false,
      type: 'number',
      valueFormatter: ({ value }) => value && formatDollars(value, true),
    },
    {
      field: 'isEnabled',
      flex: 1,
      headerName: 'Status',
      minWidth: 120,
      renderCell: ({ value }) => (
        <Box display="flex" alignItems="center" gap={1}>
          <StatusPointer status={value ? 'success' : 'default'} />
          {value ? 'Active' : 'Inactive'}
        </Box>
      ),
      sortable: false,
    },
    {
      field: 'action',
      flex: 1,
      headerName: 'Action',
      minWidth: 120,
      renderCell: (params) => (
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleOpenDisconnectModal(params.id + '')}
          disabled={canEditRoles}
        >
          Disconnect
        </Button>
      ),
      sortable: false,
    },
  ];

  const createMobileTableData = () => {
    const initialRage = mobilepage * 10;
    const finalRage = initialRage + 10;
    return investors.slice(initialRage, finalRage).map((investor) => ({
      action: (
        <Button
          fullWidth
          size="small"
          sx={{ fontSize: '14px', py: 0 }}
          variant="outlined"
          onClick={() => handleOpenDisconnectModal(investor.id)}
          disabled={canEditRoles}
        >
          Disconnect
        </Button>
      ),
      data: {
        Investor: (
          <Link
            component={RouterLink}
            to={`/investors/${investor.id}`}
            color="secondary"
            underline="hover"
            sx={{ cursor: 'pointer' }}
          >
            {investor.investor}
          </Link>
        ),
        'Joining Date': format(new Date(investor.joining_date), 'MM/dd/yyyy'),
        'Investment (USD)': formatDollars(investor.investment, true), //eslint-disable-line
        Status: (
          <Box display="flex" alignItems="center" gap={1}>
            <StatusPointer status={investor.isEnabled ? 'success' : 'default'} />
            {investor.isEnabled ? 'Active' : 'Inactive'}
          </Box>
        ),
      },
      id: investor.id,
    }));
  };

  return (
    <>
      {isMobile && (
        <>
          <List>
            {investors.length === 0 || loading ? (
              <EmptyInvestors />
            ) : (
              <>
                {createMobileTableData().map((row, i) => (
                  <MobileTableCard key={row.id + i} data={row.data} action={row.action} />
                ))}
              </>
            )}
          </List>
          <Paginator page={mobilepage} count={investors.length} limit={10} setPage={changePage} />
        </>
      )}
      {!isMobile && (
        <Table
          columns={columns}
          rows={investors}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{ NoRowsOverlay: EmptyInvestors }}
          area={faBrokersAreas.getInvestorPerFaBroker}
          onPageChange={changePage}
        />
      )}
      <ConfirmDialog
        display={openDisconnectModal}
        onClose={toggleDisconnectModal}
        buttons={
          <Box
            onClick={toggleDisconnectModal}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }} //eslint-disable-line
            justifyContent="flex-end"
          >
            <Button variant="text" color="secondary">
              No
            </Button>
            <Button
              variant="contained"
              sx={{ mt: { xs: 4, md: 0 } }} //eslint-disable-line
              onClick={() => disconnectInvestor(tempDisconnected)}
            >
              Yes
            </Button>
          </Box>
        }
      >
        <Typography variant="h3" fontWeight="bold" color="primary" mb={1}>
          Disconnect relationship
        </Typography>
        <Typography variant="body1" mb={4}>
          Do you want to disconnect this investor from the Broker?
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export { Investors };
