/* eslint-disable sort-keys */
import {
  IconArchive,
  IconCheckbox,
  IconCircleDashed,
  IconCurrencyDollar,
  IconEye,
  IconEyeOff,
  IconLicense,
  IconPlayerPause,
  IconRocket,
  IconSquareOff,
} from '@tabler/icons';

import { PROJECT_STATUSES } from '../../helpers/constants';
import { IProject, IProjectStatus, TContractStatus } from '../../pages/ProjectDetail';
// eslint-disable-next-line import/order
import { LicencePause, LicencePlay } from '../CustomIcons';
import { IOption } from './ProjectStatusBar.d';

const archiveOption: IOption = {
  icon: IconArchive,
  onlyFor: ['superAdmin', 'financial'],
  status: PROJECT_STATUSES.ARCHIVED,
  title: 'Archive project',
};
const openFundraisingOption: IOption = {
  icon: IconRocket,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.ACTIVE,
  title: 'Open fundraising',
};
const completeFundraisingOption: IOption = {
  icon: IconCurrencyDollar,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.COMPLETED,
  title: 'Complete fundraising',
};
const pauseFundraisingOption: IOption = {
  icon: IconPlayerPause,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.PAUSED,
  title: 'Pause fundraising',
};
const cancelProjectOption: IOption = {
  icon: IconSquareOff,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.CANCELED,
  title: 'Cancel project',
};
const resumeFundraisingOption: IOption = {
  icon: IconRocket,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.ACTIVE,
  title: 'Resume fundraising',
};
const closeContractOption: IOption = {
  icon: IconLicense,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.CLOSED,
  title: 'Close contract',
};
const createTokensOption: IOption = {
  icon: IconCircleDashed,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.CREATING_TOKENS,
  title: 'Create tokens',
};
const completeDevelopementOption: IOption = {
  icon: IconCheckbox,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.DEVELOPMENT_COMPLETED,
  title: 'Complete development',
};
const completeProjectOption: IOption = {
  icon: IconCheckbox,
  onlyFor: ['superAdmin'],
  status: PROJECT_STATUSES.PROJECT_COMPLETED,
  title: 'Complete project',
};
const hideProjectOption: IOption = {
  hidden: true,
  icon: IconEyeOff,
  onlyFor: ['superAdmin'],
  title: 'Hide project',
};
const showProjectOption: IOption = {
  hidden: false,
  icon: IconEye,
  onlyFor: ['superAdmin'],
  title: 'Show project',
};
const pauseContractOption: IOption = {
  contract: PROJECT_STATUSES.PAUSED,
  icon: LicencePause,
  onlyFor: ['superAdmin', 'financial'],
  title: 'Pause smart contract',
};
const resumeContractOption: IOption = {
  contract: PROJECT_STATUSES.ACTIVE,
  icon: LicencePlay,
  onlyFor: ['superAdmin', 'financial'],
  title: 'Resume smart contract',
};

const getHiddenOption = (hidden: boolean) => (hidden ? showProjectOption : hideProjectOption);
const getContractOption = (contract?: TContractStatus) => {
  return contract && ['paused', 'active_loading'].includes(contract)
    ? resumeContractOption
    : pauseContractOption;
};

const getOptions = (
  status: IProjectStatus,
  category: IProject['category'],
  hidden = false,
  contract?: TContractStatus
): IOption[] => {
  const options = {
    draft: [archiveOption, openFundraisingOption],
    active: [completeFundraisingOption, pauseFundraisingOption],
    paused: [
      resumeFundraisingOption,
      getHiddenOption(hidden),
      cancelProjectOption,
      completeFundraisingOption,
    ],
    canceled: [],
    completed: [resumeFundraisingOption, getHiddenOption(hidden), closeContractOption],
    archived: [],
    closed: [createTokensOption, getHiddenOption(hidden)],
    creating: [getHiddenOption(hidden)],
    delivered: [
      getHiddenOption(hidden),
      getContractOption(contract),
      category === 'DEVELOPMENT' ? completeDevelopementOption : completeProjectOption,
    ],
    development_completed: [
      getHiddenOption(hidden),
      completeProjectOption,
      getContractOption(contract),
    ],
    project_completed: [getHiddenOption(hidden)],
  };
  return options[status];
};

export { getOptions };
