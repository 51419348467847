import { FC, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { IFormOutletContext } from '../ProjectDetail.d'; //eslint-disable-line
import { Basic } from '.';

const BasicPresenter: FC = () => {
  const { validStatus, setShowEditButton, basicRole } = useOutletContext<IFormOutletContext>();
  const canEdit = validStatus && basicRole;

  useEffect(() => {
    setShowEditButton(canEdit);
  }, [canEdit, setShowEditButton]);

  return <Basic canEdit={canEdit} />;
};

export { BasicPresenter };
