import { joiResolver } from '@hookform/resolvers/joi';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate, useParams } from 'react-router-dom';

import { destroySmartContract } from '../../api/projects';
import { IAuth, ILoginRes, login } from '../../api/session';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { useAuth } from '../../hooks/useAuth';
import { toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { CompleteProject } from './CompleteProject';
import { IValidateIdentityForm } from './CompleteProject.d';
import { validateIdentitySchema } from './schema';

const defaultValues = { code: '', password: '' };

const CompleteProjectPresenter = () => {
  const {
    register,
    watch,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<IValidateIdentityForm>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: joiResolver(validateIdentitySchema),
    shouldFocusError: true,
  });
  const { id } = useParams();
  const nav = useNavigate();
  const { userData, setAuth } = useAuth();
  const { openToast } = useContext(toastContext);
  const abortCompleteProjectController = useAbortRequest();
  const [tempSession, setTempSession] = useState({ session: '', userId: '' });
  const [isAValidUser, setIsAValidUser] = useState(false);
  const password = watch('password');

  const goProjectDetail = () => nav(`/projects/${id}`);

  const validatePassword = async () => {
    const { data, status } = await login({ email: userData?.email ?? '', password });
    if (isRequestError(status)) {
      return setError('password', { message: 'Invalid password' });
    }
    const { session, userId } = data as ILoginRes;
    setIsAValidUser(true);
    setTempSession({ session, userId });
  };

  const handleCompleteProject = async (formData: IValidateIdentityForm) => {
    const { data, status } = await trackPromise(
      destroySmartContract(
        {
          code: formData.code,
          projectId: id ?? '',
          session: tempSession.session,
          userId: tempSession.userId,
        },
        abortCompleteProjectController.signal
      )
    );
    if (isRequestError(status)) {
      const error = data as IServerError;
      if (error.message.includes('Invalid code')) {
        return setError('code', { message: 'Invalid code' });
      } else {
        openToast({
          message: 'Your session has expired, you must validate the password again.',
          severity: 'error',
          title: 'Session expired',
        });
        return setIsAValidUser(false);
      }
    }
    const auth = data as IAuth;
    openToast({
      message: 'The smart contract is being closed.',
      severity: 'success',
      title: 'Project completed!',
    });
    setAuth(auth);
    goProjectDetail();
  };

  const completeProject = handleSubmit(handleCompleteProject);

  return (
    <CompleteProject
      register={register}
      errors={errors}
      completeProject={completeProject}
      validatePassword={validatePassword}
      isAValidUser={isAValidUser}
      goProjectDetail={goProjectDetail}
    />
  );
};

export { CompleteProjectPresenter };
