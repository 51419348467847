import styled from '@emotion/styled';

export const CancelButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 45px;
  width: 32px;
  height: 32px;
  border: 0;
  outline: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
`;
