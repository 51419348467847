import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { IDonutChartProps } from './DonutChart.d'; // eslint-disable-line
import { StyledCircle, StyledLabel } from './DonutChart.styled';

const DonutChart: FC<IDonutChartProps> = ({ data }) => {
  let valueInit = 0;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="190px"
      width="190px"
      position="relative"
    >
      <Box
        component="svg"
        display="flex"
        alignItems="center"
        justifyContent="center"
        left={0}
        top={0}
        width="100%"
        position="absolute"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        {data?.map((item, index) => {
          valueInit = index ? valueInit + data[index - 1].percentage : 0;
          return (
            <StyledCircle
              key={`donut-chart-circle-${index}`}
              cx="95"
              cy="95"
              r="80"
              fill="none"
              stroke={item.color}
              value={item.percentage}
              valueInit={valueInit}
            />
          );
        })}
      </Box>
      {data?.map((item, index) => {
        if (index) {
          if (data[index - 1].percentage > 3 || data[index].percentage > 3) {
            valueInit = index ? valueInit + data[index - 1].percentage : 0;
          } else {
            valueInit = valueInit + 3;
          }
        } else {
          valueInit = 0;
        }
        return (
          <StyledLabel
            key={`donut-chart-percentage-${index}`}
            value={item.percentage}
            valueInit={valueInit}
          >
            {Math.round(item.percentage * 10) / 10}%
          </StyledLabel>
        );
      })}
      <Stack direction="column" sx={{ alignItems: 'center' }}>
        <Typography variant="h1" color="grey.800" component="span">
          {data?.length}
        </Typography>
        <Typography variant="body2" component="span" fontWeight={600}>
          Projects
        </Typography>
      </Stack>
    </Box>
  );
};

export { DonutChart };
