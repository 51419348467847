export const Gray = {
  50: '#FFFFFF',
  75: '#F8F8F9',
  100: '#F1F2F4',
  200: '#E9EAED',
  300: '#DCDDE2',
  400: '#C7C8D1',
  500: '#A5A7B5',
  600: '#84889B',
  700: '#666B82',
  800: '#3C4159',
  900: '#1C2034',
  1000: '#000000',
};

export const Warning = {
  50: '#FDF6F0',
  100: '#FAE8D7',
  200: '#F6D1B0',
  300: '#F1BA88',
  400: '#E99141',
  500: '#DC7619',
  600: '#B05E14',
  700: '#84470F',
  800: '#582F0A',
  900: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2C1805;',
};

export const Error = {
  50: '#FFF3F3',
  100: '#F5D0CF',
  200: '#EAA2A0',
  300: '#E6837D',
  400: '#E2675A',
  500: '#CD402C',
  600: '#9E2A1E',
  700: '#6E1B15',
  800: '#3F0F0E',
  900: '#200807',
};

export const Success = {
  50: '#EBFAF0',
  100: '#C9F1D7',
  200: '#94E2AF',
  300: '#5ED487',
  400: '#32BC62',
  500: '#03A344',
  600: '#06854E',
  700: '#0B684A',
  800: '#0D4A3C',
  900: '#0C2D27',
};

export const Primary = {
  50: '#ECF3FF',
  75: '#D9E6FE',
  100: '#B2CCFE',
  200: '#8CB2FD',
  300: '#6699FC',
  400: '#3F80FC',
  500: '#1966FB',
  600: '#0450E2',
  700: '#0340B5',
  800: '#02205A',
  900: '#01102D',
};

export const Interactive = {
  primary: '#fff',
  secondary: '#000',
};
