import { FC, useCallback, useContext, useEffect, useState } from 'react'; //eslint-disable-line
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';

import {
  blockchainAreas,
  getSmartContractStatus,
  pauseSmartContract,
  TSmartContractStatus,
} from '../../api/blockchain';
import {
  getProjectDetail,
  IIncompleteFields,
  projectAreas,
  updateProjectDetail,
} from '../../api/projects';
import { IncompleteDialog } from '../../components/IncompleteDialog';
import { PROJECT_STATUSES } from '../../helpers/constants';
import { isRequestError } from '../../helpers/isRequestError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { IProject, IReport, ProjectDetail } from '.'; //eslint-disable-line
import { IMessage } from '../../api/main'; //eslint-disable-line
import { IIncompleteDialog, IProjectForm, IProjectStatus, PROJECT_DETAIL_ERRORS, TContractStatus } from './ProjectDetail.d';
import { formFieldsDecorator } from './decorators';

const initialMetrics = {
  averageInvestment: 0,
  committedInvestment: 0,
  committedPercentageGoal: 0,
  committedPercentageRaised: 0,
  committedPercentageStretchGoal: 0,
  exceptions: 0,
  numberOfInvestors: 0,
  receivedInvestment: 0,
  receivedPercentageGoal: 0,
  receivedPercentageRaised: 0,
  receivedPercentageStretchGoal: 0,
  targetedInvestmentAmount: 0,
  targetedInvestmentAmountStretch: 0,
  totalMoneyRaised: 0,
  whitelistAmount: 0,
};

const ProjectDetailPresenter: FC = () => {
  const { id } = useParams();
  const [metrics, setMetrics] = useState<IReport>(initialMetrics);
  const [status, setStatus] = useState<IProjectStatus | undefined>();
  const [hidden, setHidden] = useState<boolean | undefined>();
  const [contractStatus, setContractStatus] = useState<TContractStatus>();
  const [category, setCategory] = useState<IProject['category'] | undefined>();
  const [formFields, setFormFields] = useState<IProjectForm>();
  const { promiseInProgress: contractStatusLoading } = usePromiseTracker({
    area: blockchainAreas.getSmartContractStatus,
  });
  const [incompleteDialogPublish, setIncompleteDialogPublish] = useState<IIncompleteDialog>({
    display: false,
    fields: [],
  });
  const { openToast } = useContext(toastContext);
  const getProjectDetailController = useAbortRequest();
  const updateProjectDetailStatusController = useAbortRequest();

  //SET PROJECT DETAIL DATA
  const reqProjectDetail = useCallback(async () => {
    const { data, status: statusCode } = await getProjectDetail(
      id as string,
      getProjectDetailController.signal
    );
    if (!isRequestError(statusCode)) {
      const project = { ...data } as IProject;
      setMetrics(project.metrics);
      setFormFields(formFieldsDecorator(project));
      setStatus(project.status);
      setHidden(project.hidden);
      setCategory(project.category);
    }
    return { data, status: statusCode };
  }, [id, getProjectDetailController.signal]);

  //CHANGE PROJECT STATUS
  const onChangeProjectStatus = async (newStatus?: IProjectStatus) => {
    const requestData = { status: newStatus };
    const { data, status: statusCode } = await trackPromise(
      updateProjectDetail(id as string, requestData, updateProjectDetailStatusController.signal)
    );
    if (statusCode === 200) {
      setStatus(newStatus ?? status);
      if (newStatus === PROJECT_STATUSES.ACTIVE || newStatus === PROJECT_STATUSES.PAUSED) {
        reqProjectDetail();
      }
      if (newStatus === PROJECT_STATUSES.CANCELED) {
        openToast({
          message: 'Updating the project report will take some time.',
          severity: 'success',
          title: 'Successful project cancellation',
        });
        reqProjectDetail();
      }
      if (newStatus === PROJECT_STATUSES.CLOSED) {
        openToast({
          message: 'Please refresh the page to visualize the changes.',
          severity: 'success',
          title: 'The contract has been successfully closed',
        });
        reqProjectDetail();
      }
    } else if (statusCode === 409) {
      const error = data as IIncompleteFields;
      setIncompleteDialogPublish({
        display: true,
        fields: error.fields,
      });
    } else if (statusCode === 400) {
      const code = (data as IMessage).code ? ((data as IMessage).code as string) : 'default';
      if (Object.keys(PROJECT_DETAIL_ERRORS).includes(code)) {
        openToast({
          message: PROJECT_DETAIL_ERRORS[code],
          severity: 'error',
          title: 'Error updating project',
        });
      }
    }
  };

  //HIDE SHOW PROJECT
  const hideProject = async (hideAction: boolean) => {
    const { status: statusCode } = await trackPromise(
      updateProjectDetail(
        id as string,
        { hidden: hideAction },
        updateProjectDetailStatusController.signal
      )
    );
    if (!isRequestError(statusCode)) {
      setHidden(hideAction);
    }
  };

  //PAUSE RESUME SMART CONTRACT
  const handlePauseContract = () => {
    if (!contractStatusLoading) {
      const states: { [key in TContractStatus]?: TContractStatus } = {
        active: 'paused_loading',
        active_loading: 'paused',
        paused: 'active_loading',
        paused_loading: 'active',
      };
      const isPaused = contractStatus === 'paused';
      pauseSmartContract(id ?? '', !isPaused, updateProjectDetailStatusController.signal);
      setContractStatus((currStatus) => currStatus && states[currStatus]);
    }
  };

  const querySmartContractStatus = useCallback(async () => {
    const { data, status: statusCode } = await getSmartContractStatus(
      id ?? '',
      getProjectDetailController.signal
    );
    if (!isRequestError(statusCode)) {
      const currentContractStatus = data as TSmartContractStatus;
      setContractStatus(currentContractStatus);
    }
  }, [id, getProjectDetailController.signal]);

  const closeIncompletePublishDialog = () =>
    setIncompleteDialogPublish({
      ...incompleteDialogPublish,
      display: false,
    });

  useEffect(() => {
    trackPromise(reqProjectDetail(), projectAreas.getProjectDetail);
  }, [reqProjectDetail]);

  useEffect(() => {
    if ([PROJECT_STATUSES.TOKENS_DELIVERED, PROJECT_STATUSES.DEVELOPMENT_COMPLETED, PROJECT_STATUSES.PROJECT_COMPLETED].includes(status as PROJECT_STATUSES ?? '')) {
      querySmartContractStatus();
    }
  }, [status, querySmartContractStatus]);

  useEffect(() => {
    let statusPooling: NodeJS.Timer;
    if (
      ['paused_loading', 'active_loading', 'destroy_in_progress'].includes(contractStatus ?? '')
    ) {
      statusPooling = setInterval(querySmartContractStatus, 10000);
    }
    return () => {
      clearInterval(statusPooling);
    };
  }, [status, querySmartContractStatus, contractStatus]);
  return (
    <>
      <ProjectDetail
        status={{ hidden, status }}
        category={category}
        handleStatusChange={onChangeProjectStatus}
        handleHideProject={hideProject}
        handlePauseContract={handlePauseContract}
        reqProjectDetail={reqProjectDetail}
        contractStatus={contractStatus}
        metrics={metrics}
        form={formFields}
      />
      <IncompleteDialog
        title="You cannot publish the project"
        display={incompleteDialogPublish.display}
        onClose={closeIncompletePublishDialog}
        fields={incompleteDialogPublish.fields}
      />
    </>
  );
};

export { ProjectDetailPresenter };
