import { Box, Button, Stack } from '@mui/material';
import { FC } from 'react';

import { Tooltip } from '../../../components/Tooltip'; //eslint-disable-line
import { INavigationProps } from './Payouts.presenter.d';

const Header: FC<INavigationProps> = ({ goToCreateReport, disabled }) => {
  return (
    <Box height={64} sx={{ borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}` }}>
      <Stack direction="row" spacing={1} justifyContent="flex-end" px={2} py={2}>
        <Tooltip title={disabled ? 'The contract must be paused before creating it' : ''}>
          <span>
            <Button
              disabled={disabled}
              color="secondary"
              variant="contained"
              onClick={goToCreateReport}
              sx={{
                fontSize: 14,
                height: 32,
              }}
            >
              Create payouts
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export { Header };
