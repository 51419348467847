import { Button, Menu, MenuItem } from '@mui/material';
import { IconChevronDown } from '@tabler/icons';
import { FC, useRef } from 'react';

import { ICommissions } from '../../../../api/commissions';
import { IPaymentStatusButtonProps } from '../Commissions.d';

const PaymentStatusButton: FC<IPaymentStatusButtonProps> = ({
  handleItemSelection,
  isDisabled,
  openMenu,
  toggleMenu,
}) => {
  const menuAnchor = useRef<HTMLButtonElement>(null);
  const anchorWidth = menuAnchor?.current && menuAnchor.current.offsetWidth;

  const options: ICommissions['status'][] = ['PAID', 'PENDING'];

  return (
    <>
      <Button
        disabled={isDisabled}
        endIcon={<IconChevronDown />}
        onClick={toggleMenu}
        ref={menuAnchor}
        sx={{ fontSize: 14, height: 32, width: { md: '40%', sm: '100%' }, whiteSpace: 'nowrap' }} //eslint-disable-line
        variant="outlined"
      >
        Set payment status
      </Button>

      <Menu
        open={openMenu}
        anchorEl={menuAnchor.current}
        PaperProps={{ sx: { borderRadius: 2, boxShadow: 2, width: anchorWidth } }}
        onClose={toggleMenu}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleItemSelection(option)}
            sx={{ pl: 3, py: 1.5 }}
          >
            Set as {option.toLowerCase()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export { PaymentStatusButton };
