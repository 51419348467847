import { Box, capitalize, Typography } from '@mui/material';
import { FC } from 'react';

import { IUserDataColumn } from './UserDescriptionCard.d';

const UserDataColumn: FC<IUserDataColumn> = ({ data = {} }) => {
  return (
    <Box>
      {Object.keys(data).map((item) => (
        <Typography key={`${item}:${data[item]}`} variant="body1">
          <strong>{capitalize(item)}: </strong> {data[item]}
        </Typography>
      ))}
    </Box>
  );
};
export { UserDataColumn };
