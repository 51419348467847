const projectTypes = [
  { label: 'Land for Development', value: 'land' },
  { label: 'Repositioning of Existing Asset (Development)', value: 'repositioning_development' },
  { label: 'Repositioning of Existing Asset (Income producing)', value: 'repositioning_income' },
  { label: 'Existing Retail', value: 'retail' },
  { label: 'Existing Office', value: 'office' },
  { label: 'Existing Multifamily', value: 'multifamily' },
  { label: 'Existing Industrial', value: 'industrial' },
  { label: 'Loans', value: 'loans' },
];

export { projectTypes };
