import { StepConnector, stepConnectorClasses, styled } from '@mui/material';

const StepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}.${stepConnectorClasses.vertical}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: `2px dashed ${theme.palette.secondary.main}`,
    },
  },
  [`&.${stepConnectorClasses.active}.${stepConnectorClasses.horizontal}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: `2px dashed ${theme.palette.secondary.main}`,
    },
  },
  [`&.${stepConnectorClasses.completed}.${stepConnectorClasses.vertical}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
    },
  },
  [`&.${stepConnectorClasses.completed}.${stepConnectorClasses.horizontal}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: `2px solid ${theme.palette.secondary.main}`,
    },
  },
  [`& .MuiStepConnector-line.MuiStepConnector-lineHorizontal`]: {
    borderTop: `2px dashed ${theme.palette.text.secondary}`,
  },
  [`& .MuiStepConnector-line.MuiStepConnector-lineVertical`]: {
    borderLeft: `2px dashed ${theme.palette.text.secondary}`,
    height: '100px',
  },
}));

const StepIconStyles = {
  sx: {
    '&.MuiSvgIcon-root.MuiStepIcon-root': {
      color: 'text.secondary',
      fontWeight: 'bold',
    },
    '&.MuiSvgIcon-root.MuiStepIcon-root.Mui-active': {
      color: 'secondary.main',
    },
    '&.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed': {
      color: 'secondary.main',
    },
  },
};

export { StepIconStyles, StepperConnector };
