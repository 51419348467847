/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { IconArrowNarrowDown } from '@tabler/icons';
import { FC } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { EmptyOverlay, EmptySearch } from '../EmptyStates';
import { OverlayLoader } from '../OverlayLoader'; //eslint-disable-line
import { ITableProps } from './Table.d';
import { StyledGridRow, StyledIconArrowNarrowDown, StyledIconArrowNarrowUp } from './Table.styled';

const Table: FC<ITableProps> = ({
  area,
  columns,
  components,
  componentsProps,
  isLoading = false,
  handleSelection,
  rows,
  sx,
  ...rest
}) => {
  const fixedHeight = components?.Toolbar
    ? { xs: '720px', sm: '520px' } //eslint-disable-line
    : { xs: '630px', sm: '430px' }; //eslint-disable-line
  const testEnv = process.env.NODE_ENV === 'test';
  const { promiseInProgress: loading } = usePromiseTracker({ area });
  return (
    <Box
      data-testid="test-table"
      height={rows.length === 0 ? fixedHeight : 'auto'}
      sx={{
        '& .MuiDataGrid-columnHeader--sortable, & :not(.MuiDataGrid-columnHeader--sortable)': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
          visibility: 'visible',
          width: 'auto',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
          {
            '&:hover': {
              color: 'text.primary',
            },
            color: 'text.secondary',
            opacity: 1,
          },
        border: ({ palette }) => `1px solid ${palette.grey[300]}`,
        borderRadius: 2,
        position: 'relative',
      }}
    >
      <DataGrid
        columns={columns}
        rows={rows}
        rowHeight={48}
        autoHeight={rows.length !== 0}
        headerHeight={48}
        hideFooterSelectedRowCount
        disableSelectionOnClick={true}
        disableVirtualization={testEnv}
        loading={loading || isLoading}
        onSelectionModelChange={(selection) => {
          handleSelection && handleSelection(selection);
        }}
        components={{
          ...components,
          ColumnMenuIcon: Box,
          ColumnSortedAscendingIcon: ({ sortingOrder, ...other }) => (
            <StyledIconArrowNarrowUp {...other} />
          ),
          ColumnSortedDescendingIcon: ({ sortingOrder, ...other }) => (
            <StyledIconArrowNarrowDown {...other} />
          ),
          ColumnUnsortedIcon: ({ sortingOrder, ...other }) => <IconArrowNarrowDown {...other} />,
          LoadingOverlay: EmptyOverlay,
          NoResultsOverlay: EmptySearch,
          Row: StyledGridRow,
        }}
        componentsProps={{
          ...componentsProps,
          baseCheckbox: { color: 'secondary' },
        }}
        sx={{
          ...sx,
          '& .MuiDataGrid-cell a': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
            flexDirection: 'row',
            justifyContent: 'flex-end',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: 'primary.main',
            fontSize: 14,
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-overlay': {
            height: 'auto !important',
          },
          '& .MuiDataGrid-row:hover': {
            '&:nth-of-type(odd)': {
              backgroundColor: 'grey.100',
            },
            backgroundColor: 'white',
          },
          bgcolor: 'background.paper',
          border: 'none',
          borderRadius: 'inherit',
        }}
        {...rest}
      />

      <OverlayLoader theme="container" area={area} isLoading={isLoading} />
    </Box>
  );
};

export { Table };
