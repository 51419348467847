import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Dialog, IconButton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { IconX } from '@tabler/icons';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  blockchainAreas,
  createPlan,
  fullyCreateTokens,
  ICreateTokenRequest,
  ICreateTokensResponse,
  ITokenItem,
  verifyTokens,
} from '../../api/blockchain';
import { ILoginRes, login } from '../../api/session';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { isRequestError } from '../../helpers/isRequestError';
import { getLastPath } from '../../helpers/URLHelpers';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { authContext } from '../../hooks/useAuth/authContext';
import { requireValidationContext } from '../../hooks/useRequireValidationOnLeave';
import { toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { CreateTokens } from './CreateTokens';
import { ITokensForm } from './CreateTokens.d';
import { createTokenSchema, verifyTokenSchema } from './schema';

const createToken = 'create-tokens';
const setTokens = 'set-tokens';
const confirm = 'confirm';

const defaultSteps = [
  { active: true, label: 'Review investments', path: createToken },
  { active: false, label: 'Set tokens', path: setTokens },
  { active: false, label: 'Confirmation', path: confirm },
];

const stepsNav: { [key: string]: string } = {
  [createToken]: setTokens,
  [setTokens]: confirm,
};

const tokenStorage = 'projectTokens';

const CreateTokensPresenter: FC = () => {
  const { pathname } = useLocation();
  const nav = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const navState = state as { projectName: string };
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [steps, setSteps] = useState(defaultSteps);
  const [totalTokens, setTotalTokens] = useState<number>(0);
  const [tokensList, setTokensList] = useState<ITokenItem[]>([]);
  const [canCreateTokens, setCanCreateTokens] = useState<boolean>(false);
  const [twoFAData, setTwoFAData] = useState({
    code: '',
    session: '',
    userId: '',
  });
  const { userData, setAuth } = useContext(authContext);
  const { promiseInProgress } = usePromiseTracker({ area: blockchainAreas.createPlan });
  const [isPlanError, setIsPlanError] = useState(false);
  const {
    setIsValidationRequired,
    isValidationModalOpen,
    setIsValidationModalOpen,
    navigationRoute,
    clearValidation,
    showValidationOnNavigation,
  } = useContext(requireValidationContext);
  const createPlanController = useAbortRequest();
  const verifyTokensController = useAbortRequest();
  const createTokensController = useAbortRequest();
  const { openToast } = useContext(toastContext);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    unregister,
    formState: { errors },
    setFocus,
  } = useForm<ITokensForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: joiResolver(pathname.endsWith(confirm) ? createTokenSchema : verifyTokenSchema),
    shouldFocusError: true,
  });
  const password = watch('password');
  const goBack = () => nav(-1);
  const goNext = () => nav(stepsNav[getLastPath(pathname)], { state: navState });

  const computeTokensTotal = (obj: ITokenItem[]) => {
    const total = obj.reduce((acc, curr) => acc + curr.amount, 0);
    setTotalTokens(total);
  };

  const createTokensPlan = useCallback(async () => {
    try {
      const tokens = await createPlan(id as string, createPlanController.signal);
      computeTokensTotal(tokens);
      setTokensList(tokens);
    } catch (error) {
      setIsPlanError(true);
      openToast({
        message: 'Something bad happend, try again later',
        severity: 'error',
        title: 'Network error',
      });
    }
  }, [id, openToast, createPlanController.signal]);

  const handleVerifyTokens = async ({ name, symbol }: ICreateTokenRequest) => {
    const { data } = await verifyTokens(
      id as string,
      { name, symbol },
      verifyTokensController.signal
    );
    const res = data as ICreateTokensResponse;
    sessionStorage.setItem(tokenStorage, JSON.stringify({ name, symbol }));
    if (!res.name.length && !res.symbol.length) {
      return goNext();
    }
    if (res.name.length > 0) {
      setError('name', { message: 'This name is already taken' });
    }
    if (res.symbol.length > 0) {
      setError('symbol', { message: 'This symbol is already taken' });
    }
  };

  const validatePassword = async () => {
    const { data, status } = await login({ email: userData?.email as string, password });
    if (!isRequestError(status)) {
      const res = data as ILoginRes;
      setCanCreateTokens(true);
      setTwoFAData({ ...twoFAData, session: res.session, userId: res.userId });
    } else {
      setError('password', { message: 'Invalid password' });
    }
  };

  const handleCreateTokens = async (formData: ITokensForm) => {
    try {
      const tokens = await trackPromise(
        fullyCreateTokens(
          id as string,
          twoFAData.session,
          formData.code,
          twoFAData.userId,
          { name: formData.name, symbol: formData.symbol },
          createTokensController.signal
        ),
        blockchainAreas.createTokens
      );
      setAuth(tokens);
      nav(`/projects/${id}`, {
        state: {
          message: 'Token creation and distribution process successfully started!',
          severity: 'success',
        },
      });
      sessionStorage.removeItem(tokenStorage);
    } catch (error) {
      const err = (error as Error).message;
      if (err.includes('Invalid code')) {
        setError('code', { message: 'Invalid code' });
      } else {
        setCanCreateTokens(false);
        setFocus('password');
        openToast({
          message: 'Your session has expired, you must validate the password again.',
          severity: 'error',
          title: 'Session expired',
        });
      }
    }
  };

  const handleSubmitTokens = async (formData: ITokensForm) => {
    if (pathname.endsWith(setTokens)) {
      handleVerifyTokens({ name: formData.name, symbol: formData.symbol });
    } else {
      handleCreateTokens(formData);
    }
  };

  const leavePage = () => {
    clearValidation();
    nav(navigationRoute);
  };

  const validateOnPageLeave = () => showValidationOnNavigation(`/projects/${id}`);

  useEffect(() => {
    createTokensPlan();
  }, [createTokensPlan]);

  useEffect(() => {
    const savedTokens = sessionStorage.getItem(tokenStorage) ?? '{"name":"","symbol":""}';
    const parsed = JSON.parse(savedTokens);
    setValue('name', parsed.name);
    setValue('symbol', parsed.symbol);
    return () => {
      sessionStorage.removeItem(tokenStorage);
    };
  }, [setValue]);

  useEffect(() => {
    const selected = defaultSteps.map((step) => {
      if (pathname.endsWith(step.path)) {
        return { ...step, active: true };
      }
      return { ...step, active: false };
    });
    setSteps(selected);
  }, [pathname]);

  useEffect(() => {
    if (isMobile) {
      setSteps((currsteps) =>
        currsteps.map((step, i) => {
          if (i === 0) {
            return { ...step, label: 'Review' };
          }
          if (i === 2) {
            return { ...step, label: 'Confirm' };
          }
          return step;
        })
      );
    } else {
      setSteps((currsteps) =>
        currsteps.map((step, i) => ({ ...step, label: defaultSteps[i].label }))
      );
    }
  }, [isMobile]);

  useEffect(() => {
    setIsValidationRequired(true);
    return () => {
      clearValidation();
    };
  }, [setIsValidationRequired]); //eslint-disable-line

  return (
    <>
      <CreateTokens
        validateOnPageLeave={validateOnPageLeave}
        steps={steps}
        projectName={navState?.projectName}
      >
        <form onSubmit={handleSubmit(handleSubmitTokens)}>
          <Outlet
            context={{
              canCreateTokens,
              errors,
              register,
              tokensList,
              totalTokens,
              unregister,
              validatePassword,
              watch,
            }}
          />
          <Stack direction="row" justifyContent="flex-end" mt={3} gap={3}>
            {!pathname.endsWith(createToken) && (
              <Button type="button" variant="outlined" onClick={goBack}>
                Previous
              </Button>
            )}
            {pathname.endsWith(createToken) && (
              <Button
                disabled={promiseInProgress || isPlanError}
                type="button"
                variant="contained"
                color="secondary"
                onClick={goNext}
              >
                Next
              </Button>
            )}
            {pathname.endsWith(setTokens) && (
              <ButtonSubmit fullWidth={false} type="submit" area={blockchainAreas.verifyTokens}>
                Next
              </ButtonSubmit>
            )}
            {pathname.endsWith(confirm) && (
              <ButtonSubmit
                fullWidth={false}
                type="submit"
                disabled={!canCreateTokens}
                area={blockchainAreas.createTokens}
              >
                Create tokens
              </ButtonSubmit>
            )}
          </Stack>
        </form>
      </CreateTokens>
      <Dialog
        onClose={() => setIsValidationModalOpen(false)}
        open={isValidationModalOpen}
        PaperProps={{
          sx: { borderRadius: 2, boxShadow: 4, pb: 5, position: 'relative', pt: 7, px: 3 },
        }}
      >
        <IconButton
          sx={{ color: 'primary.main', position: 'absolute', right: 10, top: 10 }}
          onClick={() => setIsValidationModalOpen(false)}
        >
          <IconX />
        </IconButton>
        <Typography variant="h3" mb={1}>
          Leave creation and distribution tokens
        </Typography>
        <Typography>Are you sure you want to leave this process?</Typography>
        <Stack direction="row" justifyContent="flex-end" mt={4}>
          <Button variant="text" onClick={() => setIsValidationModalOpen(false)}>
            No
          </Button>
          <Button variant="contained" onClick={leavePage}>
            Yes
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export { CreateTokensPresenter };
