import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { FC, useContext } from 'react';

import {
  commissionsAreas,
  decorateMethodLabels,
  decorateStatusLabels,
} from '../../../api/commissions';
import { DownloadButton } from '../../../components/DownloadButton/DownloadButton';
import { Table } from '../../../components/Table';
import { havePermission } from '../../../helpers/havePermission';
import { quantityBeautifierStandard } from '../../../helpers/quantityBeautifier';
import { authContext } from '../../../hooks/useAuth/authContext'; // eslint-disable-line
import { ICommissionsProps } from './Commissions.d';
import { EmptyStateReport } from './components/EmptyStateReport';
import { Filter } from './components/Filter';
import { PayButton } from './components/PayButton';
import { PaymentStatusButton } from './components/PaymentStatusButton';
import { Search } from './components/Search';
import { TableHeader } from './TableHeader';

const Commissions: FC<ICommissionsProps> = ({
  applyFilter,
  applySearch,
  clearFilter,
  clearSearch,
  commissions,
  control,
  generateReport,
  handleItemSelection,
  isDisabled,
  onPaySubmit,
  openMenu,
  selectedRows,
  setSelectedRows,
  toggleMenu,
}) => {
  const { userData } = useContext(authContext);
  const financialRole = havePermission(userData?.roles ?? [], ['financial', 'superAdmin']);

  const columns: GridColDef[] = [
    {
      field: 'associate',
      flex: 3,
      headerName: 'Associate',
      minWidth: 200,
    },
    {
      field: 'commission',
      flex: 2,
      headerName: '(USD) Commission',
      minWidth: 80,
      type: 'number',
      valueFormatter: ({ value }) => value && quantityBeautifierStandard(value, true),
    },
    {
      field: 'rate',
      flex: 1,
      headerName: 'Rate',
      minWidth: 80,
      type: 'number',
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'paymentDate',
      flex: 1,
      headerName: 'Payment Date',
      minWidth: 210,
      type: 'date',
      valueFormatter: ({ value }) => (value ? format(new Date(value), 'MM/dd/yyyy HH:mm') : '-'),
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Commission Status',
      minWidth: 150,
      sortable: false,
      valueFormatter: ({ value }) => value && decorateStatusLabels(value),
    },
    {
      field: 'paymentMethod',
      flex: 1,
      headerName: 'Payment Method',
      minWidth: 150,
      sortable: false,
      valueFormatter: ({ value }) => (value ? decorateMethodLabels(value) : '-'),
    },
    {
      field: 'payoutAccount',
      headerName: 'Payout account',
      valueFormatter: ({ value }) => (value === true ? 'Yes' : 'No'),
      width: 88,
    },
  ];

  return (
    <Table
      area={commissionsAreas.getCommissions}
      checkboxSelection
      columns={columns}
      isRowSelectable={({ row }) => row.status !== 'PAID' && row.status !== 'PAYMENT_IN_PROCESS'}
      rows={commissions}
      onSelectionModelChange={setSelectedRows}
      selectionModel={selectedRows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      components={{ NoRowsOverlay: EmptyStateReport, Toolbar: TableHeader }}
      componentsProps={{
        toolbar: {
          downloadButton: (
            <DownloadButton
              fileName={`Commissions_Report_${new Date().toDateString()}`}
              data={generateReport()}
            />
          ),
          filter: <Filter applyFilter={applyFilter} clearFilter={clearFilter} control={control} />,
          payButton: <PayButton isDisabled={isDisabled || !financialRole || process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true'} onPaySubmit={onPaySubmit} />,
          paymentStatusButton: (
            <PaymentStatusButton
              handleItemSelection={handleItemSelection}
              isDisabled={isDisabled || !financialRole}
              openMenu={openMenu}
              toggleMenu={toggleMenu}
            />
          ),
          search: <Search applySearch={applySearch} clearSearch={clearSearch} />,
        },
      }}
    />
  );
};

export { Commissions };
