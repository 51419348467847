import { createContext, Dispatch, SetStateAction } from 'react';

import { IInvestment } from '../../components/PageContent'; //eslint-disable-line
import { IFilterParams } from '.';

export interface IInvestmentsContextType {
  lastPage: boolean;
  investments: IInvestment[];
  isLoading: boolean;
  setInvestments: Dispatch<SetStateAction<IInvestment[]>>;
  filterParams: IFilterParams;
  setFilterParams: Dispatch<SetStateAction<IFilterParams>>;
}

const InvestmentsContext = createContext({} as IInvestmentsContextType);

export default InvestmentsContext;
