import { createContext, Dispatch, SetStateAction } from 'react';

import { IInvestor } from '../../components/PageContent'; //eslint-disable-line
import { IFilterParams } from '.';

export interface IInvestorsContextValue {
  lastPage: boolean;
  investors: IInvestor[];
  isLoading: boolean;
  setInvestors: Dispatch<SetStateAction<IInvestor[]>>;
  filterParams: IFilterParams;
  setFilterParams: Dispatch<SetStateAction<IFilterParams>>;
}

const InvestorsContext = createContext({} as IInvestorsContextValue);

export default InvestorsContext;
