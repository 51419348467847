import { Box, Button, Drawer, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { IconCircleX } from '@tabler/icons';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { faBrokersAreas } from '../../api/brokers';
import { Interactive } from '../../theme/tokens/colors';
import { Breadcrumbs } from '../Breadcrumbs';
import { ButtonSubmit } from '../ButtonSubmit';
import { InputEndAdornment } from '../InputField'; //eslint-disable-line
import { ICommissionsModal } from './CommissionsModal.d';

const CommissionsModal: FC<ICommissionsModal> = ({
  title,
  subtitle,
  path,
  currentCommissionsRate,
  handleCommission,
  display = false,
  onClose,
}) => {
  const [commissionsRate, setCommissionsRate] = useState<string>(currentCommissionsRate + '');
  const [error, setError] = useState<string>('');
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const validateRate = (value: string) => {
    const rate = Number(value);
    if (rate <= 0) {
      setError('The rate is required');
      return false;
    }
    setError('');
    return true;
  };

  const handleSetCommission = async () => {
    if (!validateRate(commissionsRate)) {
      return;
    }
    await handleCommission(Number(commissionsRate));
    onClose();
  };

  const handleCommissionsRateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    validateRate(value);
    if (/^$|^\d{0,2}(\.\d{0,2})?$/g.test(value)) {
      setCommissionsRate(value);
    }
  };

  const handleClose = () => {
    onClose();
    setError('');
  };

  useEffect(() => {
    setCommissionsRate(`${currentCommissionsRate}`);
  }, [currentCommissionsRate]);

  return (
    <>
      <Drawer
        open={display}
        anchor={isDesktop ? 'right' : 'bottom'}
        onClose={handleClose}
        sx={{
          width: {
            xs: '100%',
          },
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        PaperProps={{
          sx: {
            height: { xs: '90%', lg: '100%' }, //eslint-disable-line
            width: { xs: '100%', lg: '512px' }, //eslint-disable-line
          },
        }}
      >
        <Box
          px={{ md: 4, xs: 3 }}
          py={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumbs
            current={'Modify Commission'}
            linksList={[
              { active: false, label: 'Home', path: '/' },
              {
                active: false,
                label: path,
                path: '',
              },
            ]}
          />
          <Button
            onClick={handleClose}
            endIcon={<IconCircleX size={20} />}
            variant="text"
            sx={{ fontSize: '1.2rem', fontWeight: 600, textTransform: 'capitalize' }}
          >
            Close
          </Button>
        </Box>
        <Box
          px={{ md: '40px', xs: '24px' }}
          sx={{ alignSelf: 'center', display: 'flex', height: '100%' }}
        >
          <Grid columns={{ xs: 5 }} spacing={4} container sx={{ alignContent: 'center' }}>
            <Grid item xs={5} sm={4}>
              <Typography variant="h1" fontWeight={700} color={Interactive.secondary}>
                {title} Commission Rate
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1" color={Interactive.secondary}>
                {subtitle}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <InputEndAdornment
                id="edit_commission_Rate"
                name="commission_rate"
                label="Rate"
                adornment="%"
                onChange={handleCommissionsRateChange}
                labelVariant="contained"
                value={commissionsRate}
                error={!!error}
                helperText={error}
              />
            </Grid>
            <Grid item xs={5}>
              <ButtonSubmit
                area={faBrokersAreas.setCommissionRate}
                onClick={handleSetCommission}
                fullWidth
                sx={{ mb: 2 }}
              >
                Set Commission
              </ButtonSubmit>
              <Button color="secondary" onClick={onClose} fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export { CommissionsModal };
