import { IconButton } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons';
import { FC } from 'react';

import { Gray } from '../../theme/tokens/colors';
import { Tooltip } from '../Tooltip'; //eslint-disable-line
import { IInfoIcon } from './InfoIcon.d';

export const InfoIcon: FC<IInfoIcon> = ({ children, height, width, svgProps }) => {
  return (
    <Tooltip title={children}>
      <IconButton
        size="small"
        sx={{
          '& svg': svgProps,
          ':hover': { background: 'none', color: 'secondary.main' },
          color: Gray[700],
          height: height,
          ml: '6px',
          p: 0,
          width: width,
        }}
      >
        <IconInfoCircle />
      </IconButton>
    </Tooltip>
  );
};
