import { IObjGeneric } from './csvExport.d';

export const csvExport = (arr: IObjGeneric[], fileName: string, extraData?: string[]) => {
  // building
  const breakLine = '\r\n';
  const header = Object.keys(arr[0]).join(',');
  const rows = arr.map((item: IObjGeneric) => Object.values(item).join(','));
  let finalArr = [header, ...rows];
  if ( extraData ) {
    finalArr = [ ...extraData, ...finalArr ];
  }
  const allData = finalArr.join(breakLine);

  //exporting
  const a = document.createElement('a');
  const universalBOM = '\uFEFF';
  a.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + allData);
  a.download = fileName + '.csv';
  document.body.appendChild(a);
  setTimeout(function () {
    a.click();
    document.body.removeChild(a);
  }, 66);
};
