import { ComponentType, FC, useContext } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

import { IUserData } from '../api/session';
import { havePermission } from '../helpers/havePermission';
import { authContext } from '../hooks/useAuth/authContext';

interface IProps extends RouteProps {
  component: ComponentType;
  justForRoles?: string[];
}

const PrivateRoute: FC<IProps> = ({ component: Component, justForRoles }) => {
  const auth = useContext(authContext);
  const currentRoles = (auth?.userData as IUserData)?.roles || [];

  if (!auth?.auth?.accessToken) {
    return <Navigate to="/login" />;
  }
  if (!justForRoles || !currentRoles.length) {
    return <Component />;
  }
  return havePermission(currentRoles, justForRoles) ? <Component /> : <Navigate to="/not-found" />;
};

const LoggedRestricted: FC<IProps> = ({ component: Component }) => {
  const auth = useContext(authContext);
  if (auth?.auth?.accessToken) {
    return <Navigate to="/" state={{ logged: true }} />;
  }
  return <Component />;
};

export { LoggedRestricted, PrivateRoute };
