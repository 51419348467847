import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { quantityBeautifier } from '../../helpers/quantityBeautifier'; // eslint-disable-line
import { Interactive, Primary } from '../../theme/tokens/colors';
import { BarChart } from '../BarChart';
import { IMetricsProps } from '../CreateProjectSteps';

const RaiseStatus: FC<IMetricsProps> = ({ metrics }) => {
  return (
    <>
      <Box height={172} width={300} data-testid="raise-status">
        <Typography variant="h3" color={Interactive.secondary}>
          Raise status:
        </Typography>
        <Box
          mb={0.5}
          sx={{
            height: '20px',
            textAlign: 'right',
          }}
          data-testid="goal-chart"
        >
          <Typography variant="body2" component="span">
            Goal: <strong>{quantityBeautifier(metrics?.targetedInvestmentAmount ?? 0)}</strong>
          </Typography>
        </Box>
        <BarChart
          data1={metrics?.receivedPercentageGoal}
          data2={metrics?.committedPercentageGoal}
        />
        <Box
          mb={0.5}
          sx={{
            height: '20px',
            textAlign: 'right',
          }}
          data-testid="stretch-goal-chart"
        >
          <Typography variant="body2" component="span">
            Stretch goal:{' '}
            <strong>{quantityBeautifier(metrics?.targetedInvestmentAmountStretch ?? 0)}</strong>
          </Typography>
        </Box>
        <BarChart
          data1={metrics?.receivedPercentageStretchGoal}
          data2={metrics?.committedPercentageStretchGoal}
        />
        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 1, width: '100%' }}>
          <Box
            sx={{
              backgroundColor: Primary[300],
              borderRadius: 1,
              height: '20px',
              width: '20px',
              zIndex: 3,
            }}
          ></Box>
          <Box
            sx={{
              height: '20px',
              marginRight: 1,
              textAlign: 'left',
            }}
          >
            <Typography variant="body2" component="span">
              Money received
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: Primary[800],
              borderRadius: 1,
              height: '20px',
              width: '20px',
              zIndex: 2,
            }}
          ></Box>{' '}
          <Box
            sx={{
              height: '20px',
              marginRight: 1,
              textAlign: 'left',
            }}
          >
            <Typography variant="body2" component="span">
              Money committed
            </Typography>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export { RaiseStatus };
