/* eslint-disable sort-keys */
import { Box, Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IconChartPie2, IconEditCircle } from '@tabler/icons';
import { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { faBrokersAreas } from '../../../api/brokers';
import { getProjectNames } from '../../../api/projects/projects';
import { IProjectNames } from '../../../api/projects/projects.d';
import { CommissionsModal } from '../../../components/CommissionsModal';
import { CommissionsPerProject } from '../../../components/CommissionsPerProject';
import { EmptyCommissionsPerProject } from '../../../components/EmptyStates/EmptyCommissionsPerProject';
import { IOption } from '../../../components/Select';
import { Table } from '../../../components/Table';
import { UserDataCard } from '../../../components/UserDataCard';
import { PROJECT_STATUSES } from '../../../helpers/constants';
import { havePermission } from '../../../helpers/havePermission';
import { isRequestError } from '../../../helpers/isRequestError';
import { IServerError } from '../../../helpers/reportError';
import { useAbortRequest } from '../../../hooks/useAbortRequest';
import { authContext } from '../../../hooks/useAuth/authContext';
import { toastContext } from '../../../hooks/useToast';
import { ICommissionsTab, IFormattedCommissionsPerProject } from '../FABrokers.d'; //eslint-disable-line

const tableColumns: GridColDef[] = [
  {
    field: 'project',
    headerName: 'Project Name',
    headerAlign: 'left',
    sortable: false,
    minWidth: 150,
    flex: 2,
  },
  {
    field: 'commission',
    headerAlign: 'right',
    headerName: 'Commission',
    sortable: false,
    type: 'number',
    valueFormatter: ({ value }) => value && `${value} %`,
    minWidth: 125,
    flex: 2,
  },
];

const Commissions: FC<ICommissionsTab> = ({
  commissions,
  userName,
  onReqHandleCommission,
  onReqHandleCommissionPerProject,
}) => {
  const [commissionsModalDisplay, setCommissionsModalDisplay] = useState<boolean>(false);
  const [formatedCommissionsPerProject, setFormatedCommissionsPerProject] = useState<
    IFormattedCommissionsPerProject[]
  >([]);
  const [projects, setProjects] = useState<IOption[]>([]);
  const { pathname } = useLocation();
  const { openToast } = useContext(toastContext);
  const isBroker = pathname.split('/')[2] === 'broker';
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const setProjectNamesController = useAbortRequest();
  const { userData } = useContext(authContext);
  const financialAndMarketingRole = havePermission(userData?.roles ?? [], ['financial', 'marketing', 'superAdmin']);

  const openCommissionsModal = () => {
    setCommissionsModalDisplay(true);
  };

  useEffect(() => {
    if (commissions && Object.values(commissions).length) {
      const commissionsFormated = Object.values(
        Object.entries(commissions.commissionPerProject || {})
      )
        .filter((commission) => projects.find((project) => project.value === commission[0]))
        .map((filteredCommissions) => {
          return {
            id: filteredCommissions[0],
            project:
              projects.find((project) => project.value === filteredCommissions[0])?.label || '',
            commission: filteredCommissions[1].amount,
          };
        });
      setFormatedCommissionsPerProject(commissionsFormated);
    }
  }, [commissions, projects]);

  useEffect(() => {
    (async () => {
      const { data, status } = await getProjectNames(
        { statuses: [PROJECT_STATUSES.DRAFT, PROJECT_STATUSES.ACTIVE, PROJECT_STATUSES.PAUSED, PROJECT_STATUSES.COMPLETED] },
        setProjectNamesController.signal
      );
      if (isRequestError(status)) {
        const error = data as IServerError;
        openToast({
          message: error.message,
          severity: 'error',
          title: (error.title as string) || 'Cannot retrieve projects names',
        });
      } else {
        setProjects(
          (data as IProjectNames[]).map((project: IProjectNames) => {
            return { label: project.name, value: project.id };
          })
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Grid
          item
          container
          direction={isMobile ? 'column' : 'row'}
          spacing={3}
          sx={{ overflow: 'auto' }}
        >
          <Grid item md={3} xs={12}>
            <UserDataCard
              title={'Commission Rate'}
              value={`${commissions?.commissionPercent ?? 0}%`}
              icon={IconChartPie2}
              sxStack={{ height: '56px' }}
              action={
                <Button
                  onClick={openCommissionsModal}
                  disabled={!financialAndMarketingRole}
                  startIcon={<IconEditCircle size={20} />}
                  color="secondary"
                  variant="text"
                >
                  <Typography component="span" variant="caption2">
                    Modify
                  </Typography>
                </Button>
              }
            />
          </Grid>
          <Grid item md={9} xs={12}>
            <CommissionsPerProject
              title={'Commission per Project'}
              projects={projects}
              onSetCommision={onReqHandleCommissionPerProject}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid item container xs={12}>
        <Grid item md={6} xs={12}>
          <Table
            columns={tableColumns}
            rows={formatedCommissionsPerProject}
            pageSize={100}
            rowsPerPageOptions={[100]}
            area={faBrokersAreas.getUserCommissions}
            components={{ NoRowsOverlay: EmptyCommissionsPerProject }}
            getRowId={(row) => formatedCommissionsPerProject.indexOf(row)}
            componentsProps={{
              pagination: {
                backIconButtonProps: { style: { display: 'none' } },
                nextIconButtonProps: { style: { display: 'none' } },
              },
            }}
          />
        </Grid>
      </Grid>
      <CommissionsModal
        title={`${userName}'s`}
        subtitle={`This commission rate will be applied for this specific
          ${isBroker ? ' Broker' : ' Financial Advisor'}.`}
        path={`${isBroker ? ' Broker details' : ' Financial Advisor details'}`}
        currentCommissionsRate={(commissions?.commissionPercent as number) ?? 0}
        display={commissionsModalDisplay}
        onClose={() => setCommissionsModalDisplay(false)}
        handleCommission={onReqHandleCommission}
      />
    </>
  );
};
export { Commissions };
