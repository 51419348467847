import Joi from 'joi';

const verifyTokenSchema = Joi.object({
  name: Joi.string().required().messages({ 'string.empty': 'The name is a required field' }),
  symbol: Joi.string().required().messages({ 'string.empty': 'The symbol is a required field' }),
});

const createTokenSchema = verifyTokenSchema.keys({
  code: Joi.string().required().messages({
    'any.required': 'The code is a required field',
    'string.empty': 'The code is a required field',
  }),
  password: Joi.string().optional(),
});

export { createTokenSchema, verifyTokenSchema };
