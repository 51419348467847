import { Box, Button, SvgIcon } from '@mui/material';
import { FC } from 'react';

// eslint-disable-next-line import/order
import {IFileUploadButton} from './FileUploadButton.d';

export const FileUploadButton: FC<IFileUploadButton> = ({ filesTypes, onUpload, disabled }) => {
  return (
    <Box py={1}>
      <input
        accept={filesTypes}
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={onUpload}
        disabled={disabled}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="outlined"
          component="span"
          disabled={disabled}
          startIcon={
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
              </svg>
            </SvgIcon>
          }
        >
          Upload file
        </Button>
      </label>
    </Box>
  );
}
