import { TextField } from '@mui/material'; //eslint-disable-line
import { FC, forwardRef, useState } from 'react';

import { styles } from './InputField.styled';
import { IInputFieldProps } from './InputField.d';
import { ShowPasswordIcon } from './ShowPassword';

const InputField: FC<IInputFieldProps> = forwardRef(
  ({ type, InputProps = {}, disabledEditable, size, labelVariant, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [inputType, setInputType] = useState<string>(() => type ?? 'text');
    const toogleShowPassword = () => {
      setInputType((currType) => (currType === 'password' ? 'text' : 'password'));
      setShowPassword((state) => !state);
    };

    return (
      <TextField
        size={size}
        InputLabelProps={{ shrink: true }}
        sx={styles(disabledEditable, labelVariant)}
        ref={ref}
        type={inputType}
        InputProps={{
          endAdornment: (
            <ShowPasswordIcon
              type={type}
              showPassword={showPassword}
              toogleShowPassword={toogleShowPassword}
            />
          ),
          ...InputProps,
        }}
        {...rest}
      />
    );
  }
);

export { InputField };
