import { BroadcastChannel } from 'broadcast-channel';
import { useCallback, useEffect, useRef, useState } from 'react';

import { accessTokenStatusObserver, IRefreshTypes, setTokens } from '../../api/main';
import { IAuth, IRefreshResponse, IUserData, refresh } from '../../api/session';
import { decodeJWT } from '../../helpers/decodeJWT';
import { isRequestError } from '../../helpers/isRequestError'; //eslint-disable-line
import { IUseAuth } from './useAuth.d';

const useAuth = (): IUseAuth => {
  const [store, setStore] = useState<IAuth>(() => {
    const item = sessionStorage.getItem('token');
    return item !== null ? JSON.parse(item) : '';
  });
  const [userData, setUserData] = useState<IUserData | undefined>();
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);
  const [accessTokenStatus, setAccessTokenStatus] = useState<IRefreshTypes>();

  const logoutChannel = useRef({
    session: new BroadcastChannel('session'),
    signal: false,
  });

  const setAuth = (value: IAuth) => {
    setStore(value);
    sessionStorage.setItem('token', JSON.stringify(value));
    logoutChannel.current.signal = false;
    setTokenExpired(false);
  };
  const getUser = useCallback(async () => {
    const decodedToken = decodeJWT(store.idToken);
    setUserData({
      email: decodedToken.email,
      firstName: decodedToken.given_name,
      id: decodedToken.sub,
      lastName: decodedToken.family_name,
      roles: decodedToken['cognito:groups'],
      validUntil: decodedToken.exp,
    });
  }, [store]);

  const logout = (signal = false) => {
    setStore((value: IAuth) => ({
      ...value,
      accessToken: '',
      idToken: '',
      refreshToken: '',
    }));
    setUserData(undefined);
    sessionStorage.removeItem('token');
    logoutChannel.current.signal = signal;
    if (signal) {
      logoutChannel.current.session.postMessage('logout');
    }
    setTokenExpired(false);
    setAccessTokenStatus(undefined);
  };

  logoutChannel.current.session.onmessage = () => {
    if (!logoutChannel.current.signal) {
      logout();
    }
  };

  const reqRefreshToken = useCallback(
    async (refreshToken: string) => {
      const { data, status } = await refresh(refreshToken);
      if (!isRequestError(status)) {
        setAccessTokenStatus('active');
        const { accessToken, idToken } = data as IRefreshResponse;
        const authUpdated = { ...store, accessToken, idToken };
        setStore(authUpdated);
        sessionStorage.setItem('token', JSON.stringify(authUpdated));
        setTokens(accessToken, store.expiresIn);
      }
    },
    [store]
  );

  const validateRefreshToken = useCallback(
    (newStatus) => {
      if (newStatus === 'expired') {
        return setTokenExpired(true);
      }
      if (newStatus === 'needToRefresh') {
        return reqRefreshToken(store.refreshToken);
      }
    },
    [reqRefreshToken, store.refreshToken]
  );

  useEffect(() => {
    validateRefreshToken(accessTokenStatus);
  }, [accessTokenStatus, validateRefreshToken]);

  useEffect(() => {
    if (store) {
      setTokens(store.accessToken, store.expiresIn);
    }
  }, [store]);

  useEffect(() => {
    if (store?.idToken && !userData) {
      getUser();
    }
  }, [store, getUser, userData]);

  useEffect(() => {
    accessTokenStatusObserver.observe((newStatus: IRefreshTypes) =>
      setAccessTokenStatus(newStatus)
    );
  }, []);

  return {
    auth: store,
    logout,
    setAuth,
    setTokenExpired,
    tokenExpired,
    userData,
  };
};
export { useAuth };
