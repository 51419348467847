import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker'; //eslint-disable-line

import { ICreateUserData, IUpdateUserData } from '../../components/CreateUserForm';
import { messageDecorator } from '../../helpers/messageDecorator';
import { IReturnError, reportError } from '../../helpers/reportError';
import { IFilterParams } from '../../pages/Users/Users.d';
import { IMessage, internalUserApi, IReturnType } from '../main'; // eslint-disable-line
import { areas } from './areas';
import { usersDecorators } from './decorators';
import { IUsersResponse } from './users.d';

const createUser = async (
  dataUser: ICreateUserData,
  signal: AbortController['signal']
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      internalUserApi.post('/users', dataUser, { signal }),
      areas.reqUser
    );
    return {
      data: messageDecorator(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const updateUser = async (
  id: string,
  dataUser: IUpdateUserData,
  signal: AbortController['signal']
): Promise<IReturnType<IMessage> | IReturnError> => {
  try {
    const res: AxiosResponse<IMessage> = await trackPromise(
      internalUserApi.put(`/users/${id}`, dataUser, { signal }),
      areas.reqUser
    );
    return {
      data: messageDecorator(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

const getUsers = async (
  req: IFilterParams,
  signal: AbortSignal
): Promise<IReturnType<IUsersResponse> | IReturnError> => {
  const params = { ...req };

  try {
    const res: AxiosResponse<IUsersResponse> = await trackPromise(
      internalUserApi.get('/users', { params, signal }),
      areas.get
    );
    return {
      data: usersDecorators(res.data),
      status: res.status,
    };
  } catch (error) {
    return reportError(error);
  }
};

export { createUser, getUsers, updateUser };
