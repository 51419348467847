import { Button, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IconEye } from '@tabler/icons';
import { format } from 'date-fns';
import { FC, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { payoutsAreas } from '../../../api/payouts';
import { Table } from '../../../components/Table';
import { havePermission } from '../../../helpers/havePermission';
import { authContext } from '../../../hooks/useAuth/authContext'; // eslint-disable-line
import { EmptyStateReport } from './EmptyStateReport';
import { Header } from './Header';
import { IPayoutsProps } from './Payouts.d';

const Payouts: FC<IPayoutsProps> = ({ rows, goToCreateReport, disabled, projectName, isLoading }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const { userData } = useContext(authContext);
  const financialRole = havePermission(userData?.roles ?? [], ['financial', 'superAdmin']);
  const financialAndMarketingRole = havePermission(userData?.roles ?? [], ['financial', 'superAdmin', 'marketing']);
  const goToReportDetail = (reportId: string) => () => {
    const reportDate = rows.find((report) => report.reportId === reportId)?.date;
    nav(`/projects/${id}/payouts-report/${reportId}`, {
      state: { date: reportDate, projectName },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'date',
      flex: 1,
      headerName: 'Report date',
      minWidth: 120,
      type: 'date',
      valueGetter: ({ value }) => value && format(new Date(value), 'MM/dd/yyyy'),
    },
    {
      field: 'amountToDistribute',
      flex: 1,
      headerName: 'Amount',
      minWidth: 150,
      type: 'number',
    },
    {
      align: 'left',
      field: 'paidUsers',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Investors paid',
      minWidth: 100,
      renderCell: (params) => (
        <Typography>
          {params.value}/{params.row.totalUsers}
        </Typography>
      ),
      type: 'number',
    },
    {
      field: 'reportId',
      flex: 1,
      headerName: 'Action',
      minWidth: 150,
      renderCell: (params) => (
        <Button
          onClick={goToReportDetail(params.value)}
          disabled={!financialAndMarketingRole}
          size="small"
          variant="text"
          color="secondary"
          startIcon={<IconEye />}
        >
          View details
        </Button>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      {rows.length === 0 && !isLoading ? (
        <EmptyStateReport disabled={disabled || !financialRole} goToCreateReport={goToCreateReport} />
      ) : (
        <Table
          columns={columns}
          rows={rows}
          components={{ Toolbar: Header }}
          componentsProps={{
            noRowsOverlay: { disabled: disabled, goToCreateReport: goToCreateReport },
            toolbar: { disabled: disabled || !financialRole, goToCreateReport },
          }}
          area={payoutsAreas.getPayoutsReportsList}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.reportId}
        />
      )}
    </>
  );
};
export { Payouts };
