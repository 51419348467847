import { Box, Button, Stack } from '@mui/material';
import { FC } from 'react';

import { IActionsButtons } from './ActionsButtons.d';

const ActionsButtons: FC<IActionsButtons> = ({ actions, actionsName }) => {
  return (
    <Box
      px={1}
      pb={2}
      pt={1}
      sx={{ display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'flex-start', width: '100%' }}
      data-testid="test-quick-actions"
    >
      {actions.map(({ icon: Icon, id, name, onClick }) => (
        <Stack alignContent="center" width={72} mx={1} key={`${actionsName}_${id}`}>
          <Button
            onClick={onClick}
            sx={{
              ':hover': { bgcolor: 'transparent', color: 'secondary.main' },
              color: 'text.secondary',
              display: 'flex',
              flexDirection: 'column',
              fontWeight: 300,
              lineHeight: '24px',
            }}
          >
            <Icon />
            {name}
          </Button>
        </Stack>
      ))}
    </Box>
  );
};

export { ActionsButtons };
