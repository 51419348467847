import { Button, Drawer, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { IconCircleX } from '@tabler/icons';
import { FC } from 'react';

import { Breadcrumbs, ILinks } from '../Breadcrumbs'; //eslint-disable-line
import { ICreateProjectModalProps } from '.';

const CreateProjectModal: FC<ICreateProjectModalProps> = ({ isOpen, close, children }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const crumbs: ILinks[] = [
    { active: false, label: 'Home', path: '/' },
    { active: false, label: 'Projects', path: '/projects' },
  ];

  return (
    <Drawer
      open={isOpen}
      anchor={isDesktop ? 'right' : 'bottom'}
      onClose={close}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
      }}
      PaperProps={{ sx: { width: { xs: '100%', lg: 1024 }, height: { xs: '90%', lg: '100%' } } }} //eslint-disable-line
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} px={3}>
        <Breadcrumbs linksList={crumbs} current="Create project" />
        <Button size="small" endIcon={<IconCircleX size={20} />} onClick={close}>
          Close
        </Button>
      </Stack>
      <Typography
        pl={{ xs: 3, md: 11 }} //eslint-disable-line
        variant="h1"
        mt={7}
        mb={5}
      >
        Create project
      </Typography>
      {children}
    </Drawer>
  );
};

export { CreateProjectModal };
