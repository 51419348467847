export const formatDate = (date?: string): string => {
  if (!date) {
    return '';
  }
  const instanceDate = new Date(date);
  const month = instanceDate.getMonth() + 1;
  const fullMonth = month >= 10 ? month : '0' + month;
  const day = instanceDate.getDate();
  const fullDay = day >= 10 ? day : '0' + day;

  return `${fullMonth}/${fullDay}/${instanceDate.getFullYear()}`;
};
