import { Button } from '@mui/material';
import { IconUserPlus } from '@tabler/icons';
import { FC, useContext } from 'react';

import UsersContext, { IUsersContextValue } from '../../pages/Users/context';

export const CreateButton: FC = () => {
  const { setModalCreateUser } = useContext(UsersContext) as IUsersContextValue;

  return (
    <Button
      fullWidth
      variant="contained"
      startIcon={<IconUserPlus />}
      onClick={() => setModalCreateUser({ display: true })}
      data-testid="test-create-user-button"
    >
      Create user
    </Button>
  );
};
