import { Tabs as TabsMUI } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Gray, Primary } from '../../theme/tokens/colors';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  variant: 'standard' | 'scrollable' | 'fullWidth' | undefined;
}

export const Tabs = styled((props: StyledTabsProps) => (
  <TabsMUI
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    display: 'flex',
    height: '3px',
    justifyContent: 'center',
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: Primary[500],
    width: '100%',
  },
  borderBottom: `1px solid ${Gray[300]}`,
  height: '72px',
});
