import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { ICardRow } from './ProjectInvestors.d';
import { StatusPointer } from './StatusPointer';

const CardRow: FC<ICardRow> = ({ name, value, status, warning, lastCard, url }) => {
  return (
    <Stack
      sx={({ palette }) => ({
        borderBottom: !lastCard ? `1px solid ${palette.grey[300]}` : 'none',
      })}
      px={2}
      direction="row"
      alignItems="center"
      role="definition"
    >
      <Typography
        py="12px"
        width="40%"
        fontWeight="bold"
        color="primary"
        fontSize="14px"
        variant="caption"
      >
        {name}:
      </Typography>
      <Stack width="60%" direction="row" alignItems="center">
        {status && <StatusPointer status={status} />}
        <Typography
          component={url ? 'a' : 'span'}
          href={url}
          target={url ? '_blank' : undefined}
          sx={{
            '&:hover': {
              textDecoration: url ? 'underline' : 'none',
            },
            color: warning ? 'error.main' : 'primary',
            ml: status ? 1 : 0,
            overflow: 'hidden',
            textDecoration: 'none',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { CardRow };
