import {
  Box,
  Button,
  Grid,
  List,
  Stack,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IconEye, IconPencil } from '@tabler/icons';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IUserData } from '../../api/session';
import { areas } from '../../api/users/areas';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { CreateUserModal } from '../../components/CreateUserModal';
import { EmptySearch } from '../../components/EmptyStates';
import { MobileTableCard } from '../../components/MobileTableCard';
import { OverlayLoader } from '../../components/OverlayLoader';
import { PageHeader } from '../../components/PageHeader';
import { Paginator } from '../../components/Paginator';
import { StatusPointer } from '../../components/ProjectInvestors';
import { Table } from '../../components/Table';
import { beautifierUserRoles } from '../../helpers/beautifierUserRoles';
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext';
// eslint-disable-next-line import/order
import { TableHeader } from '../Users/tableHeader';
import UsersContext, { IUsersContextValue } from './context';

export const Users: FC = () => {
  const { users, isLoading, filterParams, lastPage, modalCreateUser, setFilterParams, setModalCreateUser } =
    useContext(UsersContext) as IUsersContextValue;
  const authData = useContext(authContext);
  const nav = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const goInvestorDetail = useCallback((id: string) => () => nav(id), [nav]);
  const currentRoles = useMemo(() => (authData?.userData as IUserData)?.roles || [], [authData]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (currentRoles.length) {
      setIsAdmin(havePermission(currentRoles, ['systemAdmin', 'superAdmin']));
    }
  }, [currentRoles]);

  const handleEditUser = (id: string) => {
    setModalCreateUser({
      display: true,
      editUser: id,
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 2,
      headerName: 'Name',
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'email',
      flex: 2,
      headerName: 'Email',
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'roles',
      flex: 2,
      headerName: 'Roles',
      minWidth: 150,
      sortable: false,
      valueFormatter: ({ value }) => value && beautifierUserRoles(value).join(', '),
    },
    {
      field: 'isEnabled',
      flex: 1,
      headerName: 'Status',
      minWidth: 60,
      renderCell: ({ value }) => (
        <Box display="flex" alignItems="center" gap={1}>
          <StatusPointer status={value ? 'success' : 'default'} />
          <Typography noWrap>{value ? 'Active' : 'Inactive'}</Typography>
        </Box>
      ),
      sortable: true,
    },
    {
      field: 'actions',
      flex: 1,
      headerName: 'Actions',
      minWidth: 120,
      renderCell: (params) =>
        isAdmin && (
          <Grid item xs={3} md={2}>
            <Stack direction="row">
              <Button onClick={() => handleEditUser(params.row.id)} color="secondary" size="small">
                <IconPencil size={20} />
                Edit
              </Button>
            </Stack>
          </Grid>
        ),
      sortable: false,
    },
  ];
  const rows = users.map((user) => ({
    actions: 'asdfasd',
    email: user.email,
    id: user.id,
    isEnabled: user.active,
    name: `${user.firstName} ${user.lastName}`,
    roles: user.roles,
  }));

  const mobileRows = useCallback(
    () =>
      users.map((user) => ({
        action: (
          <Button
            fullWidth
            color="secondary"
            size="small"
            onClick={() => handleEditUser(user.id)}
            startIcon={
              <SvgIcon>
                <IconEye />
              </SvgIcon>
            }
          >
            View details
          </Button>
        ),
        data: {
          Email: user.email,
          Name: `${user.firstName} ${user.lastName}`,
          Status: (
            <Box display="flex" alignItems="center" gap={1}>
              <StatusPointer status={user.active ? 'success' : 'default'} />
              {user.active ? 'Active' : 'Inactive'}
            </Box>
          ),
        },
        id: user.id,
      })),
    [users, goInvestorDetail]
  );

  const onSearch = (value: string) => {
    const params = { ...filterParams, page: 1 };
    if (value) {
      params.filter = `${value}`;
      return setFilterParams(params);
    }
    delete params.filter;
    setFilterParams(params);
  };

  const onPageChange = (newPage: number) => {
    const params = { ...filterParams, page: newPage };
    setFilterParams(params);
  };

  return (
    <>
      <Stack>
        <Breadcrumbs current="Users" linksList={[{ label: 'Home', path: '/' }]} />
        <PageHeader users />
        {!isMobile && (
          <Table
            sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
            rows={rows}
            columns={columns}
            paginationMode="server"
            sortingMode={'client'}
            pageSize={10}
            rowCount={10000000}
            rowsPerPageOptions={[10]}
            isLoading={isLoading}
            area={areas.get}
            page={filterParams.page}
            onPageChange={onPageChange}
            pagination
            components={{
              NoResultsOverlay: EmptySearch,
              Toolbar: TableHeader,
            }}
            componentsProps={{
              pagination: {
                backIconButtonProps: { disabled: filterParams.page === 1 ? true : false },
                nextIconButtonProps: { disabled: lastPage },
              },
              toolbar: {
                onSearch,
              },
            }}
          />
        )}
        {isMobile && (
          <>
            <TableHeader
              onSearch={onSearch}
            />
            {isLoading && (
              <Box
                sx={{
                  border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                  borderRadius: 2,
                  minHeight: 300,
                  position: 'relative',
                }}
              >
                <OverlayLoader theme="container" area={areas.get} />
              </Box>
            )}
            {!isLoading && (
              <List>
                {mobileRows().map((row) => (
                  <MobileTableCard key={row.id} data={row.data} action={row.action} />
                ))}
              </List>
            )}
            {!isLoading && users.length === 0 && <EmptySearch />}
            <Paginator
              sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
              setPage={onPageChange}
              limit={10}
              count={10000000}
              page={filterParams?.page ?? 1}
              backIconButtonProps={{ disabled: filterParams.page === 1 ? true : false }}
              nextIconButtonProps={{ disabled: lastPage }}
            />
          </>
        )}
      </Stack>
      <CreateUserModal
        display={modalCreateUser.display}
        onClose={() => setModalCreateUser({ display: false })}
      />
    </>
  );
};
