import { Box, Typography } from '@mui/material'; //eslint-disable-line
import { FC } from 'react'

import { IStatusLabel } from './TransactionsTable.d';

export const StatusLabel: FC<IStatusLabel> = ({ color, children, ...rest }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" { ...rest }>
      <Box height="6px" width="6px" borderRadius="50%" bgcolor={color} flexShrink={0}></Box>
      <Typography ml={1} component="div" variant="body1" fontWeight={300} sx={{ maxWidth: 'calc(100% - 10px)'}}>{ children }</Typography>
    </Box>
  )
}
