import { Box, Stack, Typography } from '@mui/material';
import { IconBriefcase, IconUsers } from '@tabler/icons';
import { FC } from 'react';

import { formatDollars } from '../../helpers/quantityBeautifier'; // eslint-disable-line
import { Gray, Interactive } from '../../theme/tokens/colors';
import { IMetricsProps } from '../CreateProjectSteps';

const AverageInvestment: FC<IMetricsProps> = ({ metrics }) => {
  return (
    <Box
      height={222}
      width={268}
      p={3}
      sx={{
        border: 1,
        borderColor: Gray[300],
        borderRadius: 2,
      }}
      data-testid="average-investment"
    >
      <Typography variant="h3" color={Interactive.secondary}>
        Average investment:
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginBottom: 3, marginTop: 0.5 }}
      >
        <IconBriefcase size={24} color={Gray[700]} style={{ alignSelf: 'center' }} />
        <Typography
          variant="h3"
          component="span"
          color={Gray[800]}
          fontWeight={400}
          data-testid="average-investment-per-investor"
        >
          {formatDollars(metrics?.averageInvestment ?? 0)}
        </Typography>
        <Typography variant="caption" color={Gray[800]}>
          Per investor
        </Typography>
      </Stack>
      <Typography variant="h3" color={Interactive.secondary}>
        Number of investors:
      </Typography>
      <Stack direction="row" spacing={1} alignItems="baseline" sx={{ marginTop: 0.5 }}>
        <IconUsers size={24} color={Gray[700]} style={{ alignSelf: 'center' }} />
        <Typography
          variant="h3"
          component="span"
          color={Gray[800]}
          fontWeight={400}
          data-testid="number-of-investor"
        >
          {metrics?.numberOfInvestors}
        </Typography>
      </Stack>
    </Box>
  );
};

export { AverageInvestment };
