const areas = {
  approvePlan: 'approvePlan',
  createPlan: 'createPlan',
  createTokens: 'createTokens',
  getFinancialReports: 'getFinancialReports',
  getInvestorTokens: 'getInvestorTokens',
  getPayoutsMetrics: 'getPayoutsMetrics',
  getPlan: 'getPlan',
  getProjectTokens: 'getProjectTokens',
  getSmartContractStatus: 'getSmartContractStatus',
  getWallets: 'getWallets',
  setFinancialReport: 'setFinancialReport',
  setMetrics: 'setMetrics',
  verifyTokens: 'verifyTokens',
};

export { areas };
