import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';

import { blockchainAreas, getInvestorTokens } from '../../api/blockchain';
import { getWallets } from '../../api/blockchain/blockchain';
import { areasInvestors } from '../../api/investors';
import {
  editInvestor,
  editInvestorMode,
  getInvestorDetail,
  getTransactions,
} from '../../api/investors/investors';
import { ITransactionsResponse } from '../../api/investors/investors.d';
import { IReturnType } from '../../api/main';
import { getProjectDetail } from '../../api/projects';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import { IInvestor } from '../../components/PageContent';
import { ITransaction } from '../../components/TransactionsTable';
import { isRequestError } from '../../helpers/isRequestError';
import { IReturnError } from '../../helpers/reportError';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { toastContext } from '../../hooks/useToast';
import { IProject } from '../ProjectDetail'; //eslint-disable-line
import { InvestorDetail } from './InvestorDetail';
import { IInvestorProjectTokens, IInvestorTokens, IWallet } from './InvestorDetail.d';

export const InvestorDetailPresenter: FC = () => {
  const { userId } = useParams();
  const [investorDetail, setInvestorDetail] = useState<IInvestor>();
  const [wallet, setWallet] = useState<IWallet>();
  const [isBlockModalOpen, setIsBlockModalOpen] = useState<boolean>(false);
  const toggleBlockModal = () => setIsBlockModalOpen(!isBlockModalOpen);
  const getInvestorDetailController = useAbortRequest();
  const getWalletController = useAbortRequest();
  const getInvestorTokensController = useAbortRequest();
  const blockInvestorController = useAbortRequest();
  const getInvestorTransactions = useAbortRequest();
  const [investorTokens, setInvestorTokens] = useState<IInvestorProjectTokens[]>([]);
  const [holdings, setHoldings] = useState<ITransaction[]>([]);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [pageTransactions, setPageTransactions] = useState(0);
  const [transactionsLimit, setTransactionsLimit] = useState(10);
  const [countTransactions, setCountTransactions] = useState(0);
  const { openToast } = useContext(toastContext);
  const [investorIsEnabled, setInvestorIsEnabled] = useState<boolean>();
  const [previewMode, setPreviewMode] = useState<boolean>(false);

  const addSecondaryMarketHoldings = useCallback(
    (tokens: IInvestorProjectTokens[], transactionsList: ITransaction[]) => {
      if (tokens.length > 0 && transactionsList.length > 0) {
        const secondaryHoldings = tokens.filter((token) => {
          return !transactionsList.some(
            (transaction) => transaction.project.id === token.projectId
          );
        });
        return secondaryHoldings.map((secondaryToken) => ({
          date: null,
          investment: 0,
          project: { id: secondaryToken.projectId, title: secondaryToken.projectTitle },
          status: secondaryToken.status ?? 'active',
          value: secondaryToken.tokens,
        }));
      }
      return [];
    },
    []
  );

  useEffect(() => {
    if (pageTransactions === 0) {
      const externalHoldings = addSecondaryMarketHoldings(investorTokens, transactions);
      setHoldings([...externalHoldings, ...transactions]);
      setCountTransactions((count) => count + externalHoldings.length);
      setTransactionsLimit(10 + externalHoldings.length);
    } else {
      setHoldings([...transactions]);
    }
  }, [investorTokens, transactions, addSecondaryMarketHoldings, pageTransactions]);

  const reqInvestorTransactions = useCallback(async () => {
    const { data, status } = await getTransactions(
      {
        id: userId as string,
        limit: 10,
        page: pageTransactions + 1,
      },
      getInvestorTransactions.signal
    );
    if (!isRequestError(status)) {
      const response = data as ITransactionsResponse;
      setTransactions(response.transactions);
      if (pageTransactions === 0) {
        setCountTransactions(response.count);
      }
    }
  }, [getInvestorTransactions.signal, userId, pageTransactions]);

  const blockUser = async () => {
    const id = investorDetail?.id ?? '';
    const { status } = await editInvestor(id, !investorIsEnabled, blockInvestorController.signal);
    if (!isRequestError(status)) {
      toggleBlockModal();
      setInvestorIsEnabled(!investorIsEnabled);
      openToast({
        message: `This investor will ${
          investorIsEnabled ? 'no longer' : 'now'
        }  have access to the platform.`,
        severity: 'success',
        title: `Investor ${investorIsEnabled ? 'blocked' : 'enabled'} successfully!`,
      });
    }
  };

  const switchPreviewMode = async () => {
    const id = investorDetail?.id ?? '';
    const switchState = !previewMode;
    const { status } = await editInvestorMode(id, switchState, blockInvestorController.signal);
    if (!isRequestError(status)) {
      setPreviewMode(switchState);
      openToast({
        message: `This investor now ${
          switchState ? '' : "don't"
        } have access to the preview mode projects`,
        severity: 'success',
        title: `Investor ${switchState ? 'enabled' : 'disabled'} successfully!`,
      });
    } else {
      openToast({
        message: `Compliance officer role cannot change preview mode on investors.`,
        severity: 'error',
        title: `Action denied!`,
      });
    }
  };

  const reqInvestorDetail = useCallback(async () => {
    const { data, status } = await getInvestorDetail(
      userId as string,
      getInvestorDetailController.signal
    );
    if (!isRequestError(status)) {
      const response = data as IInvestor;
      setInvestorDetail(response);
      setPreviewMode(response['groups']?.includes('previewApproved') as boolean);
    }
  }, [getInvestorDetailController.signal, userId]);

  useEffect(() => {
    const reqWallet = async () => {
      const { data, status } = await getWallets([userId as string], getWalletController.signal);
      if (!isRequestError(status)) {
        setWallet((data as IWallet[])[0]);
      }
    };
    reqWallet();
  }, [getWalletController.signal, userId]);

  const getInvestorProjectTitles = useCallback(
    (data: IInvestorTokens[]): Promise<(IReturnError | IReturnType<IProject>)[]> => {
      const reqProjects: Promise<IReturnType<IProject> | IReturnError>[] = data.map((project) =>
        getProjectDetail(project.projectId, getInvestorTokensController.signal)
      );
      return Promise.all(reqProjects);
    },
    [getInvestorTokensController.signal]
  );

  useEffect(() => {
    const reqInvestorTokens = async () => {
      const { data, status } = await getInvestorTokens(
        userId as string,
        getInvestorTokensController.signal
      );
      if (!isRequestError(status)) {
        const investorTokensRes = data as IInvestorTokens[];
        const investorProjects = await trackPromise(
          getInvestorProjectTitles(investorTokensRes),
          blockchainAreas.getInvestorTokens
        );
        const investorTokensDecorated = investorTokensRes.map(
          (project: IInvestorTokens | IReturnError) => {
            const projectInvestorTokens = project as IInvestorTokens;
            const currentProject = investorProjects.find((p) => {
              const projectId = (p as IReturnType<IProject>).data.projectId;
              return projectId === projectInvestorTokens.projectId;
            });
            if (currentProject) {
              const foundProject = currentProject as IReturnType<IProject>;
              return {
                projectId: foundProject.data.projectId ?? '',
                projectTitle: foundProject.data.projectTitle ?? '',
                status: foundProject.data.status,
                tokens: projectInvestorTokens.tokens,
              };
            } else {
              return { projectId: '', projectTitle: '', tokens: projectInvestorTokens.tokens };
            }
          }
        );
        setInvestorTokens(investorTokensDecorated);
      }
    };
    reqInvestorTokens();
  }, [getInvestorProjectTitles, getInvestorTokensController.signal, userId]);

  useEffect(() => {
    reqInvestorDetail();
    reqInvestorTransactions();
  }, [reqInvestorDetail, reqInvestorTransactions]);

  useEffect(() => {
    setInvestorIsEnabled(investorDetail?.isEnabled);
  }, [investorDetail?.isEnabled]);

  return (
    <>
      <InvestorDetail
        basicData={investorDetail as IInvestor}
        investorIsEnabled={investorIsEnabled}
        toggleBlockModal={toggleBlockModal}
        walletData={wallet as IWallet}
        tokensData={investorTokens}
        transactions={{
          count: countTransactions,
          items: holdings,
          limit: transactionsLimit,
          page: pageTransactions,
          setPage: setPageTransactions,
          tokensData: investorTokens,
        }}
        previewMode={previewMode}
        switchPreviewMode={switchPreviewMode}
      />
      <Dialog
        open={isBlockModalOpen}
        onClose={toggleBlockModal}
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: 5,
            maxWidth: '270px',
            pb: 5,
            position: 'relative',
            pt: 7,
            px: 3,
          },
        }}
      >
        <IconButton
          color="primary"
          onClick={toggleBlockModal}
          sx={{ position: 'absolute', right: 10, top: 10 }}
        >
          <IconX />
        </IconButton>
        <Typography variant="h3">{investorIsEnabled ? 'Block' : 'Enable'} user</Typography>
        <Typography variant="body1" mt={1}>
          Do you want to {investorIsEnabled ? 'block' : 'enable'} this user?
        </Typography>
        <Stack sx={{ mt: 5 }}>
          <Button sx={{ mb: 3 }} color="secondary" onClick={toggleBlockModal}>
            No
          </Button>
          <ButtonSubmit area={areasInvestors.editInvestor} onClick={blockUser}>
            Yes
          </ButtonSubmit>
        </Stack>
      </Dialog>
    </>
  );
};
