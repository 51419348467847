import { Tab as TabMUI } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Gray, Primary } from '../../theme/tokens/colors'; //eslint-disable-line
import { IStyledTabProps } from './Tab.d';

export const Tab = styled((props: IStyledTabProps) => <TabMUI disableRipple {...props} />)(
  ({ theme }) => ({
    '&.Mui-focusVisible': {
      backgroundColor: Primary[100],
    },
    '&.Mui-selected': {
      color: Primary[500],
      fontWeight: 600,
    },
    '&:hover': {
      color: Primary[500],
      opacity: 1,
    },
    color: Gray[700],
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    height: '70px',
    marginRight: theme.spacing(1),
    minWidth: 127,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      minWidth: 120,
    },
  })
);
