import { Box, Stack, Theme, useMediaQuery } from '@mui/material';

// eslint-disable-next-line import/order
import { SearchBox } from '../../components/SearchBox';
import { ITableHeaderProps } from './Users.d';

const TableHeader = ({ onSearch }: ITableHeaderProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Stack
      direction="row"
      p={2}
      justifyContent="space-between"
      flexWrap="wrap"
      gap={2}
      mt={{ xs: 2, md: 0 }} //eslint-disable-line
      sx={{ borderBottom: ({ palette }) => (isMobile ? 'none' : `1px solid ${palette.grey[300]}`) }}
    >
      {/* eslint-disable-next-line */}
      <Box width={{ xs: '100%', sm: '50%' }}>
        <SearchBox
          labelVariant={isMobile ? 'uncontained' : 'contained'}
          onSubmit={onSearch}
          onClear={() => onSearch('')}
        />
      </Box>
    </Stack>
  );
};
export { TableHeader };
