import { FC, useEffect, useState } from 'react';

import { getInvestments } from '../../api/investments';
// import { getInvestments } from '../../api/investments';
import { IInvestmentsResponse } from '../../api/investments/investments.d';
import { IInvestment } from '../../components/PageContent';
import { isRequestError } from '../../helpers/isRequestError';
import { useAbortRequest } from '../../hooks/useAbortRequest'; // eslint-disable-line
import { IFilterParams } from '.'; //eslint-disable-line
import InvestmentsContext, { IInvestmentsContextType } from './context';
import { Investments } from './Investments'; //eslint-disable-line

export const InvestmentsPresenter: FC = () => {
  const [investments, setInvestments] = useState<IInvestment[]>([]);
  const getInvestmentsController = useAbortRequest();
  const [lastPage, setLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    limit: 10,
    page: 1,
    sortBy: 'date:DESC',
  });

  const value: IInvestmentsContextType = {
    filterParams,
    investments,
    isLoading,
    lastPage,
    setFilterParams,
    setInvestments,
  };

  const reqInvestments = async () => {
    setIsLoading(true);
    const { data, status } = await getInvestments(
      { ...filterParams},
      getInvestmentsController.signal
    );
  
    if (!isRequestError(status)) {
      const res = data as IInvestmentsResponse;
      setInvestments(res.items);
      setLastPage(res.lastPage);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    reqInvestments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInvestmentsController.signal, filterParams]);

  return (
    <InvestmentsContext.Provider value={value}>
      <Investments />
    </InvestmentsContext.Provider>
  );
};
