import { Drawer, MenuList, Theme, Toolbar, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

import { HeaderMenuButton } from '../HeaderMenuButton';
import { Logo } from '../Logo';
import { NavbarItem } from '../NavbarItem'; //eslint-disable-line
import { IProps } from './Navbar.d';

const Navbar: FC<IProps> = ({
  openNavbar,
  closeNavbar,
  pathname,
  isDesktop,
  navItems,
  headerHeight,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <Box component="aside" title="navigation bar">
      <Drawer
        variant={isDesktop ? 'persistent' : 'temporary'}
        anchor="left"
        open={openNavbar}
        onClose={closeNavbar}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: {
              xs: 235,
              lg: 207, //eslint-disable-line
              xl: 240,
            },
          },
          flexShrink: 0,
          width: {
            xs: 235,
            lg: 207, //eslint-disable-line
            xl: 240,
          },
        }}
        PaperProps={{
          sx: {
            boxShadow: isMobile
              ? '0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 16px 55px 8px rgba(0, 0, 0, 0.2), 0px 4px 24px -3px rgba(0, 0, 0, 0.15)'
              : 'none',
            boxSizing: 'border-box',
            width: 235,
          },
        }}
      >
        <Toolbar>
          <>
            <HeaderMenuButton
              openNavbar={openNavbar}
              toggleNavbar={closeNavbar}
              headerHeight={headerHeight}
            />
            <Logo />
          </>
        </Toolbar>
        <Box component="nav">
          <MenuList sx={{ py: 2 }} data-testid="test-navbar">
            {navItems.map(({ icon, name, path }) => (
              <NavbarItem
                key={`nav-${name}`}
                icon={icon}
                name={name}
                path={path}
                pathname={pathname}
                closeNavbar={closeNavbar}
              />
            ))}
          </MenuList>
        </Box>
      </Drawer>
    </Box>
  );
};

export { Navbar };
