import { joiResolver } from '@hookform/resolvers/joi';
import { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ILoginRes, login } from '../../api/session';
import { isRequestError } from '../../helpers/isRequestError';
import { IServerError } from '../../helpers/reportError';
import { toastContext } from '../../hooks/useToast'; //eslint-disable-line
import { Login } from './Login';
import { ILoginFormFields } from './Login.d';
import { loginSchema } from './schema';

const LoginPresenter: FC = () => {
  const { openToast, closeToast } = useContext(toastContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: joiResolver(loginSchema),
    shouldFocusError: true,
  });
  const nav = useNavigate();

  const onLogin = async (loginData: ILoginFormFields) => {
    closeToast();
    const { data, status } = await login(loginData);
    if (isRequestError(status)) {
      const error = data as IServerError;
      if (error.message.includes('disabled')) {
        nav(`/error`);
        return;
      }
      if (status === 410) {
        nav('/expired', { state: { email: loginData.email } });
        return;
      }

      openToast({
        message: error.message,
        severity: 'error',
        title: (error.title as string) || 'Something went wrong',
      });
      return;
    }
    const authResponse = data as ILoginRes;
    if (status === 202) {
      nav('/onboarding', { state: { ...loginData } });
      return;
    }
    nav('/twofactor', {
      state: authResponse,
    });
  };

  return <Login errors={errors} submitLogin={handleSubmit(onLogin)} register={register} />;
};

export { LoginPresenter };
